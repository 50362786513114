/* eslint-disable no-bitwise */
export const convertHoursToSeconds = (hour: string): number => {
  const hourParts = hour.split(':');
  const seconds = +hourParts[0] * 60 * 60 + +hourParts[1] * 60 + +hourParts[2];

  return seconds;
};

export const convertSecondsToHours = (seconds: number): string => {
  const hrs = `0${~~(seconds / 3600)}`.slice(-2);
  const mins = `0${~~((seconds % 3600) / 60)}`.slice(-2);
  const secs = `0${~~(seconds % 60)}`.slice(-2);

  return `${hrs}:${mins}:${secs}`;
};
