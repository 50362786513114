import styled from 'styled-components';

export const Container = styled.div``;

export const Welcome = styled.div`
  h1,
  input {
    color: var(--blizzard);
  }
  p {
    color: var(--technologicalGray);
  }
  .search {
    input {
      background-color: transparent;
      border: 1px solid var(--closedGray);
      border-radius: 20px;
      line-height: 33px;
    }
    button {
      right: 10px;
      top: 5px;
      background: transparent;
      border: none;
    }
  }
`;

export const Options = styled.div`
  a {
    color: var(--blizzard) !important;
    text-decoration: none;
  }

  .bg-btn {
    background-color: var(--asphaltGray);
    border: solid 1px var(--greyMineral);
    :focus,
    :hover {
      background-color: var(--greyMineral);
    }
  }
`;
