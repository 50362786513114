import React, { createContext, useCallback, useState, useContext } from 'react';

export interface IMessage {
  id: string;
  user_id: string;
  content: string;
}

export interface IMessagesData {
  data: IMessage[];
  last_page: number;
  total: number;
}

export interface IChat {
  id: string;
  name: string;
  messagesData: IMessagesData;
}

interface ChatsContextData {
  chats: IChat[];
  setChats: React.Dispatch<React.SetStateAction<IChat[]>>;
}

export const ChatsContext = createContext<ChatsContextData>(
  {} as ChatsContextData
);

export const ChatsProvider: React.FC = ({ children }) => {
  const [chats, setChats] = useState<IChat[]>([]);

  return (
    <ChatsContext.Provider value={{ chats, setChats }}>
      {children}
    </ChatsContext.Provider>
  );
};

export function useChats(): ChatsContextData {
  const context = useContext(ChatsContext);

  if (!context) {
    throw new Error('useChats must be used within an ChatsProvider');
  }

  return context;
}
