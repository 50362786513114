import styled from 'styled-components';

export const Container = styled.div`
  p,
  td,
  p > small {
    color: var(--grayImmensity);
  }

  .h-40vh {
    height: 40vh;
  }

  .text-no-certificates {
    color: var(--greyMineral);
    font-weight: 600;
  }

  small,
  h3,
  .price {
    color: var(--graniteGray);
  }

  .radius-20 {
    border-radius: 20px;
  }

  .bg-gray {
    background: var(--midnight);
    border-radius: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: var(--greyMineral);
  }

  .filters {
    background: var(--closedGray);
    z-index: 99;
    border-radius: 12px;
    right: 0;
    bottom: -130px;

    hr {
      width: 75%;
      margin: 0 auto;
      background-color: var(--mysteriousGrey);
    }
  }

  .button-cancel {
    background-color: transparent;
    color: var(--ultraWhite);
    text-decoration: none !important;
    border: 1px solid var(--greyMineral);
    border-radius: 12px;
    :hover {
      background-color: var(--greyMineral);
    }
  }

  button,
  .button {
    cursor: pointer;
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  div {
    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--technologicalGrayOpacity);
    }
  }

  .img-width {
    width: 100%;
    height: 140px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 992px) {
    .img-width {
      width: 12vw;
      height: 10vw;
      max-width: 186px;
      max-height: 170px;
    }
  }
`;
