import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box } from './styles';
import Slider from '~/components/Slider';

import stamp from '~/assets/icons/stamp.svg';

interface ILessonStudent {
  id: string;
  completed: boolean;
}

interface ILesson {
  id: string;
  slug: string;
  lessonStudent: ILessonStudent[];
}

interface ICourseStudent {
  id: string;
  student_id: string;
  locked_down: boolean;
  deleted_at: string;
}

interface ICourseResponse {
  id: string;
  title: string;
  description: string;
  slug: string;
  release_date: string;
  price: string;
  promotion_price: string;
  lessons: ILesson[];
  courseStudent: ICourseStudent[];
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface ICourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
  myCourse: boolean;
}

interface ICoursesCategory {
  category_id: string;
  category_name: string;
}

const CoursesCategory: React.FC<ICoursesCategory> = ({
  category_id,
  category_name,
}) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [courses, setCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    api
      .get<ICourseResponse[]>('courses/students', {
        params: {
          category_id,
        },
      })
      .then((response) => {
        const data = response.data.map((course) => {
          const myCouse = course.courseStudent.find(
            (courseStudent) =>
              courseStudent.student_id === user.id && !courseStudent.deleted_at
          );
          return {
            id: course.id,
            title: course.title,
            description: course.description,
            slug: course.slug,
            thumbnail: {
              title: course.thumbnail ? course.thumbnail.title : '',
              thumbnail_url: course.thumbnail
                ? course.thumbnail.thumbnail_url
                : '',
            },
            myCourse: !!myCouse,
          };
        });

        setCourses(data);
      });
  }, [category_id, user.id]);

  return (
    <Container>
      <div className="container-fluid pt-0 pt-xxl-3 mb-3">
        <div className="row">
          <div className="col-12">
            <h3 className="h5">{category_name}</h3>
          </div>
        </div>
      </div>
      <Slider>
        {courses.map((course) => (
          <div key={course.id} className="col-lg pb-4">
            <Box
              to={`${process.env.PUBLIC_URL}/courses/${
                course.myCourse ? '' : 'preview/'
              }${course.slug}`}
              src={course.thumbnail.thumbnail_url}
              className="d-block p-relative w-100"
            >
              <div className="short-title w-100 p-2 p-absolute">
                <div className="row h-100">
                  <div className="col-12">
                    <p className="h6 h5-xxl mb-0 py-2 pl-1 w-100 mt-2">
                      {course.thumbnail.title}
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="row h-100 align-items-end">
                      <div className="col-12">
                        <div className="see-more-link mb-3">
                          <Link
                            to={`${process.env.PUBLIC_URL}/courses/${
                              course.myCourse ? '' : 'preview/'
                            }${course.slug}`}
                            className="btn h6 px-xl-4 py-1 mt-3 mt-xl-0 mb-0"
                          >
                            {language.dashboard.button}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default CoursesCategory;
