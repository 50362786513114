import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import bgModal from '~/assets/banners/banner-modal.png';
import bg from '~/assets/banners/banner-left-side.png';

export const Modal = styled(ModalComponent)`
  .modal-body {
    padding: 10px;
  }

  .modal-content {
    border-radius: 30px;
    border: none;
  }

  .modal-body {
    padding: 3px;
  }

  .modal-dialog {
    margin: 0;
  }

  .bg-modal {
    background-color: #f5f3ed;
    border-radius: 30px;
  }

  .anim-lottie {
    rotate: 180deg;
  }

  .text-black {
    color: #000;
  }

  .btn-x {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    z-index: 1;
  }

  .bg {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 28px 28px 0 0;
    min-height: 298px;
    img {
      width: 272px;
      height: 62px;
    }
  }

  .positions-last {
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.5);

    background: linear-gradient(
      268deg,
      rgba(0, 0, 0, 0.08) 22.01%,
      rgba(0, 0, 0, 0.41) 89.3%
    );

    backdrop-filter: blur(5px);
    max-width: 360px;
    margin: 0 auto;
  }

  .timer {
    width: 50px;
    span:nth-child(1) {
      color: #fff;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 42px;
      font-weight: 700;
      line-height: 45px;
    }

    span:nth-child(2) {
      color: #fff;
      text-align: center;
      font-family: 'Archivo';
      font-size: 22px;
      font-weight: 300;
      line-height: 35px;
    }
  }

  .img-ticket {
    position: absolute;
    right: -46px;
    top: 6px;
    width: 54px;
    height: 54px;
  }

  .left-side {
    border-radius: 30px;
    background: linear-gradient(
        0deg,
        rgba(255, 174, 82, 0.71) 0%,
        rgba(255, 174, 82, 0.71) 100%
      ),
      #f5f3ed;

    h2 {
      color: #000;

      text-align: center;
      font-family: 'Quicksand';
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      margin-top: 60px;
      margin-bottom: 30px;

      span {
        background-color: #ff1a50;
        color: #fff;
      }
    }
    h3 {
      color: #000;

      text-align: center;
      font-family: 'Quicksand';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.078%;
      letter-spacing: 0.44px;
      margin-bottom: 40px;
    }

    p {
      color: #000;

      font-family: 'Source Sans Pro';
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 118%;
      text-transform: capitalize;
      span {
        color: #ff1a50;
        text-decoration-line: line-through;
      }
      img {
        width: 16px;
        height: 12px;
      }
    }

    h4 {
      color: #000;
      font-family: 'Source Sans Pro';
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 134.078%;
      letter-spacing: 0.56px;
      span {
        color: #ff1a50;
      }
    }

    a {
      color: #3a3a3a;

      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 164.5%;
      letter-spacing: 0.84px;
    }
  }

  .terms {
    color: #3a3a3a;

    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 164.5%; /* 23.03px */
    letter-spacing: 0.84px;
  }

  .right-side {
    p {
      color: #3a3a3a;

      font-family: 'Source Sans Pro';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 118%;
    }
    .personal {
      padding: 10px;
      margin: 0;
      border-radius: 30px;
      h2 {
        color: #000;

        font-family: 'Roboto';
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      h3 {
        color: #000;

        font-family: 'Roboto';
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      h4 {
        color: #000;

        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      p {
        color: #000;

        font-family: 'Roboto';
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 28px;
        span {
          font-weight: 600;
        }
      }

      ul {
        li {
          color: #000;
          font-family: 'Roboto';
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }

    .color-list {
      color: #ff1a50;
    }
  }

  .bg-change-plan {
    position: absolute;
    z-index: 2;
    min-width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    border-radius: 25px;
    background: rgba(0, 0, 0, 0.44);
  }

  .mb-23 {
    margin-bottom: 23% !important;
  }

  .card-choose {
    border-radius: 100px;
    background: #f5f3ed;
    border: 1px solid #f5f3ed;
    width: 90%;
    padding: 5px;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;

    :hover {
      transform: scale(1.1);
      filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.2));
      background: #fff;
    }
    img {
      max-width: 90px;
      max-height: 90px;
    }
    h4 {
      color: #3a3a3a;

      font-family: 'Quicksand';
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 112%;
    }
    p {
      color: #000;

      font-family: 'Quicksand';
      font-size: 24px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 112%;
      text-transform: none;
    }
    span {
      text-align: right;
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 118%;
      text-decoration-line: underline;
    }

    .choose-color {
      color: #32ca8d;
    }
    .current-color {
      color: #ff9c2b;
    }
  }

  .card-change {
    position: absolute;
    bottom: -19%;
    //transform: translateX(-50%);
    left: 5%;
    border-radius: 100px;
    background: #f5f3ed;
    border: 1px solid #f5f3ed;
    width: 90%;
    padding: 5px;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;

    :hover {
      transform: scale(1.1);
    }

    img {
      width: 90px;
      height: 90px;
    }
    h4 {
      color: #3a3a3a;

      font-family: 'Quicksand';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 112%;
    }
    p {
      color: #000;

      font-family: 'Quicksand';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 112%;
      text-transform: none;
    }
    span {
      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 118%;
      text-decoration-line: underline;
    }

    .choose-color {
      color: #32ca8d;
    }
    .current-color {
      color: #ff9c2b;
    }
  }
  .current-plan {
    border: 1px solid #ffae52 !important;
    background-color: #fff;
  }
  .input {
    border-radius: 22px;
    border: 1px solid #ffae52;
    padding: 15px 20px;
    background: #fff;
    .button-show {
      color: #000;
      border-left: 1px solid #ffae52;
    }
  }

  .btn-continue {
    color: #000;

    text-align: center;
    font-family: 'Roboto';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    border-radius: 22px;
    background: #ffae52;
    border: none;
    padding: 11.5px;
    width: 100%;
    margin-top: 30px;
    line-height: 25px;
  }

  .btn-no {
    color: #000;
    border: none;
    background-color: transparent;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .btn-back {
    color: #ff9c2b;

    text-align: right;
    font-family: 'Source Sans Pro';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
  }

  .login {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    a {
      color: #000;
    }
  }

  .total span {
    font-size: 22px !important;
  }

  .imgs-footer {
    justify-content: space-evenly;
  }

  .imgs-footer img:nth-child(1) {
    width: 153px;
    height: 31px;
  }
  .imgs-footer img:nth-child(2) {
    width: 126px;
    height: 31px;
  }
  .imgs-footer img:nth-child(3) {
    width: 98px;
    height: 31px;
  }

  hr {
    margin: 27px 0;
    border-top: 1px solid #ffae52;
  }

  @media screen and (min-width: 576px) {
    .modal-dialog {
      margin: 1.75rem auto;
    }
    .bg-modal {
      border-radius: 30px;
    }
  }

  @media screen and (min-width: 992px) {
    zoom: 0.7;
    .bg-modal {
      background-image: url(${bgModal});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .positions-last {
      max-width: 430px;
    }

    .left-side {
      border-radius: 25px;
      h2 {
        font-size: 40px;
        margin-top: 111px;
      }
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 28px;
      }
      p {
        font-size: 22px;
      }
    }
    .right-side .personal {
      margin: 0 33px;
      padding: 0px;
      h4 {
        font-size: 24px;
      }

      h3 {
        font-size: 32px;
      }

      p {
        font-size: 22px;
      }
    }

    .bg {
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 25px 25px 0 0;
      min-height: 423px;
      img {
        width: 272px;
        height: 62px;
      }
    }

    .imgs-footer img:nth-child(1) {
      width: 186px;
      height: 37px;
    }
    .imgs-footer img:nth-child(2) {
      width: 154px;
      height: 37px;
    }
    .imgs-footer img:nth-child(3) {
      width: 125px;
      height: 37px;
    }

    .card-change {
      padding: 10px;
      width: 80%;
      left: 10%;

      h4 {
        font-size: 18px;
      }
      span {
        font-size: 20px;
      }
    }

    .card-change img {
      width: 110px;
      height: 110px;
    }

    .modal-dialog {
      max-width: 1440px !important;
    }

    .modal-body {
      padding: 10px;
    }

    .card-choose {
      width: 80%;
      padding: 10px;

      h4 {
        font-size: 18px;
      }

      img {
        width: 110px;
        height: 110px;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    zoom: 0.8;
  }
  @media screen and (min-width: 1400px) {
    zoom: 0.9;
  }
  @media screen and (min-width: 1600px) {
    zoom: 1;
  }
`;
