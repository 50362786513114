import { ApexOptions } from 'apexcharts';
import React, { useState, useMemo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';

interface IInstallments {
  status: string;
}

interface IGraphParcel {
  installmentsData: IInstallments[];
}

const GraphParcel: React.FC<IGraphParcel> = ({ installmentsData }) => {
  const [percent, setPercent] = useState('0');
  const { language } = useLanguage();

  useEffect(() => {
    const succeededPaid = installmentsData.filter(
      (installment) => installment.status === 'succeeded'
    );

    const percentData = (succeededPaid.length * 100) / installmentsData.length;
    setPercent(percentData.toFixed(0));
  }, [installmentsData]);

  const series = useMemo(() => {
    let seriesAux = [];

    switch (installmentsData.length) {
      case 2:
        seriesAux = [50, 50];
        break;

      case 3:
        seriesAux = [33.33, 33.33, 33.34];
        break;

      case 4:
        seriesAux = [25, 25, 25, 25];
        break;

      case 5:
        seriesAux = [20, 20, 20, 20, 20];
        break;

      default:
        seriesAux = [100];
        break;
    }
    return seriesAux;
  }, [installmentsData.length]);

  const colors = useMemo(() => {
    const colorsAux = installmentsData.map((installment) => {
      if (installment.status === 'succeeded') {
        return '#37C694';
      }
      return '#343434';
    });

    return colorsAux;
  }, [installmentsData]);

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'donut',
        width: '100%',
        height: '100%',
      },
      plotOptions: {
        pie: {
          startAngle: -120,
          endAngle: 120,
          donut: {
            size: '77%',
          },
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: -40,
          left: 0,
          right: 0,
        },
        borderColor: '#f00',
      },

      tooltip: {
        enabled: false,
      },
      stroke: {
        colors: ['#202020'],
        curve: 'smooth',
        width: 5,
        lineCap: 'round',
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors,
    }),
    [colors]
  );

  return (
    <Container className="mx-auto mb-3">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        className="pointer-events-none"
      />
      <div className="background">
        <div className="circle-around">
          <div className="circle-inside" />
        </div>
        <div>&nbsp;</div>
        <p>{percent}%</p>
        <span>{language.payment_plans.p}</span>
      </div>
    </Container>
  );
};

export default GraphParcel;
