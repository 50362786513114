import styled from 'styled-components';

export const Container = styled.div`
  p,
  td,
  p > small {
    color: var(--grayImmensity);
  }

  small,
  .price {
    color: var(--graniteGray);
  }

  .bg-gray {
    background: var(--midnight);
    border-radius: 20px;
  }

  button,
  .button {
    cursor: pointer;
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  div {
    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--technologicalGrayOpacity);
    }
  }

  table {
    th,
    td {
      padding: 0 10px;
    }

    th {
      font-size: 0.9rem;
    }

    .cardId {
      width: 1%;
    }

    .cardNumber {
      width: 16%;
    }

    .cardExpirationDate {
      width: 15%;
    }

    .cardName {
      width: 15%;
    }

    .cardBillingAddress {
      width: 20%;
    }

    .cardPrimary {
      width: 13%;
    }

    .cardAction {
      width: 1%;
    }
  }

  @media screen and (max-width: 767px) {
    table {
      min-width: 800px;
    }
  }
`;
