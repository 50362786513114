import React, { useState, useEffect } from 'react';

import { Container } from './styles';

import giftCard from '~/assets/defaults/gift-card.png';
import wiserr from '~/assets/logotipos/wiserr-white-logo.svg';
import gift from '~/assets/defaults/gift.png';
import nfc from '~/assets/icons/nfc-icon.svg';

interface ICard {
  value: string;
  presentedBy: string;
  className?: string;
}

const Card: React.FC<ICard> = ({ value, presentedBy, className }) => {
  const [giftValue, setGiftValue] = useState('••••-••••-••••-••••');
  const [giftActive, setGiftActive] = useState(false);

  useEffect(() => {
    const placeHolder = '••••-••••-••••-••••';
    if (value.length > 0) {
      const numcharacters = value.length;
      const newState = `${value}${placeHolder.slice(numcharacters)}`;
      setGiftValue(newState);
    } else {
      setGiftValue(placeHolder);
    }
  }, [value]);

  useEffect(() => {
    setGiftActive(giftValue !== '••••-••••-••••-••••');
  }, [giftValue]);

  return (
    <Container className={className}>
      <img src={giftCard} alt="card" />
      <div className="ReactCreditCard__container">
        <div
          className={`ReactCreditCard ReactCreditCard--${
            giftActive ? 'giftcard' : 'unknown'
          }`}
        >
          <div className="ReactCreditCard__front">
            <div className="wiserr-gift">
              <img src={wiserr} alt="Wiserr Logo" />
              <div />
              <p>Wiserr Gift Card</p>
            </div>
            <div className="nfc">
              <img src={nfc} alt="nfc" />
            </div>
            <div className="ReactCreditCard__lower">
              <img src={gift} alt="visa" className="ReactCreditCard__logo" />
              <div className="ReactCreditCard__number ReactCreditCard__display">
                {giftValue}
              </div>
              <div className="ReactCreditCard__name ReactCreditCard__display ">
                <span>Gifted by</span>
                <br />
                {presentedBy}
              </div>
            </div>
            <div className="spacer" />
          </div>
          <div className="ReactCreditCard__back">
            <div className="ReactCreditCard__bar" />
            <div className="ReactCreditCard__cvc ReactCreditCard__display">
              •••
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Card;
