import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';

interface IOption {
  id: string;
  value: string;
  selected: boolean;
  disabled?: boolean;
}

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  options: IOption[];
  hasError?(hasError: boolean): void;
}

const Input: FC<SelectProps> = ({
  name,
  hasError,
  className,
  options,
  onFocus,
  onBlur,
  ...rest
}) => {
  const { language } = useLanguage();
  const inputRef = useRef<HTMLSelectElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true);
    },
    [onFocus]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <select
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            {...rest}
          >
            <option selected disabled>
              {language.select_component.input}
            </option>
            {options.map((option) => (
              <option
                value={option.id}
                selected={option.selected}
                disabled={option.disabled}
              >
                {option.value}
              </option>
            ))}
          </select>
        </div>
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
};

export default Input;
