import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-day-picker/lib/style.css';

import Routes from '~/routes';
import GlobalStyles from './styles/global';

import AppProvider from './hooks';
import api from './services/api';

interface IColors {
  ultra_white: string;
  blizzard: string;
  granite_gray: string;
  technological_gray: string;
  gray_immensity: string;
  deep_grey: string;
  grey_mineral: string;
  mysterious_grey: string;
  closed_gray: string;
  asphalt_gray: string;
  coal: string;
  midnight: string;
  error: string;
  warning: string;
  success: string;
  skeleton_transparent: string;
  skeleton_quarter: string;
  skeleton_middle: string;
  skeleton_full: string;
}

const promise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string);

const App: React.FC = () => {
  const [colors, setColors] = useState({} as IColors);

  useEffect(() => {
    const { host } = window.location;
    const domainsParts = host.split('.');
    let subDomain = 'wiserr';
    if (domainsParts.length >= 3) {
      [subDomain] = domainsParts;
    }
    api.get(`setups/${subDomain}`).then((response) => {
      setColors(response.data.setupColor);
    });
  }, []);

  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      {Object.keys(colors).length > 0 && (
        <BrowserRouter>
          <AppProvider>
            <Elements stripe={promise}>
              <Routes />
            </Elements>
          </AppProvider>
          <GlobalStyles
            ultraWhite={colors.ultra_white}
            blizzard={colors.blizzard}
            graniteGray={colors.granite_gray}
            technologicalGray={colors.technological_gray}
            grayImmensity={colors.gray_immensity}
            deepGrey={colors.deep_grey}
            greyMineral={colors.grey_mineral}
            mysteriousGrey={colors.mysterious_grey}
            closedGray={colors.closed_gray}
            asphaltGray={colors.asphalt_gray}
            coal={colors.coal}
            midnight={colors.midnight}
            error={colors.error}
            warning={colors.warning}
            success={colors.success}
            skeletonTransparent={colors.skeleton_transparent}
            skeletonQuarter={colors.skeleton_quarter}
            skeletonMiddle={colors.skeleton_middle}
            skeletonFull={colors.skeleton_full}
          />
        </BrowserRouter>
      )}
    </HttpsRedirect>
  );
};

export default App;
