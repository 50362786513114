import styled from 'styled-components';

export const Button = styled.button`
  opacity: 0.75;
  width: 30px;

  svg {
    width: 20px;
    height: 20px;
    color: var(--ultraWhite);
  }

  ::before {
    display: none;
  }

  :hover {
    opacity: 1;
  }
`;
