import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IPropsBox {
  src: string;
  disabled?: boolean;
}

interface IContainerProps {
  background: string;
}

export const Container = styled.div<IContainerProps>`
  background-image: url('${(props) => props.background}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100%;
  position: relative;

  p {
    color: var(--graniteGray);
  }

  .buy-button {
    background-color: var(--deepGrey);
    border: none;
    border-radius: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
    height: 100%;
    align-items: center;
    color: var(--ultraWhite);

    :hover {
      background-color: var(--deepGreyLighten);
      transition: 0.5s;
      text-decoration: none;
    }
  }
  ::before {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      var(--midnightOpacity) 4.26%,
      transparent 100%
    );
    backdrop-filter: blur(35px);
    width: 100%;
    height: 100%;
  }

  .old-price {
    color: var(--error);
  }

  .btn-color {
    border-radius: 15px;

    button {
      background-color: var(--deepGrey);
      padding: 20px 0 !important;
      :hover {
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .button-play {
    background-color: var(--midnight);
    border: none;
    border-radius: 20px;

    :hover {
      background-color: 'var(--midnightDarken)';
      transition: 0.5s;
    }
  }

  .border-top-courses {
    border-top: 2px solid var(--deepGrey) !important;
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Courses = styled.div``;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 298px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};

  .see-more-link {
    button {
      border: 0;
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1800px) {
    height: 298px;
  }
`;

export const Skeleton = styled.div`
  .border-top-courses {
    border-top: 2px solid var(--deepGrey) !important;
  }
  .skeleton-height {
    height: 298px;
  }

  .skeleton-arrow {
    width: 34px;
    height: 34px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
