import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { IoMdArrowUp } from 'react-icons/io';
import { use } from 'video.js/dist/types/tech/middleware';
import { FaExpandAlt } from 'react-icons/fa';
import { Container } from './styles';
import Input from '~/components/Input';

import luna from '~/assets/defaults/luna.png';
import lunaAvatar from '~/assets/defaults/luna-avatar.png';
import stars from '~/assets/defaults/Star-personal.png';
import edit from '~/assets/defaults/Edit-personal.png';
import search from '~/assets/defaults/Search-personal.png';
import game from '~/assets/defaults/Game-personal.png';

interface IBtn {
  onShow(): void;
}

interface IMessage {
  id: number;
  message: string;
  me: boolean;
}

const PersonalTutor: React.FC<IBtn> = ({ onShow }) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [changeMessage, setChangeMessage] = useState<string>('');

  useEffect(() => {
    setMessages([
      {
        id: 1,
        message: 'Test my knowledge about this lesson',
        me: true,
      },
      {
        id: 2,
        message:
          "Sure, here's an example 5-email follow-up sequence that you can use to connect with prospects who showed interest in your AI assistant software:<br><br>Email 1 - Day 1: Subject: Personal AI Assistant Software - Get More Done in Less Time Dear [Prospect's Name],<br><br>Thank you for your interest in our personal AI assistant software! We're excited to show you how it can help you get more done in less time.<br><br>Our software uses the latest AI technology to help you automate your daily tasks, prioritize your to-do list, and keep track of your schedule, all while providing you with personalized recommendations and insights.<br><br>If you're ready to start taking your productivity to the next level, I'd love to schedule a time to give you a demo of our software. Just let me know what works best for you!",
        me: false,
      },
    ]);
  }, []);

  const handleChangeMessage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChangeMessage(e.target.value);
    },
    []
  );

  const handleSendMessage = useCallback(() => {
    setMessages((state) => [
      ...state,
      {
        id: state.length + 1,
        message: changeMessage,
        me: true,
      },
    ]);
    setChangeMessage('');
  }, [changeMessage]);
  return (
    <Container className="row">
      <div className="col-12 d-flex mt-n4 mb-4">
        <button
          type="button"
          onClick={onShow}
          className="expand bg-transparent border-0 ml-auto mr-2"
        >
          <FaExpandAlt size={20} color="#565656" />
        </button>
      </div>
      <div className="col-lg-4">
        <div className="bg-luna">
          <img src={luna} className="w-100" alt="Luna" />
          <div className="desc-luna">
            <span className="d-block">Hey! My name is</span>
            <h3>LUNA</h3>
            <p>
              I’m your study sidekick!, I’m here to help you make the most of
              our this course, feel free ask any questions as you study the
              lessons
            </p>
          </div>
        </div>
      </div>
      <Form onSubmit={() => console.log('submit')} className="col-lg-8 mx-auto">
        <div className="message-side mb-4">
          {false && (
            <div className="d-flex align-items-center h-100">
              <div className="w-25 px-3">
                <button type="button" className="px-3 py-4">
                  <img src={stars} alt="stars" className="mb-3" />
                  <span className="d-block">
                    {' '}
                    Explain this lesson to me with a metaphor
                  </span>
                </button>
              </div>
              <div className="w-25 px-3">
                <button type="button" className="px-3 py-4">
                  <img src={edit} alt="Edit" className="mb-3" />
                  <span className="d-block"> Summarize this lesson for me</span>
                </button>
              </div>
              <div className="w-25 px-3">
                <button type="button" className="px-3 py-4">
                  <img src={search} alt="search" className="mb-3" />
                  <span className="d-block">
                    {' '}
                    Test my knowledge about this lesson
                  </span>
                </button>
              </div>
              <div className="w-25 px-3">
                <button type="button" className="px-3 py-4">
                  <img src={game} alt="game" className="mb-3" />
                  <span className="d-block">
                    {' '}
                    Teach me this lesson as if I were 12
                  </span>
                </button>
              </div>
            </div>
          )}

          {messages.map((message) => (
            <div key={message.id} className={message.me ? 'me' : 'd-flex luna'}>
              {!message.me && (
                <span className="mt-3">
                  <img src={lunaAvatar} width={46} height={46} alt="Luna" />
                </span>
              )}
              <p
                className="p-3"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: message.message }}
              />
            </div>
          ))}
        </div>
        <div className="position-relative">
          <Input
            type="text"
            className="text-ai px-5"
            name="textAi"
            value={changeMessage}
            onChange={handleChangeMessage}
            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Ask me anything...."
          />
          <button
            type="button"
            disabled={!changeMessage}
            onClick={handleSendMessage}
            className="btn-send border-0 rounded-circle"
          >
            <IoMdArrowUp color="#18191A" size={34} />
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default PersonalTutor;
