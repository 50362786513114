import React from 'react';

import { Container } from './styles';

export interface IData {
  id: string | number;
  [key: string]: any;
}

export interface IColumn {
  id?: string | number;
  name: string | number;
  selector: string;
  className?: string;
  cellClassName?: string;
  customCell?: (row: IData, index: number) => React.ReactNode;
}

interface ITableProps {
  data: IData[];
  columns: IColumn[];
  className?: string;
}

const Table: React.FC<ITableProps> = ({ data, columns, className }) => {
  return (
    <Container className={className}>
      <div className="align-items-center text-center py-4 p-relative text-md-left px-4 px-xxl-5">
        <div className="d-none d-md-flex flex-wrap justify-content-xl-around w-100 border-bottom pb-3">
          {columns.map((column) => (
            <>
              <div className={column.className}>
                <p className="h6">{column.name}</p>
              </div>
            </>
          ))}
        </div>
        <div className="flex-wrap justify-content-xl-around w-100">
          {data.map((content, index) => (
            <div className="mt-3" key={content.id}>
              {index > 0 && <hr className="d-md-none" />}
              <div className="d-flex flex-wrap align-items-center">
                {columns.map((column) => (
                  <div className={column.cellClassName}>
                    <div className="d-md-none">
                      <p className="mb-0 font-weight-400">{column.name}</p>
                    </div>
                    <div>
                      {column.customCell ? (
                        column.customCell(content, index)
                      ) : (
                        <p className="mb-0 font-weight-400">
                          {content[column.selector]}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Table;
