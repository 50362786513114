import React, { useEffect, useState } from 'react';
import { setTimeout } from 'timers';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container } from './styles';

const Achievements: React.FC = () => {
  const { language } = useLanguage();
  const [coursesOwned, setCoursesOwned] = useState(0);
  const [certificatesEarned, setCertificatesEarned] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    api.get('courses/students/my').then((response) => {
      setCoursesOwned(response.data.length);
    });

    api.get('certificates').then((response) => {
      setCertificatesEarned(response.data.length);
    });
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-12 col-xxl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xxl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xxl-3">
                  {language.footer.h3_1_1}
                  <br /> {language.footer.h3_1_2}
                </h3>
                <p
                  className={`${
                    loading === true ? 'skeleton' : ''
                  } h1 font-weight-bold mb-0`}
                >
                  {coursesOwned}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-12 col-xxl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xxl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xxl-3">
                  {language.footer.h3_2_1}
                  <br /> {language.footer.h3_2_2}
                </h3>
                <p
                  className={`${
                    loading === true ? 'skeleton' : ''
                  } h1 font-weight-bold mb-0`}
                >
                  {certificatesEarned}
                </p>
              </div>
            </div>
          </div>
          {false && (
            <>
              <div className="col-md-6 col-lg-12 col-xxl-6 px-2">
                <div className="border-gray-achievements mx-auto my-2">
                  <div className="p-3 d-flex d-md-block d-lg-flex d-xxl-block justify-content-between align-items-center">
                    <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xxl-3">
                      {language.footer.h3_1_1}
                      <br /> {language.footer.h3_1_2}
                    </h3>
                    <p
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } h1 font-weight-bold mb-0`}
                    >
                      {coursesOwned}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-12 col-xxl-6 px-2">
                <div className="border-gray-achievements mx-auto my-2">
                  <div className="p-3 d-flex d-md-block d-lg-flex d-xxl-block justify-content-between align-items-center">
                    <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xxl-3">
                      {language.footer.h3_2_1}
                      <br /> {language.footer.h3_2_2}
                    </h3>
                    <p
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } h1 font-weight-bold mb-0`}
                    >
                      {certificatesEarned}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Achievements;
