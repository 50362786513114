import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IStatus {
  overdue: boolean;
}

export const Container = styled.div`
  h2,
  h3 {
    font-size: 18px;
    color: var(--graniteGray);
    font-weight: 400;
  }

  .bg-gray {
    background: var(--midnight);
    border-radius: 20px;
  }

  .bd-gray {
    border: solid 1px var(--closedGray);
    border-radius: 20px;
  }

  .table-skeleton {
    height: 100px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 992px) {
    .img-width {
      width: 12vw;
      height: 10vw;
      max-width: 186px;
      max-height: 170px;
    }
  }

  @media screen and (max-width: 575px) {
    h2 span {
      font-size: 18px !important;
      font-weight: 500;
    }
  }

  @media screen and (min-width: 1200px) {
    height: 81vh;
  }

  @media screen and (min-width: 1600px) {
    .text-1600-right {
      text-align: right !important;
    }

    .d-1600-flex {
      display: flex;
    }
  }
`;

export const Box = styled.div`
  p {
    color: var(--blizzard);
    font-size: 26px;
    font-weight: 500;
  }
  h2 {
    color: var(--technologicalGray);
    font-weight: 400;
    font-size: 18px;
  }

  h3 {
    color: var(--blizzard);
    font-weight: 600;
    font-size: 36px;
  }

  h4 {
    color: var(--blizzard);
    font-weight: 600;
    font-size: 20px;
  }

  .skeleton-graph {
    width: 180px;
    height: 180px;
  }

  .bd-radius-20 {
    background-color: transparent !important;

    div.tableData div {
      background-color: transparent;
    }
  }

  .buy-button {
    background-color: var(--deepGrey);
    border: none;
    border-radius: 18px;

    :hover {
      background-color: var(--deepGreyLighten);
      transition: 0.5s;
    }

    span {
      padding: 12px 0;
      font-weight: 600;
    }
  }

  .search {
    border: 1px solid var(--closedGray);
    border-radius: 20px;

    input {
      border: none;
      background-color: transparent;
      line-height: 33px;
    }
    button {
      right: 10px;
      top: 5px;
      background: transparent;
      border: none;
    }

    svg {
      color: var(--graniteGray);
    }
  }

  span svg {
    color: var(--technologicalGray);
    font-weight: 400;
    font-size: 12px;
  }

  [aria-label] {
    position: relative;
  }

  [aria-label]::after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: -103px;
    left: -100px;
    z-index: 5000;
    pointer-events: none;
    padding: 41px 22px;
    text-decoration: none;
    font-size: 10px;
    width: 218px;
    color: var(--technologicalGray);
    background-color: var(--asphaltGray);
    border-radius: 15px;
    text-align: start;
    filter: drop-shadow(10px 5px 12px var(--midnightOpacity));
  }

  [aria-label]:hover::after {
    display: block;
  }

  .circle {
    color: var(--ultraWhite);
    font-size: 36px;
    font-weight: 500;
    background-color: var(--greyMineral);
    border-radius: 50%;
    height: 78px;
    width: 78px;
  }

  @media screen and (min-width: 992px) {
    .h-lg-343 {
      height: 343px;
    }

    .mg-box {
      margin-bottom: 2rem !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .h-xxl-30 {
      height: 30%;
    }
  }

  @media screen and (min-width: 1600px) {
    .pr-1600-3 {
      padding-right: 1rem !important;
    }

    .pl-1600-3 {
      padding-left: 1rem !important;
    }
  }

  @media screen and (max-width: 1600px) {
    .px-1600-0 {
      padding: 0 0;
    }

    [aria-label]::after {
      left: -110px;
    }
  }

  @media screen and (max-width: 1399px) {
    .circle {
      height: 64px;
      width: 64px;
      font-size: 30px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 22px;
    }

    .circle {
      font-size: 25px;
      height: 50px;
      width: 50px;
    }
  }

  @media screen and (max-width: 575px) {
    p {
      font-size: 20px;
    }
    h3 {
      font-size: 30px;
      font-weight: 400;
    }
    .buy-button {
      border-radius: 15px;

      span {
        padding: 1px 0;
      }
    }
    .circle {
      font-size: 20px;
      height: 40px;
      width: 40px;
    }
  }

  @media screen and (max-width: 400px) {
    [aria-label]::after {
      left: -150px;
    }

    .bd-radius-20 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;

export const Status = styled.span<IStatus>`
  color: ${(props) => (props.overdue ? 'var(--error)' : 'var(--success)')};
  font-weight: 600;

  @media screen and (min-width: 1600px) {
    .d-1600-none {
      display: none !important;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  p {
    color: var(--technologicalGray) !important;
  }
  h3 {
    font-weight: 400 !important;
    color: var(--ultraWhite);
  }
  h2 {
    color: var(--ultraWhite);
  }
  button {
    border: none;
  }

  .bd-card {
    border: 2px solid var(--greyMineral);
    border-radius: 20px;

    h3 {
      color: var(--technologicalGray) !important;
    }
  }
  .modal-content {
    background: var(--asphaltGray) !important;
  }

  .btn-color .buy-button {
    border-radius: 50px !important;
    font-weight: 400;
    padding: 0.5rem 1.5rem !important;
    background-color: var(--ultraWhite) !important;

    :hover {
      background-color: var(--ultraWhiteDarken) !important;
      transition: 0.3s;
    }
    span {
      color: var(--closedGray) !important;
      padding: 8px;
    }

    .info-alert {
      font-size: 14px !important;
    }
  }

  .btn-continue .buy-button {
    border-radius: 50px !important;
    font-weight: 400;
    padding: 0.5rem 1.5rem !important;
    background-color: var(--closedGray) !important;
    :hover {
      background-color: var(--closedGrayLighten) !important;
      transition: 0.3s;
    }
    span {
      color: var(--ultraWhite) !important;
      padding: 8px;
    }

    .info-alert {
      font-size: 14px !important;
    }
  }

  .bg-img {
    background-color: var(--closedGray);
    border-radius: 14px;
  }

  .card-terms {
    background-color: var(--asphaltGray);
    border-radius: 22px;
  }

  /* .height {
    height: 420px;
    p {
      color: var(--graniteGray) !important;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 1.25rem;
    }

    ::-webkit-scrollbar {
      background-color: var(--asphaltGray);
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--deepGrey) !important;
    }
  } */

  @media screen and (max-width: 991px) {
    .buy-button .info-alert {
      font-size: 11px !important;
    }
  }

  @media screen and (max-width: 450px) {
    /* .height {
      height: 300px;
      p {
        font-size: 16px;
        margin-bottom: 1rem;
      }
    } */

    .btn-color .buy-button {
      padding: 0.5rem 0.8rem !important;
    }
    .payment-color {
      text-align: center;
    }
  }
`;
