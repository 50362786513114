import React, { useCallback, useEffect, useState } from 'react';

import { useChats, IChat } from '~/hooks/Chats';
import { useAuth } from '~/hooks/Auth';
import {
  initializeSocketCheckChat,
  initializeSocketCheckChatEvents,
  disconnectSocketCheckChat,
  ICheckChat,
} from '~/services/socketCheckChat';

import { Container } from './styles';
import Chat from './Chat';
import api from '~/services/api';

const FloatChats: React.FC = () => {
  const { chats, setChats } = useChats();
  const { user } = useAuth();
  const [socketInitialized, setSocketInitialized] = useState(false);

  const handleCheckChat = useCallback(
    async (data: ICheckChat) => {
      const response = await api.get(`chats/${data.chat_id}/${user.id}`);
      const responseCoach = await api.get(
        `coaches/students/check-coach/${data.user_id}`
      );
      const nameParts = responseCoach.data.name.split(' ');
      setChats((state) => {
        const newChat: IChat = {
          id: response.data.id,
          name: `${nameParts[0]} ${nameParts[1]}`,
          messagesData: response.data.messagesData,
        };
        return [...state, newChat];
      });
    },
    [setChats, user.id]
  );

  useEffect(() => {
    setSocketInitialized(initializeSocketCheckChat());

    return () => {
      disconnectSocketCheckChat();
    };
  }, []);

  useEffect(() => {
    if (socketInitialized) {
      initializeSocketCheckChatEvents({
        onCheckChat: handleCheckChat,
      });
    }
  }, [handleCheckChat, socketInitialized]);

  const handleRemove = useCallback(
    (chatIndex) => {
      const newChats = chats.slice();
      newChats.splice(chatIndex, 1);
      setChats(newChats);
    },
    [chats, setChats]
  );

  return (
    <Container className="fixed-bottom">
      <div className="d-flex justify-content-end align-items-end">
        {chats.map((chat, index) => (
          <Chat
            key={chat.id}
            data={chat}
            onRemove={() => handleRemove(index)}
          />
        ))}
      </div>
    </Container>
  );
};

export default FloatChats;
