import React, {
  FC,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  hasError?(hasError: boolean): void;
}

const Input: FC<InputProps> = ({
  name,
  hasError,
  className,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }
  }, [error, hasError]);

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setIsFocuses(false);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  return (
    <>
      <Container
        className={className}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <textarea
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            name={name}
            {...rest}
          />
        </div>
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
};

export default Input;
