import styled from 'styled-components';
import { lighten } from 'polished';

import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  height: 100%;

  .max-width {
    max-width: 120px !important;
  }
`;
export const Modal = styled(ModalComponent)`
  .gray-button {
    :hover {
      background-color: ${lighten(0.1, '#4a4b4b')}!important;
      transition: 0.5s;
    }
  }
`;
