/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useLanguage } from '~/hooks/Language';
import { useSetup } from '~/hooks/Setup';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import wiserr from '~/assets/defaults/wiserr.png';
import api from '~/services/api';

interface SignInFormData {
  password: string;
  confirm_password: string;
}

interface IParams {
  token: string;
}

const ChangePassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const params = useParams<IParams>();
  const { language } = useLanguage();
  const {
    setupName,
    logo,
    logoMobile,
    loginBackground,
    socialMedias,
    socialUserName,
  } = useSetup();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string().required(language.change_password.erro_1),
          confirm_password: Yup.string().when('password', {
            is: (password) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              language.change_password.erro_2
            ),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('students/sessions/change-password', {
          token: params.token,
          password: data.password,
        });

        if (response.data) {
          Swal.fire('', language.change_password.success, 'success').then(
            () => {
              history.push(`${process.env.PUBLIC_URL}/`);
            }
          );
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', language.change_password.erro_3, 'error');
        }
      }
    },
    [
      history,
      language.change_password.erro_1,
      language.change_password.erro_2,
      language.change_password.erro_3,
      language.change_password.success,
      params.token,
    ]
  );

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center align-items-center h-100">
          <Background
            src={loginBackground}
            className="d-none d-lg-block col-lg-6 text-center"
          >
            {/* <div className="shade" /> */}
            <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
              <img src={logo} alt="Logo" />
              <div className="text-center w-100">
                <div className="d-flex justify-content-center">
                  {socialMedias.map((socialMedia) => (
                    <a
                      key={socialMedia.link}
                      href={socialMedia.link}
                      target="blank"
                    >
                      <img
                        src={socialMedia.icon}
                        alt="Social Media"
                        className="mx-2"
                      />
                    </a>
                  ))}
                </div>
                <p className="d-block my-2">{socialUserName}</p>
              </div>
            </div>
          </Background>
          {setupName === 'wiserr' && (
            <div className="p-absolute position d-none d-md-block">
              <img src={wiserr} alt="Wiserr" />
            </div>
          )}
          <AnimationContainer className="col-11 col-sm-9 col-lg-6 px-0 px-sm-3 my-5 my-lg-0">
            <div className="align-items-center mb-xl-3">
              <div className="py-5 px-sm-5 px-lg-4 px-xl-5 px-4 shadow login">
                <div className="col-12 text-center d-lg-none mb-4">
                  <img src={logoMobile} alt="logo" className="" />
                </div>
                <h1 className="h5 h4-sm h2-md h4-lg h3-xl h2-xxl mb-0 font-weight-bold text-white text-center text-sm-left">
                  {language.change_password.h1}
                </h1>

                <Form ref={formRef} onSubmit={handleSubmit} className="pt-5">
                  <label htmlFor="password" className="d-block mb-1 small">
                    {language.change_password.label_1}
                  </label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    className=""
                  />
                  <label
                    htmlFor="confirm_password"
                    className="d-block mb-1 mt-4 small"
                  >
                    {language.change_password.label_2}
                  </label>
                  <Input
                    type="password"
                    id="confirm_password"
                    name="confirm_password"
                    className=""
                  />
                  <div className="d-flex align-items-center justify-content-between border-0 flex-wrap flex-lg-nowrap mt-4 bg-transparent">
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      className="small w-100 w-md-25 w-lg-50 mt-2 mt-md-0 text-center text-md-left"
                    >
                      {language.change_password.a}
                    </Link>
                    <button
                      type="submit"
                      className="btn-submit mt-2 w-100 w-lg-50"
                    >
                      <span className="text-white d-block px-4 py-3 font-weight-bold">
                        {language.change_password.button}
                      </span>
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </AnimationContainer>
        </div>
      </div>
    </Container>
  );
};

export default ChangePassword;
