import React from 'react';

import { Container, Content } from './styles';

import logoMenu from '~/assets/logotipos/logo-menu.svg';
import luna from '~/assets/defaults/luna.png';

interface IMessage {
  id: number;
  message: string;
  me: boolean;
}

interface IProps {
  active: boolean;

  onClose(): void;
}

const BgBlurAi: React.FC<IProps> = ({ active, children, onClose }) => {
  return (
    <Container active={active}>
      <Content>
        <div className="row-close d-flex align-items-center justify-content-between">
          <img src={logoMenu} alt="logo" className="logo pl-2 pl-lg-0" />
          <button
            type="button"
            onClick={onClose}
            className="mb-0 btn-close bg-transparent border-top-0 border-left-0 border-right-0 text-white h5 font-weight-normal border-bottom"
          >
            Close
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center mb-5">
          <div className="avatar">
            <img src={luna} width={100} height={100} alt="Luna" />
          </div>
          <span className="ml-3 d-block mb-0 h5 font-weight-normal text-white">
            Hey! My name is
            <span className="d-block display-3 font-weight-normal text-white">
              LUNA
            </span>
          </span>
        </div>
        <div>{children}</div>
      </Content>
    </Container>
  );
};

export default BgBlurAi;
