import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import {
  Container,
  Courses,
  BoxRecents,
  BoxOthers,
  BoxLoading,
  BoxBanner,
} from './styles';
import Slider from '~/components/Slider';
import BannerSlider from '~/components/BannerSlider';

import stamp from '~/assets/icons/stamp.svg';

interface ILessonStudent {
  id: string;
  student_id: string;
  time_watched: string;
  completed: boolean;
  coach_released: boolean;
  answered_quiz: boolean;
}

interface ILesson {
  id: string;
  slug: string;
  answer_quiz: boolean;
  coach_unlock: boolean;
  watch_full_video: boolean;
  lessonStudent: ILessonStudent[];
}

interface ICourseStudent {
  id: string;
  student_id: string;
  last_lesson_slug: string;
  deleted_at: string;
}

interface ICourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  release_date: string;
  lessons: ILesson[];
  courseStudent: ICourseStudent[];
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface IRecents {
  id: string;
  title: string;
  description: string;
  slug: string;
  slugLesson: string;
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
  myCourse: boolean;
}

interface IDashboardProps {
  active: boolean;
}

const Dashboard: React.FC<IDashboardProps> = ({ active }) => {
  const { user } = useAuth();
  const { language, idiom } = useLanguage();
  const [recents, setRecents] = useState<IRecents[]>([]);
  const [courses, setCourses] = useState<IRecents[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<ICourse[]>('courses/students/recents')
      .then(async (response) => {
        const data = response.data.map((course) => {
          const myCourse = course.courseStudent.find(
            (courseStudent) =>
              courseStudent.student_id === user.id && !courseStudent.deleted_at
          );

          let slugLesson = 'introduction';
          if (myCourse) {
            const courseStudentSelected = course.courseStudent.find(
              (courseStudent) => courseStudent.student_id === user.id
            );

            if (courseStudentSelected) {
              slugLesson = courseStudentSelected.last_lesson_slug;
            }
          }

          return {
            id: course.id,
            title: course.title,
            description: course.description,
            slug: course.slug,
            thumbnail: {
              title: course.thumbnail ? course.thumbnail.title : '',
              thumbnail_url: course.thumbnail
                ? course.thumbnail.thumbnail_url
                : '',
            },
            myCourse: !!myCourse,
            slugLesson,
          };
        });

        const responseOthers = await api.get<ICourse[]>(
          'courses/students/others'
        );
        const dataOthers = responseOthers.data.map((course) => {
          const myCourse = course.courseStudent.find(
            (courseStudent) =>
              courseStudent.student_id === user.id && !courseStudent.deleted_at
          );

          let slugLesson = 'introduction';
          if (myCourse) {
            const courseStudentSelected = course.courseStudent.find(
              (courseStudent) => courseStudent.student_id === user.id
            );

            if (courseStudentSelected) {
              slugLesson = courseStudentSelected.last_lesson_slug;
            }
          }

          return {
            id: course.id,
            title: course.title,
            description: course.description,
            slug: course.slug,
            thumbnail: {
              title: course.thumbnail ? course.thumbnail.title : '',
              thumbnail_url: course.thumbnail
                ? course.thumbnail.thumbnail_url
                : '',
            },
            myCourse: !!myCourse,
            slugLesson,
          };
        });

        setCourses(dataOthers);
        setRecents(data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [user.id]);

  return (
    <>
      <Container className={active ? 'd-block container-lg px-1' : 'd-none'}>
        <div className="container-fluid pt-0 pt-lg-3 mb-4 banner-slider">
          <BannerSlider>
            {courses.map((coursesItem) => (
              <div key={coursesItem.id} className="col-lg">
                <BoxBanner
                  src={coursesItem.thumbnail.thumbnail_url}
                  className="d-block p-relative w-100"
                >
                  <div className="short-title w-100 p-2 p-xxl-4 p-absolute">
                    <div className="row h-100">
                      <div className="col-12">
                        <p className="h6 h5-xl h1-xxl font-weight-600 mb-0 py-2 pl-3 w-40 mt-2">
                          {coursesItem.title}
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row h-100 align-items-end">
                          <div className="col-12">
                            <div className="see-more-link mb-3">
                              <Link
                                to={`${
                                  process.env.PUBLIC_URL
                                }/${idiom}/courses/${
                                  coursesItem.myCourse
                                    ? `${coursesItem.slug}/${coursesItem.slugLesson}`
                                    : `preview/${coursesItem.slug}`
                                }`}
                                className="btn h5 px-4 py-1 py-sm-2 ml-3 mt-sm-3 mt-xl-0 mb-0"
                              >
                                {language.dashboard.button}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxBanner>
              </div>
            ))}
          </BannerSlider>
        </div>
        <Courses>
          <div className="container-fluid pt-0 pt-lg-3 mb-3">
            <div className="row">
              <div className="col-12">
                <h3 className="h5">{language.dashboard.h3_1}</h3>
              </div>
            </div>
            <div className={`${loading && 'd-none'}`}>
              <Slider>
                {recents.map((course) => (
                  <div key={course.id} className="col-lg padding-box">
                    <BoxRecents
                      href={`${process.env.PUBLIC_URL}/${idiom}/courses/${
                        course.myCourse
                          ? `${course.slug}/${course.slugLesson}`
                          : `preview/${course.slug}`
                      }`}
                      src={course.thumbnail.thumbnail_url}
                      className="d-block p-relative w-100"
                    >
                      <div className="short-title w-100 p-2 p-xxl-4 p-absolute">
                        <div className="row h-100">
                          <div className="col-12">
                            <p className="h6 h5-xl h4-xxl font-weight-600 mb-0 py-2 pl-3 w-100 mt-2">
                              {course.thumbnail.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    </BoxRecents>
                  </div>
                ))}
              </Slider>
            </div>
            {loading && (
              <div className="row mx-0">
                <BoxLoading className="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3">
                  <div className="d-block p-relative w-100">
                    <div className="loading-recents w-100 p-2 p-xxl-4" />
                  </div>
                </BoxLoading>
                <BoxLoading className="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 d-none d-sm-block">
                  <div className="d-block p-relative w-100">
                    <div className="loading-recents w-100 p-2 p-xxl-4" />
                  </div>
                </BoxLoading>
                <BoxLoading className="col-md-4 col-lg-6 col-xl-4 col-xxl-3 d-none d-md-block d-lg-none d-xl-block">
                  <div className="d-block p-relative w-100">
                    <div className="loading-recents w-100 p-2 p-xxl-4" />
                  </div>
                </BoxLoading>
                <BoxLoading className="col-md-4 col-lg-6 col-xl-4 col-xxl-3 d-none d-md-block d-lg-none d-xl-block">
                  <div className="d-block p-relative w-100">
                    <div className="loading-recents w-100 p-2 p-xxl-4" />
                  </div>
                </BoxLoading>
              </div>
            )}
          </div>
        </Courses>
      </Container>
    </>
  );
};

export default Dashboard;
