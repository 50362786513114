import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IPropsBox {
  src: string;
}

export const Container = styled.div`
  padding-bottom: 20px;

  .content {
    height: calc(100vh - 172px);
    overflow-y: auto;
  }
`;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 185px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1800px) {
    height: 250px;
  }
`;
