import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, BuyButton, Skeleton } from './styles';

interface ITableData {
  id: string;
  thumbail: string;
  name: string;
  courses: string[];
  price: string;
  slug: string;
  purchased: boolean;
  disabled: boolean;
  info: string;
}
interface ICourseProduct {
  id: string;
  course: {
    id: string;
    title: string;
  };
}

interface IProducts {
  id: string;
  thumbnail: {
    thumbnail_url: string;
  };
  name: string;
  courseProduct: ICourseProduct[];
  price: string;
  slug: string;
  mandatory_purchase: string;
  mandatory_gift: boolean;
}
interface IMyProductsProps {
  active: boolean;
  search?: string;
}

interface IOrder {
  id: string;
  product_id: string;
  status: string;
}

const MyProducts: React.FC<IMyProductsProps> = ({ active, search }) => {
  const { user, updateUser } = useAuth();
  const { language, idiom } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ITableData[]>([]);

  const loadProducts = useCallback(async () => {
    try {
      const response = await api.get<IProducts[]>('products/students', {
        params: {
          name: search,
        },
      });
      if (response.data) {
        let disabledAux = false;
        const data = response.data.map((product) => {
          const courses = product.courseProduct.map(
            (courseProduct) => courseProduct.course.title
          );

          let affiliateProduct;
          let disableProduct;
          if (user.affiliate && user.affiliate.affiliatesProducts) {
            affiliateProduct = user.affiliate.affiliatesProducts.find(
              (affiliateProductData) =>
                affiliateProductData.product_id === product.id
            );
            disableProduct = user.affiliate.affiliatesProducts.find(
              (affiliateProductData) =>
                affiliateProductData.product_id === product.mandatory_purchase
            );
          }

          const productFind = response.data.find(
            (productData) => productData.id === product.mandatory_purchase
          );

          let disabled = disabledAux;
          if (!disabledAux) {
            if (product.mandatory_gift) {
              disabled = false;
            } else {
              disabled =
                (!!product.mandatory_purchase && !disableProduct) ||
                !!(
                  product.mandatory_purchase &&
                  !disableProduct &&
                  product.mandatory_gift
                );
            }

            disabledAux = disabled;
          } else if (!product.mandatory_purchase) {
            disabled = false;
          }

          return {
            id: product.id,
            thumbail: product.thumbnail.thumbnail_url,
            name: product.name,
            courses,
            price: product.price,
            slug: product.slug,
            mandatory_purchase: product.mandatory_purchase,
            mandatory_gift: product.mandatory_gift,
            purchased: !!affiliateProduct,
            disabled,
            info: productFind
              ? `${language.requires_purchase.requires_1} ${productFind.name} ${language.requires_purchase.requires_2}`
              : '',
          };
        });

        console.log(data);

        const reponseOrders = await api.get<IOrder[]>('orders', {
          params: {
            filter: 'AllOrders',
          },
        });

        if (reponseOrders.data) {
          data.forEach((product, index) => {
            const myOrder = reponseOrders.data.find(
              (order) => product.id === order.product_id
            );
            if (
              !product.purchased &&
              myOrder &&
              myOrder.status === 'awaiting confirmation'
            ) {
              data[index].disabled = true;
              data[index].info = 'Awaiting confirmation';
            }
          });

          reponseOrders.data.forEach((order) => {
            const productSelected = data.find(
              (product) => order.product_id === product.mandatory_purchase
            );

            if (productSelected) {
              const nextProductSelected = data.find(
                (product) => productSelected.id === product.mandatory_purchase
              );
              const productIndex = data.findIndex(
                (product) => productSelected.id === product.mandatory_purchase
              );

              if (nextProductSelected) {
                if (nextProductSelected.mandatory_gift) {
                  data[productIndex].disabled = false;
                }

                const nextToNextProductSelected = data.find(
                  (product) =>
                    nextProductSelected.id === product.mandatory_purchase
                );

                const nextProductIndex = data.findIndex(
                  (product) =>
                    nextProductSelected.id === product.mandatory_purchase
                );

                if (nextToNextProductSelected) {
                  if (nextToNextProductSelected.mandatory_gift) {
                    data[nextProductIndex].disabled = false;
                  }
                }
              }
            }
          });
        }

        console.log(data);

        setProducts(data);
        setLoading(false);
      }
    } catch (error) {
      Swal.fire('Oops...', language.change_password.erro_3, 'error');
    } finally {
      setLoading(false);
    }
  }, [
    language.change_password.erro_3,
    language.requires_purchase.requires_1,
    language.requires_purchase.requires_2,
    search,
    user.affiliate,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadProducts();
  }, [search, user, loadProducts]);

  const handleSucceededPaid = useCallback(
    async (data) => {
      const affiliateProductsData: any = [];
      let response = await api.get(`products/students/${data.product_id}`);
      if (response.data) {
        let affiliateProductData: any = {
          product_id: response.data.id,
        };
        affiliateProductsData.push(affiliateProductData);
        if (response.data.mandatory_purchase && response.data.mandatory_gift) {
          response = await api.get(`products/students/${data.product_id}`);
          if (response.data) {
            affiliateProductData = {
              product_id: response.data.id,
            };
            affiliateProductsData.push(affiliateProductData);
            if (
              response.data.mandatory_purchase &&
              response.data.mandatory_gift
            ) {
              response = await api.get(`products/students/${data.product_id}`);
              if (response.data) {
                affiliateProductData = {
                  product_id: response.data.id,
                };
                affiliateProductsData.push(affiliateProductData);
                if (
                  response.data.mandatory_purchase &&
                  response.data.mandatory_gift
                ) {
                  response = await api.get(
                    `products/students/${data.product_id}`
                  );
                  if (response.data) {
                    affiliateProductData = {
                      product_id: response.data.id,
                    };
                    affiliateProductsData.push(affiliateProductData);
                  }
                }
              }
            }
          }
        }
      }

      if (user.affiliate) {
        user.affiliate.active_affiliate = true;
        if (user.affiliate.affiliatesProducts) {
          user.affiliate.affiliatesProducts.push(
            ...affiliateProductsData.reverse()
          );
        } else {
          user.affiliate.affiliatesProducts = affiliateProductsData;
        }
      }

      updateUser(user);
      loadProducts();
    },
    [updateUser, user, loadProducts]
  );

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="container-fluid">
          <div className="row">
            {loading && (
              <>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
              </>
            )}
            {products.map((product) => (
              <div
                key={product.id}
                className="col-md-6 col-lg-12 col-xl-6 pb-4"
              >
                <Box
                  src={product.thumbail}
                  className="course-link d-block p-relative thumb w-100"
                >
                  <div className="short-title w-100 p-2 p-lg-4 p-absolute">
                    <div className="row h-100">
                      <div className="col-12 d-flex justify-content-between mt-4">
                        <p className="mb-0 py-2 pl-1 w-75 h5 h4-xxl font-weight-600">
                          {product.name}
                        </p>
                        <p className="mb-0 py-2 pr-1 w-20 h5 h4-xxl text-right text-white font-weight-600">
                          ${product.price}
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row h-100 align-items-end">
                          <div className="col-12">
                            <div className="d-flex see-more-link pb-3">
                              <BuyButton
                                to={`${process.env.PUBLIC_URL}/${idiom}/product/${product.slug}/payment`}
                                className="h6 py-2 px-3 px-sm-4 px-lg-3 px-xl-4 mb-0"
                                disabled={product.disabled || product.purchased}
                              >
                                <span className="d-block">
                                  {product.purchased
                                    ? language.buy_component.button_1
                                    : language.products.button_4}
                                </span>
                                {product.info && product.disabled && (
                                  <span className="info-alert">
                                    {product.info}
                                  </span>
                                )}
                              </BuyButton>
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/${product.slug}`}
                                className="d-flex align-items-center ml-1 ml-xl-4 py-2 px-3 px-sm-4 px-lg-3 px-xl-4 show-courses h6 mb-0"
                              >
                                {language.products.button_3}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default MyProducts;
