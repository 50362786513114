import { io, Socket } from 'socket.io-client';
import { IMessage } from '~/hooks/Chats';

let socket: Socket | undefined;

interface ISocketChat {
  chat_id: string;
  onReceiveNewMessage?(message: IMessage): void;
}

export const initializeSocketChat = (): boolean => {
  socket = io(process.env.REACT_APP_API_URL as string, {
    reconnection: true,
    reconnectionDelay: 500,
  });

  return !!socket;
};

export const initializeSocketChatEvents = ({
  chat_id,
  onReceiveNewMessage,
}: ISocketChat): void => {
  if (socket) {
    socket.on('connect', () => {
      console.log('socket connected');
      socket?.emit('join-room', { roomID: chat_id });
    });

    socket.on('user-disconnected', (userData: any) => {
      console.log('user disconnected-- closing peers', userData);
    });

    socket.on('disconnect', () => {
      console.log('socket disconnected --');
    });

    socket.on('error', (err: any) => {
      console.log('socket error --', err);
    });

    socket.on('new-message', (data: IMessage) => {
      if (onReceiveNewMessage) {
        onReceiveNewMessage(data);
      }
    });
  }
};

export const disconnectSocketChat = (): void => {
  if (socket) {
    socket.disconnect();
    socket = undefined;
  }
};
