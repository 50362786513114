import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, BoxLoading } from './styles';
import stamp from '~/assets/icons/stamp.svg';
import noCourse from '~/assets/icons/no-purchased-course-icon.svg';

import {
  convertHoursToSeconds,
  convertSecondsToHours,
} from '~/utils/convertTime';

interface ILessonStudent {
  id: string;
  student_id: string;
  time_watched: string;
  completed: boolean;
  coach_released: boolean;
  answered_quiz: boolean;
}

interface ILesson {
  id: string;
  slug: string;
  watch_full_video: boolean;
  coach_unlock: boolean;
  answer_quiz: boolean;
  lessonStudent: ILessonStudent[];
  video: {
    duration: string;
  };
}

interface ICourseStudent {
  id: string;
  student_id: string;
  last_lesson_slug: string;
}

interface ICourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  release_date: string;
  lessons: ILesson[];
  courseStudent: ICourseStudent[];
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
  video: {
    duration: string;
  };
}

interface IMyCourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  slugLesson: string;
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface IMyCoursesProps {
  active: boolean;
}

const MyCourses: React.FC<IMyCoursesProps> = ({ active }) => {
  const { user } = useAuth();
  const { language, idiom } = useLanguage();
  const [myCourses, setMyCourses] = useState<IMyCourse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get<ICourse[]>('courses/students/my')
      .then((response) => {
        const data = response.data.map((myCourse) => {
          let slugLesson = 'introduction';
          if (myCourse) {
            const courseStudentSelected = myCourse.courseStudent.find(
              (courseStudent) => courseStudent.student_id === user.id
            );

            if (courseStudentSelected) {
              slugLesson = courseStudentSelected.last_lesson_slug;
            }
          }

          return {
            id: myCourse.id,
            title: myCourse.title,
            description: myCourse.description,
            slug: myCourse.slug,
            slugLesson,
            thumbnail: {
              title: myCourse.thumbnail ? myCourse.thumbnail.title : '',
              thumbnail_url: myCourse.thumbnail
                ? myCourse.thumbnail.thumbnail_url
                : '',
            },
          };
        });
        setMyCourses(data);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [active, user.id]);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="row pt-3">
        <div className="col-12 mb-4">
          <h3 className="h5">{language.my_courses.h3}</h3>
        </div>
        {loading && (
          <>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>

            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
          </>
        )}
        {myCourses.length > 0 ? (
          <>
            {myCourses.map((course) => (
              <div key={course.id} className="col-sm-6 col-xl-4 mb-5">
                <Box
                  to={`${process.env.PUBLIC_URL}/${idiom}/courses/${course.slug}/${course.slugLesson}`}
                  src={course.thumbnail.thumbnail_url}
                  className="course-link d-block p-relative thumb w-100"
                >
                  <div className="short-title w-100 p-4 p-absolute">
                    <div className="row h-100">
                      <div className="col-12 h-title h4-xxl-1800">
                        <p className="mb-0 py-2 px-xxl-4 w-100 h5 h4-xxl mt-4 font-weight-600">
                          {course.thumbnail.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </>
        ) : (
          <div className={`${loading === true ? 'd-none' : ''} col-12`}>
            <div className="row h-40vh align-items-center justify-content-center">
              <div className="col-md-8 text-center text-md-left d-md-flex align-items-center justify-content-center">
                <img
                  src={noCourse}
                  className="mb-3 mb-md-0"
                  alt="No Course Purchased icon "
                />
                <p className="h5 mb-0 pl-md-3 text-no-courses text-center">
                  {language.no_register.p}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default MyCourses;
