import React, { useMemo } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Slider, { Settings } from 'react-slick';

import { Button } from './styles';

interface IButtonProps {
  className?: string;
  onClick?(): void;
}

interface ICarouselProps {
  children: any;
}

const PrevArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  return (
    <Button type="button" className={`${className} h-75`} onClick={onClick}>
      <FaChevronLeft />
    </Button>
  );
};

const NextArrow: React.FC<IButtonProps> = ({ className, onClick }) => {
  return (
    <Button type="button" className={`${className} h-75`} onClick={onClick}>
      <FaChevronRight />
    </Button>
  );
};

const Carousel: React.FC<ICarouselProps> = ({ children }) => {
  const settings: Settings = useMemo(
    () => ({
      className: 'center',
      centerMode: true,
      infinite: children.length > 4,
      slidesToShow: 4,
      centerPadding: '0px',
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: true,
      touchMove: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            centerPadding: '0px',
            infinite: children.length > 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            centerPadding: '0px',
            infinite: children.length >= 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            centerPadding: '0px',
            infinite: children.length >= 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            infinite: children.length >= 1,
          },
        },
      ],
    }),
    [children.length]
  );

  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
