import styled from 'styled-components';

export const Container = styled.div`
  width: 270px;
  height: 200px;
  position: relative;

  .pointer-events-none {
    pointer-events: none;
  }

  .background {
    position: absolute;
    width: 270px;
    height: 170px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .circle-around {
      position: absolute;
      width: 140px;
      height: 140px;
      background: radial-gradient(
        79.65% 79.65% at 17.75% 20.35%,
        var(--closedGray) 0%,
        var(--coal) 100%
      );
      box-shadow: 1px 3px 6px var(--midnightOpacity);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);

      .circle-inside {
        width: 105px;
        height: 105px;
        background: radial-gradient(
          79.65% 79.65% at 17.75% 20.35%,
          var(--asphaltGray) 0%,
          var(--asphaltGray) 12.18%,
          var(--asphaltGray) 100%
        );
        border-radius: 235px;
        transform: rotate(180deg);
      }
    }
    p {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: var(--blizzard);
      margin-bottom: 0;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--technologicalGray);
      margin-bottom: -30px;
    }
  }
`;
