import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import { Container, Profile, Avatar } from './styles';
import Achievements from './Achievements';
import Social from './Social';

import Coach from '~/components/Footer/Coach';
import api from '~/services/api';

interface IParams {
  slug: string;
  slugLesson: string;
}

interface IFooter {
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: React.FC<IFooter> = ({ setShowFooter }) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const params = useParams<IParams>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    if (params.slug) {
      api
        .get(`courses/students/${params.slug}`)
        .then(async (responseCourse) => {
          const response = await api.get(
            `lessons/students/${responseCourse.data.id}/${params.slugLesson}`
          );
          setShowFooter(response.data.coach_button);
        });
      // .catch((err) => {
      //   setShowFooter(false);
      //   try {
      //     if (err.response.data.message !== 'Lesson not found') {
      //       Swal.fire(
      //         'Opss...',
      //         language.error_component.error_message,
      //         'error'
      //       );
      //     }
      //   } catch (error) {
      //     Swal.fire(
      //       'Opss...',
      //       language.error_component.error_message,
      //       'error'
      //     );
      //   }
      // });
    }
  }, [language.error_component.error_message, params, setShowFooter]);

  return (
    <Container className="container px-2 px-lg-3">
      {Object.keys(params).length === 0 ? (
        <div className="mt-3 mt-xxl-5 d-md-flex d-lg-block align-items-center bg-user px-2 py-3 py-xl-4 py-xxl-5">
          <Profile className="text-center w-md-50 w-lg-auto">
            <div className="w-100 p-relative mb-4">
              <Avatar
                src={user.avatar.avatar_url}
                className={loading === true ? 'skeleton' : ''}
              />
            </div>
            <h2 className={`${loading === true ? 'skeleton' : ''} h5 mb-4`}>
              {user.name.trim()}
            </h2>
          </Profile>
          <Achievements />
        </div>
      ) : (
        <div className="d-md-flex d-lg-block">
          {/* <Introduction className="my-3 my-md-0 mx-md-2 mx-lg-0 py-3 py-md-5 py-lg-3 px-3 text-center w-md-50 w-lg-auto">
            <div className="mb-3">
              <img src={warning} alt="Warning" className="d-block mx-auto" />
              <h3 className="h5 mt-4">{language.footer.p_1}</h3>
              <hr />
              <p>
                Mire el video completo, luego marque la casilla a continuación
                para continuar una vez que haya terminado.
              </p>
              <button type="button" className="w-100 py-3 mt-3">
                <span className="d-flex justify-content-center font-weight-bold align-items-center py-1 h6 small mb-0">
                  {language.footer.button}
                  <img src={arrowRight} alt="Arrow Right" className="ml-2" />
                </span>
              </button>
            </div>
            <div />
          </Introduction> */}
          {/* <Coach /> */}
        </div>
      )}
      <Social />
    </Container>
  );
};

export default Footer;
