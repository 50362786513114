import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import BinancePay from '~/components/BinancePay';
import TetherPay from '~/components/TetherPay';
import CreditPay from '~/components/CreditCardPay';
import GiftPay from '~/components/GiftCardPay';

import closeIcon from '~/assets/icons/close.svg';

interface IBuyButtonProps {
  order_id?: string;
  product_id: string;
  title: string;
  description?: string;
  price: string;
  totalPrice?: string;
  className?: string;
  purchased?: boolean;
  disabled?: boolean;
  info?: string;
  installments?: number;
  anticipated?: boolean;
  onSucceededPaid?(data?: any): void;
}

const BuyButton: React.FC<IBuyButtonProps> = ({
  order_id,
  product_id,
  title,
  price,
  totalPrice,
  className,
  purchased,
  disabled,
  info,
  installments,
  anticipated,
  onSucceededPaid,
  children,
}) => {
  const { language } = useLanguage();
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(async () => {
    setShow(true);
  }, []);

  const handleSucceededPaid = useCallback(
    (data) => {
      if (onSucceededPaid) {
        onSucceededPaid(data);
      }
      handleClose();
    },
    [handleClose, onSucceededPaid]
  );

  return (
    <Container className={className}>
      <button
        type="button"
        className="w-100 buy-button h-100 pd-button py-2 px-4 px-lg-3 px-xl-4 px-xxl-5"
        onClick={handleShow}
        disabled={disabled || purchased}
      >
        <span className="d-block">
          {purchased ? (
            language.buy_component.button_1
          ) : (
            <>
              {children && children.toString().length > 0
                ? children
                : language.buy_component.button_2}
            </>
          )}
        </span>
        {info && disabled && <span className="info-alert">{info}</span>}
      </button>

      <Modal
        className="modal-cripto-method px-0"
        centered
        size="xl"
        show={show}
      >
        <Modal.Header className="border-0 p-3">
          <Modal.Title className="text-center w-100" />
          <button type="button" className="close-button" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          <div className="col-12">
            <h3 className="h5 h4-sm text-center payment-color mb-5">
              {language.buy_product_button.h3}
            </h3>
          </div>
          <div className="row justify-content-center my-lg-5 pt-lg-5">
            <div className="col-sm-8 col-lg-5 col-xl-3 pb-lg-3 pb-xl-0">
              <BinancePay
                order_id={order_id}
                price={price}
                product_id={product_id}
                title={title}
                totalPrice={totalPrice}
                installments={installments}
                onSucceededPaid={handleSucceededPaid}
                anticipated={anticipated}
              />
            </div>
            <div className="col-sm-8 col-lg-5 col-xl-3 pb-lg-3 pb-xl-0 mt-4 mt-lg-0">
              <TetherPay
                order_id={order_id}
                price={price}
                product_id={product_id}
                title={title}
                totalPrice={totalPrice}
                installments={installments}
                anticipated={anticipated}
              />
            </div>
            <div
              className={`col-sm-8 col-lg-5 col-xl-3 pt-lg-3 pt-xl-0 mt-4 mt-lg-0 ${
                location.pathname.match('/profile/payment-plans') ||
                location.pathname.match('/product')
                  ? 'd-none'
                  : ''
              }`}
            >
              <CreditPay
                product_id={product_id}
                onSucceededPaid={handleSucceededPaid}
              />
            </div>
            <div className="col-sm-8 col-lg-5 col-xl-3 pt-lg-3 pt-xl-0 mt-4 mt-lg-0">
              <GiftPay
                order_id={order_id}
                product_id={product_id}
                price={price}
                onSucceededPaid={handleSucceededPaid}
                anticipated={anticipated}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  );
};

export default BuyButton;
