import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  @media screen and (min-width: 1400px) {
    .col-xxl-4 {
      max-width: 33.33% !important;
    }
  }
`;

export const Welcome = styled.div`
  h1,
  input {
    color: var(--blizzard);
  }
  p {
    color: var(--technologicalGray);
  }
  .search {
    border: 1px solid var(--closedGray);
    border-radius: 20px;

    input {
      border: none;
      background-color: transparent;
      line-height: 33px;
    }
    button {
      right: 10px;
      top: 5px;
      background: transparent;
      border: none;
    }
  }
`;

export const Options = styled.div`
  a {
    color: var(--blizzard) !important;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }

  .bg-btn {
    background-color: var(--asphaltGray);
    border: solid 1px var(--greyMineral);
    :focus {
      background-color: var(--greyMineral);
    }
    :hover {
      background-color: var(--greyMineral);
    }
  }
`;

export const BookBanner = styled.div`
  .thumb {
    border-radius: 20px;
    text-decoration: none;
  }

  .see-more-link {
    a {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;

      :hover {
        color: var(--blizzardLighten);
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .thumb-link {
    background-image: url('https://cdn.wiserr.io/promotional-stuff/el-atajo-del-milionario-full-hd.png');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    height: 292px;
  }

  a {
    height: 100%;
    border-radius: 20px;

    .short-title {
      padding: 50px;
      bottom: 0;
      background: linear-gradient(
        284.04deg,
        transparent 0%,
        var(--midnightOpacity) 100%
      );
      border-radius: 20px 20px 20px 20px;
      height: 100%;

      p {
        color: var(--ultraWhite);
      }
    }
  }
  @media screen and (max-width: 575px) {
    .thumb-link {
      background-position: 82%;
    }
  }
  @media screen and (max-width: 524px) {
    a {
      .short-title {
        padding: 35px 50px;
      }
    }
  }
`;
