import styled from 'styled-components';

export const Container = styled.div`
  background: var(--coal);
  border-radius: 20px;

  hr {
    width: 75%;
    margin: 0.5em auto 1em;
    border-top: 1px solid var(--grayImmensity);
  }

  .border-bottom {
    border-bottom: solid 1px var(--closedGray) !important;

    p {
      color: var(--graniteGray);
    }
  }
`;
