import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IContainerProps {
  active: boolean;
}

interface IPropsBox {
  src: string;
}

export const Container = styled.div<IContainerProps>`
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  left: 0;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  z-index: 100;

  .logo {
    width: 45px;
    height: 31px;
    position: relative;
    top: -56px;
    left: -27px;
  }
`;

export const Content = styled.div`
  padding: 50px;
  padding-top: 6rem;
  width: 100%;
  min-height: 100%;
  fill: rgba(0, 0, 0, 0.65);

  backdrop-filter: blur(50px);
  //border-top-left-radius: 20px;
  //border-top-right-radius: 20px;
`;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 17vw;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-duration: 0.3s;
  :hover {
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.45),
      0px 15px 64px -45px rgba(199, 197, 197, 0.42);
    transform: scale(1.11);
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--blizzard) !important;
    }
  }
`;
