import styled from 'styled-components';

interface ICoursesSearchProps {
  searchActive: boolean;
}

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  h1 {
    position: relative;
    z-index: 1001;
  }

  .search-index {
    position: relative;
    z-index: 1001;
  }
  .container-lg {
    max-width: 1600px !important;
  }
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    span {
      color: transparent !important;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Welcome = styled.div`
  padding: 20px 20px 0;

  h1,
  input {
    color: var(--blizzard);
  }

  h1 {
    span {
      transition-duration: 0.3s;
      visibility: hidden;
      opacity: 0;
    }

    span.active {
      visibility: visible;
      opacity: 1;
    }
  }

  p {
    color: var(--technologicalGray);
  }

  .search {
    input {
      background-color: transparent;
      border: 1px solid var(--closedGray);
      border-radius: 10px;
      line-height: 33px;
      height: 38px;
    }
    button {
      right: 15px;
      top: 5px;
      background: transparent;
      border: none;
    }
  }

  @media screen and (min-width: 1740px) {
    .pad-1740 {
      padding: 0 0.2rem !important;
    }
  }
`;

export const Options = styled.div`
  button:not(.options-button) {
    color: var(--blizzard) !important;
  }

  .bg-btn {
    background-color: var(--asphaltGray);
    border: solid 1px #2f2f2f;
    text-decoration: none;
    font-size: 13px;
    border-radius: 10px;

    :focus {
      background-color: var(--greyMineral);
    }

    :hover {
      background-color: var(--greyMineral);
    }
  }

  @media screen and (max-width: 767px) {
    .options-button {
      transition-duration: 0.3s;
      background: var(--midnight);
      color: var(--graniteGray);
      border-radius: 20px;

      svg {
        transition-duration: 0.3s;
        color: var(--graniteGray);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: var(--midnight);
      color: var(--technologicalGray);
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;

        + .bg-btn {
          border-top: solid 1px var(--closedGray);
        }
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 300px;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: var(--coal);
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: var(--asphaltGray);
        }
      }
    }
  }
`;

export const CoursesSearch = styled.div<ICoursesSearchProps>`
  width: 100%;
  top: 0;
  min-height: 100vh;
  background-color: var(--coal);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: ${(props) => (props.searchActive ? '20px' : '10vh')};
  opacity: ${(props) => (props.searchActive ? 1 : 0)};
  visibility: ${(props) => (props.searchActive ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  z-index: 100;
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 45px;
  height: 45px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px var(--blizzardOpacity);
  box-sizing: border-box;
`;
