import styled from 'styled-components';

export const Container = styled.div`
  .buy-button {
    background-color: var(--deepGrey);
    border: none;
    border-radius: 20px;

    :hover {
      background-color: var(--deepGreyLighten);
      transition: 0.5s;
    }

    :disabled {
      background-color: var(--deepGreyDarken);
      color: var(--blizzardOpacity);
      opacity: 0.5;
      cursor: not-allowed;
    }

    .info-alert {
      display: block;
      background-color: var(--deepGrey);
      position: absolute;
      border-radius: 50px;
      top: -55px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition-duration: 0.3s;
      z-index: 1;
      height: 50px;
      min-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      :after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        transform: rotateZ(45deg);
        background: var(--deepGrey);
        bottom: -3px;
        left: 15%;
        z-index: -1;
      }
    }

    :hover {
      .info-alert {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
