/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import { useSetup } from '~/hooks/Setup';
import api from '~/services/api';

import { Container, Menu } from './styles';
import logoMenu from '~/assets/logotipos/logo-menu.svg';
import en from '~/assets/icons/eua-flag.png';
import es from '~/assets/icons/spain-flag.png';

const Header: React.FC = () => {
  const { user, signOut } = useAuth();
  const { language, handleSelectIdiom, idiom } = useLanguage();
  const { logo } = useSetup();
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [lang] = useState(() => {
    const langSymbol = location.pathname.substr(0, 3);
    if (langSymbol !== '/en' && langSymbol !== '/es') {
      return ``;
    }

    return langSymbol;
  });

  useEffect(() => {
    setActive(false);
  }, [location]);

  const handleClick = useCallback(
    async (url) => {
      const token = localStorage.getItem('@Membership:token');
      const userLocal = localStorage.getItem('@Membership:user');
      const data = JSON.stringify([token, userLocal]);
      const response = await api.post('affiliates/sessions/set-data', {
        student_id: user.id,
        data,
      });
      if (response.data) {
        window.location.href = `${url}${lang}/check-login/${response.data.token}`;
      }
    },
    [lang, user.id]
  );

  return (
    <Container className="w-100 vh-lg-100 d-flex flex-lg-column justify-content-between justify-content-lg-start align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img
            src={logoMenu}
            alt="logo"
            className="mt-lg-4 pl-2 pl-lg-0 w-75"
          />
        </Link>
      </div>
      <Menu active={active} className="h-lg-100 w-auto w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="h-lg-100 d-flex flex-column p-absolute p-lg-relative menu-group overflow active">
          <div className="px-4 px-lg-0 h-lg-100 d-flex flex-column py-5 py-lg-1 py-xl-3 menu">
            <Link
              to={`${process.env.PUBLIC_URL}${lang}/dashboard`}
              className={`menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/dashboard.*/) ? 'active' : ''
              }`}
            >
              <div className="dashboard d-none d-lg-block" />
              <small>{language.header.small_1}</small>
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}${lang}/profile/my-profile`}
              className={`menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/profile.*/) ? 'active' : ''
              }`}
            >
              <div className="profile d-none d-lg-block" />
              <small>{language.header.small_2}</small>
            </Link>

            <Link
              to={`${process.env.PUBLIC_URL}${lang}/resources/downloads`}
              className={`menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/resources.*/) ? 'active' : ''
              }`}
            >
              <div className="resources d-none d-lg-block" />
              <small>{language.header.small_3}</small>
            </Link>

            {user.affiliate && user.affiliate.franchise_owner && (
              <>
                {false && (
                  <Link
                    to={`${process.env.PUBLIC_URL}${lang}/products/discover`}
                    className={`products-link menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                      location.pathname.match(/\/products.*/) ? 'active' : ''
                    }`}
                  >
                    <div className="products d-none d-lg-block" />
                    <small>{language.header.small_4}</small>
                  </Link>
                )}
              </>
            )}
            {user.affiliate && user.affiliate.active_affiliate && (
              <>
                {false && (
                  <button
                    type="button"
                    onClick={() =>
                      handleClick(process.env.REACT_APP_AFFILIATES_URL)
                    }
                    className="menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center border-0"
                  >
                    <div className="affiliates d-none d-lg-block" />
                    <small>{language.header.small_6}</small>
                  </button>
                )}
              </>
            )}
            {user.affiliate && !user.affiliate.franchise_owner && (
              <>
                {false && (
                  <Link
                    to={`${process.env.PUBLIC_URL}${lang}/become-affiliate`}
                    className={`become-affiliate-link menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                      location.pathname.match(/\/become-affiliate.*/)
                        ? 'active'
                        : ''
                    }`}
                  >
                    <div className="become-affiliate d-none d-lg-block" />
                    <small>{language.header.small_5}</small>
                  </Link>
                )}
              </>
            )}

            {false && (
              <Link
                to={`${process.env.PUBLIC_URL}${lang}/faq`}
                className={`menu-item px-2 px-lg-0 py-2 py-xl-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                  location.pathname.match(/\/faq.*/) ? 'active' : ''
                }`}
              >
                <div className="faq d-none d-lg-block" />
                <small>{language.header.small_7}</small>
              </Link>
            )}
          </div>
          <div className="px-4 px-lg-0 d-flex flex-column pb-3 py-lg-3 logoff">
            <div className="d-flex justify-content-lg-center flex-lg-column">
              <button
                type="button"
                className={`${
                  idiom === 'en' ? 'flag-text-light' : ''
                } min-height flag-text border-0 bg-transparent menu-item pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                onClick={() => handleSelectIdiom('en')}
              >
                <img
                  src={en}
                  alt="EN"
                  className={`${
                    idiom === 'en' ? 'active-idiom' : ''
                  } mr-1 mr-lg-0`}
                />
                <span className="d-none d-lg-block">
                  {language.header.button_1}
                </span>
              </button>
              <button
                type="button"
                className={`${
                  idiom === 'es' ? 'flag-text-light' : ''
                } min-height flag-text border-0 bg-transparent menu-item mt-0 mt-xxl-2 pb-lg-3 pb-xxl-4 w-lg-100 w-lg-auto d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0`}
                onClick={() => handleSelectIdiom('es')}
              >
                <img
                  src={es}
                  alt="ES"
                  className={`${
                    idiom === 'es' ? 'active-idiom' : ''
                  } mr-1 mr-lg-0`}
                />
                <span className="d-none d-lg-block">
                  {language.header.button_2}
                </span>
              </button>
            </div>
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 my-5 my-lg-0 menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout" />
              <small>{language.header.small_8}</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
