import React, { useState, useEffect } from 'react';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, Skeleton } from './styles';

import openLink from '~/assets/icons/open-link-icon.svg';

interface IResources {
  id: string;
  name: string;
  link: string;
  resource_url: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface IResourceProps {
  active: boolean;
  search?: string;
}

const Resource: React.FC<IResourceProps> = ({ active, search }) => {
  const { language } = useLanguage();
  const [resources, setResources] = useState<IResources[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get('resources', {
        params: {
          type: 'Links',
          search,
        },
      })
      .then((response) => {
        setResources(response.data);
        setLoading(false);
      });
  }, [search]);

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="row">
          {loading && (
            <>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
              <Skeleton className="col-md-6 col-xxl-4 pb-4">
                <div className="skeleton w-100" />
              </Skeleton>
            </>
          )}
          {resources.map((resource) => (
            <div key={resource.id} className="col-md-6 col-xxl-4 pb-4">
              <Box
                href={resource.link}
                src={resource.thumbnail.thumbnail_url}
                className="course-link d-block p-relative thumb w-100"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="short-title w-100 px-5 px-lg-4 px-xl-5 py-xl-5 py-4 p-absolute">
                  <div className="row h-100">
                    <div className="col-12">
                      <p className="mb-0 w-100 h5">{resource.name}</p>
                    </div>
                    <div className="col-12">
                      <div className="row h-100 align-items-end">
                        <div className="col-12">
                          <div className="see-more-link">
                            <a
                              href={resource.link}
                              className="btn h6 px-4 py-1 py-sm-2 mb-0"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {language.resources.button_3}
                              <img
                                src={openLink}
                                alt="Open Link"
                                className="ml-2"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Resource;
