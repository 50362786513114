import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
    border-radius: 8px;
    background-color: transparent;
  }
  .ReactCreditCard__container {
    margin: 0 auto;
  }

  label {
    color: var(--grayImmensity);
    font-size: 0.9rem;
  }

  .radius-10 {
    border-radius: 10px !important;
  }

  .flags {
    top: 10px;
    right: 20px;
  }

  .form-control {
    border-color: var(--closedGray) !important;
  }

  .credit-number {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: var(--closedGray) !important;
  }

  .credit-date,
  .credit-cvc,
  .credit-zip-code {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: var(--closedGray) !important;
  }
  .credit-date {
    border-bottom-right-radius: 0;
  }
  .credit-cvc {
    border-bottom-left-radius: 0;
    border-left: 0;
  }

  @media screen and (max-width: 375px) {
    .ReactCreditCard__container {
      width: 100%;
      max-width: 315px;
    }

    .flags-xs {
      right: 4px !important;
    }
  }

  @media screen and (max-width: 350px) {
    .ReactCreditCard__container {
      left: -25px;
    }
  }
`;
