import styled from 'styled-components';

export const Container = styled.div`
  background: var(--asphaltGray);
  border-radius: 20px;

  h3 {
    color: var(--technologicalGray);
    font-weight: 300 !important;
  }

  p {
    color: var(--blizzard);
  }

  .border-gray-achievements {
    padding: 2px;
    color: var(--blizzard);
    background-image: linear-gradient(0deg, var(--coal), var(--coal)),
      radial-gradient(circle at top left, var(--closedGray), var(--closedGray));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 15px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;

    border: none !important;
    color: transparent !important;
    pointer-events: none;
    box-shadow: none;
    span {
      color: transparent !important;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 2040px) {
    .p-2400 {
      padding: 1.5rem 2rem !important;
    }
  }

  @media screen and (min-width: 2300px) {
    .p-2400 {
      padding: 1.5rem 2.7rem !important;
    }
  }
`;
