import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FiChevronDown, FiArrowUpRight } from 'react-icons/fi';
import { BiEditAlt } from 'react-icons/bi';
import { Form } from '@unform/web';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FormHandles } from '@unform/core';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import { useLanguage } from '~/hooks/Language';
import { Container, Welcome, Options, Avatar } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import MyProfile from './MyProfile';
import SubscriptionsCards from './SubscriptionsCards';
import Subscriptions from './Subscriptions';
import Purchases from './Purchases';
import Receipts from './Receipts';
import PaymentOptions from './PaymentOptions';
import PaymentPlans from './PaymentPlans';
import { useAuth, User } from '~/hooks/Auth';
import profilePhoto from '~/assets/defaults/photo-profile.png';
import InputDate from '~/components/InputDate';
import InputMask from '~/components/InputMask';

// import menu from '~/assets/icons/menu-icon.svg';

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface IUser {
  name: string;
  email: string;
  username: string;
  phone: string;
  referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  password?: string;
  confirm_password?: string;
  old_password?: string;
  address?: IAddress;
}

const Profile: React.FC = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [saveAvatar, setSaveAvatar] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [saveExperts, setSaveExperts] = useState(false);
  const [avatarData, setAvatarData] = useState<File | null>(null);
  const [userData, setUserData] = useState({} as IUser);
  const [avatar, setAvatar] = useState(user.avatar.avatar_url || profilePhoto);
  const [birth, setBirth] = useState(new Date(1900, 0, 1));
  const [phoneDetails, setPhoneDetails] = useState('');
  const [userName, setUserName] = useState(user.username);
  const [addressDetails, setAddressDetails] = useState('');
  const [experts, setExperts] = useState('');
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(9);
    }
    return location.pathname.slice(12);
  });
  const [optionMobile, setOptionMobile] = useState(language.profile.button_1);
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });

  useEffect(() => {
    const data = {
      name: user.name,
      email: user.email,
      username: user.username,
      phone: user.phone,
      referral_code: user.referral_code,
      email_notifications: user.email_notifications,
      sms_notifications: user.sms_notifications,
      address: user.address as any,
    };

    if (user.address) {
      setAddressId(user.address.id);
      setZipCode(user.address.zip_code);
      setCountry(user.address.country);
      setStreet(user.address.street);
      setNumber(user.address.number);
      setNeighborhood(user.address.neighborhood);
      setState(user.address.state);
      setCity(user.address.city);
      setComplement(user.address.complement);
    }

    setUserData(data);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    switch (optionSelected) {
      case 'my-purchases':
        setOptionMobile(language.profile.button_2);
        break;
      case 'subscriptions':
        setOptionMobile('Suscripciones');
        break;
      case 'payment-options':
        setOptionMobile(language.profile.button_3);
        break;
      case 'payment-plans':
        setOptionMobile(language.profile.button_5);
        break;
      case 'receipts':
        setOptionMobile(language.profile.button_4);
        break;
      default:
        setOptionMobile(language.profile.button_1);
        break;
    }
  }, [language.profile, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(9);
    } else {
      page = location.pathname.slice(12);
    }

    const box = document.querySelector('.options-box');
    if (box) {
      box.classList.remove('active');
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  const handleChange = useCallback((e) => {
    const file = e.target.files[0];
    setSaveAvatar(true);
    setAvatarData(file);
    setAvatar(URL.createObjectURL(file));
  }, []);

  const handleChangeUserName = useCallback((e) => {
    setSaveDetails(true);
    setUserName(e.target.value);
  }, []);

  const handleChangePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPhoneDetails(e.target.value);
    setSaveDetails(true);
  }, []);

  const handleChangeDateBirth = useCallback((e) => {
    setSaveDetails(true);
    setBirth(e);
  }, []);

  const handleChangeAddress = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setAddressDetails(e.target.value);
      setSaveDetails(true);
    },
    []
  );

  const handleChangeExperts = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setExperts(e.target.value);
      setSaveExperts(true);
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: IUser) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          // name: Yup.string().required(language.my_profile.erro_1),
          // email: Yup.string().required(language.my_profile.erro_2),
          username: Yup.string().required(language.my_profile.erro_3),
          phone: Yup.string().required(language.my_profile.erro_4),
          // referral_code: Yup.string().required(language.my_profile.erro_5),
          // email_notifications: Yup.boolean(),
          // sms_notifications: Yup.boolean(),
          // password: Yup.string(),
          // confirm_password: Yup.string().when('password', {
          //   is: (password) => password && password.length > 0,
          //   then: Yup.string().oneOf(
          //     [Yup.ref('password'), undefined],
          //     language.my_profile.erro_7
          //   ),
          // }),
          // old_password: Yup.string().when('password', {
          //   is: (password) => password && password.length > 0,
          //   then: Yup.string().required(language.my_profile.erro_8),
          // }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let avatar_id: string;
        let avatar_url: string;
        if (avatarData) {
          const avatarFormData = new FormData();
          avatarFormData.append('avatar', avatarData as File);
          const responseAvatar = await api.post('avatars', avatarFormData);

          if (responseAvatar.data) {
            avatar_url = responseAvatar.data.avatar_url;
            avatar_id = responseAvatar.data.id;
            await api.patch(`students/profile/avatar`, {
              avatar_id,
            });
          } else {
            avatar_id = user.avatar.id;
            avatar_url = avatar;
          }
        } else {
          avatar_id = user.avatar.id;
          avatar_url = avatar;
        }

        const {
          // name,
          // email,
          username,
          phone,
          // referral_code,
          address,
          // old_password,
          // password,
          // confirm_password,
        } = data;

        const formData = {
          avatar_id,
          customer_id: user.customer_id,
          // name,
          // email,
          username,
          phone,
          // referral_code,
          address,
          // email_notifications: emailNotification,
          // sms_notifications: smsNotification,
        };

        const response = await api.put<User>('students/profile', formData);
        if (response.data) {
          let addressData: any;

          Swal.fire(
            language.my_profile.success_1,
            language.my_profile.success_2,
            'success'
          ).then(() => {
            const profileData = {
              id: response.data.id,
              avatar_id,
              customer_id: response.data.customer_id,
              name: response.data.name,
              email: response.data.email,
              username: response.data.username,
              phone: response.data.phone,
              referral_code: response.data.referral_code.toLowerCase(),
              email_notifications: response.data.email_notifications,
              sms_notifications: response.data.sms_notifications,
              avatar: {
                id: avatar_id,
                avatar_url,
              },
              address: addressData,
              affiliate: user.affiliate,
            };

            updateUser(profileData);
          });
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            language.error_component.error_message,
            'error'
          ).then(() => setLoading(false));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      language.my_profile.erro_3,
      language.my_profile.erro_4,
      language.my_profile.success_1,
      language.my_profile.success_2,
      language.error_component.error_message,
      avatarData,
      user.customer_id,
      user.avatar.id,
      user.affiliate,
      avatar,
      updateUser,
    ]
  );

  return (
    <Container className="py-3 py-lg-0">
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2 mb-4 text-center text-md-left">
                      {language.profile.h1}
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <Form
                ref={formRef}
                initialData={userData}
                onSubmit={handleSubmit}
              >
                <div className="container-fluid">
                  <div className="row">
                    <div className="mb-3 mb-xl-0 col-xl-3 px-2 pb-4">
                      <div className="bg-gray h-100">
                        <div className="row">
                          <div className="col-md-4 col-xl-12 pr-lg-0">
                            <div className="d-flex align-items-center mt-3 pt-4 px-4 border-custom">
                              <label
                                htmlFor="avatar"
                                className="p-relative py-3 mr-3 mb-0"
                              >
                                <Avatar
                                  src={loading !== true ? avatar : ''}
                                  className={
                                    loading === true
                                      ? 'skeleton rounded-circle'
                                      : ''
                                  }
                                />
                                {/* <img
                                  src={profilePhotoAdd}
                                  alt="profilePhotoAdd"
                                  className={`${
                                    loading === true ? 'd-none' : ''
                                  } p-absolute profile-photo-icon`}
                                /> */}
                              </label>
                              <div className="name">
                                <p className="mb-0">{user.name}</p>
                                <span>Free Member</span>
                              </div>
                              <Input
                                type="file"
                                id="avatar"
                                name="avatar"
                                className="d-none"
                                onChange={handleChange}
                              />
                            </div>
                            {saveAvatar && (
                              <button
                                type="submit"
                                className="d-flex mx-auto btn-save"
                              >
                                Save Avatar
                              </button>
                            )}
                            <hr className="mx-3" />
                          </div>
                          <div className="col-12">
                            <div className="d-flex">
                              <h3
                                className={`${
                                  loading === true ? 'skeleton' : ''
                                } my-4 px-3`}
                              >
                                {language.my_profile.h3_1}
                              </h3>
                              {saveDetails ? (
                                <button
                                  type="submit"
                                  className="ml-auto btn-save"
                                >
                                  save
                                </button>
                              ) : (
                                <BiEditAlt
                                  color="#828282"
                                  size={20}
                                  className="my-4"
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-8 col-xl-12">
                            <div className="row contact-info">
                              <div className="col-md-6 col-xl-12 mb-1">
                                <div className="d-flex align-items-center px-3">
                                  <label
                                    htmlFor="username"
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } mb-0 w-40 py-2`}
                                  >
                                    {language.my_profile.label_2}
                                  </label>
                                  <Input
                                    id="username"
                                    name="username"
                                    onChange={handleChangeUserName}
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } py-2 border-color w-60 border-0`}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-xl-12 mb-1">
                                <div className="d-flex align-items-center px-3">
                                  <label
                                    htmlFor="phone"
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } mb-0 w-40 py-2`}
                                  >
                                    {language.my_profile.label_3}
                                  </label>
                                  <InputMask
                                    kind="custom"
                                    placeholder="(000) 000-00000"
                                    options={{
                                      mask: '(999) 999-99999',
                                    }}
                                    onChange={handleChangePhone}
                                    id="phone"
                                    name="phone"
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } py-2 bg-transparent w-60 border-0`}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-xl-12 mb-1">
                                <div className="d-flex align-items-center px-3">
                                  <label
                                    htmlFor="date_birth"
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } mb-0 w-40 py-2`}
                                  >
                                    Date of Birth
                                  </label>
                                  <InputDate
                                    name="date_birth"
                                    onChange={handleChangeDateBirth}
                                    dateFormat="MM/dd/yyyy"
                                    selected={birth}
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } bg-transparent border-0`}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-xl-12 mb-1">
                                <div className="d-flex px-3 mt-2">
                                  <label
                                    htmlFor="address"
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } mb-0 w-40 py-2`}
                                  >
                                    Address
                                  </label>
                                  {/* <Textarea
                                    id="address"
                                    name="address"
                                    value={streetData}
                                    onChange={handleChangeAddress}
                                    rows={2}
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } py-2 border-color w-60 border-0`}
                                  /> */}
                                  <div className="py-2 address border-color w-60 border-0">{`${streetData}, ${numberData} - ${neighborhoodData} - ${cityData} - ${stateData} / ${countryData}`}</div>
                                </div>
                                <hr className="mx-3" />
                              </div>
                              <div className="col-12">
                                <div className="px-3">
                                  <div className="d-flex">
                                    <h3 className="mr-3">Assigned Experts</h3>
                                    {saveExperts ? (
                                      <button
                                        type="submit"
                                        className="ml-auto mb-2 btn-save"
                                      >
                                        save
                                      </button>
                                    ) : (
                                      <BiEditAlt
                                        color="#828282"
                                        size={20}
                                        className=""
                                      />
                                    )}
                                  </div>
                                  <Textarea
                                    id="experts"
                                    name="experts"
                                    rows={2}
                                    onChange={handleChangeExperts}
                                    className={`${
                                      loading === true ? 'skeleton' : ''
                                    } px-0 py-2 border-0`}
                                  />
                                </div>
                                <hr className="mx-3" />
                                <div className="col-12">
                                  <div className="">
                                    <h3 className="my-4">
                                      Latest Activity
                                      <FiArrowUpRight
                                        color="#828282"
                                        size={25}
                                      />
                                    </h3>
                                    <div className="d-flex">
                                      <div className="pr-2">
                                        <span className="bg-doc d-flex align-items-center">
                                          <IoDocumentTextOutline
                                            size={20}
                                            color="#6F6E6E"
                                          />
                                        </span>
                                        <div className="v-line mx-auto my-3" />
                                      </div>
                                      <div className="p-activity">
                                        <p>
                                          <span>Jane Admin</span> added new file{' '}
                                          <span>Signed contract</span>
                                        </p>
                                        <span className="time">1 day ago</span>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="pr-2">
                                        <span className="bg-doc d-flex align-items-center">
                                          <IoDocumentTextOutline
                                            size={20}
                                            color="#6F6E6E"
                                          />
                                        </span>
                                        <div className="v-line mx-auto my-3" />
                                      </div>
                                      <div className="p-activity">
                                        <p>
                                          <span>Jane Admin</span> added new file{' '}
                                          <span>Signed contract</span>
                                        </p>
                                        <span className="time">1 day ago</span>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div className="pr-2">
                                        <span className="bg-doc d-flex align-items-center">
                                          <IoDocumentTextOutline
                                            size={20}
                                            color="#6F6E6E"
                                          />
                                        </span>
                                        <div className="v-line mx-auto my-3" />
                                      </div>
                                      <div className="p-activity">
                                        <p>
                                          <span>Jane Admin</span> added new file{' '}
                                          <span>Signed contract</span>
                                        </p>
                                        <span className="time">1 day ago</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-11 col-md-12 col-xl-9 mx-3 mx-md-0 py-4">
                      <div className="options-box d-lg-flex align-items-center justify-content-between justify-content-md-center justify-content-lg-between justify-content-xl-start text-center text-lg-left">
                        <button
                          type="button"
                          className="d-flex d-md-none justify-content-between align-items-center py-1 py-lg-3 px-3 w-100 border-0 options-button"
                          onClick={handleClickBox}
                        >
                          <p className="w-100 mb-0 bg-btn rounded-pill m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-lighter">
                            {optionMobile}
                          </p>
                          <FiChevronDown size={25} />
                        </button>
                        <div className="options d-md-flex justify-content-space-evenly align-items-center w-100">
                          <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-profile`}
                              activeClassName="active"
                            >
                              {language.profile.button_1}
                            </NavLink>
                          </div>
                          <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}${langSelected}/profile/my-purchases`}
                              activeClassName="active"
                            >
                              {language.profile.button_2}
                            </NavLink>
                          </div>
                          <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}${langSelected}/profile/payment-plans`}
                              activeClassName="active"
                            >
                              {language.profile.button_5}
                            </NavLink>
                          </div>
                          {false && (
                            <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                              <NavLink
                                to={`${process.env.PUBLIC_URL}${langSelected}/profile/subscriptions`}
                                activeClassName="active"
                              >
                                Suscripciones
                              </NavLink>
                            </div>
                          )}
                          <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}${langSelected}/profile/payment-options`}
                              activeClassName="active"
                            >
                              {language.profile.button_3}
                            </NavLink>
                          </div>
                          <div className="bg-btn w-75 w-lg-auto mx-auto py-2 p-lg-4">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}${langSelected}/profile/receipts`}
                              activeClassName="active"
                            >
                              {language.profile.button_4}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      {optionSelected === 'my-profile' && (
                        <MyProfile active={optionSelected === 'my-profile'} />
                      )}
                      {optionSelected === 'my-purchases' && (
                        <Purchases active={optionSelected === 'my-purchases'} />
                      )}
                      {optionSelected === 'payment-plans' && (
                        <PaymentPlans
                          active={optionSelected === 'payment-plans'}
                        />
                      )}
                      {optionSelected === 'subscriptions-cards' && (
                        <SubscriptionsCards
                          active={optionSelected === 'subscriptions-cards'}
                        />
                      )}
                      {optionSelected === 'subscriptions' && (
                        <Subscriptions
                          active={optionSelected === 'subscriptions'}
                        />
                      )}
                      {optionSelected === 'payment-options' && (
                        <PaymentOptions
                          active={optionSelected === 'payment-options'}
                        />
                      )}
                      {optionSelected === 'receipts' && (
                        <Receipts active={optionSelected === 'receipts'} />
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </Options>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
