import React, { useCallback, useState, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { useHistory, useLocation, NavLink } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Options, Avatar } from './styles';

import Dashboard from './Dashboard';
import Discover from './Discover';
import MyCourses from './MyCourses';
import Upcoming from './Upcoming';
import Certificates from './Certificates';
import DiscoverNew from './DiscoverNew';
import CoursesSearch from '~/components/CoursesSearch';

const Home: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const userName = user.name.split(' ');

  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(11) || 'dashboard';
    }
    return location.pathname.slice(14) || 'dashboard';
  });
  const [optionMobile, setOptionMobile] = useState(language.courses.button_1);
  const [search, setSearch] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  useEffect(() => {
    switch (optionSelected) {
      case 'discover':
        setOptionMobile(language.courses.button_2);
        break;
      case 'my-courses':
        setOptionMobile(language.courses.button_3);
        break;
      case 'upcoming':
        setOptionMobile(language.courses.button_4);
        break;
      case 'certificates':
        setOptionMobile(language.courses.button_5);
        break;
      case 'discover-new':
        setOptionMobile(language.courses.button_2);
        break;
      default:
        setOptionMobile(language.courses.button_1);
        break;
    }
  }, [language.courses, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(11) || 'dashboard';
    } else {
      page = location.pathname.slice(14) || 'dashboard';
    }

    const box = document.querySelector('.options-box');
    if (box) {
      box.classList.remove('active');
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setSearchActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (!search.length) {
      setSearchActive(false);
    }
  }, [search]);

  return (
    <Container className="py-3 py-lg-0 mt-n4">
      <div className="container-lg">
        <div className="row mt-3 mt-xl-4">
          <div className="col-12 p-0">
            <Welcome>
              <div
                className={
                  optionSelected === 'dashboard' ||
                  optionSelected === 'discover-new'
                    ? 'container-lg px-0'
                    : 'container-lg px-0'
                }
              >
                <div className="row justify-content-center justify-content-md-start justify-content-lg-center">
                  <div className="col-md-10 col-lg-12 px-md-2">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-xxl-3">
                        <h1 className="h2 pl-1 pl-lg-5 text-center text-md-left mb-0">
                          <span
                            className={
                              searchActive || search.length > 0 ? 'active' : ''
                            }
                          >
                            {search}
                          </span>
                          <span
                            className={
                              searchActive || search.length > 0 ? '' : 'active'
                            }
                          >
                            {language.courses.h1} {userName[0].trim()}!
                          </span>
                        </h1>
                      </div>
                      <div className="col-xxl-6">
                        <Options>
                          <div className="row justify-content-center">
                            <div className="col-11 col-lg-12 mx-lg-3 px-3 px-lg-0 py-3">
                              <div className="row justify-content-center text-center">
                                <div className="options-box col-12">
                                  <button
                                    type="button"
                                    className="d-flex d-md-none justify-content-between align-items-center py-3 px-3 w-100 border-0 options-button"
                                    onClick={handleClickBox}
                                  >
                                    <p className="w-100 mb-0 bg-btn m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-lighter">
                                      {optionMobile}
                                    </p>
                                    <FiChevronDown size={25} />
                                  </button>
                                  <div className="options d-md-flex justify-content-around  justify-content-xl-center my-0 col-12 px-4 px-lg-0">
                                    <NavLink
                                      to={`${process.env.PUBLIC_URL}${langSelected}/dashboard`}
                                      className={`${
                                        loading === true ? 'skeleton' : ''
                                      } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                      activeClassName={
                                        optionSelected === 'dashboard' &&
                                        loading === false
                                          ? 'active'
                                          : ''
                                      }
                                    >
                                      <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                        {language.courses.button_1}
                                      </span>
                                    </NavLink>

                                    <NavLink
                                      to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/discover`}
                                      className={`${
                                        loading === true ? 'skeleton' : ''
                                      } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                      activeClassName="active"
                                    >
                                      <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                        {language.courses.button_2}
                                      </span>
                                    </NavLink>

                                    <NavLink
                                      to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/my-courses`}
                                      className={`${
                                        loading === true ? 'skeleton' : ''
                                      } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                      activeClassName="active"
                                    >
                                      <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                        {language.courses.button_3}
                                      </span>
                                    </NavLink>

                                    <NavLink
                                      to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/upcoming`}
                                      className={`${
                                        loading === true ? 'skeleton' : ''
                                      } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                      activeClassName="active"
                                    >
                                      <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                        {language.courses.button_4}
                                      </span>
                                    </NavLink>

                                    <NavLink
                                      to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/certificates`}
                                      className={`${
                                        loading === true ? 'skeleton' : ''
                                      } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                      activeClassName="active"
                                    >
                                      <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                        {language.courses.button_5}
                                      </span>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Options>
                      </div>
                      <div className="search-index col-lg-5 col-xxl-3 text-center align-items-center d-flex justify-content-end">
                        <div
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } p-relative search w-75`}
                        >
                          <input
                            type="text"
                            className={` w-100 px-3`}
                            id="search"
                            placeholder={
                              loading === true ? '' : language.courses.input
                            }
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                          <button
                            type="submit"
                            className={`${
                              loading === true ? 'd-none' : ''
                            } p-absolute`}
                          >
                            <FaSearch color="#8c8c8c" />
                          </button>
                        </div>
                        <Avatar
                          src={user.avatar?.avatar_url}
                          className={loading === true ? 'skeleton' : ''}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
            <div className="p-relative">
              {optionSelected === 'dashboard' && (
                <Dashboard active={optionSelected === 'dashboard'} />
              )}
              {optionSelected === 'discover' && (
                <Discover active={optionSelected === 'discover'} />
              )}
              {optionSelected === 'my-courses' && (
                <MyCourses active={optionSelected === 'my-courses'} />
              )}
              {optionSelected === 'upcoming' && (
                <Upcoming active={optionSelected === 'upcoming'} />
              )}
              {optionSelected === 'certificates' && (
                <Certificates active={optionSelected === 'certificates'} />
              )}
              {optionSelected === 'discover-new' && (
                <DiscoverNew active={optionSelected === 'discover-new'} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {searchActive && (
          <CoursesSearch active={searchActive} search={search} />
        )}
      </div>
    </Container>
  );
};

export default Home;
