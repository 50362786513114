import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: var(--technologicalGray);
  }
  .search {
    input {
      background-color: transparent;
      border: 1px solid var(--closedGray);
      border-radius: 20px;
    }
    button {
      right: 10px;
      top: 7px;
      background: transparent;
      border: none;
    }
  }
`;

export const Options = styled.div`
  border-radius: 25px;
  background: #1f1f1f;
  button,
  a {
    text-decoration: none;
    border: none;
    background: none;
    color: var(--technologicalGray);
    -webkit-text-stroke: 0.5px var(--midnight) 00;

    :hover {
      color: var(--ultraWhite);
      -webkit-text-stroke: 0.5px var(--ultraWhite);
    }
  }

  h3 {
    color: #cbccce;
    font-size: 18px;
    font-weight: 500;
  }

  label {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  input,
  .address {
    color: #cbccce;
    font-weight: 400;

    width: 100%;
  }

  input {
    height: 39px;
  }

  textarea {
    height: auto;
    resize: none;
  }

  .btn-save {
    color: #5f85fc;
  }

  .button-show {
    top: -4px !important;
  }

  .react-datepicker-wrapper {
    width: 60%;
    padding: 5px 10px;
  }

  .p-activity p {
    color: #8c8c8c;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    span:nth-child(1) {
      color: #cbccce;
      font-weight: 500;
    }
    span:nth-child(2) {
      color: #5f85fc;
    }
  }

  .p-activity span.time {
    color: #8c8c8c;
    font-size: 12px;
    font-weight: 400;
    display: block;
  }

  .bg-doc {
    height: 28px;
    background-color: #2f2f2f;
    padding: 6px 5px;
    border-radius: 10px;
  }

  .v-line {
    height: 20px;
    width: 1px;
    background-color: #2f2f2f;
  }

  .name p {
    color: #cbccce;
    font-size: 20px;
    font-weight: 600;
  }

  .name span {
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 400;
  }

  .border-profile {
    border-bottom: 1px solid var(--closedGray);
  }

  hr {
    border-top: 1px solid #2f2f2f;
  }

  button.active,
  a.active {
    color: var(--ultraWhite);
    -webkit-text-stroke: 0.5px var(--ultraWhite);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .box-menu {
      z-index: 1;
      width: 200px;
      right: 13px;
      top: 30px;
      background: var(--closedGray);
      border-radius: 12px 0px 12px 12px;
      padding: 10px;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      .item-menu {
        border-bottom: 1px solid var(--grayImmensity);
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .box-menu.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (max-width: 767px) {
    .options-button {
      transition-duration: 0.3s;
      background: var(--coal);
      border-radius: 20px;

      p {
        color: var(--ultraWhite);
        -webkit-text-stroke: 0.5px var(--ultraWhite);
      }

      svg {
        transition-duration: 0.3s;
        color: var(--ultraWhite);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: var(--coal);
      color: var(--technologicalGray);
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;

        + .bg-btn {
          border-top: solid 1px var(--closedGray);
        }
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 220px;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: var(--coal);
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: var(--asphaltGray);
        }
      }
    }
  }

  @media (min-width: 0px) and (max-width: 767px) {
    .box-menu {
      z-index: 1;
      width: 200px;
      right: 13px;
      top: 30px;
      background: var(--closedGray);
      border-radius: 12px 0px 12px 12px;
      padding: 10px;
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;

      .item-menu {
        border-bottom: 1px solid var(--grayImmensity);
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }

    .box-menu.active {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 38px;
  box-shadow: inset 0px 0px 0px 4px var(--blizzardOpacity);
  box-sizing: border-box;
`;
