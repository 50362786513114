import styled from 'styled-components';
import { lighten } from 'polished';

import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  height: 100%;
`;

export const Modal = styled(ModalComponent)`
  .modal-binance-btn {
    color: #303030;
    background-color: #fcd535;
    border-radius: 12px;
    transition-duration: 0.3s;

    :hover {
      color: ${lighten(0.1, '#303030')}!important;
      background-color: ${lighten(0.1, '#fcd535')}!important;
    }
  }
`;
