import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { useLanguage } from '~/hooks/Language';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Courses, Box, Skeleton } from './styles';
import Player from '~/components/Player';

import members from '~/assets/icons/members-white-icon.svg';
import video from '~/assets/icons/video-white-icon.svg';
import profit from '~/assets/icons/profit-icon.svg';
import arrowLeft from '~/assets/icons/arrow-left-icon.svg';
import play from '~/assets/icons/play.svg';

import { formatPrice } from '~/utils/format';

interface IParams {
  slug: string;
}

interface ICourseStudent {
  id: string;
  student_id: string;
  last_lesson_slug: string;
  deleted_at: string;
}

interface ICourseProduct {
  id: string;
  course: {
    id: string;
    title: string;
    price: string;
    slug: string;
    released: string;
    courseStudent: ICourseStudent[];
    slugLesson: string;
    thumbnail: {
      title: string;
      thumbnail_url: string;
    };
  };
}

interface IAffiliatesProduct {
  id: string;
  affiliate_id: string;
}

interface IProductResponse {
  id: string;
  name: string;
  description: string;
  price: number;
  created_at: string;
  thumbnail: {
    thumbnail_url: string;
  };
  video: {
    video_url: string;
  };
  courseProduct: ICourseProduct[];
  affiliatesProducts: IAffiliatesProduct[];
  mandatory_purchase: string;
  mandatory_gift: boolean;
}

interface IProduct {
  id: string;
  name: string;
  description: string;
  price: string;
  totalCourses: number;
  totalPriceCourses: string;
  percentDiscount: number;
  created_at: string;
  thumbnail_url: string;
  video_url: string;
  courseProduct: ICourseProduct[];
  purchased: boolean;
  mandatory_purchase: string;
  info: string;
  mandatory_gift: boolean;
  disabled: boolean;
}

interface IProducts {
  id: string;
  thumbnail: {
    thumbnail_url: string;
  };
  name: string;
  courseProduct: ICourseProduct[];
  price: string;
  slug: string;
  mandatory_purchase: string;
  mandatory_gift: boolean;
}

interface IOrder {
  id: string;
  product_id: string;
  status: string;
}

const Product: React.FC = () => {
  const { language, idiom } = useLanguage();
  const { user, updateUser } = useAuth();
  const params = useParams<IParams>();
  const history = useHistory();
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const [langSelected] = useState(() => {
    return idiom === 'en' || idiom === 'es' ? `/${idiom}` : '';
  });

  const loadProducts = useCallback(() => {
    api
      .get<IProductResponse>(`products/students/${params.slug}`)
      .then(async (response) => {
        const totalPriceCourses = response.data.courseProduct.reduce(
          (acumulador: number, valorAtual: ICourseProduct) => {
            const { price } = valorAtual.course;
            return acumulador + parseInt(price, 10);
          },
          0
        );

        const percentDiscount =
          ((totalPriceCourses - response.data.price) * 100) / totalPriceCourses;
        const courseProduct = response.data.courseProduct.map(
          (courseProductData) => {
            let slugLesson = 'introduction';
            const courseStudentSelected = courseProductData.course.courseStudent.find(
              (courseStudent) => courseStudent.student_id === user.id
            );

            if (courseStudentSelected) {
              slugLesson = courseStudentSelected.last_lesson_slug;
            }

            return {
              id: courseProductData.id,
              course: {
                id: courseProductData.course.id,
                title: courseProductData.course.title,
                price: formatPrice(parseFloat(courseProductData.course.price)),
                slug: courseProductData.course.slug,
                slugLesson,
                courseStudent: courseProductData.course.courseStudent,
                released: courseProductData.course.released,
                thumbnail: {
                  title: courseProductData.course.thumbnail.title,
                  thumbnail_url:
                    courseProductData.course.thumbnail.thumbnail_url,
                },
              },
            };
          }
        );

        const purchased = !!response.data.affiliatesProducts.find(
          (affiliatesProduct) =>
            user.affiliate &&
            affiliatesProduct.affiliate_id === user.affiliate.id
        );

        const data: IProduct = {
          id: response.data.id,
          name: response.data.name,
          description: response.data.description,
          price: formatPrice(response.data.price),
          totalCourses: response.data.courseProduct.length,
          totalPriceCourses: formatPrice(totalPriceCourses),
          percentDiscount,
          created_at: response.data.created_at,
          thumbnail_url: response.data.thumbnail
            ? response.data.thumbnail.thumbnail_url
            : '',
          video_url: response.data.video ? response.data.video.video_url : '',
          courseProduct,
          purchased,
          mandatory_purchase: response.data.mandatory_purchase,
          mandatory_gift: response.data.mandatory_gift,
          disabled: true,
          info: '',
        };

        const responseProducts = await api.get<IProducts[]>(
          'products/students'
        );

        if (responseProducts.data.length > 0) {
          const dataProduct = responseProducts.data.map((productData) => {
            const courses = productData.courseProduct.map(
              (courseProductData) => courseProductData.course.title
            );

            let affiliateProduct;
            let disableProduct;
            if (user.affiliate && user.affiliate.affiliatesProducts) {
              affiliateProduct = user.affiliate.affiliatesProducts.find(
                (affiliateProductData) =>
                  affiliateProductData.product_id === productData.id
              );
              disableProduct = user.affiliate.affiliatesProducts.find(
                (affiliateProductData) =>
                  affiliateProductData.product_id ===
                  productData.mandatory_purchase
              );
            }

            const productFind = responseProducts.data.find(
              (responseProduct) =>
                responseProduct.id === productData.mandatory_purchase
            );

            const disabled =
              (!!productData.mandatory_purchase && !disableProduct) ||
              !!(
                productData.mandatory_purchase &&
                !disableProduct &&
                !productData.mandatory_gift
              );

            return {
              id: productData.id,
              thumbail: productData.thumbnail.thumbnail_url,
              name: productData.name,
              courses,
              price: productData.price,
              slug: productData.slug,
              mandatory_purchase: productData.mandatory_purchase,
              mandatory_gift: productData.mandatory_gift,
              purchased: !!affiliateProduct,
              disabled,
              info: productFind
                ? `${language.requires_purchase.requires_1} ${productFind.name} ${language.requires_purchase.requires_2}`
                : '',
            };
          });

          const reponseOrders = await api.get<IOrder[]>('orders', {
            params: {
              filter: 'AllOrders',
            },
          });

          if (reponseOrders.data) {
            dataProduct.forEach((productData, index) => {
              const myOrder = reponseOrders.data.find(
                (order) => productData.id === order.product_id
              );
              if (
                !productData.purchased &&
                myOrder &&
                myOrder.status === 'awaiting confirmation'
              ) {
                dataProduct[index].disabled = true;
                dataProduct[index].info = 'Awaiting confirmation';
              }
            });

            reponseOrders.data.forEach((order) => {
              const productSelected = dataProduct.find(
                (productData) =>
                  order.product_id === productData.mandatory_purchase
              );

              if (productSelected) {
                const nextProductSelected = dataProduct.find(
                  (productData) =>
                    productSelected.id === productData.mandatory_purchase
                );
                const productIndex = dataProduct.findIndex(
                  (productData) =>
                    productSelected.id === productData.mandatory_purchase
                );

                if (nextProductSelected) {
                  if (nextProductSelected.mandatory_gift) {
                    dataProduct[productIndex].disabled = false;
                  }

                  const nextToNextProductSelected = dataProduct.find(
                    (productData) =>
                      nextProductSelected.id === productData.mandatory_purchase
                  );

                  const nextProductIndex = dataProduct.findIndex(
                    (productData) =>
                      nextProductSelected.id === productData.mandatory_purchase
                  );

                  if (nextToNextProductSelected) {
                    if (nextToNextProductSelected.mandatory_gift) {
                      dataProduct[nextProductIndex].disabled = false;
                    }
                  }
                }
              }
            });

            const productSeleted = dataProduct.find(
              (productData) => productData.slug === params.slug
            );
            if (productSeleted) {
              data.info = productSeleted.info;
              data.disabled = productSeleted.disabled;
            }
          }
        }

        setProduct(data);
      })
      .finally(() => setLoading(false));
  }, [
    language.requires_purchase.requires_1,
    language.requires_purchase.requires_2,
    params.slug,
    user.affiliate,
    user.id,
  ]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleSucceededPaid = useCallback(
    async (data) => {
      const affiliateProductsData: any = [];
      let response = await api.get(`products/students/${data.product_id}`);
      if (response.data) {
        let affiliateProductData: any = {
          product_id: response.data.id,
        };
        affiliateProductsData.push(affiliateProductData);
        if (response.data.mandatory_purchase && response.data.mandatory_gift) {
          response = await api.get(
            `products/students/${response.data.mandatory_purchase}`
          );
          if (response.data) {
            affiliateProductData = {
              product_id: response.data.id,
            };
            affiliateProductsData.push(affiliateProductData);
            if (
              response.data.mandatory_purchase &&
              response.data.mandatory_gift
            ) {
              response = await api.get(
                `products/students/${response.data.mandatory_purchase}`
              );
              if (response.data) {
                affiliateProductData = {
                  product_id: response.data.id,
                };
                affiliateProductsData.push(affiliateProductData);
                if (
                  response.data.mandatory_purchase &&
                  response.data.mandatory_gift
                ) {
                  response = await api.get(
                    `products/students/${response.data.mandatory_purchase}`
                  );
                  if (response.data) {
                    affiliateProductData = {
                      product_id: response.data.id,
                    };
                    affiliateProductsData.push(affiliateProductData);
                  }
                }
              }
            }
          }
        }
      }

      if (user.affiliate) {
        user.affiliate.active_affiliate = true;
        if (user.affiliate.affiliatesProducts) {
          user.affiliate.affiliatesProducts.push(
            ...affiliateProductsData.reverse()
          );
        } else {
          user.affiliate.affiliatesProducts = affiliateProductsData;
        }
      }

      updateUser(user);
      loadProducts();
    },
    [updateUser, user, loadProducts]
  );

  return (
    <>
      {loading && (
        <Skeleton>
          <div className="container py-5">
            <div className="row align-items-center align-items-lg-start">
              <div className="col-4 col-lg-12 mb-lg-4">
                <button
                  type="button"
                  className="skeleton border-0 bg-transparent"
                >
                  <div className="skeleton-arrow arrow-xs" />
                </button>
              </div>
              <div className="col-lg-7 d-none d-lg-block">
                <h1 className="skeleton h2 h1-lg mb-5">
                  Aspectos básicos para comenzar tu negocio en línea
                </h1>
              </div>
              <div className="col-8 col-lg-5 my-3 my-lg-0 text-right">
                <h2 className="skeleton h2 h1-lg font-weight-bold mb-0">
                  $197.00
                </h2>
                <br />
                <s className="skeleton h4 old-price">$297.00</s>
              </div>
              <div className="col-12 d-lg-none mt-4">
                <h1 className="skeleton h2 h1-lg mb-4">
                  Aspectos básicos para comenzar tu negocio en línea
                </h1>
              </div>
              <div className="col-lg-9">
                <p className="skeleton h6 h5-lg font-weight-light mb-0">
                  Esta capacitación te ayudará a obtener las bases necesarias
                  para iniciarte en el mundo del marketing por Internet.
                </p>
              </div>
              <div className="col-lg-8 col-xl-7 col-xxl-6">
                <div className="row justify-content-between py-5">
                  <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-start">
                    <img src={members} alt="members" className="mr-3" />
                    <p className="skeleton mb-0 h6">15 Members Enrolled</p>
                  </div>
                  <div className="my-1 my-lg-0 col-lg-3 d-flex align-items-center justify-content-lg-center">
                    <img src={video} alt="video" className="mr-3" />
                    <p className="skeleton mb-0 h6">9 videos</p>
                  </div>
                  <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-end">
                    <img src={profit} alt="hour" className="mr-3" />
                    <p className="skeleton mb-0 h6">Total time: 02:21:30</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 my-1 my-lg-0">
                    <button
                      type="button"
                      className="skeleton button-play w-100 py-3"
                    >
                      <span className="d-block py-1 w-100">
                        {language.product_franchise.button_1}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-6 my-1 my-lg-0">
                    <button type="button" className="skeleton py-3 h-100 w-100">
                      <span className="d-block py-1 w-100">
                        {language.product_franchise.button_2}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="px-0">
              <h2 className="h6 mb-4 mt-lg-2 pt-5 border-top-courses font-weight-bold p-relative">
                {language.course.h2}
              </h2>
              <div className="row">
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
              </div>
            </div>
          </div>
        </Skeleton>
      )}
      {Object.keys(product).length > 0 && (
        <>
          <Container
            background={product.thumbnail_url}
            className={loading === true ? 'd-none' : ''}
          >
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div className="row align-items-center align-items-lg-start">
                    <div className="col-3 col-lg-12">
                      <button
                        type="button"
                        onClick={() =>
                          history.push(
                            `${process.env.PUBLIC_URL}${langSelected}/products/discover`
                          )
                        }
                        className="border-0 bg-transparent mb-lg-4"
                      >
                        <img
                          src={arrowLeft}
                          alt="Arrow Left"
                          className="arrow-xs"
                        />
                      </button>
                    </div>
                    <div className="col-lg-8 d-none d-lg-block">
                      <h1 className="h2 h1-lg mb-0">{product.name}</h1>
                    </div>
                    {!product.purchased && (
                      <>
                        <div className="col-9 col-lg-4 text-right">
                          <h2 className="h2 h1-lg font-weight-bold mb-0">
                            {product.price}
                          </h2>
                          <s className="h4 old-price">
                            {product.totalPriceCourses}
                          </s>
                        </div>
                      </>
                    )}
                    <div className="col-12 d-lg-none mt-4">
                      <h1 className="h2 h1-lg mb-0">{product.name}</h1>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <p className="h6 h5-lg font-weight-light">
                    {product.description}
                  </p>
                </div>
                <div className="col-lg-8 col-xl-7 col-xxl-6 text-center text-lg-left">
                  <div className="row justify-content-between py-5">
                    <div className="my-1 my-lg-0 col-lg-3 d-flex align-items-center justify-content-lg-start">
                      <img src={members} alt="members" className="mr-3" />
                      <p className="mb-0 h6">
                        {product.totalCourses} {language.product_franchise.p_1}
                      </p>
                    </div>
                    <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-center">
                      <img src={video} alt="video" className="mr-3" />
                      <p className="mb-0 h6">
                        {product.totalPriceCourses}{' '}
                        {language.product_franchise.p_2}
                      </p>
                    </div>
                    <div className="my-1 my-lg-0 col-lg-5 d-flex align-items-center justify-content-lg-end">
                      <img src={profit} alt="profit" className="mr-3" />
                      <p className="mb-0 h6">
                        {language.product_franchise.p_3}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 my-1 my-lg-0">
                      <button
                        type="button"
                        onClick={handleShow}
                        className="button-play w-100 py-3"
                      >
                        <span className="d-block py-1 w-100">
                          <img src={play} alt="Play Icon" className="mr-2" />
                          {language.product_franchise.button_1}
                        </span>
                      </button>
                    </div>
                    <div className="col-lg-6 my-1 my-lg-0">
                      {/* <BuyButton
                        product_id={product.id}
                        title={product.name}
                        price={product.price.slice(1)}
                        totalPrice={product.totalPriceCourses.slice(1)}
                        purchased={product.purchased}
                        className="font-weight-600 h-100 btn-color"
                        disabled={product.disabled}
                        info={product.info}
                        onSucceededPaid={handleSucceededPaid}
                      > */}
                      <Link
                        to={`${process.env.PUBLIC_URL}/${idiom}/product/${params.slug}/payment`}
                        className="buy-button py-3 mb-0"
                      >
                        <span className="py-1">
                          {language.product_franchise.button_2}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 border-top-courses px-0 mt-5 pt-5">
                <h2 className="h6 mb-4 font-weight-bold">
                  {language.course.h2}
                </h2>
                <Courses className="row">
                  {product.courseProduct && product.courseProduct.length > 0 ? (
                    <>
                      {product.courseProduct.map((courseProductData) => (
                        <div className="col-md-6 col-xl-4 mb-5">
                          <Box
                            to={`${process.env.PUBLIC_URL}/courses${
                              !product.purchased
                                ? `/preview/${courseProductData.course.slug}`
                                : `/${courseProductData.course.slug}/${courseProductData.course.slugLesson}`
                            }`}
                            src={
                              courseProductData.course.thumbnail.thumbnail_url
                            }
                            disabled={!courseProductData.course.released}
                            className="d-block p-relative w-100"
                          >
                            <div className="short-title p-absolute w-100 p-4">
                              <div className="row h-100">
                                <div className="col-12 d-flex justify-content-between">
                                  <p className="mb-0 py-2 pl-1 mt-3 w-75 h5 h4-sm">
                                    {courseProductData.course.thumbnail.title}
                                  </p>
                                  <s className="mb-0 py-2 pr-1 w-25 h6 h5-lg text-right text-white">
                                    {courseProductData.course.price}
                                  </s>
                                </div>
                                <div className="col-12">
                                  <div className="row align-items-end h-100">
                                    <div className="col-12">
                                      <div className="see-more-link mb-2">
                                        <button
                                          type="button"
                                          className="px-4 py-2 mt-5 h6"
                                        >
                                          {!courseProductData.course.released
                                            ? language.courses.button_4
                                            : language.product_franchise
                                                .button_3}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Box>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <p className="h5 text-center">
                            {language.course.p_4}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </Courses>
              </div>
            </div>
          </Container>
          <Modal
            className="modal-introduction"
            show={show}
            size="xl"
            onHide={handleClose}
            centered
          >
            <Modal.Body className="p-0">
              <Player src={product.video_url} className="video-size" />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Product;
