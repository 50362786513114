import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--asphaltGray);
  border-radius: 10px;
  border: 1.5px solid var(--deepGrey);
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    position: relative;

    .button-show {
      position: absolute;
      top: -6px;
      right: -10px;
      width: 60px;
      text-align: center;
      background: none;
      border: 0;
      border-left: 1px solid var(--ultraWhiteOpacity);
      padding: 6px 10px 5px;
      color: var(--graniteGray);
    }
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--graniteGray);
    transition-duration: 0.2s;

    ::placeholder {
      color: var(--deepGrey);
    }
  }

  svg {
    margin-right: 16px;
    color: var(--deepGrey);
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--error);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      svg {
        color: var(--graniteGray);
      }
    `}

  ${(props) =>
    props.isFocuses &&
    css`
      color: var(--graniteGray);
      border-color: var(--graniteGray);

      svg {
        color: var(--graniteGray);
      }
    `}
`;
