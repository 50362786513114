import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import Carousel from 'react-elastic-carousel';
import { Modal } from 'react-bootstrap';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';

import { FormHandles } from '@unform/core';
import { useLanguage } from '~/hooks/Language';

import { Container, ButtonQuestion, Question, Answer } from './styles';
import Input from '~/components/Input';

import quizGolden from '~/assets/icons/quiz-golden-icon.svg';
import closeIcon from '~/assets/icons/close.svg';

interface IOptions {
  id: string;
  answer: string;
  correct_answer: boolean;
}

export interface IQuestion {
  id: string;
  question: string;
  options: IOptions[];
}

export interface IAnswers {
  question_id: string;
  correct_answer: boolean;
}

interface QuizzesProps {
  dataQuestions: IQuestion[];
  onAnsweredQuestions?(answers: IAnswers[]): void;
  answered: boolean;
  showQuestions?: boolean;
  hideButton?: boolean;
  onCloseModal?(): void;
}

type ICarouselType<T, R> = Omit<T, keyof R> & R;

type ICarousel = ICarouselType<
  Carousel,
  {
    goTo(index: number): void;
  }
>;

const Quizzes: React.FC<QuizzesProps> = ({
  dataQuestions,
  onAnsweredQuestions,
  answered,
  showQuestions,
  hideButton,
  onCloseModal,
}) => {
  const carouselRef = useRef<ICarousel>(null);
  const formRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answers, setAnswers] = useState<IAnswers[]>([]);
  const [answersSelected, setAnswersSelected] = useState<string[]>([]);
  const [hasError, setHasError] = useState('');
  const [quizAnswered, setQuizAnswered] = useState(false);
  const [checkAnswer, setCheckAnswer] = useState<string[]>([]);

  useEffect(() => {
    setQuizAnswered(answered);
  }, [answered]);

  useEffect(() => {
    if (dataQuestions && dataQuestions.length > 0) {
      setQuestions(dataQuestions);
    }
  }, [dataQuestions]);

  useEffect(() => {
    if (typeof showQuestions !== typeof undefined) {
      setShow(showQuestions as boolean);
    }
  }, [showQuestions]);

  const handleClose = useCallback(() => {
    setShow(false);
    if (onCloseModal) {
      onCloseModal();
    }
  }, [onCloseModal]);

  const handleShow = useCallback(() => setShow(true), []);

  const handleChangeItem = useCallback(({ index }) => {
    setQuestionNumber(index);
  }, []);

  const handleChangeAnswer = useCallback(
    (question_id, answer_id) => {
      const answeredQuestions = questions.find(
        (question) => question.id === question_id
      );
      if (answeredQuestions) {
        const correctAnswer = answeredQuestions.options.find(
          (answer: IOptions) => answer.id === answer_id
        );
        if (correctAnswer) {
          const data: IAnswers = {
            question_id,
            correct_answer: correctAnswer.correct_answer,
          };

          const newAnswersSelected = answersSelected.slice();
          const newAnswers = answers.slice();

          newAnswers[questionNumber] = data;
          newAnswersSelected[questionNumber] = correctAnswer.id;
          setAnswers(newAnswers);
          setAnswersSelected(newAnswersSelected);
        }
      }
    },
    [answers, answersSelected, questionNumber, questions]
  );

  const handleClick = useCallback(() => {
    const newCheckAnswer = checkAnswer.slice();
    if (answers[questionNumber]) {
      if (answers[questionNumber].correct_answer) {
        newCheckAnswer[questionNumber] = 'Correct!';
      } else {
        newCheckAnswer[questionNumber] = 'Oops...';
      }
    } else {
      newCheckAnswer[questionNumber] = 'Answer is mandatory';
    }
    setCheckAnswer(newCheckAnswer);
    const questionsAnswers = answers.filter((answer) => !!answer);

    if (questionsAnswers.length === questions.length && formRef.current) {
      formRef.current?.submitForm();
    }
  }, [checkAnswer, answers, questionNumber, questions.length]);

  const handleSubmit = useCallback(() => {
    setHasError('');
    try {
      if (questions.length !== answers.length) {
        const error = language.quiz_component.error_message;
        throw error;
      }
      if (!checkAnswer.find((answer) => answer !== 'Correct!')) {
        if (onAnsweredQuestions) {
          onAnsweredQuestions(answers);
        }
        setQuizAnswered(true);
      } else {
        setHasError('Responda corretamente todas as peguntas');
      }
    } catch (error) {
      const err: any = error;
      setHasError(err);
    }
  }, [
    answers,
    checkAnswer,
    language.quiz_component.error_message,
    onAnsweredQuestions,
    questions.length,
  ]);

  const handleSelectQuestion = useCallback((question) => {
    setQuestionNumber(question);
    if (carouselRef.current) {
      carouselRef.current.goTo(question);
    }
  }, []);

  return (
    <Container>
      {!hideButton && (
        <button
          type="button"
          onClick={handleShow}
          className={`w-100 mb-3 border-0 py-3 ${
            quizAnswered ? 'border-quizz' : 'border-quizz'
          }`}
        >
          <span className="p-2">
            <b>
              {quizAnswered
                ? language.quiz_component.button_1
                : language.quiz_component.button_2}
            </b>
          </span>
        </button>
      )}
      <Modal
        size="xl"
        className="modal-quizzes"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 py-lg-5 pr-lg-5">
          <Modal.Title className="text-center pt-5 pt-lg-0 w-100">
            <img src={quizGolden} alt={language.quiz_component.h4} />{' '}
            {language.quiz_component.h4}
          </Modal.Title>
          <button type="button" className="close-button" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-2 px-lg-3">
          <div className="w-100 w-md-75 mx-auto px-2">
            {questions.map((_, indexQuestion) => (
              <ButtonQuestion
                type="button"
                active={indexQuestion === questionNumber}
                correct={
                  !!(
                    checkAnswer[indexQuestion] &&
                    checkAnswer[indexQuestion] === 'Correct!'
                  )
                }
                incorrect={
                  !!(
                    checkAnswer[indexQuestion] &&
                    checkAnswer[indexQuestion] !== 'Correct!'
                  )
                }
                onClick={() => handleSelectQuestion(indexQuestion)}
              >
                {`${indexQuestion + 1}`.padStart(2, '0')}
                <RiCheckboxCircleFill
                  size={12}
                  color="#37C694"
                  className="correct"
                />
                <RiCloseCircleFill
                  size={12}
                  color="#FF1A50"
                  className="incorrect"
                />
              </ButtonQuestion>
            ))}
          </div>
          <Form ref={formRef} onSubmit={handleSubmit} className="w-100">
            <Carousel
              ref={carouselRef}
              showArrows={false}
              pagination={false}
              onChange={handleChangeItem}
            >
              {questions.map((question, indexQuestion) => (
                <Question
                  key={question.id}
                  className="w-100 w-md-75 mx-auto mt-4"
                >
                  <div className="d-flex justify-content-between">
                    <h3 className="h5 py-2">
                      {indexQuestion + 1}) {question.question}
                    </h3>
                    {checkAnswer[indexQuestion] && (
                      <p
                        className={`
                          message-alert
                          ${
                            checkAnswer[indexQuestion] === 'Correct!'
                              ? 'success'
                              : 'error'
                          }`}
                      >
                        {checkAnswer[indexQuestion]}
                      </p>
                    )}
                  </div>
                  {question.options.map((option, indexOption) => (
                    <Answer
                      key={option.id}
                      className="d-flex align-items-center"
                    >
                      <label
                        htmlFor={`answer-${indexQuestion + 1}-${
                          indexOption + 1
                        }`}
                        className={`mb-0 ml-1 py-3 ${
                          answersSelected.find(
                            (answerSelected) => answerSelected === option.id
                          )
                            ? 'selected'
                            : ''
                        }`}
                      >
                        <Input
                          type="radio"
                          name={`question-${indexQuestion + 1}`}
                          id={`answer-${indexQuestion + 1}-${indexOption + 1}`}
                          value={option.answer}
                          className="answer d-none"
                          onChange={() =>
                            handleChangeAnswer(question.id, option.id)
                          }
                        />
                        {option.answer}
                      </label>
                    </Answer>
                  ))}
                </Question>
              ))}
            </Carousel>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div
            className={`w-100 w-md-75 m-auto pb-5 px-2 d-flex flex-column ${
              hasError ? 'justify-content-between' : 'justify-content-end'
            }`}
          >
            {hasError && (
              <div className="d-flex align-items-center justify-content-center text-center px-2 py-3 rounded">
                <p className="mb-0">{hasError}</p>
              </div>
            )}
            <button
              type="button"
              className="gray-button py-3 w-100"
              onClick={handleClick}
            >
              <span className="d-block py-1 px-2">
                {language.quiz_component.button_3}
              </span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Quizzes;
