import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useAuth, User } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import InputAddress from '~/components/InputAddress';

import profilePhoto from '~/assets/defaults/photo-profile.png';
import InputDate from '~/components/InputDate';

interface IMyProfileProps {
  active: boolean;
}

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface IUser {
  name: string;
  email: string;
  // username: string;
  // phone: string;
  // referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  password?: string;
  confirm_password?: string;
  old_password?: string;
  address?: IAddress;
}

const MyProfile: React.FC<IMyProfileProps> = ({ active }) => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { language } = useLanguage();
  const [avatarData, setAvatarData] = useState<File | null>(null);
  const [avatar, setAvatar] = useState(user.avatar.avatar_url || profilePhoto);
  const [userData, setUserData] = useState({} as IUser);
  const [changePassword, setChangePassword] = useState(false);
  const [emailNotification, setEmailNotification] = useState(
    user.email_notifications
  );
  const [smsNotification, setSmsNotification] = useState(
    user.sms_notifications
  );
  const [loading, setLoading] = useState(true);
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [birth, setBirth] = useState(new Date(1900, 0, 1));

  useEffect(() => {
    const data = {
      name: user.name,
      email: user.email,
      // username: user.username,
      // phone: user.phone,
      // referral_code: user.referral_code,
      email_notifications: user.email_notifications,
      sms_notifications: user.sms_notifications,
      address: user.address as any,
    };

    if (user.address) {
      setAddressId(user.address.id);
      setZipCode(user.address.zip_code);
      setCountry(user.address.country);
      setStreet(user.address.street);
      setNumber(user.address.number);
      setNeighborhood(user.address.neighborhood);
      setState(user.address.state);
      setCity(user.address.city);
      setComplement(user.address.complement);
    }
    setUserData(data);
    setLoading(false);
  }, [user]);

  const handleClickChangePassword = useCallback(() => {
    setChangePassword((state) => !state);
  }, []);

  const handleChangeZipCode = useCallback((address: IAddress) => {
    const {
      zipCode,
      country,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = address;
    setZipCode(zipCode);
    setCountry(country);
    setStreet(street);
    setNumber(number);
    setNeighborhood(neighborhood);
    setState(state);
    setCity(city);
    setComplement(complement);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.value);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.value);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.value);
  }, []);

  const handleChangeDateBirth = useCallback((e) => {
    setBirth(e);
  }, []);

  const handleSubmit = useCallback(
    async (data: IUser) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.my_profile.erro_1),
          email: Yup.string().required(language.my_profile.erro_2),
          // username: Yup.string().required(language.my_profile.erro_3),
          // phone: Yup.string().required(language.my_profile.erro_4),
          // referral_code: Yup.string().required(language.my_profile.erro_5),
          email_notifications: Yup.boolean(),
          sms_notifications: Yup.boolean(),
          password: Yup.string(),
          confirm_password: Yup.string().when('password', {
            is: (password) => password && password.length > 0,
            then: Yup.string().oneOf(
              [Yup.ref('password'), undefined],
              language.my_profile.erro_7
            ),
          }),
          old_password: Yup.string().when('password', {
            is: (password) => password && password.length > 0,
            then: Yup.string().required(language.my_profile.erro_8),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let avatar_id: string;
        let avatar_url: string;
        if (avatarData) {
          const avatarFormData = new FormData();
          avatarFormData.append('avatar', avatarData as File);
          const responseAvatar = await api.post('avatars', avatarFormData);

          if (responseAvatar.data) {
            avatar_url = responseAvatar.data.avatar_url;
            avatar_id = responseAvatar.data.id;
            await api.patch(`students/profile/avatar`, {
              avatar_id,
            });
          } else {
            avatar_id = user.avatar.id;
            avatar_url = avatar;
          }
        } else {
          avatar_id = user.avatar.id;
          avatar_url = avatar;
        }

        const {
          name,
          email,
          // username,
          // phone,
          // referral_code,
          address,
          old_password,
          password,
          confirm_password,
        } = data;

        const formData = {
          avatar_id,
          customer_id: user.customer_id,
          name,
          email,
          // username,
          // phone,
          // referral_code,
          address,
          email_notifications: emailNotification,
          sms_notifications: smsNotification,
        };

        if (
          old_password &&
          password &&
          confirm_password &&
          password === confirm_password
        ) {
          Object.assign(formData, { old_password, password });
        }

        const response = await api.put<User>('students/profile', formData);
        if (response.data) {
          let addressData: any;
          if (zipCodeData) {
            const formAddressData = {
              student_id: response.data.id,
              zip_code: zipCodeData,
              country: countryData,
              street: streetData,
              number: numberData,
              neighborhood: neighborhoodData,
              city: cityData,
              complement: complementData,
              state: stateData,
            };

            let responseAddress: any;

            if (addressId.length > 0) {
              responseAddress = await api.put(
                `adresses/students/${addressId}`,
                formAddressData
              );
            } else {
              responseAddress = await api.post(
                'adresses/students',
                formAddressData
              );
            }

            addressData = {
              id: responseAddress.data.id,
              student_id: response.data.id,
              zip_code: zipCodeData,
              country: countryData,
              street: streetData,
              number: numberData,
              neighborhood: neighborhoodData,
              city: cityData,
              complement: complementData,
              state: stateData,
            };
          }

          Swal.fire(
            language.my_profile.success_1,
            language.my_profile.success_2,
            'success'
          ).then(() => {
            const profileData = {
              id: response.data.id,
              avatar_id,
              customer_id: response.data.customer_id,
              name: response.data.name,
              email: response.data.email,
              username: response.data.username,
              phone: response.data.phone,
              referral_code: response.data.referral_code.toLowerCase(),
              email_notifications: response.data.email_notifications,
              sms_notifications: response.data.sms_notifications,
              avatar: {
                id: avatar_id,
                avatar_url,
              },
              address: addressData,
              affiliate: user.affiliate,
            };

            updateUser(profileData);
          });
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            language.error_component.error_message,
            'error'
          ).then(() => setLoading(false));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      language.my_profile.erro_1,
      language.my_profile.erro_2,
      language.my_profile.erro_7,
      language.my_profile.erro_8,
      language.my_profile.success_1,
      language.my_profile.success_2,
      language.error_component.error_message,
      avatarData,
      user.customer_id,
      user.avatar.id,
      user.affiliate,
      emailNotification,
      smsNotification,
      avatar,
      zipCodeData,
      countryData,
      streetData,
      numberData,
      neighborhoodData,
      cityData,
      complementData,
      stateData,
      addressId,
      updateUser,
    ]
  );

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="container-fluid">
          <Form
            ref={formRef}
            initialData={userData}
            onSubmit={handleSubmit}
            className="row"
          >
            <div className="mt-3 mt-xl-0 col-12 px-2 px-lg-0">
              <div className="bg-gray h-100">
                <div className="row align-items-center">
                  <div className="col-md-6 mb-1 mb-lg-3">
                    <label
                      htmlFor="name"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_5}
                    </label>
                    <Input
                      id="name"
                      name="name"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-md-6 mb-1 mb-lg-3">
                    <label
                      htmlFor="email"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_7}
                    </label>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="zipCode"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_8}
                    </label>
                    <InputAddress
                      name="zipCode"
                      value={zipCodeData}
                      onChange={handleChangeZipCode}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="country"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_9}
                    </label>
                    <Input
                      name="country"
                      value={countryData}
                      onChange={handleChangeCountry}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="street"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_10}
                    </label>
                    <Input
                      name="street"
                      value={streetData}
                      onChange={handleChangeStreet}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-3 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="number"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_11}
                    </label>
                    <Input
                      name="number"
                      value={numberData}
                      onChange={handleChangeNumber}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-3 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="neighborhood"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_12}
                    </label>
                    <Input
                      name="neighborhood"
                      value={neighborhoodData}
                      onChange={handleChangeNeighborhood}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-6 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="state"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_13}
                    </label>
                    <Input
                      name="state"
                      value={stateData}
                      onChange={handleChangeState}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-md-4 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="city"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_14}
                    </label>
                    <Input
                      name="city"
                      value={cityData}
                      onChange={handleChangeCity}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-5 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="complement"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_15}
                    </label>
                    <Input
                      name="complement"
                      value={complementData}
                      onChange={handleChangeComplement}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } py-2 border-color`}
                    />
                  </div>
                  <div className="col-lg-3 mb-1 mb-lg-3 pt-md-4">
                    <label
                      htmlFor="date_birth"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      Date of birth
                    </label>
                    <InputDate
                      name="date_birth"
                      onChange={handleChangeDateBirth}
                      dateFormat="MM/dd/yyyy"
                      selected={birth}
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } bg-transparent border-0 w-100`}
                    />
                  </div>
                  <div className="col-12 pt-4 pt-lg-5">
                    <h3 className={`${loading === true ? 'skeleton' : ''} h5`}>
                      {language.my_profile.h3_3}
                    </h3>
                  </div>
                  <div className="col-md-6 mb-1 mb-lg-3 pt-1 pt-lg-3">
                    <label
                      htmlFor="old-password"
                      className={`${loading === true ? 'skeleton' : ''} mb-1`}
                    >
                      {language.my_profile.label_16}
                    </label>
                    <div className="p-relative">
                      <Input
                        type="password"
                        id="old-password"
                        name="old_password"
                        className={`${
                          loading === true ? 'skeleton' : ''
                        } py-2 w-75-profile border-color`}
                      />
                    </div>
                  </div>
                  {changePassword ? (
                    <>
                      <div className="col-md-6 mb-1 mb-lg-3 pt-1 pt-lg-3">
                        <label
                          htmlFor="password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_17}
                        </label>
                        <Input
                          type="password"
                          id="password"
                          name="password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-2 border-color`}
                        />
                      </div>
                      <div className="col-md-6 mb-1 mb-lg-3">
                        <label
                          htmlFor="confirm-password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } mb-1`}
                        >
                          {language.my_profile.label_18}
                        </label>
                        <Input
                          type="password"
                          id="confirm-password"
                          name="confirm_password"
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } py-2 border-color`}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col-md-12 mb-3 mb-md-0">
                      <button
                        type="button"
                        className={`${
                          loading === true ? 'skeleton' : ''
                        } border-0 bg-transparent button-color`}
                        onClick={handleClickChangePassword}
                      >
                        {language.my_profile.button_1}
                      </button>
                    </div>
                  )}
                  <div className="col-12 d-none">
                    <h3 className="h5">Notifications</h3>
                  </div>
                  <div className="col-md-7 d-none">
                    <div className="flex-wrap flex-md-nowrap">
                      <div className="d-flex align-items-center w-100">
                        <Input
                          type="checkbox"
                          id="email-notifications"
                          name="email_notifications"
                          className="bg-transparent border-0 checkbox-width"
                          onChange={() =>
                            setEmailNotification(!emailNotification)
                          }
                          checked={emailNotification}
                        />
                        <label htmlFor="email-notifications" className="mb-0">
                          Email notifications
                        </label>
                      </div>
                      <div className="d-flex align-items-center w-100">
                        <Input
                          type="checkbox"
                          id="sms-notifications"
                          name="sms_notifications"
                          className="bg-transparent border-0 checkbox-width"
                          onChange={() => setSmsNotification(!smsNotification)}
                          checked={smsNotification}
                        />
                        <label htmlFor="sms-notifications" className="mb-0">
                          SMS notifications
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 ml-auto mt-5">
                    <button
                      type="submit"
                      className={`${
                        loading === true ? 'skeleton' : ''
                      } profile-button py-3 w-100 mt-5 mb-4 mb-lg-0`}
                    >
                      <span>{language.my_profile.button_2}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default MyProfile;
