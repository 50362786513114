import styled from 'styled-components';

export const Container = styled.div`
  hr {
    border: solid 3px var(--greyMineral);
    background-color: var(--greyMineral);
  }
  .box,
  .box-light {
    background: var(--coal);
    border-radius: 20px;

    h1,
    h2 {
      color: var(--ultraWhite);
    }

    p {
      color: var(--grayImmensity);
    }

    .text-gray {
      color: var(--technologicalGray);
    }

    a {
      color: var(--greyMineral);
      text-decoration: underline;
    }
    span {
      color: var(--graniteGray);
      font-weight: 600;
    }
  }

  .dollar {
    border: solid 4px var(--ultraWhite);
    border-radius: 50%;
    color: var(--ultraWhite) !important;
  }

  .box-light {
    background: var(--closedGray) !important;
  }
  button {
    background-color: var(--greyMineral);
    border: none;
    border-radius: 20px;

    :hover {
      background-color: var(--greyMineralLighten);
      transition: 0.5s;
    }

    :disabled {
      opacity: 0.7;
      cursor: not-allowed;
      background-color: var(--greyMineral);
    }
  }

  @media screen and (min-width: 575px) {
    .p-sm-owner {
      padding: 3.6rem !important;
    }
  }

  @media screen and (min-width: 1199px) {
    .p-sm-owner {
      padding: 4.5rem 3.6rem !important;
    }
  }
`;

export const Welcome = styled.div`
  p,
  h2 {
    color: var(--grayImmensity);
  }
  .text-black-gray {
    color: var(--greyMineral);
  }
`;
