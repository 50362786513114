import React, { useCallback, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { HiOutlineDocumentAdd } from 'react-icons/hi';
import { format } from 'date-fns';
import { Container } from './styles';
import Textarea from '~/components/Textarea';

interface IPost {
  id: number;
  post: string;
  date: string;
  background: string;
}

const LessonNotes: React.FC = () => {
  const [newPost, setNewPost] = useState('');
  const [background, setBackground] = useState('yellow');
  const [bgOrange, setBgOrange] = useState('orange');
  const [bgBlue, setBgBlue] = useState('blue');
  const [posts, setPosts] = useState<IPost[]>([]);

  useEffect(() => {
    switch (background) {
      case 'orange':
        setBgOrange('yellow');
        setBgBlue('blue');
        break;
      case 'blue':
        setBgBlue('yellow');
        setBgOrange('orange');
        break;
      default:
        setBackground('yellow');
        setBgBlue('blue');
        setBgOrange('orange');
        break;
    }
  }, [background]);

  const handleChangeNewPost = useCallback((e) => {
    setNewPost(e.target.value);
  }, []);

  const handleChangeBg = useCallback((bgColor) => {
    setBackground(bgColor);
  }, []);

  const handleChangePost = useCallback(() => {
    setPosts([
      {
        id: posts.length + 1,
        post: newPost,
        date: format(new Date(), 'MMMM dd, h:mm a'),
        background,
      },
      ...posts,
    ]);
    setNewPost('');
  }, [background, newPost, posts]);
  return (
    <Container className="d-flex flex-wrap">
      <div className="w-20 mb-4 position-relative">
        <button
          type="button"
          onClick={() => handleChangeBg(bgOrange)}
          className={`bg-${bgOrange} border-0 bg-3 d-flex flex-column justify-content-between bg-post py-4 px-3`}
        >
          ...
        </button>
        <button
          type="button"
          onClick={() => handleChangeBg(bgBlue)}
          className={`bg-${bgBlue} border-0 bg-2 d-flex flex-column justify-content-between bg-post py-4 px-3`}
        >
          ...
        </button>
        <div
          className={`bg-${background} d-flex flex-column justify-content-between bg-post mx-3 py-4 px-3`}
        >
          <Form onSubmit={() => console.log('submit')}>
            <Textarea
              name="post"
              className="border-0"
              value={newPost}
              onChange={(e) => handleChangeNewPost(e)}
              placeholder="Type here...Select other colors by clicking on them"
            />
            <div className="d-flex">
              {newPost && (
                <button
                  type="button"
                  onClick={handleChangePost}
                  className="bg-dark border-0 py-1 px-2 rounded-pill ml-auto"
                >
                  <HiOutlineDocumentAdd
                    size={20}
                    color="#fff"
                    className="mr-1"
                  />
                  Save
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>
      <div className="w-80 d-flex flex-wrap pl-4">
        {posts.map((post) => (
          <div className="w-25 mb-4" key={post.id}>
            <div
              className={`bg-${post.background} d-flex flex-column justify-content-between mx-3 bg-post py-4 px-3`}
            >
              <p>{post.post}</p>
              <span>{post.date}</span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default LessonNotes;
