import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../services/api';

interface IAffiliatesProducts {
  id: string;
  product_id: string;
  product: {
    name: string;
  };
}

interface IAddress {
  id: string;
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zip_code: string;
}
export interface User {
  id: string;
  customer_id: string;
  name: string;
  email: string;
  username: string;
  phone: string;
  referral_code: string;
  email_notifications: boolean;
  sms_notifications: boolean;
  avatar: {
    id: string;
    avatar_url: string;
  };
  address?: IAddress;
  affiliate?: {
    id: string;
    student_id: string;
    product_id: string;
    active_affiliate: boolean;
    affiliate_commission: number;
    crowdfunding_participation: boolean;
    crowdfunding_share: number;
    franchise_owner: boolean;
    franchise_commission: number;
    affiliatesProducts?: IAffiliatesProducts[];
    is_elite: boolean;
  };
}

interface AuthState {
  token: string;
  user: User;
}

interface SignUpCredentials {
  name: string;
  email: string;
  password: string;
  referenced: string;
}

interface SignInCredentials {
  email: string;
  password: string;
  destination?: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signUp(credentials: SignUpCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
  setLogin(token: string, user: User, cacheToken: string): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Membership:token');
    const user = localStorage.getItem('@Membership:user');

    if (token && user) {
      api.defaults.headers.authorization = `Barear ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password, destination }: SignInCredentials) => {
      const response = await api.post<AuthState>('students/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@Membership:token', token);
      localStorage.setItem('@Membership:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Barear ${token}`;

      setData({ token, user });
      if (destination) {
        history.push(`${process.env.PUBLIC_URL}/${destination}`);
      }
    },
    [history]
  );

  const signUp = useCallback(
    async ({ name, email, password, referenced }) => {
      await api.post<AuthState>('students/sessions/create', {
        name,
        email,
        password,
        referenced,
      });

      signIn({ email, password });
    },
    [signIn]
  );

  const signOut = useCallback(() => {
    const cacheToken = localStorage.getItem('@Membership:cacheToken');
    localStorage.removeItem('@Membership:token');
    localStorage.removeItem('@Membership:user');
    localStorage.removeItem('@Membership:cacheToken');
    if (cacheToken) {
      api.get(`students/sessions/remove-data/${cacheToken}`);
    }

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@Membership:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  const setLogin = useCallback(
    (token, user, cacheToken) => {
      localStorage.setItem('@Membership:token', token);
      localStorage.setItem('@Membership:user', JSON.stringify(user));
      localStorage.setItem('@Membership:cacheToken', cacheToken);

      api.defaults.headers.authorization = `Barear ${token}`;

      setData({
        token,
        user,
      });
    },
    [setData]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signUp, signOut, updateUser, setLogin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
