import { lighten } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  show: boolean;
  color: string;
  background: string;
}

export const Container = styled.div<IContainerProps>`
  transition-duration: 0.3s;
  position: fixed;
  right: ${(props) => (props.show ? '25px' : '-100%')};
  max-width: 300px;
  bottom: 50px;
  z-index: 1400;
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border-color: ${(props) => lighten(0.2, props.color)};

  @media screen and (max-width: 340px) {
    right: ${(props) => (props.show ? '15px' : '-100%')};
  }
`;
