import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  .dark-text,
  p,
  p > small {
    color: var(--grayImmensity);
  }

  a {
    color: var(--grayImmensity);
    text-decoration: none !important;
  }

  .colorPayOptions {
    color: var(--graniteGray) !important;
    img {
      filter: brightness(1.3);
    }
    :hover {
      color: var(--graniteGrayLighten) !important;
      img {
        filter: brightness(1.5);
      }
    }
  }

  .copy {
    right: 0;
    top: 20px;
  }

  .bg-gray {
    background: var(--midnight);
    border-radius: 20px;
  }

  .qr-code,
  .star-flag {
    width: 98px;
    height: 100px;
  }

  .star-flag {
    margin-top: -20px;
  }

  hr {
    background-color: var(--closedGray);
  }

  .infos {
    .empty {
      width: 97px;
    }
  }

  .alert-text {
    width: 81%;
    margin-left: auto;
    margin-bottom: 5px;
  }

  .border-bottom {
    border-bottom: solid 1px var(--closedGray) !important;
  }

  .bg-skeleton {
    background: var(--coal);
    border-radius: 20px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: table;
    margin: 0 auto;
    span {
      color: transparent !important;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .star-flag {
      margin-top: -110px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    button.height-custom {
      height: 44px;
      padding: 0 !important;
    }
  }

  @media screen and (max-width: 350px) {
    .brand-group img {
      width: 60px;
    }
  }
`;
