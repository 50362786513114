import React, { useCallback, useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import { NavLink, useHistory, useLocation } from 'react-router-dom';
import MyProducts from './MyProducts';
import Discover from './Discover';

import { Container, Welcome, Options } from './styles';
import { useLanguage } from '~/hooks/Language';

const ProductsList: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { language, idiom } = useLanguage();

  const [loading, setLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(10);
    }
    return location.pathname.slice(13);
  });
  const [langSelected] = useState(() => {
    return idiom === 'en' || idiom === 'es' ? `/${idiom}` : '';
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    switch (optionSelected) {
      case 'my-products':
        setOptionSelected('my-products');
        break;
      default:
        setOptionSelected('discover');
        break;
    }
  }, [language.courses, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(10);
    } else {
      page = location.pathname.slice(13);
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <Container className="py-3 py-lg-0">
      <div className="container">
        <div className="row mt-3 mt-xl-0 mt-xxl-5">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-12 px-md-0 px-lg-4 px-xxl-3">
                    <div className="row">
                      <div className="col-lg-7 col-xxl-4">
                        <h1 className="h2-md h4 text-center text-md-left">
                          {language.products.h1}
                        </h1>
                      </div>
                      <div className="col-lg-5 col-xxl-4 text-center align-items-center d-flex">
                        <div className="p-relative search w-100">
                          <input
                            type="text"
                            className="w-100 px-3"
                            id="name"
                            placeholder={language.products.input}
                            onChange={handleSearch}
                          />
                          <button type="submit" className="p-absolute">
                            <FaSearch />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-11 col-md-9 col-lg-12 mx-lg-3 px-3 px-lg-0 py-3 my-3 my-xxl-4">
                    <div className="row justify-content-center text-center">
                      <div className="d-flex align-items-center justify-content-center my-1 my-lg-0 col-12 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}${langSelected}/products/discover`}
                          className="bg-btn rounded-pill mr-1 mx-xl-3 px-2 px-xl-4 py-1 py-xl-2 font-weight-lighter"
                          activeClassName="active"
                        >
                          <span className="font-weight-bold d-block px-2">
                            {language.products.button_1}
                          </span>
                        </NavLink>
                        <NavLink
                          to={`${process.env.PUBLIC_URL}${langSelected}/products/my-products`}
                          className="bg-btn rounded-pill ml-1 mx-xl-3 px-2 px-xl-4 py-1 py-xl-2 font-weight-lighter"
                          activeClassName="active"
                        >
                          <span className="font-weight-bold d-block px-2">
                            {language.products.button_2}
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
            <MyProducts
              active={optionSelected === 'my-products'}
              search={search}
            />
            <Discover active={optionSelected === 'discover'} search={search} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProductsList;
