import React from 'react';

import { AuthProvider } from './Auth';
import { ChatsProvider } from './Chats';
import { LanguageProvider } from './Language';
import { SetupProvider } from './Setup';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <ChatsProvider>
      <LanguageProvider>
        <SetupProvider>{children}</SetupProvider>
      </LanguageProvider>
    </ChatsProvider>
  </AuthProvider>
);

export default AppProvider;
