import styled, { keyframes } from 'styled-components';

interface IBackground {
  src: string;
}

export const Container = styled.div`
  background-color: var(--asphaltGray);
  min-height: 100vh;

  .position {
    right: 0;
    top: 0;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  .login {
    background: linear-gradient(
      180deg,
      var(--closedGrayOpacity) 0.29%,
      var(--closedGray) 101.74%
    );
    border-radius: 10px;
    color: var(--graniteGray);
  }

  > div {
    background: var(--closedGray);
    background: linear-gradient(
      136deg,
      var(--closedGrayOpacity) 0%,
      var(--closedGray) 100%
    );
    border: none;
    padding: 1px;
    background-image: linear-gradient(
        136deg,
        var(--asphaltGray),
        var(--asphaltGray)
      ),
      linear-gradient(136deg, var(--grayImmensity), var(--closedGray));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: var(--ultraWhite);

    @media screen and (min-width: 992px) {
      width: 75%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      width: 60%;
    }
  }

  h1 {
    margin-bottom: 24px;
    color: var(--graniteGray);
  }

  h2 {
    color: var(--technologicalGray);
    font-weight: bold !important;
  }

  p {
    color: var(--greyMineral);
  }

  .small {
    color: var(--grayImmensity);
  }

  .btn-submit {
    background-color: var(--midnight);
    color: var(--blizzard);
    -webkit-text-decoration: none;
    text-decoration: none;
    border: none;
    border-radius: 15px;
    :hover {
      background-color: var(--grayImmensity);
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }

  form {
    > div {
      padding: 6.5px 10px;
      border: 1px solid var(--mysteriousGrey);
      background-color: var(--asphaltGray);
    }
    input {
      width: 100%;
      height: 40px;
    }

    .button-show {
      height: 56px !important;
      top: -8px !important;
      right: 0 !important;
      font-weight: bold;
    }

    input[id='password'] {
      width: 81%;
    }

    a {
      color: var(--greyMineral);
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: var(--greyMineralDarken);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;
    }
  }
`;

export const Background = styled.div<IBackground>`
  background-image: url(${(props) => props.src});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  .shade {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      var(--midnight) 0%,
      var(--midnight) 100%
    );
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .content {
    position: relative;
    z-index: 1;
    opacity: 0.8;
  }
`;
