import React, { useEffect, useState, useCallback } from 'react';
import List from 'react-chatview';

import api from '~/services/api';

import { Container } from './styles';
import CoursesCategory from '~/components/CoursesCategory';

interface ICategories {
  id: string;
  name: string;
}

interface IMyCoursesProps {
  active: boolean;
}

const Discover: React.FC<IMyCoursesProps> = ({ active }) => {
  const [categories, setCategories] = useState<ICategories[]>([]);

  useEffect(() => {
    api
      .get('categories/students', {
        params: {
          page: 1,
        },
      })
      .then((response) => {
        setCategories(response.data);
      });
  }, []);

  const handleLoad = useCallback(async () => {
    try {
      const response = await api.get('categories/students', {
        params: {
          page: 1,
        },
      });
      if (response.data) {
        setCategories((state) => [...state, ...response.data]);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <List
        className="content"
        scrollLoadThreshold={100}
        onInfiniteLoad={handleLoad}
        key={(category: ICategories) => category.id}
      >
        {categories.map((category) => (
          <CoursesCategory
            category_id={category.id}
            category_name={category.name}
          />
        ))}
      </List>
    </Container>
  );
};

export default Discover;
