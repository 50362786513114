import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, Skeleton } from './styles';

interface ITableData {
  id: string;
  thumbail: string;
  name: string;
  courses: string[];
  price: string;
  slug: string;
  purchased: boolean;
  disabled: boolean;
  info: string;
}
interface ICourseProduct {
  id: string;
  course: {
    id: string;
    title: string;
  };
}

interface IProducts {
  id: string;
  thumbnail: {
    thumbnail_url: string;
  };
  name: string;
  courseProduct: ICourseProduct[];
  price: string;
  slug: string;
  mandatory_purchase: string;
  mandatory_gift: boolean;
}
interface IMyProductsProps {
  active: boolean;
  search?: string;
}

const MyProducts: React.FC<IMyProductsProps> = ({ active, search }) => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ITableData[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    api
      .get<IProducts[]>('products/students/my', {
        params: {
          name: search,
        },
      })
      .then((response) => {
        const data = response.data.map((product) => {
          const courses = product.courseProduct.map(
            (courseProduct) => courseProduct.course.title
          );

          let affiliateProduct;
          let disableProduct;
          if (user.affiliate && user.affiliate.affiliatesProducts) {
            affiliateProduct = user.affiliate.affiliatesProducts.find(
              (affiliateProductData) =>
                affiliateProductData.product_id === product.id
            );
            disableProduct = user.affiliate.affiliatesProducts.find(
              (affiliateProductData) =>
                affiliateProductData.product_id === product.mandatory_purchase
            );
          }

          const productFind = response.data.find(
            (productData) => productData.id === product.mandatory_purchase
          );

          const disabled =
            (!!product.mandatory_purchase && !disableProduct) ||
            !!(
              product.mandatory_purchase &&
              !disableProduct &&
              !product.mandatory_gift
            );

          return {
            id: product.id,
            thumbail: product.thumbnail.thumbnail_url,
            name: product.name,
            courses,
            price: product.price,
            slug: product.slug,
            purchased: !!affiliateProduct,
            disabled,
            info: productFind
              ? `${language.requires_purchase.requires_1} ${productFind.name} ${language.requires_purchase.requires_2}`
              : '',
          };
        });
        setProducts(data);
        setLoading(false);
      });
  }, [
    active,
    language.requires_purchase.requires_1,
    language.requires_purchase.requires_2,
    search,
    user,
  ]);

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="container-fluid">
          <div className="row">
            {loading && (
              <>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
                <Skeleton className="col-md-6 col-lg-12 col-xl-6 pb-4">
                  <div className="skeleton w-100" />
                </Skeleton>
              </>
            )}
            {products.map((product) => (
              <div
                key={product.id}
                className="col-md-6 col-lg-12 col-xl-6 pb-4"
              >
                <Box
                  src={product.thumbail}
                  className="course-link d-block p-relative thumb w-100"
                >
                  <div className="short-title w-100 p-2 p-lg-4 p-absolute">
                    <div className="row h-100">
                      <div className="col-12">
                        <p className="mb-0 py-2 pl-1 pl-xl-4 w-75 h5 h4-xxl mt-4">
                          {product.name}
                        </p>
                      </div>
                      <div className="col-12 ">
                        <div className="row h-100 align-items-end">
                          <div className="col-12">
                            <div className="see-more-link pl-1 pl-xl-4 pb-3">
                              <Link
                                to={`${process.env.PUBLIC_URL}/product/${product.slug}`}
                                className="h6 mr-1 py-2 px-4 show-courses"
                              >
                                {language.products.button_3}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};
export default MyProducts;
