import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  :hover {
    color: var(--graniteGrayLighten);
    background-color: var(--greyMineralLighten);
    transition: 0.5s;
    img {
      filter: brightness(1.5);
      transition: 0.5s;
    }
  }

  span {
    color: var(--grayImmensity);
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: 44px;
    padding: 0 !important;
  }
`;

export const Modal = styled(ModalComponent)`
  .payment-button {
    :hover {
      color: var(--graniteGrayLighten);
      background-color: var(--greyMineralLighten);
      transition: 0.5s;
    }
  }
`;
