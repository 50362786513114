import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.footer`
  transition-duration: 0.3s;
  padding: 10px 20px;

  h2 {
    color: var(--blizzard);
  }

  .bg-user {
    background: var(--asphaltGray);
    border-radius: 20px;
  }

  .btn-copy {
    background-color: transparent;
    border: solid 1.5px var(--grayImmensity);
    border-radius: 15px;
    height: 56px;
    color: var(--graniteGray);
    font-weight: 300;
    width: 95%;
  }

  .color-gray {
    color: var(--grayImmensity);
  }
`;

export const Profile = styled.div`
  img {
    padding: 3px;
    background-image: linear-gradient(0deg, var(--coal), var(--coal)),
      radial-gradient(
        circle at top left,
        var(--blizzardOpacity),
        var(--blizzardOpacity)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  p {
    color: var(--ultraWhiteOpacity);
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;

    border: none !important;
    color: transparent !important;
    pointer-events: none;
    box-shadow: none;
    span {
      color: transparent !important;
    }
    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px var(--blizzardOpacity);
  box-sizing: border-box;
`;

export const Social = styled.div`
  .titleSocial {
    background: var(--closedGray);
    border-radius: 20px 20px 0px 0px;
  }

  h4 {
    color: var(--blizzard);
    font-weight: 400 !important;
  }

  p {
    color: var(--blizzard) !important;
  }

  .contentSocial {
    background: var(--asphaltGray);
    border-radius: 0 0 20px 20px;
  }
`;

export const Introduction = styled.div`
  background-color: var(--asphaltGray);
  border-radius: 20px;
  h3 {
    color: var(--graniteGray);
  }
  hr {
    background-color: var(--closedGray);
  }
  p {
    color: var(--greyMineral);
  }
  button {
    border: none;
    border-radius: 20px;
    background-color: var(--technologicalGray);
    color: var(--midnight);
  }
`;
