import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FaPlay } from 'react-icons/fa';
import { Canvas, Container, Music } from './styles';

interface IAnalyzer {
  analyzer: AnalyserNode;
  bufferLength: number;
  dataArray: Uint8Array;
}

interface IWaveForm {
  analyzerData: IAnalyzer;
}

interface IAudio {
  audio: string;
  thumbnail?: string;
}

let source: MediaElementAudioSourceNode | undefined;
let audioCtx: any;
let analyzer: any;

let aniLoop = 0;

let play = false;

function animateBars(
  analyser: AnalyserNode,
  canvas: HTMLCanvasElement,
  canvasCtx: CanvasRenderingContext2D | null,
  dataArray: Uint8Array,
  bufferLength: number
) {
  if (canvasCtx) {
    const ctxCanvas = canvasCtx;

    analyser.getByteFrequencyData(dataArray);

    const gradient = ctxCanvas.createLinearGradient(0, 0, canvas.width, 0);

    gradient.addColorStop(0, '#9DF6CB99');
    gradient.addColorStop(0.15, '#769BED99');
    gradient.addColorStop(0.3, '#079CFE99');
    gradient.addColorStop(0.4, '#9445C299');
    gradient.addColorStop(0.6, '#C341AE99');
    gradient.addColorStop(1, '#FB5A4399');

    const HEIGHT = canvas.height;
    const barWidth = Math.ceil(canvas.width / bufferLength) * 5;
    let barHeight;
    let x = 0;

    for (let i = 0; i < bufferLength; i += 1) {
      barHeight = (dataArray[i] / 300) * HEIGHT;
      ctxCanvas.fillStyle = gradient; // use gradient instead of solid color
      ctxCanvas.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);
      x += barWidth + 1;
    }
  }
}

const WaveForm: React.FC<IWaveForm> = ({ analyzerData }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const draw = useCallback((dataArrayData, dataAnalyzer, bufferLengthData) => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      if (!canvas || !dataAnalyzer) return;
      const canvasCtx = canvas.getContext('2d');

      const animate = () => {
        // eslint-disable-next-line no-self-assign
        canvas.width = canvas.width;
        animateBars(
          dataAnalyzer,
          canvas,
          canvasCtx,
          dataArrayData,
          bufferLengthData
        );

        aniLoop = requestAnimationFrame(animate);
        if (!play) {
          cancelAnimationFrame(aniLoop);
        }
      };

      animate();
    }
  }, []);

  useEffect(() => {
    draw(
      analyzerData.dataArray,
      analyzerData.analyzer,
      analyzerData.bufferLength
    );
    console.log('draw');
  }, [
    analyzerData.analyzer,
    analyzerData.bufferLength,
    analyzerData.dataArray,
    draw,
  ]);

  return <Canvas ref={canvasRef} />;
};

const WavesAudio: React.FC<IAudio> = ({ audio, thumbnail }) => {
  const [analyzerData, setAnalyzerData] = useState({} as IAnalyzer);
  const audioElmRef = useRef<HTMLAudioElement>(null);
  const [isFocused, setIsfocused] = useState(false);
  const [btnPlay, setBtnPlay] = useState(true);

  useEffect(() => {
    setIsfocused(true);
    return () => {
      setIsfocused(false);
      analyzer = undefined;
      audioCtx = undefined;
      source = undefined;
      play = false;
    };
  }, []);

  const audioAnalyzer = useCallback(() => {
    if (!analyzer && !audioCtx) {
      audioCtx = new window.AudioContext();
      analyzer = audioCtx.createAnalyser();
      analyzer.fftSize = 2048;
    }
    const bufferLength = analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    if (audioElmRef.current) {
      if (source) {
        source.disconnect();
      } else {
        source = audioCtx.createMediaElementSource(audioElmRef.current);
      }

      source?.connect(analyzer);
      source?.connect(audioCtx.destination);
    }

    // set the analyzerData state with the analyzer, bufferLength, and dataArray
    const data = { analyzer, bufferLength, dataArray };
    setAnalyzerData(data);

    return () => audioCtx.close();
  }, []);

  const handleLoadAudio = useCallback(() => {
    console.log('play');
    audioAnalyzer();
    setBtnPlay(false);
    play = true;
  }, [audioAnalyzer]);

  const handlePlay = useCallback(() => {
    if (audioElmRef.current) {
      if (btnPlay) {
        audioElmRef.current.play();
        setBtnPlay(false);
        play = true;
      } else {
        audioElmRef.current.pause();
        setBtnPlay(true);
        play = false;
      }
    }
  }, [btnPlay]);

  const handlePause = useCallback(() => {
    if (audioElmRef.current) {
      audioElmRef.current.pause();
      setBtnPlay(true);
      play = false;
    }
  }, []);

  return (
    <Container className="container">
      <div className="row">
        <div className="col-12 px-0">
          <Music thumbnail={thumbnail} className="position-relative">
            <button
              type="button"
              className={`${!btnPlay && 'opacity-0'} h-100 w-100`}
              onClick={handlePlay}
            >
              <FaPlay size={50} color="#ddd" />
            </button>
            {isFocused && <WaveForm analyzerData={analyzerData} />}
          </Music>
        </div>
        <div className="col-12 px-0 my-3">
          <div className="d-flex align-items-center justify-content-between">
            <audio
              src={audio}
              controls
              className="w-100"
              ref={audioElmRef}
              onPlay={handleLoadAudio}
              onPause={handlePause}
              crossOrigin="anonymous"
            >
              <track kind="captions" />
            </audio>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WavesAudio;
