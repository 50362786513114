import React, { useCallback, useState } from 'react';

import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import Textarea from '~/components/Textarea';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, CopyAlert, Modal } from './styles';

import tetherLogo from '~/assets/logotipos/tether-logo.png';
import tetherSvg from '~/assets/logotipos/tether-logo.svg';
import closeIcon from '~/assets/icons/close.svg';
import qrCodeTether from '~/assets/defaults/qr-code-tether.png';
import copy from '~/assets/icons/copy.svg';
import example from '~/assets/defaults/img-example1.png';

interface ITetherPayProps {
  order_id?: string;
  product_id: string;
  title: string;
  price: string;
  totalPrice?: string;
  installments?: number;
  anticipated?: boolean;
}

const TetherPay: React.FC<ITetherPayProps> = ({
  order_id,
  product_id,
  title,
  price,
  totalPrice,
  installments,
  anticipated,
}) => {
  const { language } = useLanguage();
  const [show, setShow] = useState(false);
  const [showTether, setShowTether] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(async () => {
    setShow(true);
  }, []);

  const handleTetherClose = useCallback(() => setShowTether(false), []);
  const handleTetherShow = useCallback(async () => {
    setLoading(true);
    try {
      const data = {
        order_id,
        product_id,
        amount: parseFloat(price),
        installments,
        anticipated,
      };
      const response = await api.post('payments/tether', data);
      if (response.data) {
        setOrderId(response.data.id);
        setShowTether(true);
      }
    } catch (error) {
      Swal.fire('Opss...', language.error_component.error_message, 'error');
    } finally {
      setLoading(false);
    }
  }, [
    anticipated,
    installments,
    language.error_component.error_message,
    order_id,
    price,
    product_id,
  ]);

  const handleSubmit = useCallback(
    async ({ transaction_hash }) => {
      setLoading(true);
      try {
        const response = await api.patch(`orders/products/${orderId}`, {
          transaction_hash,
        });

        if (response.data) {
          Swal.fire(
            language.error_component.all_right_message,
            language.error_component.verification_message,
            'success'
          ).then(() => {
            handleTetherClose();

            handleClose();
            setAlreadyPaid(false);
          });
        }
      } catch (error) {
        Swal.fire('Opss...', language.error_component.error_message, 'error');
      } finally {
        setLoading(false);
      }
    },
    [
      handleClose,
      handleTetherClose,
      language.error_component.all_right_message,
      language.error_component.error_message,
      language.error_component.verification_message,
      orderId,
    ]
  );

  const copyAddress = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = 'TMwjeYr7me4M4GVHmwy6MWTtsUXQw3tRTo';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, []);

  return (
    <Container>
      <button
        type="button"
        className="h4 w-100 h-100 payment-button"
        onClick={handleTetherShow}
      >
        <img src={tetherLogo} alt="Tether" className="mb-5 mb-xl-4" />
        Tether <br />
        <span className="font-size-50">{language.buy_product_button.span}</span>
      </button>

      <Modal className="modal-tether px-0" centered size="xl" show={showTether}>
        <Modal.Header className="border-0 p-3">
          <Modal.Title className="text-center w-100" />
          <button
            type="button"
            className="close-button"
            onClick={handleTetherClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-3 px-sm-5">
          {!alreadyPaid ? (
            <>
              <div className="col-12">
                <h3 className="h5 h3-sm payment-color mb-4 text-center">
                  {title}
                </h3>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-6 pt-4 pb-5 px-3 px-sm-5 text-center tether-box">
                  <h4 className="text-center font-weight-bold mt-4">
                    {price} USDT{' '}
                    <img
                      src={tetherSvg}
                      alt="Binance"
                      className="ml-4 img-icon"
                    />
                  </h4>
                  {totalPrice && (
                    <s className="old-price small">${totalPrice}</s>
                  )}
                  <div className="d-flex justify-content-center my-5">
                    <img
                      src={qrCodeTether}
                      alt="QR CODE"
                      className="qr-code-binance"
                    />
                  </div>
                  <button
                    className="btn-copy font-size-78"
                    type="button"
                    onClick={copyAddress}
                  >
                    TMwjeYr7me4M4GVHmwy6MWTtsUXQw3tRTo
                    <img src={copy} alt="copy" className="ml-2" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setAlreadyPaid(true)}
                    className="btn-confirm"
                  >
                    {language.buy_product_button_tether.button_1}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-9 col-xl-7 px-0 px-sm-3 mx-auto text-center">
                <h3 className="h5 h3-sm payment-color mb-4 ">
                  {language.buy_product_button_tether.h3}
                </h3>

                <div className="card-exemple">
                  <p className="px-4 px-sm-5 pt-2 pt-sm-3 mb-2 mb-sm-3">
                    <span className="px-lg-4 small normal-sm">
                      {language.buy_product_button_tether.span}
                    </span>
                  </p>
                  <img
                    src={example}
                    alt="Example"
                    className="w-100 img-exemple"
                  />
                </div>
              </div>
              <Form
                onSubmit={handleSubmit}
                className="col-lg-9 col-xl-7 mx-auto px-0 px-sm-3 pt-4 pb-5"
              >
                <Textarea
                  name="transaction_hash"
                  placeholder={language.buy_product_button_tether.placeholder}
                />
                <button
                  type="submit"
                  className="btn-submit-tether font-weight-bold"
                >
                  {language.buy_product_button_tether.button_2}
                </button>
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
      <CopyAlert show={copied} className="alert">
        <p className="mb-0">Copied</p>
      </CopyAlert>
    </Container>
  );
};

export default TetherPay;
