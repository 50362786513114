import styled from 'styled-components';

interface IButtonQuestion {
  active: boolean;
  correct?: boolean;
  incorrect?: boolean;
}

export const Container = styled.div`
  .border-silver {
    cursor: not-allowed;
  }
  .border-quizz {
    border-radius: 15px;
    background-color: var(--greyMineral);
  }
`;

export const ButtonQuestion = styled.button<IButtonQuestion>`
  color: ${(props) => (props.active ? '#bdbdbd' : 'var(--grayImmensity)')};
  background: ${(props) =>
    props.active ? 'var(--mysteriousGrey)' : 'var(--asphaltGray)'};
  border-radius: 7px;
  font-weight: 600;
  border: none;
  padding: 10px;
  transition-duration: 0.3s;
  position: relative;

  + button {
    margin-left: 12px;
  }

  :hover {
    color: var(--graniteGray);
    background: var(--mysteriousGrey);
  }

  svg {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition-duration: 0.3s;
  }

  .correct {
    opacity: ${(props) => (props.correct ? 1 : 0)};
  }

  .incorrect {
    opacity: ${(props) => (props.incorrect ? 1 : 0)};
  }
`;

export const Question = styled.div`
  border-radius: 10px;
  padding: 30px 0;

  + div {
    margin-top: 10px;
  }

  .message-alert {
    border-radius: 10px;
    color: var(--ultraWhite);
    padding: 5px 20px;
    min-width: 150px;
    text-align: center;
  }

  .success {
    background: var(--success);
  }

  .error {
    background: var(--error);
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
`;

export const Answer = styled.div`
  label {
    color: var(--graniteGray);
    opacity: 0.8;
    border: 1.5px solid var(--grayImmensity);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.3s;

    .answer {
      width: auto;
      padding: 0;
      background: none;
      border: 0;

      input {
        width: auto;
      }
    }
  }

  label.selected,
  label:hover {
    border: 1.5px solid var(--blizzard);
    background: var(--blizzard);
    opacity: 0.8;
    color: var(--greyMineral);
  }

  + div {
    margin-top: 10px;
  }
`;
