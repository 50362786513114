import React from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import facebook from '~/assets/icons/facebook.svg';
import telegram from '~/assets/icons/telegram.svg';
import instagram from '~/assets/icons/instagram.svg';
import twitter from '~/assets/icons/twitter.svg';

const Social: React.FC = () => {
  const { language } = useLanguage();

  return (
    <Container className="my-3">
      <div className="container">
        <div className="row text-center">
          <div className="col-12 titleSocial py-2">
            <h4 className="h6 small py-1 my-1">{language.footer.h4}</h4>
          </div>
          <div className="col-12 px-3 contentSocial">
            <p className="small text-center mt-3">
              <b>{language.footer.b}</b>
              &nbsp;{language.footer.p_2}
            </p>
            <div className="d-flex justify-content-around pb-3">
              <a href="https://twitter.com/WiserrOfficial" target="blank">
                <img
                  src={twitter}
                  alt={language.footer.img_1}
                  className="mx-1"
                />
              </a>
              <a href="https://facebook.com/wiserrofficial/" target="blank">
                <img
                  src={facebook}
                  alt={language.footer.img_2}
                  className="mx-1"
                />
              </a>
              <a href="https://t.me/wiserrofficial" target="blank">
                <img
                  src={telegram}
                  alt={language.footer.img_3}
                  className="mx-1"
                />
              </a>
              <a
                href="https://www.instagram.com/millonario.digital/"
                target="blank"
              >
                <img
                  src={instagram}
                  alt={language.footer.img_4}
                  className="mx-1"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Social;
