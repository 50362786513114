import styled from 'styled-components';
import banner from '~/assets/banners/banner-faq.jpg';

export const Container = styled.div`
  min-height: calc(100vh - 60px);

  .h-40vh {
    height: 40vh;
  }

  .text-no-courses {
    color: var(--greyMineral);
    font-weight: 600;
  }
`;

export const Welcome = styled.div`
  background-image: url(${banner});
  background-position: center;
  background-size: cover;
  box-shadow: -2px 5px 15px var(--midnightOpacity);
  p {
    color: var(--technologicalGray);
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: none;
    background-color: var(--mysteriousGrey);

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      color: var(--graniteGray);
      background-color: var(--mysteriousGrey);
    }
  }
`;

export const Question = styled.div`
  .question {
    transition-duration: 0.3s;
    background: var(--asphaltGray);
    color: var(--graniteGray);
    border-radius: 20px;

    svg {
      min-height: 25px;
      min-width: 25px;
      transition-duration: 0.5s;
    }
  }

  .answer {
    transition-duration: 0.3s;
    background: var(--coal);
    color: var(--technologicalGray);
    border-radius: 0 0 15px 15px;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    p {
      overflow: auto;
      height: 130px;
      padding-right: 10px;
      ::-webkit-scrollbar {
        background-color: transparent !important;
        width: 5px;
      }
    }
  }

  .question-box.active {
    .question {
      border-radius: 15px 15px 0px 0px;

      svg {
        transform: rotate(180deg);
        transition-duration: 0.5s;
      }
    }

    .answer {
      height: 180px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      p {
        white-space: pre-line;
      }

      ::-webkit-scrollbar {
        background-color: var(--coal);
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--asphaltGray);
      }
    }
  }
`;

export const ChatButton = styled.a`
  width: 103px;
  height: 103px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 100;
  cursor: pointer;
  border: none;
  padding: 2px;
  background-color: var(--greyMineral);
  border-radius: 10px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: var(--ultraWhite);
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
