import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Welcome } from './styles';

import affiliateIcon from '~/assets/icons/affiliate-icon.svg';
import franchise from '~/assets/icons/franchise-icon.svg';
import check from '~/assets/icons/icon-circle-check.svg';
import noCheck from '~/assets/icons/icon-circle-gray.svg';

const Affiliate: React.FC = () => {
  const history = useHistory();
  const { user, updateUser } = useAuth();
  const { language, idiom } = useLanguage();
  const [hasCourse, setHasCourse] = useState(false);

  useEffect(() => {
    api.get('courses/students/my').then((response) => {
      setHasCourse(response.data.length > 0);
    });
  }, []);

  const handleClickUpgradeAffiliate = useCallback(async () => {
    if (user.affiliate) {
      const affiliate = {
        active_affiliate: true,
        affiliate_commission: user.affiliate.affiliate_commission,
        franchise_owner: user.affiliate.franchise_owner,
        crowdfunding_participation: user.affiliate.crowdfunding_participation,
        crowdfunding_share: user.affiliate.crowdfunding_share,
        franchise_commission: user.affiliate.franchise_commission,
        is_elite: user.affiliate.is_elite,
      };
      const response = await api.put(
        `affiliates/students/${user.affiliate.id}`,
        affiliate
      );
      const userData = user;
      userData.affiliate = response.data;
      updateUser(userData);
    }
  }, [updateUser, user]);

  const handleClickUpgradeFranchise = useCallback(async () => {
    history.push(`${process.env.PUBLIC_URL}/${idiom}/products/discover`);
  }, [history, idiom]);

  return (
    <Container className="py-3 py-lg-0 text-center text-md-left">
      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2 mb-3">{language.become_affiliate.h1}</h1>
                    <h2 className="h6 mb-4">
                      {language.become_affiliate.h2_1}
                    </h2>
                    <p className="h6">{language.become_affiliate.p_1}</p>
                    <p className="h6">{language.become_affiliate.p_2}</p>

                    <h3 className="h6 mt-4 mt-lg-5">
                      {language.become_affiliate.h3_1}
                    </h3>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-5 my-2 my-lg-5 order-1 order-xl-0">
              <div className="row text-left align-items-center p-4 p-sm-5 m-2 ml-0 box">
                <div className="d-flex col-7 px-0">
                  <h2 className="h5 h3-sm mb-0">
                    {language.become_affiliate.h2_2}
                  </h2>
                  <img
                    src={affiliateIcon}
                    alt={language.become_affiliate.img_1}
                    className="ml-4"
                  />
                </div>
                <div className="col-5 text-right">
                  <h3 className="h5 h2-sm">{language.become_affiliate.h3_2}</h3>
                </div>
                <div className="col-12 px-0">
                  <hr className="rounded-pill w-25" />
                </div>
                <div className="col-12 px-0">
                  <p className="h5 py-4">{language.become_affiliate.p_3}</p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={noCheck} alt="Icon no check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0">{language.become_affiliate.p_4}</p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={check} alt="Icon check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0">
                    <span>10% </span>
                    {language.become_affiliate.p_6}
                  </p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={noCheck} alt="Icon no check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0">{language.become_affiliate.p_8}</p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={check} alt="Icon check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0 pr-xxl-3">
                    <span className="text-uppercase">
                      {language.become_affiliate.h3_2}{' '}
                    </span>
                    {language.become_affiliate.p_10}
                  </p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={check} alt="Icon check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0">
                    <span> {language.become_affiliate.span_1} </span>
                    {language.become_affiliate.p_12}
                  </p>
                </div>
                <div className="col-1 mt-2 mb-2 pl-0 text-center text-md-right">
                  <img src={noCheck} alt="Icon no check" />
                </div>
                <div className="col-11 col-md-5 col-lg-11 mb-2 mt-2">
                  <p className="mb-0">{language.become_affiliate.p_14}</p>
                </div>
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="w-75 mt-5 mb-3"
                    disabled={
                      !hasCourse ||
                      (user.affiliate && user.affiliate.active_affiliate)
                    }
                    onClick={handleClickUpgradeAffiliate}
                  >
                    <span className="d-block py-3">
                      {user.affiliate && user.affiliate.active_affiliate
                        ? language.become_affiliate.button_1_2
                        : language.become_affiliate.button_1_1}
                    </span>
                  </button>
                  <Link to="/" className="d-block">
                    {language.become_affiliate.a_1}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 my-2 my-lg-4 px-xl-0 pl-xxl-3 order-0 order-xl-1">
              <div className="row align-items-center text-left p-4 p-sm-owner m-2 mr-0 box-light">
                <div className="d-flex col-9 px-0">
                  <h2 className="h5 h3-sm mb-0">
                    {language.become_affiliate.h2_3}
                  </h2>
                  <img
                    src={franchise}
                    alt={language.become_affiliate.img_2}
                    className="ml-4"
                  />
                </div>
                <div className="col-3 text-right">
                  <h3 className="h5 h2-sm">
                    <span className="dollar px-3 py-2">$</span>
                  </h3>
                </div>
                <div className="col-12 px-0">
                  <hr className="rounded-pill w-25" />
                </div>

                <div className="row my-xl-3 pt-xl-3 pb-xl-2 align-items-center">
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span>40% </span>
                      {language.become_affiliate.p_16}
                    </p>
                  </div>
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span>10% </span>
                      {language.become_affiliate.p_18}
                    </p>
                  </div>
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span>{language.become_affiliate.span_2} </span>
                      {language.become_affiliate.p_20}
                    </p>
                  </div>
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span className="text-uppercase">
                        {language.become_affiliate.h3_2}{' '}
                      </span>
                      {language.become_affiliate.p_22}
                    </p>
                  </div>
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span>{language.become_affiliate.span_1} </span>
                      {language.become_affiliate.p_24}
                    </p>
                  </div>
                  <div className="col-1 pr-0 pr-sm-3 mt-3 mb-2 text-center text-md-right">
                    <img src={check} alt="Icon check" />
                  </div>
                  <div className="col-11 col-md-5 col-lg-11 pl-4 pl-sm-3 pl-xl-4 mb-2 mt-3">
                    <p className="mb-0 h6 h5-sm">
                      <span>{language.become_affiliate.span_3} </span>
                      {language.become_affiliate.p_26}
                    </p>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="w-75 mt-5 mt-xl-4 mb-3"
                    onClick={handleClickUpgradeFranchise}
                  >
                    <span className="d-block py-3">
                      {user.affiliate && user.affiliate.franchise_owner
                        ? language.become_affiliate.button_2_2
                        : language.become_affiliate.button_2_1}
                    </span>
                  </button>
                  <Link to="/" className="d-block ">
                    {language.become_affiliate.a_2}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Affiliate;
