import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { differenceInDays, parseISO, getDate, getYear, format } from 'date-fns';

import api from '~/services/api';

import { Container, Box, Status, Modal } from './styles';
import Graph from '~/components/GraphParcel';
import Table from '~/components/Table';
import BuyButton from '~/components/BuyButton';
import { useLanguage } from '~/hooks/Language';
import { formatPrice } from '~/utils/format';
import closeIcon from '~/assets/icons/close.svg';
import walletIcon from '~/assets/icons/wallet.svg';
import check from '~/assets/icons/check.svg';

interface IProduct {
  id: string;
  name: string;
  price: number;
}

interface IOffer {
  id: string;
  title: string;
  price: number;
}

interface ISubOrderResponse {
  id: string;
  amount_paid: string;
  updated_at: string;
}

interface IInstallmentOrderResponse {
  id: string;
  status: string;
  current_installment: number;
  amount_paid: string;
  due_date?: string;
  installments: number;
  updated_at: string;
  subOrders: ISubOrderResponse[];
  merchant_id: string;
  anticipated: boolean;
  deleted_at?: Date;
}

interface IInstallmentResponse {
  id: string;
  status: string;
  current_installment: number;
  amount_paid: string;
  due_date?: string;
  installments: number;
  installmentsOrders: IInstallmentOrderResponse[];
  product: IProduct;
  offer: IOffer;
  merchant_id: string;
  updated_at: string;
}

interface IInstallmentData {
  status: string;
}

interface IPayment {
  id: string;
  date: string;
  amount: string;
  anticipated: boolean;
}

interface IInstallment {
  id: string;
  product_id: string;
  offer_id: string;
  name: string;
  status: string;
  credit_amount: string;
  installmentsData: IInstallmentData[];
  amount: string;
  paid: string;
  left_pay: string;
  due_day: number;
  due_month: string;
  due_year: number;
  overdue: boolean;
  all_paid: boolean;
  save: string;
  remaining_amount: string;
  order_id: string;
  payments: IPayment[];
  anticipated: boolean;
  anticipated_amount: string;
}

interface IPaymentOptionsProps {
  active: boolean;
}

const PaymentPlans: React.FC<IPaymentOptionsProps> = ({ active }) => {
  const { language } = useLanguage();
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [installmentSelectedData, setInstallmentsSelectedData] = useState<
    IInstallment
  >({} as IInstallment);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => {
    setShow(false);
    setInstallmentsSelectedData({} as IInstallment);
  }, []);
  const handleShow = useCallback(async (installment) => {
    setInstallmentsSelectedData(installment);
    if (installment.product_id) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    api.get<IInstallmentResponse[]>('orders/students').then((response) => {
      const responseData = response.data.filter(
        (installment) => !!installment.product || !!installment.offer
      );

      const data: IInstallment[] = responseData.map((installment) => {
        const current_date = new Date();
        let name = '';
        if (installment.product) {
          name = installment.product.name;
        } else {
          name = installment.offer.title;
        }

        const installmentSelected: IInstallment = {
          id: '',
          product_id: installment.product ? installment.product.id : '',
          offer_id: installment.offer ? installment.offer.id : '',
          name,
          status: '',
          credit_amount: '',
          installmentsData: [],
          amount: '',
          paid: '',
          left_pay: '',
          due_day: 0,
          due_month: '',
          due_year: 0,
          overdue: false,
          all_paid: false,
          save: '',
          remaining_amount: '',
          order_id: installment.id,
          payments: [],
          anticipated: false,
          anticipated_amount: '$0.00',
        };

        const installmentsDatas: IInstallmentData[] = [];
        const installmentData: IInstallmentData = {
          status: installment.status,
        };

        let paid = 0;
        let anticipated_amount = 0;
        let left_pay = 0;
        let save = 0;

        installmentsDatas.push(installmentData);

        let descountData: {
          discount: number;
          price: number;
        }[] = [];

        if (installment.product) {
          descountData = [
            {
              discount:
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.25
                ) - parseFloat(installment.product.price.toString()),
              price:
                parseFloat(installment.product.price.toString()) +
                parseFloat(installment.product.price.toString()) * 0,
            },
            {
              discount:
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.25
                ) -
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.1
                ),
              price: parseFloat(
                (
                  (parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.1) /
                  2
                ).toFixed(0)
              ),
            },
            {
              discount:
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.25
                ) -
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.15
                ),
              price: parseFloat(
                (
                  (parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.15) /
                  3
                ).toFixed(0)
              ),
            },
            {
              discount:
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.25
                ) -
                Math.ceil(
                  parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.2
                ),
              price: parseFloat(
                (
                  (parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.2) /
                  4
                ).toFixed(0)
              ),
            },
            {
              discount: 0,
              price: parseFloat(
                (
                  (parseFloat(installment.product.price.toString()) +
                    parseFloat(installment.product.price.toString()) * 0.25) /
                  5
                ).toFixed(0)
              ),
            },
          ];
        }

        if (installment.status !== 'succeeded') {
          installmentSelected.id = installment.id;
          left_pay += parseFloat(installment.amount_paid);

          const due_date = parseISO(
            installment.due_date || installment.updated_at
          );

          const diffDay = differenceInDays(due_date, current_date);

          if (diffDay < 0) {
            const day = diffDay * -1;
            if (day === 1) {
              installmentSelected.status = `${day} ${language.payment_plans.status_1}`;
            } else {
              installmentSelected.status = `${day} ${language.payment_plans.status_2}`;
            }
            installmentSelected.overdue = true;
          } else if (diffDay > 0) {
            if (diffDay === 1) {
              installmentSelected.status = `${diffDay} ${language.payment_plans.status_3}`;
            } else {
              installmentSelected.status = `${diffDay} ${language.payment_plans.status_4}`;
            }
          } else {
            installmentSelected.status = `${language.payment_plans.status_5}`;
          }

          installmentSelected.amount = formatPrice(
            parseFloat(installment.amount_paid)
          );

          installmentSelected.due_day = getDate(due_date);
          installmentSelected.due_month = format(due_date, 'MMMM');
          installmentSelected.due_year = getYear(due_date);

          if (installment.installments === installment.current_installment) {
            save = 0;
            installmentSelected.remaining_amount = formatPrice(
              parseFloat(installment.amount_paid)
            );
          } else if (descountData.length > 0) {
            save = descountData[installment.current_installment - 1].discount;
            installmentSelected.remaining_amount = formatPrice(
              descountData[installment.current_installment - 1].price
            );
          }
        } else {
          paid += parseFloat(installment.amount_paid);
          anticipated_amount += parseFloat(installment.amount_paid);
          installmentSelected.payments.push({
            id: installment.id,
            date: format(parseISO(installment.updated_at), 'MMM dd, yyyy'),
            amount: formatPrice(parseFloat(installment.amount_paid)),
            anticipated: false,
          });

          if (installment.installmentsOrders.length === 0) {
            installmentSelected.id = installment.id;
            const due_date = parseISO(installment.updated_at);

            installmentSelected.status = `${language.payment_plans.status_6}`;
            installmentSelected.all_paid = true;
            save = 0;
            installmentSelected.remaining_amount = formatPrice(0);

            installmentSelected.amount = formatPrice(
              parseFloat(installment.amount_paid)
            );

            installmentSelected.due_day = getDate(due_date);
            installmentSelected.due_month = format(due_date, 'MMMM');
            installmentSelected.due_year = getYear(due_date);
          }
        }

        let credit_amount = parseFloat(installment.amount_paid);

        const anticipatedOrder = installment.installmentsOrders.find(
          (installmentOrder) =>
            installmentOrder.anticipated &&
            installmentOrder.status === 'succeeded'
        );

        installment.installmentsOrders.forEach((subInstallment) => {
          if (!subInstallment.deleted_at) {
            if (!subInstallment.anticipated) {
              const subInstallmentData: IInstallmentData = {
                status: subInstallment.status,
              };
              installmentsDatas.push(subInstallmentData);
              if (subInstallment.status !== 'succeeded') {
                if (subInstallment.subOrders.length > 0) {
                  subInstallment.subOrders.forEach((subOrder) => {
                    credit_amount += parseFloat(subOrder.amount_paid);
                    paid += parseFloat(subOrder.amount_paid);
                    installmentSelected.payments.push({
                      id: subOrder.id,
                      date: format(
                        parseISO(subOrder.updated_at),
                        'MMM dd, yyyy'
                      ),
                      amount: formatPrice(parseFloat(subOrder.amount_paid)),
                      anticipated: false,
                    });
                  });
                }

                left_pay += parseFloat(subInstallment.amount_paid);
                if (!installmentSelected.id) {
                  installmentSelected.id = subInstallment.id;

                  const due_date = parseISO(
                    subInstallment.due_date || subInstallment.updated_at
                  );
                  const diffDay = differenceInDays(due_date, current_date);

                  if (diffDay < 0) {
                    const day = diffDay * -1;
                    if (day === 1) {
                      installmentSelected.status = `${day} ${language.payment_plans.status_1}`;
                    } else {
                      installmentSelected.status = `${day} ${language.payment_plans.status_2}`;
                    }
                    installmentSelected.overdue = true;
                  } else if (diffDay > 0) {
                    if (diffDay === 1) {
                      installmentSelected.status = `${diffDay} ${language.payment_plans.status_3}`;
                    } else {
                      installmentSelected.status = `${diffDay} ${language.payment_plans.status_4}`;
                    }
                  } else {
                    installmentSelected.status = `${language.payment_plans.status_5}`;
                  }

                  installmentSelected.amount = formatPrice(
                    parseFloat(subInstallment.amount_paid)
                  );

                  installmentSelected.due_day = getDate(due_date);
                  installmentSelected.due_month = format(due_date, 'MMMM');
                  installmentSelected.due_year = getYear(due_date);

                  if (
                    installment.installments ===
                    subInstallment.current_installment
                  ) {
                    save = 0;
                    installmentSelected.remaining_amount = formatPrice(
                      parseFloat(subInstallment.amount_paid)
                    );
                  } else {
                    save =
                      descountData[subInstallment.current_installment - 1]
                        .discount;
                  }
                }
              } else {
                if (subInstallment.subOrders.length > 0) {
                  subInstallment.subOrders.forEach((subOrder) => {
                    credit_amount += parseFloat(subOrder.amount_paid);
                    paid += parseFloat(subOrder.amount_paid);
                    installmentSelected.payments.push({
                      id: subOrder.id,
                      date: format(
                        parseISO(subOrder.updated_at),
                        'MMM dd, yyyy'
                      ),
                      amount: formatPrice(parseFloat(subOrder.amount_paid)),
                      anticipated: false,
                    });
                  });
                }

                paid += parseFloat(subInstallment.amount_paid);
                if (!anticipatedOrder) {
                  installmentSelected.payments.push({
                    id: subInstallment.id,
                    date: format(
                      parseISO(subInstallment.updated_at),
                      'MMM dd, yyyy'
                    ),
                    amount: formatPrice(parseFloat(subInstallment.amount_paid)),
                    anticipated: false,
                  });
                } else if (subInstallment.due_date) {
                  if (
                    parseISO(anticipatedOrder.updated_at) <
                      parseISO(subInstallment.due_date) &&
                    !subInstallment.merchant_id
                  ) {
                    installmentSelected.payments.push({
                      id: subInstallment.id,
                      date: format(
                        parseISO(subInstallment.due_date),
                        'MMM dd, yyyy'
                      ),
                      amount: formatPrice(
                        parseFloat(subInstallment.amount_paid)
                      ),
                      anticipated: true,
                    });
                  } else {
                    anticipated_amount = paid;
                    installmentSelected.payments.push({
                      id: subInstallment.id,
                      date: format(
                        parseISO(subInstallment.updated_at),
                        'MMM dd, yyyy'
                      ),
                      amount: formatPrice(
                        parseFloat(subInstallment.amount_paid)
                      ),
                      anticipated: false,
                    });
                  }
                }

                if (
                  installment.installments ===
                  subInstallment.current_installment
                ) {
                  installmentSelected.id = subInstallment.id;
                  const due_date = parseISO(subInstallment.updated_at);

                  installmentSelected.status = `${language.payment_plans.status_6}`;
                  installmentSelected.all_paid = true;
                  save = 0;
                  installmentSelected.remaining_amount = formatPrice(0);

                  installmentSelected.amount = formatPrice(
                    parseFloat(subInstallment.amount_paid)
                  );

                  installmentSelected.due_day = getDate(due_date);
                  installmentSelected.due_month = format(due_date, 'MMMM');
                  installmentSelected.due_year = getYear(due_date);
                }
              }

              credit_amount += parseFloat(subInstallment.amount_paid);
            }
          }
        });

        if (anticipatedOrder) {
          anticipated_amount += parseFloat(anticipatedOrder.amount_paid);
          installmentSelected.anticipated = true;
          installmentSelected.anticipated_amount = formatPrice(
            anticipated_amount
          );
          installmentSelected.payments.push({
            id: anticipatedOrder.id,
            date: format(parseISO(anticipatedOrder.updated_at), 'MMM dd, yyyy'),
            amount: formatPrice(parseFloat(anticipatedOrder.amount_paid)),
            anticipated: false,
          });
        }

        installmentSelected.installmentsData = installmentsDatas;
        installmentSelected.credit_amount = formatPrice(credit_amount);
        installmentSelected.paid = formatPrice(paid);
        installmentSelected.left_pay = formatPrice(left_pay);
        installmentSelected.save = formatPrice(save);

        if (installmentSelected.remaining_amount.length === 0) {
          installmentSelected.remaining_amount = formatPrice(left_pay - save);
        }

        return installmentSelected;
      });
      setInstallments(data);
      setLoading(false);
    });
  }, [
    language.payment_plans.status_1,
    language.payment_plans.status_2,
    language.payment_plans.status_3,
    language.payment_plans.status_4,
    language.payment_plans.status_5,
    language.payment_plans.status_6,
  ]);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        cell: (row: IPayment) => (
          <>{row.anticipated ? <s>{row.date}</s> : row.date}</>
        ),
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true,
        cell: (row: IPayment) => (
          <>{row.anticipated ? <s>{row.amount}</s> : row.amount}</>
        ),
      },
    ],
    []
  );

  const handleOnceSucceededPaid = useCallback(async () => {
    try {
      await api.patch(
        `orders/installments/close/${installmentSelectedData.order_id}`
      );
    } catch (error) {
      console.log(error);
    }
  }, [installmentSelectedData.order_id]);

  return (
    <>
      <Container
        className={active ? 'd-block overflow-auto pr-xl-2' : 'd-none'}
      >
        {loading && (
          <div className="container-fluid mb-3 bg-gray">
            <div className="row px-3 px-xxl-5 py-5 justify-content-center">
              <div className="col-md-9 col-lg-12 flex-wrap bd-gray py-3 px-4 d-lg-flex justify-content-between align-items-center">
                <div className="col col-xl-7">
                  <h2 className="skeleton px-0 px-sm-3 mb-lg-0">
                    Internet Marketing Academy
                  </h2>
                </div>
                <div className="col col-xl-5 d-1600-flex align-items-center justify-content-end">
                  <h3 className="">{language.payment_plans.h2_1} </h3>

                  <span className="skeleton px-3 h4 pl-2">
                    188 days past due
                  </span>
                </div>
              </div>
            </div>
            <Box className="row px-3 px-xxl-5 pb-5 justify-content-center">
              <div className="col-md-9 col-lg-6 col-xxl-4 px-1600-0 pl-xxl-0 mb-4 mb-lg-0">
                <div className="row mx-0">
                  <div className="col-12 text-center px-1 py-4 bd-gray mb-4 mb-lg-3">
                    <h2 className="mb-4">{language.payment_plans.h2_2}</h2>
                    <br />
                    <h3 className="skeleton mb-4">0000000</h3>
                    <br />
                    <div className="skeleton skeleton-graph rounded-circle mx-auto" />
                  </div>
                  <div className="col-sm-6 px-0 mt-lg-3 pr-sm-2 mb-4 mb-sm-0 pr-1600-3">
                    <div className="bd-gray text-center px-3 py-4 h-100">
                      <h2 className="mb-3">{language.payment_plans.h2_3}</h2>

                      <h4 className="skeleton mb-0 mb-sm-3">0000000</h4>
                    </div>
                  </div>
                  <div className="col-sm-6 px-0 mt-lg-3 pl-sm-2 mb-4 mb-sm-0 pl-1600-3">
                    <div className="bd-gray text-center px-3 py-4 h-100">
                      <h2 className="mb-3">{language.payment_plans.h2_4}</h2>
                      <h4 className="skeleton mb-0 mb-sm-3">0000000</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-6 col-xxl-4 pl-0 pl-lg-3 pr-0 pr-xxl-3">
                <div className="row mx-0">
                  <div className="col-12 text-center py-4 bd-gray h-30 mg-box mb-4 mb-lg-0">
                    <h2 className="mb-4">{language.payment_plans.h2_5}</h2>
                    <h3 className="skeleton mb-0 mb-sm-4">0000000</h3>
                  </div>
                  <div className="col-12 text-center p-4 bd-gray h-lg-343">
                    <h2 className="mb-2">{language.payment_plans.h2_6}</h2>
                    <div className="d-flex h-100">
                      <div className="skeleton my-auto w-100 table-skeleton" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-12 col-xxl-4 px-1600-0 pr-xxl-0 mt-4 mt-xxl-0">
                <div className="row justify-content-end mx-0">
                  <div className="col-lg-6 col-xxl-12 mg-box px-0 mb-4 mb-lg-0">
                    <div className="col-12 px-0 text-center py-4 bd-gray h-100 h-xxl-30">
                      <h2 className="mb-4">{language.payment_plans.h2_7}</h2>
                      <h3 className="skeleton mb-0 mb-sm-3 mb-lg-2 mb-xl-4">
                        0000000
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xxl-12 mg-box px-0 pl-lg-3 pl-xxl-0 mb-5 mb-lg-0">
                    <div className="col-12 px-0 text-center py-4 bd-gray">
                      <h2 className="mb-0">{language.payment_plans.h2_8}</h2>
                      <div className="d-flex flex-wrap align-items-center mt-4 mb-1 my-xxl-4">
                        <div className="col-4 col-xxl-6 justify-content-end justify-content-lg-center d-flex">
                          <div className="skeleton d-flex align-items-center justify-content-center circle">
                            00
                          </div>
                        </div>
                        <div className="col-8 col-xxl-6 pl-0">
                          <p className="skeleton mb-0">
                            January <br className="d-none d-xxl-block" />
                            0000
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5 col-xxl-12 px-0">
                    <button
                      type="button"
                      className="skeleton w-100 buy-button h-100 pd-button py-2 px-4 px-lg-3 px-xl-4 px-xxl-5"
                    >
                      <span className="d-block">
                        {language.payment_plans.button}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        )}
        {installments.map((installment, index) => (
          <div key={index.toString()} className="container-fluid mb-3 bg-gray">
            <div className="row px-3 px-xxl-5 py-5 justify-content-center">
              <div className="col-md-9 col-lg-12 flex-wrap bd-gray py-3 px-4 d-lg-flex justify-content-between align-items-center">
                <h2 className="col col-xl-7 px-0 px-sm-3 mb-lg-0">
                  {installment.name}
                </h2>
                <h3 className="col col-xl-5 text-1600-right px-0 px-sm-3 mb-0">
                  {language.payment_plans.h2_1}{' '}
                  <Status overdue={installment.overdue} className="h4 pl-2">
                    <br className="d-sm-none d-lg-block d-1600-none" />
                    {installment.status}
                  </Status>
                </h3>
              </div>
            </div>
            <Box className="row px-3 px-xxl-5 pb-5 justify-content-center">
              <div className="col-md-9 col-lg-6 col-xxl-4 px-1600-0 pl-xxl-0 mb-4 mb-lg-0">
                <div className="row mx-0">
                  <div className="col-12 text-center px-1 py-4 bd-gray mb-4 mb-lg-3">
                    <h2 className="mb-4">{language.payment_plans.h2_2}</h2>
                    <h3 className="mb-4">{installment.credit_amount}</h3>
                    <Graph installmentsData={installment.installmentsData} />
                  </div>
                  <div className="col-sm-6 px-0 mt-lg-3 pr-sm-2 mb-4 mb-sm-0 pr-1600-3">
                    <div className="bd-gray text-center px-3 py-4 h-100">
                      <h2 className="mb-3">{language.payment_plans.h2_3}</h2>
                      {installment.anticipated && (
                        <s className="mb-0 mb-sm-3 text-danger">
                          {installment.paid}
                        </s>
                      )}
                      <h4 className="mb-0 mb-sm-3">
                        {installment.anticipated
                          ? installment.anticipated_amount
                          : installment.paid}
                      </h4>
                    </div>
                  </div>
                  <div className="col-sm-6 px-0 mt-lg-3 pl-sm-2 mb-4 mb-sm-0 pl-1600-3">
                    <div className="bd-gray text-center px-3 py-4 h-100">
                      <h2 className="mb-3">{language.payment_plans.h2_4}</h2>
                      <h4 className="mb-0 mb-sm-3">{installment.left_pay}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-6 col-xxl-4 pl-0 pl-lg-3 pr-0 pr-xxl-3">
                <div className="row mx-0">
                  <div className="col-12 text-center py-4 bd-gray h-30 mg-box mb-4 mb-lg-0">
                    <h2 className="mb-4">{language.payment_plans.h2_5}</h2>
                    <h3 className="mb-0 mb-sm-4">{installment.amount}</h3>
                  </div>
                  <div className="col-12 text-center p-4 bd-gray h-lg-343">
                    <h2 className="mb-2">{language.payment_plans.h2_6}</h2>
                    <Table columns={columns} data={installment.payments} />
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-12 col-xxl-4 px-1600-0 pr-xxl-0 mt-4 mt-xxl-0">
                <div className="row justify-content-end mx-0">
                  <div className="col-lg-6 col-xxl-12 mg-box px-0 mb-4 mb-lg-0">
                    <div className="col-12 px-0 text-center py-4 bd-gray h-100 h-xxl-30">
                      <h2 className="mb-4">
                        {language.payment_plans.h2_7}{' '}
                        <span aria-label="Simply dummy text of the printing and typesetting industry">
                          <MdInfoOutline size={20} />
                        </span>
                      </h2>
                      <h3 className="mb-0 mb-sm-3 mb-lg-2 mb-xl-4">
                        {installment.save}
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xxl-12 mg-box px-0 pl-lg-3 pl-xxl-0 mb-5 mb-lg-0">
                    <div className="col-12 px-0 text-center py-4 bd-gray">
                      <h2 className="mb-0">{language.payment_plans.h2_8}</h2>
                      <div className="d-flex flex-wrap align-items-center mt-4 mb-1 my-xxl-4">
                        <div className="col-4 col-xxl-6 justify-content-end justify-content-lg-center d-flex">
                          <div className="d-flex align-items-center justify-content-center circle">
                            {installment.due_day}
                          </div>
                        </div>
                        <div className="col-8 col-xxl-6 pl-0">
                          <p className="mb-0">
                            {installment.due_month}{' '}
                            <br className="d-none d-xxl-block" />
                            {installment.due_year}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!installment.all_paid && (
                    <div className="col-lg-5 col-xxl-12 px-0">
                      {installment.product_id ? (
                        <button
                          type="button"
                          className="w-100 buy-button h-100 pd-button py-2 px-4 px-lg-3 px-xl-4 px-xxl-5"
                          onClick={() => handleShow(installment)}
                        >
                          <span className="d-block">
                            {language.payment_plans.button}
                          </span>
                        </button>
                      ) : (
                        <BuyButton
                          order_id={installment.id}
                          product_id={installment.offer_id}
                          title={installment.name}
                          price={installment.amount.slice(1).replace(',', '')}
                          className="w-100 buy-button h-100 pd-button px-4 px-lg-3 px-xl-4 px-xxl-5"
                        >
                          {language.payment_plans.button}
                        </BuyButton>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        ))}

        <Modal
          className="modal-terms px-0"
          centered
          size="xl"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="border-0 pb-0 pt-3 px-4">
            <Modal.Title className="text-center w-100" />
            <button
              type="button"
              className="close-button"
              onClick={handleClose}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </Modal.Header>
          <Modal.Body className="px-2 px-sm-4 pb-5">
            <div className="col-12 d-flex justify-content-center px-2">
              <img src={walletIcon} alt="wallet icon" className="pr-4" />
              <h2 className="h5 h4-sm payment-color text-center mb-0 font-weight-600">
                {language.payment_plans.modal_h2_1}
              </h2>
            </div>
            {/* {console.log(installmentSelectedData)} */}
            {Object.keys(installmentSelectedData).length > 0 && (
              <div className="row mx-0 mx-sm-2 justify-content-center mt-5">
                <div className="col-xl-8 px-2 px-sm-3 mb-4 mb-xl-0">
                  <div className="card-terms h-100 px-4 py-5 p-lg-5">
                    <h2 className="h1-md payment-color font-weight-bold pb-2 mb-2 mb-sm-4">
                      {installmentSelectedData.remaining_amount.slice(0, -3)}{' '}
                      {language.payment_plans.modal_h2_2}
                    </h2>
                    <h3 className="h5">{language.payment_plans.modal_h3_1}</h3>
                    <div className="col-12 d-lg-flex px-0 my-5">
                      <div className="col-lg-6 px-0">
                        <div className="d-flex my-4">
                          <img src={check} alt="check icon" className="mr-4" />
                          <p className="mb-0">
                            {language.payment_plans.modal_p_1}{' '}
                            <span className="font-weight-bold">
                              {installmentSelectedData.save.slice(0, -3)}
                            </span>
                          </p>
                        </div>
                        <div className="d-flex my-4">
                          <img src={check} alt="check icon" className="mr-4" />
                          <p className="mb-0">
                            {language.payment_plans.modal_p_2}
                          </p>
                        </div>
                        <div className="d-flex my-4">
                          <img src={check} alt="check icon" className="mr-4" />
                          <p className="mb-0">
                            {language.payment_plans.modal_p_3}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 px-0">
                        <div className="d-flex align-items-start my-4">
                          <img
                            src={check}
                            alt="check icon"
                            className="mr-4 mt-2"
                          />
                          <p className="mb-0">
                            {language.payment_plans.modal_p_4}
                          </p>
                        </div>
                        <div className="d-flex align-items-start my-4">
                          <img
                            src={check}
                            alt="check icon"
                            className="mr-4 mt-2"
                          />
                          <p className="mb-0">
                            {language.payment_plans.modal_p_5}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 px-0 px-sm-3 pl-lg-0">
                      <BuyButton
                        order_id={installmentSelectedData.order_id}
                        product_id={installmentSelectedData.product_id}
                        title={installmentSelectedData.name}
                        price={installmentSelectedData.remaining_amount
                          .slice(1)
                          .replace(',', '')}
                        onSucceededPaid={handleOnceSucceededPaid}
                        className="btn-color"
                        anticipated
                      >
                        {language.payment_plans.modal_button_1}
                      </BuyButton>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 px-2 px-sm-3">
                  <div className="bd-card h-100 px-4 px-lg-5 px-xl-4 py-5">
                    <h2 className="h1-md payment-color font-weight-bold pb-2 mb-2 mb-sm-4">
                      {installmentSelectedData.amount.slice(0, -3)}
                    </h2>
                    <h3 className="h6">{language.payment_plans.modal_h3_2}</h3>

                    <div className="pt-4">
                      <p className="my-4">{language.payment_plans.modal_p_6}</p>
                      <p className="my-4">{language.payment_plans.modal_p_7}</p>
                      <p className="my-4">{language.payment_plans.modal_p_8}</p>
                    </div>
                    <div className="col-12 mt-5 mt-xl-0 px-0 px-sm-3 pl-lg-0">
                      <BuyButton
                        order_id={installmentSelectedData.id}
                        product_id={installmentSelectedData.product_id}
                        title={installmentSelectedData.name}
                        price={installmentSelectedData.amount
                          .slice(1)
                          .replace(',', '')}
                        className="btn-continue"
                      >
                        {language.payment_plans.modal_button_2}
                      </BuyButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default PaymentPlans;
