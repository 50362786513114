import styled from 'styled-components';
import { lighten } from 'polished';

interface IPropsBox {
  src: string;
}

export const Container = styled.div`
  .show-courses {
    //height: 32px;
    display: inline-block;
  }
`;

export const Box = styled.div<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 250px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;
      :hover {
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1400px) {
    max-height: 340px;
    height: 18vw;
  }
`;

export const Skeleton = styled.div`
  .skeleton {
    height: 250px;
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
    @media screen and (min-width: 1400px) {
      max-height: 340px;
      height: 18vw;
    }
  }
`;
