/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '~/services/api';

interface ISocialMedia {
  icon: string;
  link: string;
}

interface SetupContextData {
  setupName: string;
  logo: string;
  logoMobile: string;
  loginBackground: string;
  socialMedias: ISocialMedia[];
  socialUserName: string;
}

export const SetupContext = createContext<SetupContextData>(
  {} as SetupContextData
);

export const SetupProvider: React.FC = ({ children }) => {
  const [setupName, setSetupName] = useState('');
  const [logo, setLogo] = useState('');
  const [logoMobile, setLogoMobile] = useState('');
  const [loginBackground, setLoginBackground] = useState('');
  const [socialMedias, setSocialMedias] = useState<ISocialMedia[]>([]);
  const [socialUserName, setSocialUserName] = useState('');

  useEffect(() => {
    const { host } = window.location;
    const domainsParts = host.split('.');
    let subDomain = 'wiserr';
    if (domainsParts.length >= 3) {
      [subDomain] = domainsParts;
    }
    api.get(`setups/${subDomain}`).then((response) => {
      setSetupName(response.data.name);
      setLogo(response.data.logo_url);
      setLogoMobile(response.data.logoMobile_url);
      setLoginBackground(response.data.loginBackground_url);
      setSocialMedias(
        response.data.setupSocialMedias.map((setupSocialMedia: any) => ({
          icon: setupSocialMedia.icon_url,
          link: setupSocialMedia.link,
        }))
      );
      setSocialUserName(response.data.social_username);
    });
  }, []);

  return (
    <SetupContext.Provider
      value={{
        setupName,
        logo,
        logoMobile,
        loginBackground,
        socialMedias,
        socialUserName,
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

export function useSetup(): SetupContextData {
  const context = useContext(SetupContext);

  if (!context) {
    throw new Error('useSetup must be used within an LogoProvider');
  }

  return context;
}
