import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Modal } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';

import { useLanguage } from '~/hooks/Language';
import getValidationErros from '~/utils/getValidationsErrors';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';

import { Container } from './styles';
import CreditCard from '~/components/CreditCard';

import visa from '~/assets/icons/cc-visa.svg';
import mastercard from '~/assets/icons/cc-mastercard.svg';
import discover from '~/assets/icons/cc-discover.svg';
import amex from '~/assets/icons/cc-amex.svg';
import ccDefault from '~/assets/icons/cc-default.svg';
import wallet from '~/assets/icons/wallet.svg';
import options from '~/assets/icons/options.svg';
import addCard from '~/assets/icons/add-card.svg';
import Default from '~/assets/defaults/subscription.png';

interface ISubscriptionsCardsProps {
  active: boolean;
}

interface IApiCreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  address: string;
  primary_card: boolean;
}

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  flagImg: string;
  primary_card: boolean;
}

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
  primary_card?: boolean;
}

interface IOrderResponse {
  id: string;
  amount_paid: number;
  created_at: string;
  course: {
    title: string;
    // thumbnail_id: string;
  };
  product: {
    name: string;
  };
}

interface IOrder {
  id: string;
  amount_paid: string;
  title: string;
  // thumbnail_id: string;
  created_at: string;
}

const Subscriptions: React.FC<ISubscriptionsCardsProps> = ({ active }) => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const formCreditCardRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [creditCards, setCreditCards] = useState<IReponseCreditCard[]>([]);
  const [creditCardSelected, setCreditCardSelected] = useState<
    IReponseCreditCard
  >({} as IReponseCreditCard);

  useEffect(() => {
    api.get('payments/customers/credit-cards').then((response) => {
      const data = response.data.map((card: IApiCreditCard) => {
        let flagImg = '';
        switch (card.brand) {
          case 'Visa':
            flagImg = visa;
            break;
          case 'MasterCard':
            flagImg = mastercard;
            break;
          case 'American Express':
            flagImg = amex;
            break;
          case 'Discover':
            flagImg = discover;
            break;

          default:
            flagImg = ccDefault;
            break;
        }
        return {
          flagImg,
          id: card.id,
          name: card.name,
          number: `****-${card.number}`,
          expiration: card.expirity,
          flag: card.brand,
          primary_card: card.primary_card,
        };
      });
      setCreditCards(data);
    });
  }, []);

  const handleClose = useCallback(() => {
    setShowPayment(false);
    setShowDelete(false);
  }, []);
  const handleCloseCard = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback(() => setShow(true), []);
  const handleShowPayment = useCallback(() => setShowPayment(true), []);

  const handleShowDelete = useCallback((card: IReponseCreditCard) => {
    setCreditCardSelected(card);
    setShowDelete(true);
  }, []);

  const handleSubmitCreditCard = useCallback(
    async (data: ICreditCard) => {
      try {
        formCreditCardRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name on card is a required'),
          number: Yup.string().required('Card number is a required'),
          expiration: Yup.string().required('Expiration date is a required'),
          cvc: Yup.string().required('CVC is a required'),
          flag: Yup.string().required('Flag is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, number, expiration, cvc, flag } = data;
        const [exp_month, exp_year] = expiration.split('/');

        const formData = {
          name,
          number: number.replace(/ /g, ''),
          exp_month,
          exp_year,
          cvc,
          primary_card: creditCards.length === 0,
        };

        const response = await api.post(
          'payments/customers/credit-cards',
          formData
        );

        if (response.data) {
          const numberMask = `****-${number.substr(-4)}`;
          let flagImg = '';
          switch (flag) {
            case 'Visa':
              flagImg = visa;
              break;
            case 'MasterCard':
              flagImg = mastercard;
              break;
            case 'American Express':
              flagImg = amex;
              break;
            case 'Discover':
              flagImg = discover;
              break;

            default:
              flagImg = ccDefault;
              break;
          }
          const creditCardForm: IReponseCreditCard = {
            id: response.data.id,
            name,
            number: numberMask,
            expiration,
            flag,
            flagImg,
            primary_card: creditCards.length === 0,
          };
          setCreditCards((state) => [...state, creditCardForm]);
          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formCreditCardRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.error_component.error_message, 'error');
        }
      }
    },
    [creditCards.length, handleClose, language.error_component.error_message]
  );

  const handleDeleteCreditCard = useCallback(async () => {
    try {
      if (creditCardSelected.primary_card && creditCards.length > 1) {
        const newCreditCardsList = creditCards.slice();
        if (creditCardSelected === creditCards[0]) {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[1].id}`
          );
          newCreditCardsList[1].primary_card = true;
        } else {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[0].id}`
          );
          newCreditCardsList[0].primary_card = true;
        }
        setCreditCards(newCreditCardsList);
      }
      await api.delete(
        `payments/customers/credit-cards/${creditCardSelected.id}`
      );
      const cards = creditCards.filter(
        (card) => card.id !== creditCardSelected.id
      );
      setCreditCards(cards);
      Swal.fire('Okay!', 'Successfully deleted.', 'success').then(() => {
        handleClose();
      });
    } catch (error) {
      Swal.fire('Opss...', language.error_component.error_message, 'error');
    }
  }, [
    creditCardSelected,
    creditCards,
    handleClose,
    language.error_component.error_message,
  ]);

  const handleChange = useCallback(
    async (index) => {
      const newCreditCardsList = creditCards.slice();
      newCreditCardsList.forEach((card) => {
        // eslint-disable-next-line no-param-reassign
        card.primary_card = false;
      });
      newCreditCardsList[index].primary_card = true;

      await api.patch(
        `payments/customers/credit-cards/${newCreditCardsList[index].id}`
      );

      setCreditCards(newCreditCardsList);
    },
    [creditCards]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get<IOrderResponse[]>('orders')
      .then((response) => {
        const data: IOrder[] = response.data.map((order) => ({
          id: order.id,
          // thumbnail_id: order.course.thumbnail_id,
          title: order.course ? order.course.title : order.product.name,
          amount_paid: formatPrice(order.amount_paid),
          created_at: format(parseISO(order.created_at), 'yyyy-MM-dd'),
        }));
        setOrders(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <div className="row bg-gray p-2 pb-5">
              {orders.map((order) => (
                <div className="col-12 mb-md-3 mb-lg-0">
                  <div className="row justify-content-md-end justify-content-lg-start">
                    <div className="col-md-4 col-lg-3 mt-5">
                      <div className="d-flex align-items-center h-100 justify-content-center">
                        <div>
                          <img
                            src={Default}
                            alt={order.title}
                            className="w-auto w-lg-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mt-5">
                      <div className="d-flex h-100">
                        <div>
                          <h3 className="h6">Producto</h3>
                          <p>{order.title}</p>
                          <h3 className="h6">Siguiente Fecha de facturación</h3>
                          <p>{order.created_at}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-6 mt-5">
                      <div className="d-flex h-100">
                        <div>
                          <h3 className="h6">Siguiente pago</h3>
                          <p>{order.amount_paid}</p>
                          <h3 className="h6">Expira</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 mt-md-n4 mt-lg-5">
                      <div className="d-flex align-items-center h-100">
                        <div>
                          <h3 className="h6">$97/mo</h3>
                          <button
                            type="button"
                            className="px-4 py-1 button-cancel"
                          >
                            cancelar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Subscriptions;
