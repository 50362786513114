import styled from 'styled-components';

export const Container = styled.div`
  p {
    color: #333;
    font-family: 'Patrick Hand';
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
  }
  span {
    color: #6d6c6c;
    text-align: end;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  textarea {
    resize: none;
    color: #333;
    font-family: 'Patrick Hand';
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    ::placeholder {
      color: #333;
    }
  }

  .bg-yellow {
    background-image: linear-gradient(180deg, #ffeba5 0%, #fee97c 100%);
  }
  .bg-blue {
    background-image: linear-gradient(180deg, #93dcdb 0%, #70cfcd 100%);
  }

  .bg-orange {
    background-image: linear-gradient(180deg, #ffdab0 0%, #ffc98a 100%);
  }

  .bg-post {
    height: 250px;
    position: relative;
    z-index: 3;
  }

  .bg-2 {
    height: 250px;
    width: 86%;
    position: absolute;
    left: 40px;
    top: 15px;
    z-index: 2;
    transform: rotate(2deg);
    transition: all 0.3s ease-in-out;
    :hover {
      transform: scale(1.1);
    }
  }

  .bg-3 {
    height: 250px;
    width: 86%;
    position: absolute;
    left: 56px;
    top: 30px;
    z-index: 1;
    transform: rotate(3deg);
    transition: all 0.3s ease-in-out;
    :hover {
      transform: scale(1.1);
    }
  }
`;
