import React, { useRef, useState, useEffect, useCallback } from 'react';
// import ReactPlayer from 'react-player';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import { Container } from './styles';

interface playerProps {
  src: string;
  srcUpdate?: string;
  className?: string;
  onReload: () => void;
}

const Player: React.FC<playerProps> = ({
  src,
  srcUpdate,
  className,
  onReload,
}) => {
  const playerRef = useRef(null);
  const [elements, setElements] = useState(document.querySelectorAll('video'));
  const [paused, setPaused] = useState(false);
  // const [videoJS, setVideoJs] = useState<VideoJsPlayer>({} as VideoJsPlayer);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach((el) =>
        el.addEventListener('contextmenu', (e) => e.preventDefault())
      );
    } else {
      setElements(document.querySelectorAll('video'));
    }
  }, [elements]);

  // useEffect(() => {
  //   const options: VideoJsPlayerOptions = {
  //     fluid: true,
  //     preload: 'auto',
  //     html5: {
  //       hls: {
  //         enableLowInitialPlaylist: true,
  //         smoothQualityChange: true,
  //         overrideNative: true,
  //       },
  //     },
  //     autoplay: false,
  //     controls: true,
  //     sources: [
  //       {
  //         src,
  //         type: 'video/mp4',
  //       },
  //     ],
  //   };
  //   if (playerRef.current) {
  //     const player = videojs(playerRef.current, options);
  //     setVideoJs(player);
  //   }
  // }, [src]);

  // useEffect(() => {
  //   if (srcUpdate) {
  //     setPaused(false);
  //     videoJS.src({ src: srcUpdate, type: 'video/mp4' });
  //     videoJS.currentTime(currentTime);
  //     videoJS.play();
  //   }
  // }, [currentTime, srcUpdate, videoJS]);

  const handlePlay = useCallback(
    (e) => {
      if (paused) {
        setCurrentTime(e.target.currentTime);
        onReload();
        setPaused(false);
      }
    },
    [onReload, paused]
  );

  const handlePause = useCallback((e) => {
    setPaused(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    console.log(e.target);
  }, []);

  return (
    <Container className={className}>
      <div data-vjs-player>
        <video
          ref={playerRef}
          className="video-js"
          onPlay={handlePlay}
          onPause={handlePause}
          onDragLeave={handleDragLeave}
        >
          <track kind="captions" />
        </video>
      </div>
    </Container>
  );
};

export default Player;
