import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { IoIosSend, IoMdClose } from 'react-icons/io';
import { FormHandles } from '@unform/core';

import { IChat, IMessagesData, IMessage } from '~/hooks/Chats';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import { Container, Header, Content, Footer } from './styles';
import Textarea from '~/components/Textarea';
import {
  initializeSocketChat,
  initializeSocketChatEvents,
  disconnectSocketChat,
} from '~/services/socketChat';

interface IChatProps {
  data: IChat;
  onRemove?(): void;
}

const Chat: React.FC<IChatProps> = ({ data, onRemove }) => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [minimized, setMinimize] = useState(false);
  const [placeholder, setPlaceholder] = useState('Mensagem');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [socketInitialized, setSocketInitialized] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [newMessages, setNewMessages] = useState<IMessage[]>([]);
  const [newMessageQuantity, setNewMessageQuantity] = useState(0);

  useEffect(() => {
    setMessages(data.messagesData.data);
    setLastPage(data.messagesData.last_page);
  }, [data.messagesData]);

  const handleReceiveNewMessage = useCallback(
    (dataMessage: IMessage) => {
      if (dataMessage.user_id !== user.id) {
        setMessages((state) => [dataMessage, ...state]);
        setNewMessages((state) => [dataMessage, ...state]);
      }
    },
    [user.id]
  );

  useEffect(() => {
    if (newMessages.length > 0) {
      if (minimized) {
        setNewMessageQuantity(newMessages.length);
      } else {
        setNewMessageQuantity(0);
        setNewMessages([]);
      }
    } else {
      setNewMessageQuantity(0);
    }
  }, [newMessages, minimized]);

  useEffect(() => {
    setSocketInitialized(initializeSocketChat());

    return () => {
      disconnectSocketChat();
    };
  }, []);

  useEffect(() => {
    if (socketInitialized) {
      initializeSocketChatEvents({
        chat_id: data.id,
        onReceiveNewMessage: handleReceiveNewMessage,
      });
    }
  }, [data.id, handleReceiveNewMessage, socketInitialized]);

  const loadMessages = useCallback(
    async (pageData) => {
      const response = await api.get<IMessagesData>(`messages/${data.id}`, {
        params: {
          page: pageData,
        },
      });
      setMessages((state) => [...state, ...response.data.data]);
      setPage(pageData + 1);
      setLastPage(response.data.last_page);
    },
    [data.id]
  );

  const handleClickMaximize = useCallback(() => {
    setMinimize(false);
  }, []);

  const handleClickMinimize = useCallback(() => {
    setMinimize(true);
  }, []);

  const handleClickClose = useCallback(() => {
    if (onRemove) {
      onRemove();
    }
  }, [onRemove]);

  const handleKeyPress = useCallback((e) => {
    const key = e.which || e.keyCode;
    if (e.key === 'Enter' || key === 13) {
      e.preventDefault();
      if (formRef.current) {
        formRef.current.submitForm();
      }
    }
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      if (formData.message) {
        const response = await api.post('messages', {
          chat_id: data.id,
          user_id: user.id,
          content: formData.message,
        });
        const message: IMessage = {
          id: response.data.id,
          user_id: user.id,
          content: formData.message,
        };
        setMessages((state) => [message, ...state]);
        if (formRef.current) {
          formRef.current.reset();
        }
      }
    },
    [data.id, user.id]
  );

  const handleLoad = useCallback(async () => {
    try {
      if (page < lastPage) {
        await loadMessages(page + 1);
      }
    } catch (error) {
      console.log(error);
    }
  }, [lastPage, loadMessages, page]);

  return (
    <Container minimized={minimized}>
      <Header className="header d-flex justify-content-between w-100">
        <button
          type="button"
          onClick={handleClickMaximize}
          className="border-0 bg-transparent btn-maximize d-flex"
        >
          <p>{data.name}</p>
          {newMessageQuantity > 0 && (
            <span className="alert-message">{newMessageQuantity}</span>
          )}
        </button>
        <button
          type="button"
          onClick={handleClickMinimize}
          className="border-0 bg-transparent btn-minimize"
        >
          <AiOutlineMinus size={20} color="#bdbdbd" />
        </button>
        <button
          type="button"
          onClick={handleClickClose}
          className="border-0 bg-transparent btn-close"
        >
          <IoMdClose size={20} color="#bdbdbd" />
        </button>
      </Header>
      <Content
        scrollLoadThreshold={100}
        onInfiniteLoad={handleLoad}
        flipped
        className="content"
      >
        {messages.map((message) => (
          <p
            key={message.id}
            className={message.user_id === user.id ? 'me' : 'you'}
          >
            {message.content}
          </p>
        ))}
      </Content>
      <Footer ref={formRef} onSubmit={handleSubmit} className="footer">
        <Textarea
          name="message"
          className="textarea"
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          onFocus={() => setPlaceholder('')}
          onBlur={() => setPlaceholder('Mensagem')}
        />
        <button type="submit" className="border-0 bg-transparent">
          <IoIosSend size={24} color="#bdbdbd" />
        </button>
      </Footer>
    </Container>
  );
};

export default Chat;
