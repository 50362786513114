import React, { useCallback, useEffect, useState } from 'react';
import {
  Link,
  useParams,
  useHistory,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { FiChevronDown } from 'react-icons/fi';
import { FaSearch } from 'react-icons/fa';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import {
  convertHoursToSeconds,
  convertSecondsToHours,
} from '~/utils/convertTime';

import {
  Container,
  Courses,
  Box,
  Skeleton,
  Welcome,
  Avatar,
  BtnFilter,
} from './styles';
import Player from '~/components/Player';
import BuyButton from '~/components/BuyButton';

import members from '~/assets/icons/members-white-icon.svg';
import video from '~/assets/icons/video-white-icon.svg';
import hour from '~/assets/icons/hour-white-icon.svg';
import play from '~/assets/icons/play.svg';
import arrowLeft from '~/assets/icons/arrow-left-icon.svg';
import { Options } from '../Home/styles';
import Slider from '~/components/Slider';
import CoursesSearch from '~/components/CoursesSearch';

interface IParams {
  slug: string;
}

interface ILesson {
  video: {
    duration: string;
  };
}

interface ICourse {
  id: string;
  categories: string[];
  title: string;
  description: string;
  price: string;
  promotion_price: string;
  show_promotion_price: boolean;
  thumbnail: string;
  short_title: string;
  membersEnrolled: number;
  lessons: number;
  totalTime: string;
  slug: string;
  video: string;
}

interface ICourseStudent {
  student_id: string;
}

interface IOtherResponse {
  id: string;
  slug: string;
  thumbnail: {
    thumbnail_url: string;
    title: string;
  };
  courseStudent: ICourseStudent[];
}

interface IOther {
  id: string;
  thumbnail: string;
  short_title: string;
  slug: string;
  purchased: boolean;
}

const Course: React.FC = () => {
  const { user } = useAuth();
  const { language, idiom } = useLanguage();
  const params = useParams<IParams>();
  const history = useHistory();
  const location = useLocation();
  const userName = user.name.split(' ');
  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(11) || 'dashboard';
    }
    return location.pathname.slice(14) || 'dashboard';
  });
  const [optionMobile, setOptionMobile] = useState(language.courses.button_1);
  const [course, setCourse] = useState<ICourse>({} as ICourse);
  const [others, setOthers] = useState<IOther[]>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });

  useEffect(() => {
    switch (optionSelected) {
      case 'discover':
        setOptionMobile(language.courses.button_2);
        break;
      case 'my-courses':
        setOptionMobile(language.courses.button_3);
        break;
      case 'upcoming':
        setOptionMobile(language.courses.button_4);
        break;
      case 'certificates':
        setOptionMobile(language.courses.button_5);
        break;
      case 'discover-new':
        setOptionMobile(language.courses.button_2);
        break;
      default:
        setOptionMobile(language.courses.button_1);
        break;
    }
  }, [language.courses, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(11) || 'dashboard';
    } else {
      page = location.pathname.slice(14) || 'dashboard';
    }

    const box = document.querySelector('.options-box');
    if (box) {
      box.classList.remove('active');
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setSearchActive(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setSearchActive(false);
      setSearch('');
    }, 500);
  }, []);

  useEffect(() => {
    api
      .get(`courses/students/${params.slug}`, {
        params: {
          type: 'BUY',
          withThumbnail: true,
          withVideo: true,
          withCourseCategory: true,
          withCategory: true,
          withLessons: true,
          withLessonsVideos: true,
          withCourseStudent: true,
        },
      })
      .then(async (response) => {
        const seconds =
          response.data.lessons.length > 0
            ? response.data.lessons.reduce(
                (acumulador: number, valorAtual: ILesson) => {
                  const newSeconds = convertHoursToSeconds(
                    valorAtual.video.duration
                  );
                  return acumulador + newSeconds;
                },
                0
              )
            : 0;
        const introductionTime = convertHoursToSeconds(
          response.data.video.duration
            ? response.data.video.duration
            : '00:00:00'
        );
        const totalTime = convertSecondsToHours(seconds + introductionTime);
        const categories = response.data.courseCategory.map(
          (courseCategory: any) => courseCategory.category.name
        );
        const data = {
          id: response.data.id,
          categories,
          title: response.data.title,
          description: response.data.description,
          price: response.data.price,
          promotion_price: response.data.promotion_price,
          show_promotion_price: response.data.show_promotion_price,
          thumbnail: response.data.thumbnail
            ? response.data.thumbnail.thumbnail_url
            : '',
          short_title: response.data.thumbnail
            ? response.data.thumbnail.title
            : '',
          membersEnrolled: response.data.courseStudent.length,
          lessons: response.data.video
            ? response.data.lessons.length + 1
            : response.data.lessons.length,
          totalTime,
          slug: response.data.slug,
          video: response.data.video.video_url,
        };

        const responseOther = await api.get<IOtherResponse[]>(
          `courses/students/others/${response.data.id}`
        );

        const dataOthers = responseOther.data.map((other) => {
          const purchased = !!other.courseStudent.find(
            (courseStudent) => courseStudent.student_id === user.id
          );
          return {
            id: other.id,
            slug: other.slug,
            thumbnail: other.thumbnail ? other.thumbnail.thumbnail_url : '',
            short_title: other.thumbnail ? other.thumbnail.title : '',
            purchased,
          };
        });

        setOthers(dataOthers);
        setCourse(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug, user.id]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleSucceededPaid = useCallback(() => {
    history.push(`${process.env.PUBLIC_URL}/dashboard/my-courses`);
  }, [history]);

  const handleClickTakeCourse = useCallback(
    async (courseData: ICourse) => {
      history.push(
        `${process.env.PUBLIC_URL}/courses/${courseData.slug}/introduction`
      );
    },
    [history]
  );

  return (
    <>
      {loading && (
        <Skeleton>
          <div className="container py-5">
            <div className="row align-items-center align-items-lg-start">
              <div className="col-4 col-lg-12 mb-lg-4">
                <button
                  type="button"
                  className="skeleton border-0 bg-transparent"
                >
                  <div className="skeleton-arrow arrow-xs" />
                </button>
              </div>
              <div className="col-lg-7 d-none d-lg-block">
                <h1 className="skeleton h2 h1-lg mb-5">
                  Aspectos básicos para comenzar tu negocio en línea
                </h1>
              </div>
              <div className="col-8 col-lg-5 my-3 my-lg-0 text-right">
                <h2 className="skeleton h2 h1-lg font-weight-bold mb-0">
                  $197.00
                </h2>
                <br />
                <s className="skeleton h4 old-price">$297.00</s>
              </div>
              <div className="col-12 d-lg-none mt-4">
                <h1 className="skeleton h2 h1-lg mb-4">
                  Aspectos básicos para comenzar tu negocio en línea
                </h1>
              </div>
              <div className="col-lg-9">
                <p className="skeleton h6 h5-lg font-weight-light mb-0">
                  Esta capacitación te ayudará a obtener las bases necesarias
                  para iniciarte en el mundo del marketing por Internet.
                </p>
              </div>
              <div className="col-lg-8 col-xl-7 col-xxl-6">
                <div className="row justify-content-between py-5">
                  <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-start">
                    <img src={members} alt="members" className="mr-3" />
                    <p className="skeleton mb-0 h6">15 Members Enrolled</p>
                  </div>
                  <div className="my-1 my-lg-0 col-lg-3 d-flex align-items-center justify-content-lg-center">
                    <img src={video} alt="video" className="mr-3" />
                    <p className="skeleton mb-0 h6">9 videos</p>
                  </div>
                  <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-end">
                    <img src={hour} alt="hour" className="mr-3" />
                    <p className="skeleton mb-0 h6">Total time: 02:21:30</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 my-1 my-lg-0">
                    <button
                      type="button"
                      className="skeleton button-play w-100 py-3"
                    >
                      <span className="d-block py-1 w-100">
                        {language.course.button_1}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-6 my-1 my-lg-0">
                    <button type="button" className="skeleton py-3 h-100 w-100">
                      <span className="d-block py-1 w-100">
                        {language.course.button_2}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="px-0">
              <h2 className="h6 mb-4 mt-lg-2 pt-5 border-top-courses font-weight-bold p-relative">
                {language.course.h2_1}
              </h2>
              <div className="row">
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="skeleton-height skeleton d-block p-relative w-100" />
                </div>
              </div>
            </div>
          </div>
        </Skeleton>
      )}

      {Object.keys(course).length > 0 && course.price ? (
        <>
          <Container
            background={course.thumbnail}
            className={loading === true ? 'd-none' : ''}
          >
            <div className="container pt-4 pb-5">
              <div className="row">
                <div className="col-12 p-0">
                  <Welcome>
                    <div
                      className={
                        optionSelected === 'dashboard' ||
                        optionSelected === 'discover-new'
                          ? 'container-lg px-0'
                          : 'container-lg px-0'
                      }
                    >
                      <div className="row justify-content-center justify-content-md-start justify-content-lg-center">
                        <div className="col-md-10 col-lg-12 px-md-2">
                          <div className="row align-items-center">
                            <div className="col-lg-7 col-xxl-3">
                              <h1 className="h2 pl-1 text-center text-md-left mb-0">
                                <span
                                  className={
                                    searchActive || search.length > 0
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  {search}
                                </span>
                                <span
                                  className={
                                    searchActive || search.length > 0
                                      ? ''
                                      : 'active'
                                  }
                                >
                                  {language.courses.h1} {userName[0].trim()}!
                                </span>
                              </h1>
                            </div>
                            <div className="col-xxl-6">
                              <Options>
                                <div className="row justify-content-center">
                                  <div className="col-11 col-lg-12 mx-lg-3 px-3 px-lg-0 py-3">
                                    <div className="row justify-content-center text-center">
                                      <div className="options-box col-12">
                                        <button
                                          type="button"
                                          className="d-flex d-md-none justify-content-between align-items-center py-3 px-3 w-100 border-0 options-button"
                                          onClick={handleClickBox}
                                        >
                                          <p className="w-100 mb-0 bg-btn m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-lighter">
                                            {optionMobile}
                                          </p>
                                          <FiChevronDown size={25} />
                                        </button>
                                        <div className="options d-md-flex justify-content-around  justify-content-xl-center my-0 col-12 px-4 px-lg-0">
                                          <NavLink
                                            to={`${process.env.PUBLIC_URL}${langSelected}/dashboard`}
                                            className={`${
                                              loading === true ? 'skeleton' : ''
                                            } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                            activeClassName={
                                              optionSelected === 'dashboard' &&
                                              loading === false
                                                ? 'active'
                                                : ''
                                            }
                                          >
                                            <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                              {language.courses.button_1}
                                            </span>
                                          </NavLink>

                                          <NavLink
                                            to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/discover`}
                                            className={`${
                                              loading === true ? 'skeleton' : ''
                                            } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                            activeClassName="active"
                                          >
                                            <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                              {language.courses.button_2}
                                            </span>
                                          </NavLink>

                                          <NavLink
                                            to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/my-courses`}
                                            className={`${
                                              loading === true ? 'skeleton' : ''
                                            } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                            activeClassName="active"
                                          >
                                            <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                              {language.courses.button_3}
                                            </span>
                                          </NavLink>

                                          <NavLink
                                            to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/upcoming`}
                                            className={`${
                                              loading === true ? 'skeleton' : ''
                                            } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                            activeClassName="active"
                                          >
                                            <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                              {language.courses.button_4}
                                            </span>
                                          </NavLink>

                                          <NavLink
                                            to={`${process.env.PUBLIC_URL}${langSelected}/dashboard/certificates`}
                                            className={`${
                                              loading === true ? 'skeleton' : ''
                                            } bg-btn m-1 my-lg-0 mx-xl-2 pr-4 px-md-2 px-xxl-3 py-1 py-xxl-1 font-weight-600`}
                                            activeClassName="active"
                                          >
                                            <span className="text-white d-block mr-1 mr-md-0 px-2 py-1">
                                              {language.courses.button_5}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Options>
                            </div>
                            <div className="search-index col-lg-5 col-xxl-3 text-center align-items-center d-flex justify-content-end">
                              <div
                                className={`${
                                  loading === true ? 'skeleton' : ''
                                } p-relative search w-75`}
                              >
                                <input
                                  type="text"
                                  className={` w-100 px-3`}
                                  id="search"
                                  placeholder={
                                    loading === true
                                      ? ''
                                      : language.courses.input
                                  }
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  value={search}
                                />
                                <button
                                  type="submit"
                                  className={`${
                                    loading === true ? 'd-none' : ''
                                  } p-absolute`}
                                >
                                  <FaSearch color="#8c8c8c" />
                                </button>
                              </div>
                              <Avatar
                                src={user.avatar.avatar_url}
                                className={loading === true ? 'skeleton' : ''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Welcome>
                </div>
              </div>
              <div className="row align-items-center align-items-lg-start">
                <div className="col-4 col-lg-12 mb-lg-4 mt-4 d-flex">
                  {course.categories.map((category, index) => (
                    <b
                      key={index.toString()}
                      className="d-block category rounded-pill"
                    >
                      <span className="d-block rounded-pill px-3 py-1">
                        {category}
                      </span>
                    </b>
                  ))}
                </div>
                <div className="col-12">
                  <h1 className="mb-3">{course.title}</h1>
                </div>

                <div className="col-lg-9 col-xxl-8">
                  <p className="h6 h5-lg font-weight-light mb-0">
                    {course.description}
                  </p>
                </div>
                <div className="col-lg-8 col-xl-7 col-xxl-6">
                  <div className="row justify-content-between py-5">
                    <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-start">
                      <img src={members} alt="members" className="mr-3" />
                      <p className="mb-0 h6">
                        {course.membersEnrolled} {language.course.p_1}
                      </p>
                    </div>
                    <div className="my-1 my-lg-0 col-lg-3 d-flex align-items-center justify-content-lg-center">
                      <img src={video} alt="video" className="mr-3" />
                      <p className="mb-0 h6">
                        {course.lessons} {language.course.p_2}
                      </p>
                    </div>
                    <div className="my-1 my-lg-0 col-lg d-flex align-items-center justify-content-lg-end">
                      <img src={hour} alt="hour" className="mr-3" />
                      <p className="mb-0 h6">
                        {language.course.p_3} {course.totalTime}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6 my-1 my-lg-0">
                      <button
                        type="button"
                        onClick={handleShow}
                        className="button-play w-100 py-3"
                      >
                        <span className="d-block py-1 w-100">
                          <img src={play} alt="Play Icon" className="mr-2" />
                          {language.course.button_1}
                        </span>
                      </button>
                    </div> */}
                    <div className="col-lg-6 my-1 my-lg-0">
                      <button
                        type="button"
                        className="btn-play"
                        onClick={() => handleClickTakeCourse(course)}
                      >
                        <span className="d-block py-1 w-100">Take Course</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <BtnFilter>
              <div className="container">
                <div className="row">
                  <div className="col-12 mb-4">
                    <Slider>
                      <div className="pad-20">
                        <button type="button" className="bg-mkt w-100">
                          ONLINE MARKETING
                        </button>
                      </div>
                      <div className="pad-20">
                        <button type="button" className="bg-ecom w-100">
                          ECOMMERCE
                        </button>
                      </div>
                      <div className="pad-20">
                        <button type="button" className="bg-adver w-100">
                          ADVERTISING
                        </button>
                      </div>
                      <div className="pad-20">
                        <button type="button" className="bg-sales w-100">
                          SALES
                        </button>
                      </div>
                      <div className="pad-20">
                        <button type="button" className="bg-adver w-100">
                          ADVERTISING
                        </button>
                      </div>
                      <div className="pad-20">
                        <button type="button" className="bg-sales w-100">
                          SALES
                        </button>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </BtnFilter>

            <Courses>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2 className="h5 pl-4">{language.course.h2_1}</h2>
                  </div>
                </div>
                <div>
                  <Slider>
                    {others.map((other) => (
                      <div key={other.id} className="col-lg padding-box">
                        <Box
                          to={`${process.env.PUBLIC_URL}/courses${
                            !other.purchased ? '/preview' : ''
                          }/${other.slug}`}
                          src={other.thumbnail}
                          className="d-block p-relative w-100"
                        >
                          <div className="short-title p-absolute w-100 p-2">
                            <div className="row h-100">
                              <div className="col-12">
                                <p className="mb-0 py-2 pl-1 pl-xl-4 mt-3 w-75 h5 h4-sm">
                                  {other.short_title}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Courses>

            <Courses>
              <div className="container">
                <div className="row px-0">
                  <div className="col-12">
                    <h2 className="h5 pl-4">{language.course.h2_2}</h2>
                  </div>
                </div>
                <div>
                  <Slider>
                    {others.map((other) => (
                      <div key={other.id} className="col-lg padding-box">
                        <Box
                          to={`${process.env.PUBLIC_URL}/courses${
                            !other.purchased ? '/preview' : ''
                          }/${other.slug}`}
                          src={other.thumbnail}
                          className="d-block p-relative w-100"
                        >
                          <div className="short-title p-absolute w-100 p-2">
                            <div className="row h-100">
                              <div className="col-12">
                                <p className="mb-0 py-2 pl-1 pl-xl-4 mt-3 w-75 h5 h4-sm">
                                  {other.short_title}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </Courses>
          </Container>
          <div className="container-fluid">
            {searchActive && (
              <CoursesSearch active={searchActive} search={search} />
            )}
          </div>
          <Modal
            className="modal-introduction"
            show={show}
            size="xl"
            onHide={handleClose}
            centered
          >
            <Modal.Body className="p-0">
              <Player src={course.video} className="video-size" />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <div
            className={`${loading === true ? 'd-none' : ''} container vh-100`}
          >
            <div className="row h-100 align-items-center text-center">
              <div className="col-12">
                <h1 className="h4 color-course-not-found">
                  {language.course_not_found.h1}
                </h1>
                <button
                  type="button"
                  onClick={() => document.location.reload()}
                  className="btn payment-button font-weight-600 mt-3 py-3 px-5"
                >
                  {language.course_not_found.button}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Course;
