import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Content, Box } from './styles';

import stamp from '~/assets/icons/stamp.svg';

import logoMenu from '~/assets/logotipos/logo-menu.svg';

interface ILessonStudent {
  id: string;
  completed: boolean;
}

interface ILesson {
  id: string;
  slug: string;
  lessonStudent: ILessonStudent[];
}

interface ICourseStudent {
  id: string;
  student_id: string;
  last_lesson_slug: string;
  deleted_at: string;
}

interface ICourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  slugLesson: string;
  release_date: string;
  lessons: ILesson[];
  courseStudent: ICourseStudent[];
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface ICourseFound {
  id: string;
  title: string;
  description: string;
  slug: string;
  slugLesson: string;
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
  myCourse: boolean;
}

interface ICoursesSearchProps {
  active: boolean;
  search: string;
}

let cancelToken: undefined | CancelTokenSource;

const CoursesSearch: React.FC<ICoursesSearchProps> = ({ active, search }) => {
  const { user } = useAuth();
  const { language, idiom } = useLanguage();
  const [courses, setCourses] = useState<ICourseFound[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (cancelToken) {
      cancelToken.cancel('canceled');
    }

    if (search.length > 0) {
      cancelToken = axios.CancelToken.source();

      api
        .get<ICourse[]>('courses/students', {
          cancelToken: cancelToken.token,
          params: {
            title: search,
          },
        })
        .then((response) => {
          const data = response.data.map((course) => {
            const myCourse = course.courseStudent.find(
              (courseStudent) =>
                courseStudent.student_id === user.id &&
                !courseStudent.deleted_at
            );
            let slugLesson = 'introduction';
            if (myCourse) {
              const courseStudentSelected = course.courseStudent.find(
                (courseStudent) => courseStudent.student_id === user.id
              );

              if (courseStudentSelected) {
                slugLesson = courseStudentSelected.last_lesson_slug;
              }
            }
            return {
              id: course.id,
              title: course.title,
              description: course.description,
              slug: course.slug,
              thumbnail: {
                title: course.thumbnail ? course.thumbnail.title : '',
                thumbnail_url: course.thumbnail
                  ? course.thumbnail.thumbnail_url
                  : '',
              },
              myCourse: !!myCourse,
              slugLesson,
            };
          });

          setCourses(data);
          setLoading(false);
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
      setCourses([]);
    }
  }, [search, user.id]);

  return (
    <>
      <Container active={active}>
        <Content>
          <img src={logoMenu} alt="logo" className="logo pl-2 pl-lg-0" />
          <div className="row pt-3">
            {courses.map((course) => (
              <div className="col-sm-6 col-xl-4 col-xxl-3 mb-5">
                <Box
                  src={course.thumbnail.thumbnail_url}
                  to={`${process.env.PUBLIC_URL}/${idiom}/courses/${
                    course.myCourse
                      ? `${course.slug}/${course.slugLesson}`
                      : `preview/${course.slug}`
                  }`}
                  className="d-block p-relative w-100 course-link"
                >
                  <div className="short-title p-absolute w-100 p-4 p-5-1800">
                    <div className="row h-100">
                      <div className="col-12">
                        <p className="h6 h5-xl h4-xxl font-weight-600 mb-0 py-2 pl-3 w-100 mt-2">
                          {course.thumbnail.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </div>
        </Content>
      </Container>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default CoursesSearch;
