import styled from 'styled-components';

interface IContainerProps {
  active: boolean;
}

export const Container = styled.div<IContainerProps>`
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;

  top: ${(props) => (props.active ? 0 : 1000)}px;
  left: 0;
  opacity: 1;

  transition: all 0.5s ease-in-out;
  z-index: 2000;

  .row-close {
    position: relative;
    top: -10px;
    left: 0px;
  }

  .logo {
    width: 45px;
    height: 31px;

    margin-left: 40px;
  }
  .btn-close {
    margin-right: 50px;
  }
  .avatar {
    position: relative;
    padding: 8px;
    display: inline-flex;
  }

  .avatar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px 24px 24px 0px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      179deg,
      rgba(255, 255, 255, 0.16) 0.36%,
      rgba(185, 174, 174, 0.32) 0.37%,
      rgba(21, 21, 21, 0.8) 100.24%
    );
    backdrop-filter: blur(20px);
    z-index: 1;
  }

  .avatar img {
    border-radius: 20px 20px 20px 0px;
    position: relative;
    z-index: 2;
  }
`;

export const Content = styled.div`
  padding-top: 3rem;
  width: 100%;
  min-height: 100%;
  fill: rgba(0, 0, 0, 0.65);

  backdrop-filter: blur(50px);
  //border-top-left-radius: 20px;
  //border-top-right-radius: 20px;
`;
