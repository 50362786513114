import styled from 'styled-components';

export const Container = styled.div`
  input {
    border: none;
    border-radius: 0;
    color: #9c9c9c;
    ::placeholder {
      color: #9c9c9c;
    }
  }

  .message-side {
    height: 460px;
    overflow: auto;
    padding-right: 10px;
  }

  .message-side button {
    background: transparent;
    border-radius: 26px;
    border: 1px solid #393838;
    backdrop-filter: blur(20px);
    color: #9c9c9c;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    text-align: start;
  }

  .me p {
    border-radius: 26px 26px 0px 26px;
    background: linear-gradient(
        0deg,
        rgba(41, 41, 41, 0.6) 0%,
        rgba(41, 41, 41, 0.6) 100%
      ),
      linear-gradient(
        180deg,
        rgba(255, 174, 82, 0.75) 0%,
        rgba(255, 156, 43, 0.75) 100%
      );
    backdrop-filter: blur(20px);
    color: #e4e4e4;
    font-size: 20px;
    font-weight: 400;
    max-width: 75%;
    width: fit-content;

    margin-left: auto;
  }

  .luna p {
    color: #e9e8e8;
    font-size: 20px;
    font-weight: 400;
  }

  .btn-send {
    padding: 3px;
    background: rgba(217, 217, 217, 0.2);
    position: absolute;
    right: 30px;
    top: 12px;
  }

  .text-ai {
    border-radius: 22px;
    border: 1px solid rgba(51, 51, 51, 0.8);
    background: rgba(31, 32, 32, 0.6);
    box-shadow: 1px 16px 30px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    height: 64px;
    div {
      height: 26px;
      padding-right: 30px;
    }
  }

  .bg-luna {
    border-radius: 28px;
    background: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0.27) 0%,
      rgba(25, 26, 27, 0.7) 100%
    );
    backdrop-filter: blur(10px);
    padding: 12px;
  }
  .bg-luna img {
    border-radius: 24px 24px 0px 0px;
    margin-bottom: -103px;
  }
  .desc-luna {
    border-radius: 0px 0px 24px 24px;
    background: linear-gradient(
      180deg,
      rgba(182, 181, 181, 0.01) 17.23%,
      rgba(35, 35, 35, 0.8) 52.37%
    );
    backdrop-filter: blur(3px);

    width: 100%;
    padding: 5px 23px 36px 23px;
  }

  .desc-luna span {
    color: #fafafa;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
  .desc-luna h3 {
    color: #fff;
    font-family: 'Inter';
    font-size: 80px;
    font-weight: 400;
    line-height: 75px;
  }
  .desc-luna p {
    color: #fafafa;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.17px;
    margin-bottom: 0px;
  }
  .expand svg {
    transition: all 0.2s ease-in-out;
    :hover {
      color: #fff !important;
    }
  }
`;
