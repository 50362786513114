import styled, { keyframes } from 'styled-components';
import bgLogin from '~/assets/defaults/bg-login.png';
import bg from '~/assets/banners/banner-left-side.png';

interface IBackground {
  src: string;
}

export const Container = styled.div`
  background-color: var(--asphaltGray);
  min-height: 100vh;

  .position {
    right: 0;
    top: 0;
    z-index: 1;
  }

  .text-black {
    color: #000;
  }

  .roboto {
    font-family: 'Roboto', sans-serif;
  }

  .sans-pro {
    font-family: 'Source Sans Pro', sans-serif;
  }

  .bg-right-side {
    min-height: 100vh;
    background-color: #f5f3ed;
  }

  .bg {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 25px 25px 0 0;
    min-height: 275px;
    img {
      width: 272px;
      height: 62px;
    }
  }

  .left-side {
    border-radius: 25px;
    background: linear-gradient(
        0deg,
        rgba(255, 174, 82, 0.71) 0%,
        rgba(255, 174, 82, 0.71) 100%
      ),
      #f5f3ed;

    h2 {
      color: #000;

      text-align: center;
      font-family: 'Quicksand', sans-serif;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      margin-top: 80px;
      margin-bottom: 50px;
    }
    h3 {
      color: #000;

      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 134.078%;
      letter-spacing: 0.32px;
      margin-bottom: 60px;
      letter-spacing: 0.4px;
    }

    p {
      color: #000;

      font-family: 'Source Sans Pro';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 118%;
      text-transform: capitalize;
      img {
        width: 16px;
        height: 12px;
      }
    }

    .socials {
      color: #3a3a3a;
    }

    .socials a {
      color: #3a3a3a;

      text-align: center;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 164.5%;
      letter-spacing: 0.84px;
    }
  }

  @media screen and (min-width: 992px) {
    .bg-right-side {
      background-image: url(${bgLogin});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      min-height: 730px;
      height: 100vh;
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  .login {
    background: #f5f3ed;

    border-radius: 0px;
    color: var(--graniteGray);
  }

  h1 {
    margin-bottom: 24px;
    color: var(--ultraWhite);
  }

  h2 {
    color: var(--graniteGray);
    font-weight: bold !important;
  }

  p {
    color: var(--graniteGray);
  }

  .normal-sm a {
    color: #000;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background: linear-gradient(180deg, #3fde9e 0%, #29bc81 100%);
    color: #f9ede5;
    text-decoration: none;
    border: none;
    border-radius: 22px;
    font-size: 22px;

    span {
      padding: 11px;
      color: #000;
    }
  }

  .small {
    color: var(--grayImmensity);
  }

  form {
    margin: 30px 0;

    > div {
      padding: 7px 10px;
      border: 1px solid #ffae52;
      background-color: #fff !important;
      border-radius: 22px;
    }

    input {
      width: 100%;
      height: 40px;
      color: #000;
    }

    input[id='password'] {
      width: 77%;
    }

    .btn-show-custom {
      .button-show {
        position: absolute;
        top: -8px;
        right: 0px;
        width: 70px;
        text-align: center;
        background: none;
        border-width: 0px 0px 0px 1px;
        border-top-style: initial;
        border-right-style: initial;
        border-bottom-style: initial;
        border-top-color: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-image: initial;
        font-weight: bold;
        padding: 6px;
        color: #333;
        height: 56px !important;
      }
    }

    a {
      color: var(--greyMineral);
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: var(--greyMineralDarken);
      }
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 68%;
      }
    }
  }

  @media screen and (min-width: 992px) {
    > div {
      background: var(--closedGray);
      background: linear-gradient(
        180deg,
        rgba(231, 229, 228, 1) 0.29%,
        rgba(249, 237, 229, 1) 86.94%
      );

      border: none;
      padding: 1px;
      background-image: linear-gradient(
          136deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.5)
        ),
        linear-gradient(
          135deg,
          rgba(255, 197, 132, 1) 0.29%,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0) 80%,
          rgba(59, 215, 152, 1) 101.74%
        );
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-image-slice: 1;
      border-radius: 30px;
      transition-duration: 0.3s;
      color: var(--ultraWhite);
      box-shadow: 0px 4px 4px 0px rgba(203, 203, 203, 0.25);

      @media screen and (min-width: 992px) {
        width: 75%;
        margin: 0 auto;
      }

      @media screen and (min-width: 1700px) {
        width: 60%;
      }
    }

    .login {
      background: linear-gradient(
        180deg,
        rgba(249, 237, 229, 1) 0.29%,
        rgba(249, 237, 229, 1) 86.94%
      );

      border-radius: 30px;
      color: var(--graniteGray);
    }

    .button-login span {
      color: #f9ede5;
    }

    form {
      margin: 60px 0;
    }
  }
`;

export const Background = styled.div<IBackground>`
  background-image: url(${(props) => props.src});
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  min-height: 730px;

  position: relative;

  .content {
    position: relative;
    z-index: 1;
    opacity: 0.8;

    a {
      color: var(--graniteGray);
    }
  }
`;
