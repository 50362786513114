import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import {
  differenceInMilliseconds,
  addDays,
  parseISO,
  differenceInHours,
} from 'date-fns';
import { Form } from '@unform/web';
import { IoClose } from 'react-icons/io5';
import { Modal } from './styles';

import logo from '~/assets/logotipos/logo-white-modal.svg';
import checkWhite from '~/assets/icons/check-white.svg';
import safe from '~/assets/defaults/safe-modal.png';
import money from '~/assets/defaults/money-modal.png';
import proteced from '~/assets/defaults/proteced.png';
import ticket from '~/assets/defaults/ticket.svg';

import arrow from '~/assets/animations/arrow.json';

import Input from '../Input';
import { formatPrice } from '~/utils/format';
import apiNew from '~/services/apiNew';
import { useAuth } from '~/hooks/Auth';

interface IPrice {
  id: string;
  name: string;
  value: string;
  type: string;
  period: number;
  file: {
    file_url: string;
  };
}

interface IProduct {
  prices: IPrice[];
}

interface IOrder {
  id: string;
  created_at: string;
  price: IPrice;
}

interface IPlan {
  id: string;
  thumbnail: string;
  name: string;
  oldPrice: string;
  price: string;
  type: string;
  period: number;
  planPeriod: string;
  shortPlanPeriod: string;
}

interface IModal {
  show: boolean;
  onClose: () => void;
}
const ModalBuyPlan: React.FC<IModal> = ({ show, onClose }) => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [plan, setPlan] = useState({} as IPlan);
  const [othersPlans, setOthersPlans] = useState(false);
  const [isYourDetails, setIsYourDetails] = useState(true);
  const [myPlan, setMyPlan] = useState({} as IPlan);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [orderDate, setOrderDate] = useState('');

  useEffect(() => {
    apiNew
      .get<IProduct>(`products/${process.env.REACT_APP_WISERR_PLANS}`)
      .then((response) => {
        const data = response.data.prices.map<IPlan>((priceData) => {
          let planPeriod = 'Monthly';
          let shortPlanPeriod = 'Mon';

          if (priceData.type === 'subscription' && priceData.period === 12) {
            planPeriod = 'Yearly';
            shortPlanPeriod = 'Year';
          }

          if (priceData.type === 'lifetime') {
            planPeriod = 'Lifetime Access';
            shortPlanPeriod = 'One-time';
          }

          return {
            id: priceData.id,
            thumbnail: priceData.file.file_url,
            name: priceData.name,
            oldPrice: formatPrice(parseFloat(priceData.value)).replace(
              '.00',
              ''
            ),
            price: formatPrice(
              Math.floor(parseFloat(priceData.value) * 0.8)
            ).replace('.00', ''),
            type: priceData.type,
            period: priceData.period,
            planPeriod,
            shortPlanPeriod,
          };
        });

        setPlans(data.slice(1, 4));
      });
  }, []);

  useEffect(() => {
    apiNew
      .get<IOrder>(
        `orders/students/${user.id}/products/${process.env.REACT_APP_WISERR_PLANS}`,
        {
          params: {
            status: ['succeeded', 'active'],
          },
        }
      )
      .then((response) => {
        let planPeriod = 'Monthly';
        let shortPlanPeriod = 'Mon';

        if (
          response.data.price.type === 'subscription' &&
          response.data.price.period === 12
        ) {
          planPeriod = 'Yearly';
          shortPlanPeriod = 'Year';
        }

        if (response.data.price.type === 'lifetime') {
          planPeriod = 'Lifetime Access';
          shortPlanPeriod = 'One-time';
        }

        const planSelected = {
          id: response.data.price.id,
          thumbnail: response.data.price.file.file_url,
          name: response.data.price.name,
          oldPrice: '',
          price: formatPrice(parseFloat(response.data.price.value)).replace(
            '.00',
            ''
          ),
          type: response.data.price.type,
          period: response.data.price.period,
          planPeriod,
          shortPlanPeriod,
        };

        const planIndex = plans.findIndex(
          (planData) => planData.id === planSelected.id
        );

        setOrderDate(response.data.created_at);
        setMyPlan(planSelected);
        if (plans.length > 0) {
          setPlan(plans[planIndex + 1]);
        }
      });
  }, [plans, user.id]);

  useEffect(() => {
    if (orderDate) {
      if (differenceInHours(new Date(), parseISO(orderDate)) > 24) {
        setShowModal(false);
      } else {
        setShowModal(show);
      }
    }
  }, [orderDate, show]);

  const plansData = useMemo<IPlan[]>(() => {
    const planIndex = plans.findIndex((planData) => planData.id === myPlan.id);
    if (planIndex >= 0) {
      return plans.slice(planIndex + 1, plans.length);
    }

    return plans;
  }, [myPlan.id, plans]);

  const local = localStorage.getItem('date');
  if (local === null) {
    localStorage.setItem('date', new Date().getTime().toString());
  }

  const memoizedTargetDate = useMemo(() => {
    return orderDate ? parseISO(orderDate) : new Date();
  }, [orderDate]);

  const calculateTimeRemaining = useCallback(() => {
    const now = new Date();
    const difference = differenceInMilliseconds(
      addDays(memoizedTargetDate, 1),
      now
    );

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }, [memoizedTargetDate]);

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeRemaining]);

  const handleUpgradePlan = useCallback(() => {
    setIsYourDetails(false);
  }, []);

  const handleSelectNewPlan = useCallback((planSelected) => {
    setPlan(planSelected);
    setOthersPlans(false);
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        onClose();
        setOthersPlans(false);
        setIsYourDetails(true);
      }}
      className="modal-plans"
      centered
      size="xl"
    >
      <Modal.Body className="bg-modal">
        <Form onSubmit={() => console.log('teste')}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6">
                <div className="left-side position-relative mb-3 mb-sm-0 position-relative">
                  <button
                    type="button"
                    onClick={() => {
                      onClose();
                      setOthersPlans(false);
                      setIsYourDetails(true);
                    }}
                    className="d-lg-none btn-x"
                  >
                    <IoClose color="#fff" size={30} />
                  </button>
                  <div
                    className={`${
                      othersPlans ? 'd-flex' : 'd-none'
                    } bg-change-plan  flex-column justify-content-center align-items-center`}
                  >
                    {plansData.map((planData) => (
                      <button
                        type="button"
                        onClick={() => handleSelectNewPlan(planData)}
                        className={`${
                          myPlan.id === planData.id ? 'current-plan' : ''
                        } d-flex align-items-center card-choose my-1`}
                      >
                        <img src={planData.thumbnail} alt={planData.name} />

                        <div className="ml-3 ml-lg-4">
                          <h4 className="text-left mb-1 mb-lg-2">
                            {planData.name}
                          </h4>
                          <p className="text-left mb-0">
                            {myPlan.id === planData.id
                              ? myPlan.price
                              : planData.price}{' '}
                            {planData.planPeriod === 'Lifetime Access'
                              ? planData.shortPlanPeriod
                              : planData.planPeriod}
                          </p>
                        </div>

                        {myPlan.id === planData.id ? (
                          <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                            Current <br className="d-lg-none" />
                            Plan
                          </span>
                        ) : (
                          <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 choose-color">
                            Choose <br className="d-lg-none" />
                            Plan
                          </span>
                        )}
                      </button>
                    ))}
                  </div>
                  <div className="bg text-center position-relative">
                    <img
                      src={logo}
                      alt="Wiserr"
                      className="mt-4 mb-3 my-lg-5"
                    />
                    <div className="positions-last p-4">
                      <div className="d-flex justify-content-between">
                        <div className="w-100 d-flex justify-content-between justify-content-sm-around">
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.days}
                            </span>
                            <span className="d-block">
                              {timeRemaining.days > 1 ? 'Days' : 'Day'}
                            </span>
                          </div>
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.hours}
                            </span>
                            <span className="d-block">
                              {timeRemaining.hours > 1 ? 'Hrs' : 'Hr'}
                            </span>
                          </div>
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.minutes}
                            </span>
                            <span className="d-block">
                              {timeRemaining.minutes > 1 ? 'Mins' : 'Min'}
                            </span>
                          </div>
                          <div className="timer text-center">
                            <span className="d-block">
                              {timeRemaining.seconds}
                            </span>
                            <span className="d-block">
                              {timeRemaining.seconds > 1 ? 'Secs' : 'Sec'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => setOthersPlans(true)}
                      className={`${
                        othersPlans ? 'opacity-0' : 'opacity-1'
                      } d-flex align-items-center card-change current-plan my-1`}
                    >
                      <img src={plan.thumbnail} alt={plan.name} />

                      <div className="ml-3 ml-lg-4 ">
                        <h4 className="text-left mb-1 mb-lg-2">{plan.name}</h4>
                        <p className="text-left mb-0">
                          {myPlan.id === plan.id ? myPlan.price : plan.price}{' '}
                          {plan.planPeriod === 'Lifetime Access'
                            ? plan.shortPlanPeriod
                            : plan.planPeriod}
                        </p>
                      </div>

                      <span className="text-center text-lg-right ml-auto mr-2 mr-lg-5 current-color">
                        Upgrade <br className="d-lg-none" />
                        Plan
                      </span>
                    </button>
                  </div>
                  <div className="px-3 px-sm-4 px-lg-5 bg-left-bottom">
                    <h2 className="text-center">
                      Upgrade to a Higher Plan Today &{' '}
                      <span className="position-relative">
                        &nbsp;Get&nbsp;20%&nbsp;OFF&nbsp;
                        <img src={ticket} alt="Ticket" className="img-ticket" />
                      </span>
                    </h2>
                    <h3 className="text-center px-lg-4">
                      Starting and growing a business requires massive action,{' '}
                      <b>and we love rewarding action takers!</b>
                    </h3>
                    {plansData.map((planData) => (
                      <p key={planData.id} className="text-center px-2 px-lg-4">
                        <img src={checkWhite} alt="Check" className="mr-4" />
                        {planData.name.replace('Plan - Lifetime', '')}
                        {planData.id === myPlan.id ? (
                          <>
                            :{' '}
                            <b className="font-weight-bold">
                              &nbsp;{myPlan.price} {myPlan.planPeriod}
                            </b>
                          </>
                        ) : (
                          <>
                            {' '}
                            <span>
                              {planData.oldPrice}/
                              {planData.shortPlanPeriod.toLowerCase()}
                            </span>
                            :
                            <b className="font-weight-bold">
                              &nbsp;{planData.price} {planData.planPeriod}
                            </b>
                          </>
                        )}
                      </p>
                    ))}
                    <h4 className="text-center mt-4">
                      <span role="img" aria-label="emoji">
                        👉🏾
                      </span>{' '}
                      <span>24-Hr Only</span> Special Savings&nbsp;Reward{' '}
                      <span role="img" aria-label="emoji">
                        🎁
                      </span>
                    </h4>

                    <div className="text-center mt-5 pb-4 terms">
                      <Link to="/" className="mx-1 mx-lg-3">
                        Privacy Policy
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Terms of Service
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Security
                      </Link>{' '}
                      |
                      <Link to="/" className="mx-1 mx-lg-3">
                        Cookies
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-side">
                  <div className="mt-3 d-flex">
                    <button
                      onClick={() => {
                        onClose();
                        setOthersPlans(false);
                        setIsYourDetails(true);
                      }}
                      type="button"
                      className="d-none d-lg-block btn-back ml-auto mr-4 mr-lg-5"
                    >
                      Close
                    </button>
                  </div>
                  <div className="personal">
                    {isYourDetails ? (
                      <>
                        <div className="px-4 px-lg-0">
                          <h2>
                            You are in!{' '}
                            <span role="img" aria-label="emoji">
                              🎉
                            </span>
                          </h2>
                          <h4 className="mb-4">
                            Congratulations on Taking the First Step!
                          </h4>
                          <p className="mb-5">
                            You’ve made a great decision by choosing the{' '}
                            <span className="font-weight-600">
                              {myPlan.name}
                            </span>{' '}
                            to start your entrepreneurial journey.{' '}
                          </p>
                          <h3 className="mb-4">But why stop there?</h3>
                          <p className="mb-5">
                            As an action taker, we want to reward your decision
                            to join us with an exclusive opportunity.
                          </p>
                          <h3 className="mb-4">
                            Upgrade to our higher plan and get:
                          </h3>
                          <ul className="pl-4 pl-lg-5">
                            <li>
                              <span className="h5">Exclusive savings:</span>{' '}
                              Gain limited-time access to additional 20% savings
                              on any plan,{' '}
                              <span className="font-weight-600 color-list">
                                valid for the next 24 hours.
                              </span>
                            </li>
                            <li>
                              <span className="h5">Resource Libraries:</span>{' '}
                              Unlock a library of exclusive templates,
                              checklists, e-books and detailed reports on
                              trending business topics.
                            </li>
                            <li>
                              <span className="h5">
                                Discounts on Future Products:
                              </span>{' '}
                              Enjoy exclusive Enjoy exclusive discounts on
                              future product and services.
                            </li>
                            <li>
                              <span className="h5">VIP Community Access:</span>{' '}
                              Join our VIP community for exclusive discussions
                              and networking opportunities.
                            </li>
                            <li>
                              <span className="h5">
                                Priority Access to New Courses:
                              </span>{' '}
                              Be the first to access new courses before they are
                              publicly available.
                            </li>
                          </ul>
                        </div>
                        <button
                          type="button"
                          onClick={handleUpgradePlan}
                          className="d-flex align-items-center justify-content-center btn-continue mt-5"
                          disabled={myPlan.id === plan.id}
                        >
                          <span className="mr-2">
                            Yes! I Want To Upgrade My Plan Now & Save 20%
                          </span>{' '}
                          <span className="anim-lottie">
                            <Lottie
                              options={{
                                animationData: arrow,
                                autoplay: true,
                                loop: true,
                                rendererSettings: {
                                  preserveAspectRatio: 'xMidYMid slice',
                                },
                              }}
                              height={45}
                              width={80}
                            />
                          </span>
                        </button>
                        <div className="d-flex d-lg-none my-5">
                          <button
                            type="button"
                            onClick={() => {
                              onClose();
                              setOthersPlans(false);
                              setIsYourDetails(true);
                            }}
                            className=" btn-no mx-auto"
                          >
                            No, thanks I don’t want the savings
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="px-4 px-lg-0">
                        <h2 className="mt-lg-5 pt-lg-5">Almost there</h2>
                        <p>Enter your payment details below</p>
                        <span className="text-black d-block mb-3">
                          Credit Card Number
                        </span>
                        <Input
                          name="number"
                          type="number"
                          className="mb-4 input"
                        />
                        <div className="d-lg-flex">
                          <div className="w-lg-50 pr-lg-3">
                            <span className="text-black d-block mb-3">
                              Expiration
                            </span>
                            <Input
                              name="expiration"
                              type="number"
                              className="mb-4 input"
                            />
                          </div>
                          <div className="w-lg-50 pl-lg-3">
                            <span className="text-black d-block mb-3">
                              CVC*
                            </span>
                            <Input
                              name="cvc"
                              type="number"
                              className="mb-4 input"
                            />
                          </div>
                        </div>
                        <span className="text-black d-block mb-3">
                          Billing Address
                        </span>
                        <Input name="address" className="mb-4 input mb-5" />

                        <p className="d-flex justify-content-between">
                          <span className="font-weight-normal">Subtotal</span>
                          <span className="font-weight-normal">
                            {formatPrice(
                              parseFloat(plan.price.replace('$', ''))
                            )}
                          </span>
                        </p>
                        <p className="d-flex justify-content-between">
                          <span className="font-weight-normal">
                            Special Offer
                          </span>
                          <span className="font-weight-normal">-</span>
                        </p>
                        <hr />
                        <p className="total d-flex justify-content-between">
                          <span>Order Total:</span>
                          <span>{plan.price}</span>
                        </p>
                        <button type="button" className={` btn-continue`}>
                          Pay Now
                        </button>
                        <div className="d-flex my-4 imgs-footer">
                          <img src={safe} alt="Safe" />
                          <img src={money} alt="Money" />
                          <img src={proteced} alt="Proteced" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuyPlan;
