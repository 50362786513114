import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import { darken } from 'polished';
import { Link } from 'react-router-dom';
import video from '~/assets/temp/video.png';

interface ILessonData {
  coachLocked?: boolean;
}

interface IBgLesson {
  background: string;
}

interface IOthersLessons {
  src: string;
}

export const Container = styled.div`
  .open-video {
    left: 0px;
    top: 0px;
    z-index: 100;
    width: 100vw;
    pointer-events: none;
    height: 100vh;
    background: rgba(0, 0, 0, 0.44);
  }
  .video-lesson {
    position: fixed;
    left: 17px;
    top: 17px;
    border-radius: 20px;
    z-index: 101;
    max-width: 1380px !important;
    overflow: hidden;
    height: calc(100vh - 100px) !important;
    max-height: 780px !important;
    padding: 0 !important;
    video {
      object-fit: cover;
    }
  }

  input {
    border: none;
    border-radius: 0;
    color: #9c9c9c;
    ::placeholder {
      color: #9c9c9c;
    }
  }

  .message-side {
    height: calc(100vh - 360px);
    overflow: auto;
    padding-right: 10px;
  }

  .message-side button {
    background: transparent;
    border-radius: 26px;
    border: 1px solid #393838;
    backdrop-filter: blur(20px);
    color: #9c9c9c;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    text-align: start;
  }

  .me p {
    border-radius: 26px 26px 0px 26px;
    background: linear-gradient(
        0deg,
        rgba(41, 41, 41, 0.6) 0%,
        rgba(41, 41, 41, 0.6) 100%
      ),
      linear-gradient(
        180deg,
        rgba(255, 174, 82, 0.75) 0%,
        rgba(255, 156, 43, 0.75) 100%
      );
    backdrop-filter: blur(20px);
    color: #e4e4e4;
    font-size: 20px;
    font-weight: 400;
    max-width: 75%;
    width: fit-content;

    margin-left: auto;
  }

  .luna p {
    color: #e9e8e8;
    font-size: 20px;
    font-weight: 400;
  }

  .btn-send {
    padding: 3px;
    background: rgba(217, 217, 217, 0.2);
    position: absolute;
    right: 30px;
    top: 12px;
  }

  .text-ai {
    border-radius: 22px;
    border: 1px solid rgba(51, 51, 51, 0.8);
    background: rgba(31, 32, 32, 0.6);
    box-shadow: 1px 16px 30px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    height: 64px;
    div {
      height: 26px;
      padding-right: 30px;
    }
  }

  .bg-luna {
    border-radius: 28px;
    background: linear-gradient(
      180deg,
      rgba(14, 14, 14, 0.27) 0%,
      rgba(25, 26, 27, 0.7) 100%
    );
    backdrop-filter: blur(10px);
    padding: 12px;
  }
  .bg-luna img {
    border-radius: 24px 24px 0px 0px;
    margin-bottom: -103px;
  }
  .desc-luna {
    border-radius: 0px 0px 24px 24px;
    background: linear-gradient(
      180deg,
      rgba(182, 181, 181, 0.01) 17.23%,
      rgba(35, 35, 35, 0.8) 52.37%
    );
    backdrop-filter: blur(3px);

    width: 100%;
    padding: 5px 23px 36px 23px;
  }

  .desc-luna span {
    color: #fafafa;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
  .desc-luna h3 {
    color: #fff;
    font-family: 'Inter';
    font-size: 80px;
    font-weight: 400;
    line-height: 75px;
  }
  .desc-luna p {
    color: #fafafa;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.17px;
    margin-bottom: 0px;
  }
`;

export const Skeleton = styled.div`
  .skeleton-player {
    height: 400px !important;
    width: 100% !important;
  }

  .skeleton-arrow {
    width: 38px;
    height: 38px;
  }

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: 575px) {
    .skeleton-player {
      height: 200px !important;
    }
  }
`;

export const Welcome = styled.div`
  position: relative;

  h1 {
    color: #f5f5f5;
    font-style: normal;
    line-height: normal;
    margin-top: 15%;
  }

  p {
    color: var(--technologicalGray);
  }

  .teste {
    color: #f00;
  }

  .height {
    height: 820px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      background-color: var(--midnight);
      width: 0px;
    }
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const LinkLesson = styled(Link)<IOthersLessons>`
  border-radius: 20px;
  text-decoration: none;
  height: 185px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-duration: 0.3s;
  :hover {
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.45),
      0px 15px 64px -45px rgba(199, 197, 197, 0.42);
    transform: scale(1.11);
    text-decoration: none;
  }

  span {
    color: #f5f5f5;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const Locked = styled.div`
  img {
    width: 50px;
    height: 50px;
  }

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: 55px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const LessonBg = styled.div<IBgLesson>`
  background: url(${(props) => props.background});

  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 90%;
  position: relative;

  h1 {
    color: #f5f5f5;
    font-size: 80px;
    font-weight: 600;
  }

  p {
    color: var(--graniteGray);
  }

  .separator {
    background: linear-gradient(
      90deg,
      #464646 -12.11%,
      rgba(70, 70, 70, 0) 103.4%
    );
    width: 100%;
    height: 6px;
    margin-top: -6px;
  }

  ::before {
    content: '';
    position: absolute;
    background: linear-gradient(
        0deg,
        var(--midnight) 4.26%,
        var(--midnightOpacity) 100%
      ),
      linear-gradient(90deg, var(--midnight) 4.26%, var(--midnightOpacity) 100%);
    //backdrop-filter: blur(20px);
    width: 100%;
    height: 100%;
  }

  .container,
  .container-lg {
    max-width: 1600px !important;
  }

  .old-price {
    color: var(--error);
  }

  .button-play {
    background-color: var(--midnight);
    border: none;
    border-radius: 20px;

    :hover {
      background-color: 'var(--midnightDarken)';
      transition: 0.5s;
    }
  }

  .btn-watch {
    font-family: 'Poppins';
    width: 267px;
    border-radius: 22px;
    background: linear-gradient(270.03deg, #fb5a43 0%, #c341ae 100%);
    border: 2px solid rgba(255, 255, 255, 0.8);
    transition-duration: 0.3s;
    font-weight: 600;
    font-size: 20px;
    padding: 15px;

    :hover {
      background: linear-gradient(
        270.03deg,
        ${darken(0.05, '#fb5a43')} 0%,
        ${darken(0.05, '#c341ae')} 100%
      );
    }
  }

  .border-top-courses {
    border-top: 2px solid var(--deepGrey) !important;
  }

  .pd-button {
    padding: 20px 0 !important;
  }

  .category {
    background: linear-gradient(90deg, #fb5a43 0%, #c341ae 100%);
    padding: 2px;

    span {
      background: #18191a;
    }

    + b {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    .arrow-xs {
      width: 30px;
      height: 30px;
    }
  }
`;

export const LessonData = styled.div<ILessonData>`
  position: relative;

  .lock-message {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 330px;
    z-index: 1;
    padding: 10px;
    border-radius: 10px;
    right: 20px;
    top: -70px;
    border: solid 2px var(--greyMineral);
    background: var(--coal);
    transition-duration: 0.3s;
  }

  :hover {
    .lock-message {
      opacity: ${(props) => (props.coachLocked ? '1' : '0')};
      visibility: ${(props) => (props.coachLocked ? 'visible' : 'hidden')};
    }
  }

  h3 {
    color: var(--graniteGray);
  }

  h4 {
    color: var(--blizzard);
  }

  a:hover {
    text-decoration: none;
  }

  .btn-module {
    color: #969696;
    border-bottom: 6px solid #f5f5f500;
  }

  .btn-module-active {
    color: #f5f5f5;
    border-bottom: 6px solid #f5f5f5;
  }

  .player {
    height: 400px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
  }

  p {
    color: var(--grayImmensity);
  }

  .border-gray {
    border-color: var(--grayImmensity) !important;
  }

  /* @media screen and (min-width: 1200px) {
    .description {
      height: 4vw;
      max-height: 50px;
      overflow: auto;
    }
  } */

  /* @media screen and (min-width: 1700px) {
    .description {
      height: auto;
      overflow: auto;
    }
  } */

  @media screen and (max-width: 575px) {
    .player {
      height: 200px;
    }
  }
`;

export const Video = styled.div`
  width: 100%;
  height: 350px;
  background: var(--midnight);
  border-radius: 20px;
  background-image: url(${video});
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Lessons = styled.div`
  overflow-y: auto;

  @media screen and (min-width: 1200px) {
    height: 10vw !important;
    max-height: 295px;
  }

  @media screen and (min-width: 1300px) {
    height: 12vw !important;
  }

  @media screen and (max-width: 1199px) {
    height: 131px !important;
  }
`;

export const Modules = styled.div``;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;
    background-color: var(--coal);
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: var(--technologicalGray) 591 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em 1em;
    color: var(--technologicalGray);

    > div {
      text-align: center;
    }
  }

  .DayPicker-Weekday {
    color: var(--blizzard);
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    border-radius: 50%;
    color: var(--technologicalGray);
    border: 1px solid var(--technologicalGray);
    font-weight: bold;
    transition-duration: 0.3s;
  }

  .DayPicker-Day--outside {
    background: transparent;
    border-radius: 50%;
    color: var(--greyMineral);
    border: 1px solid var(--greyMineral);
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: var(--technologicalGrayDarken);
    color: var(--closedGray) !important;
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: var(--deepGrey) !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    border: 1px solid var(--technologicalGray) !important;
    background: var(--technologicalGray) !important;
    color: var(--closedGray) !important;
    font-weight: bold;
  }
`;

export const ModalCoach = styled(ModalComponent)`
  .group {
    max-width: 380px;

    .input-calendar {
      border: 1px solid var(--closedGray);
      border-radius: 15px;
      height: 45px;
    }
  }
`;

export const SideBar = styled.div`
  min-width: 321px;
  max-width: 322px;
  background-color: var(--coal);

  @media (min-width: 992px) and (max-width: 1399px) {
    min-width: 269px;
    max-width: 270px;
  }

  @media screen and (max-width: 1199px) {
    background-color: transparent;
  }

  @media screen and (max-width: 991px) {
    max-width: unset;
  }
`;
