import React, { useCallback, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, ModalGiftCard, GiftInput } from './styles';
import InputMask from '../InputMask';
import Input from '../Input';
import Card from '../Card';

import giftLogo from '~/assets/logotipos/gift-card-logo.svg';
import closeIcon from '~/assets/icons/close.svg';

interface IPaymentData {
  hash: string;
}
interface IGiftCardPayProps {
  order_id?: string;
  product_id: string;
  price: string;
  anticipated?: boolean;
  onSucceededPaid?(data?: any): void;
}

const GiftCardPay: React.FC<IGiftCardPayProps> = ({
  order_id,
  product_id,
  price,
  anticipated,
  onSucceededPaid,
}) => {
  const { language } = useLanguage();
  const formRef = useRef<FormHandles>(null);
  const [showGiftCard, setShowGiftCard] = useState(false);
  const [giftValue, setGiftValue] = useState('');
  const [presentedBy, setPresentedBy] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleGiftCardClose = useCallback(() => setShowGiftCard(false), []);
  const handleGiftCardShow = useCallback(async () => {
    setShowGiftCard(true);
  }, []);

  const handleChange = useCallback(async (e) => {
    const hash = e.target.value;
    setGiftValue(hash);
    if (hash.length === 19) {
      try {
        const response = await api.get(`gift-cards/${hash}`);
        if (response.data) {
          const name = response.data.created_by.split(' ');
          setPresentedBy(`${name[0]} ${name[name.length - 1]}`);
          setErrorMessage('');
        }
      } catch (error) {
        if (error.response.data) {
          setErrorMessage(error.response.data.message);
        }
      }
    } else {
      setErrorMessage('');
      setPresentedBy('');
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IPaymentData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          hash: Yup.string().required(language.gift_required.required),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { hash } = data;

        const formData = {
          order_id,
          product_id,
          amount: parseFloat(price),
          hash,
          anticipated,
        };

        const response = await api.post('payments/gift-cards', formData);

        if (response.data) {
          if (onSucceededPaid) {
            onSucceededPaid(response.data);
          }
          handleGiftCardClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          setErrorMessage(errors.hash);
        } else {
          const err = error as any;
          if (
            err.response.data &&
            err.response.data.message ===
              'Gift card invalid for this franchise or course'
          ) {
            Swal.fire(
              'Opss...',
              language.error_component.error_message_axios,
              'error'
            );
          } else {
            Swal.fire(
              'Opss...',
              language.error_component.error_message,
              'error'
            );
          }
        }
      }
    },
    [
      anticipated,
      handleGiftCardClose,
      language.error_component.error_message,
      language.error_component.error_message_axios,
      language.gift_required.required,
      onSucceededPaid,
      order_id,
      price,
      product_id,
    ]
  );

  return (
    <Container>
      <button
        type="button"
        className="h4 h-100 w-100 payment-button"
        onClick={handleGiftCardShow}
      >
        <img src={giftLogo} alt="Gift Card" className="mb-5 mb-xl-4" />
        Gift Card
      </button>

      <ModalGiftCard
        className="modal-binance-pay px-0"
        centered
        size="md"
        show={showGiftCard}
      >
        <ModalGiftCard.Header className="border-0 p-3">
          <ModalGiftCard.Title className="text-center w-100" />
          <button
            type="button"
            className="close-button"
            onClick={handleGiftCardClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </ModalGiftCard.Header>
        <ModalGiftCard.Body className="px-sm-5">
          <div className="col-12">
            <h3 className="h5 h3-sm text-center payment-color mb-4">
              {language.buy_product_button_gift_card.title}
            </h3>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 text-center ">
              <Card value={giftValue} presentedBy={presentedBy} />
            </div>
            <div className="col-12 mt-4">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <label className="d-block mb-2">
                  {language.buy_product_button_gift_card.label_2}
                </label>
                <GiftInput
                  isError={!!errorMessage}
                  className="d-flex align-items-center border-input mb-4"
                >
                  <div className="w-80-350 w-100">
                    <InputMask
                      kind="custom"
                      options={{
                        mask: 'SSSS-SSSS-SSSS-SSSS',
                      }}
                      name="hash"
                      className="bg-transparent border-0 py-2"
                      onChange={handleChange}
                      errorMessage={errorMessage}
                    />
                  </div>
                  <img
                    src={giftLogo}
                    alt="Gift Card"
                    className="height-gift mr-4"
                  />
                </GiftInput>
                {/* <label className="d-block mb-2">
                  {language.buy_product_button_gift_card.label_3}
                </label>
                <div className="d-flex border-input mb-4">
                  <Input
                    name="name"
                    className="w-100 pl-4 bg-transparent border-0"
                    value={presentedBy}
                    disabled
                  />
                </div> */}
                <button
                  type="submit"
                  className="font-weight-bold py-2 mt-2 w-100"
                >
                  {language.buy_product_button_gift_card.button}
                </button>
              </Form>
            </div>
          </div>
        </ModalGiftCard.Body>
        <ModalGiftCard.Footer className="border-0" />
      </ModalGiftCard>
    </Container>
  );
};

export default GiftCardPay;
