import React, { useEffect } from 'react';

import { Container } from './styles';

interface IAlertProps {
  show: boolean;
  setShow(show: boolean): void;
  content: string;
  color?: string;
  showTime?: number;
  background?: string;
}

const Alert: React.FC<IAlertProps> = ({
  show,
  setShow,
  content,
  color,
  showTime,
  background,
}) => {
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, showTime || 1000);
  }, [setShow, show, showTime]);

  return (
    <Container
      show={show}
      color={color || '#ebc034'}
      background={background || 'transparent'}
      className="alert"
    >
      <p className="mb-0">{content}</p>
    </Container>
  );
};

export default Alert;
