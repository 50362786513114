import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import Header from '~/components/Header';
import Footer from '~/components/Footer';
import FloatChats from '~/components/FloatChats';
import ModalBuyPlan from '~/components/ModalBuyPlan';

import { Wrapper, HeaderSpacer, Separator, FooterSpacer } from './styles';
import BeforeQuestions from '~/components/BeforeQuestions';

const DefaultLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const { signOut } = useAuth();
  const [showFooter, setShowFooter] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem('@Wiserr:ModalBuyPlanClosed')) {
      setShowModal(true);
    }
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  const noFullSize = useMemo(
    () =>
      !location.pathname.includes('dashboard') &&
      !location.pathname.match(/\/product\/.*/) &&
      !location.pathname.match(/\/courses\/preview.*/) &&
      !location.pathname.match(/\/courses.*/) &&
      !location.pathname.match(/\/faq.*/) &&
      !location.pathname.match(/\/become-affiliate.*/),
    [location.pathname]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get(`/`).catch(() => {
      signOut();
    });
  }, [location, signOut]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleClose = useCallback(() => {
    sessionStorage.setItem('@Wiserr:ModalBuyPlanClosed', 'true');
    setShowModal(false);
  }, []);

  return (
    <Wrapper>
      <HeaderSpacer>
        <Header />
      </HeaderSpacer>
      <div className="d-flex flex-wrap flex-lg-nowrap separator-container">
        <Separator
          noPadding={
            (!!location.pathname.match(/\/dashboard.*/) ||
              !!location.pathname.match(/\/discover.*/) ||
              !!location.pathname.match(/\/my-courses.*/) ||
              !!location.pathname.match(/\/upcoming.*/) ||
              !!location.pathname.match(/\/certificates.*/) ||
              !!location.pathname.match(/\/preview.*/) ||
              !!location.pathname.match(/\/faq.*/) ||
              !!location.pathname.match(/\/courses.*/) ||
              !!location.pathname.match(/\/product\/.*/)) &&
            !location.pathname.match(/\/products\/discover.*/)
          }
          fullSize={!noFullSize}
        >
          {children}
          <FloatChats />
        </Separator>
        {noFullSize && (
          <FooterSpacer
            show={showFooter}
            className={`${
              location.pathname.match(/\/courses.*/) && width < 1200
                ? 'd-none'
                : 'd-block'
            }`}
          >
            <Footer setShowFooter={setShowFooter} />
          </FooterSpacer>
        )}
      </div>
      <ModalBuyPlan show={showModal} onClose={handleClose} />
      {/* <BeforeQuestions /> */}
    </Wrapper>
  );
};

export default DefaultLayout;
