import styled from 'styled-components';

interface ISeparatorProps {
  noPadding: boolean;
  fullSize: boolean;
}

interface IFooterSpacer {
  show: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: var(--asphaltGray);
  min-height: 100vh;

  .separator-container {
    width: calc(100% - 90px);
  }

  @media screen and (max-width: 991px) {
    display: block;

    .separator-container {
      width: 100%;
    }
  }
`;

export const HeaderSpacer = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 60px;
    justify-content: flex-start;
  }

  @media (min-height: 500px) and (max-height: 800px) {
    @media screen and (min-width: 1400px) {
      align-items: start;
    }
  }
`;

export const Separator = styled.div<ISeparatorProps>`
  background-color: var(--midnight);
  width: ${(props) => (props.fullSize ? '100%' : '100%')};
  padding: ${(props) => (props.noPadding ? '0px' : '15px')};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 0;
  }
`;

export const FooterSpacer = styled.div<IFooterSpacer>`
  background: var(--coal);
  max-width: 323px;
  min-width: 322px;
  transition-duration: 0.3s;
  width: ${(props) => (props.show ? '100%' : '0')};

  @media (min-width: 992px) and (max-width: 1399px) {
    min-width: 270px;
  }

  @media screen and (max-width: 991px) {
    min-width: 100%;
  }

  @media (min-height: 500px) and (max-height: 800px) {
    @media screen and (min-width: 1400px) {
      min-height: 850px;
    }
  }
`;
