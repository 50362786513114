import React, { FC, useCallback, useEffect, useState } from 'react';

import apiVideo from '~/services/apiVideo';

import Player from '~/components/Player_old';

const SignUp: FC = () => {
  const [url, setUrl] = useState('');
  const [urlUpdate, setUrlUpdate] = useState('');

  useEffect(() => {
    apiVideo(
      'courses-and-lessons/00fd70c2267f87ae7fbb-03-crear-un-esquema.mp4'
    ).then((response) => {
      setUrl(response);
    });
  }, []);

  const handleReload = useCallback(async () => {
    apiVideo(
      'courses-and-lessons/00fd70c2267f87ae7fbb-03-crear-un-esquema.mp4'
    ).then((response) => {
      setUrlUpdate(response);
    });
  }, []);

  return (
    <>
      {url.length > 0 && (
        <Player
          onReload={handleReload}
          className="w-50"
          src={url}
          srcUpdate={urlUpdate}
        />
      )}
    </>
  );
};

export default SignUp;
