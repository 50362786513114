/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  SyntheticEvent,
  ChangeEvent,
} from 'react';
import { useLocation, useParams, useHistory, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DayPicker, { DayModifiers } from 'react-day-picker';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ApiCalendar from 'react-google-calendar-api';

import { IoMdArrowUp } from 'react-icons/io';
import { useAuth } from '~/hooks/Auth';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import {
  Container,
  Welcome,
  Locked,
  LessonData,
  Lessons,
  ModalCoach,
  Calendar,
  Skeleton,
  SideBar,
  LessonBg,
  LinkLesson,
} from './styles';
import Player from '~/components/Player';
import Modules from '~/components/Modules';
import Quizzes, { IAnswers, IQuestion } from '~/components/Quizzes';
import Alert from '~/components/Alert';
import Coach from '~/components/Footer/Coach';
import Social from '~/components/Footer/Social';
import NextStep from '~/components/Footer/NextStep';
import calendar from '~/assets/icons/calendar-icon.svg';
import profile from '~/assets/defaults/photo-profile.png';
import arrowLeft from '~/assets/icons/arrow-left-icon.svg';

import Select from '~/components/Select';
import getValidationErros from '~/utils/getValidationsErrors';
import Actions from './Actions';
import Notes from './Notes';
import Support from './Support';
import WaveForm from '~/components/WaveForm';

import lockedIcon from '~/assets/icons/locked.svg';
import members from '~/assets/icons/members-white-icon.svg';
import video from '~/assets/icons/video-white-icon.svg';
import hourIcon from '~/assets/icons/hour-white-icon.svg';
import lunaAvatar from '~/assets/defaults/luna-avatar.png';
import BgBlurAi from '~/components/BgBlurAi';
import Input from '~/components/Input';

interface IParams {
  slug: string;
  slugLesson: string;
}

interface ILessonStudent {
  id: string;
  student_id: string;
  time_watched: string;
  completed: boolean;
  answered_quiz: boolean;
  coach_released: boolean;
  status: string;
  deleted_at: string;
}

interface ICourseLesson {
  id: string;
  title: string;
  duration: string;
  slug: string;
  lessonStudent: ILessonStudent[];
  watch_full_video: boolean;
  answer_quiz: boolean;
  coach_unlock: boolean;
  notes: any;
}

interface ICourseStudent {
  id: string;
  student_id: string;
  locked_down: boolean;
  end_date?: Date;
  locked: boolean;
}

interface IExamStudent {
  id: string;
  exam_id: string;
  note: string;
}

interface IModuleResponse {
  id: string;
  title: string;
  locked: boolean;
  slug: string;
  moduleStudent: {
    id: string;
    locked: boolean;
  };
  lessons: ILesson[];
  exam?: {
    id: string;
    module_id: string;
    questions: IQuestion[];
    examStudent: IExamStudent;
    examsStudents: IExamStudent[];
  };
}

interface IResponseCourse {
  id: string;
  title: string;
  subtitle: string;
  presented_by: string;
  description: string;
  categories: string;
  page_title: string;
  meta_description: string;
  video: {
    video_url: string;
    media_id: string;
    duration: string;
  };
  thumbnail: {
    thumbnail_url: string;
  };
  modules: IModuleResponse[];
  lessons: ICourseLesson[];
  courseStudent: ICourseStudent[];
}

interface IlessonDownload {
  id: string;
  name: string;
  download_url: string;
  path: string;
  thumbnail: {
    thumbnail_url: string;
  };
  deleted_at: string | null;
}

interface ILesson {
  id: string;
  title: string;
  description: string;
  duration: string;
  watch_full_video: boolean;
  answer_quiz: boolean;
  coach_button: boolean;
  coach_unlock: boolean;
  allow_about: boolean;
  allow_next_step: boolean;
  allow_special_note: boolean;
  allow_discussion: boolean;
  allow_downloads: boolean;
  special_notes_coach: boolean;
  about: string;
  next_step: string;
  special_note: string;
  slug: string;
  video_url: string;
  thumbnail_url: string;
  downloads: IlessonDownload[];
  lessonStudent: ILessonStudent[];
  quiz: IQuestion[];
  notes: IAnswers[];
}

interface ILessonList {
  id: string;
  title: string;
  duration: string;
  status: string;
  slug: string;
  finished: boolean;
}

interface IModule {
  id: string;
  title: string;
  locked: boolean;
  lessons: ILessonList[];
  slug: string;
  type?: 'introduction' | 'exam';
  exam?: {
    id: string;
    questions: IQuestion[];
    answered: boolean;
    allQuestionsCorrect: boolean;
  };
}

interface IOption {
  id: string;
  value: string;
  selected: boolean;
  disabled?: boolean;
}

interface IHour {
  hour: number;
  available: boolean;
}

interface IHourDAta {
  hour: string;
}

interface IMessage {
  id: number;
  message: string;
  me: boolean;
}

// interface IAnnotation {
//   id: string;
//   content: string;
// }

// let typingTimer = 0;

const config = {
  clientId:
    '447389969020-6vs0ubb20s5ii4v5epp9en65mepfh7oq.apps.googleusercontent.com',
  apiKey: 'AIzaSyAFIKsXFxZbluvp0tOK8NXJl5-PEXJdsSc',
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
};

const apiCalendar = new ApiCalendar(config);

const DoubleButton: React.FC = () => {
  const [logado, setLogado] = useState(false);
  const handleItemClick = useCallback((event: SyntheticEvent, name: string) => {
    if (name === 'sign-in') {
      apiCalendar.handleAuthClick();
      setLogado(true);
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
      setLogado(false);
    }
  }, []);

  useEffect(() => {
    if (logado) {
      apiCalendar.listUpcomingEvents(10).then((data: any) => {
        console.log(data);
      });
    }
  }, [logado]);

  return (
    <>
      <button type="button" onClick={(e) => handleItemClick(e, 'sign-in')}>
        sign-in
      </button>
      <button type="button" onClick={(e) => handleItemClick(e, 'sign-out')}>
        sign-out
      </button>
    </>
  );
};

const Resources: React.FC = () => {
  const { user } = useAuth();
  const { language } = useLanguage();
  const location = useLocation();
  const history = useHistory();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [modules, setModules] = useState<IModule[]>([]);
  const [lesson, setLesson] = useState({} as ILesson);
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showCertificateAlert, setShowCertificateAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [courseStudentId, setCourseStudentId] = useState('');
  const [courseConclude, setCourseConclude] = useState(false);
  const [locked, setLocked] = useState(false);
  const [showAi, setShowAi] = useState(false);
  const [coachLocked, setCoachLocked] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [changeMessage, setChangeMessage] = useState<string>('');
  const [langSelected] = useState(() => {
    return location.pathname.substr(0, 3) === '/en' ||
      location.pathname.substr(0, 3) === '/es'
      ? location.pathname.substr(0, 3)
      : '';
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayOptions, setDayOptions] = useState<IOption[]>([]);
  // const [annotation, setAnnotation] = useState<IAnnotation>({
  //   id: '',
  //   content: '',
  // });

  const [indexModule, setIndexModule] = useState(0);

  const [openVideo, setOpenVideo] = useState(false);

  const checkIfReleaseCertificate = useCallback(
    async (modulesData: IModuleResponse[], courseIsConclude?: boolean) => {
      if (!courseConclude && !courseIsConclude) {
        try {
          const results: boolean[] = [];
          let allLessonWatched = true;

          modulesData.forEach((module) => {
            if (module.exam) {
              const result = module.exam.examsStudents.find(
                (examStudent) =>
                  module.exam?.module_id === module.id &&
                  examStudent.note === '10.00'
              );
              results.push(!!result);
            }

            if (allLessonWatched) {
              const lessons: ILesson[] = [];
              module.lessons.forEach((lessonData) => {
                const lessonStudent = lessonData.lessonStudent.filter(
                  (lessonStudentData) =>
                    lessonStudentData.student_id === user.id &&
                    !lessonStudentData.deleted_at
                );
                // eslint-disable-next-line no-param-reassign
                lessonData.lessonStudent = lessonStudent;
                lessons.push(lessonData);
              });
              const lessonSelected = lessons.find(
                (lessonData) => !lessonData.lessonStudent[0].completed
              );
              allLessonWatched = !lessonSelected;
            }
          });

          let noReleaseCertificate: boolean | undefined;

          if (results.length > 0) {
            noReleaseCertificate = results.find((result) => !result);
          }

          if (
            typeof noReleaseCertificate === typeof undefined &&
            allLessonWatched
          ) {
            await api.post(`certificates/${params.slug}`);
            setCourseConclude(true);
            setShowCertificateAlert(true);
          } else {
            setShowAlert(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [params.slug, courseConclude, user.id]
  );

  useEffect(() => {
    setMessages([
      {
        id: 1,
        message: 'Test my knowledge about this lesson',
        me: true,
      },
      {
        id: 2,
        message:
          "Sure, here's an example 5-email follow-up sequence that you can use to connect with prospects who showed interest in your AI assistant software:<br><br>Email 1 - Day 1: Subject: Personal AI Assistant Software - Get More Done in Less Time Dear [Prospect's Name],<br><br>Thank you for your interest in our personal AI assistant software! We're excited to show you how it can help you get more done in less time.<br><br>Our software uses the latest AI technology to help you automate your daily tasks, prioritize your to-do list, and keep track of your schedule, all while providing you with personalized recommendations and insights.<br><br>If you're ready to start taking your productivity to the next level, I'd love to schedule a time to give you a demo of our software. Just let me know what works best for you!",
        me: false,
      },
    ]);
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/courses/students/${params.slug}/check`)
      .then(async (responseData) => {
        if (responseData.data.locked) {
          const response = await api.get(`courses/students/${params.slug}`, {
            params: {
              type: 'BUY',
            },
          });

          await api.post('courses-students', {
            course_id: response.data.id,
            student_id: user.id,
          });
        }

        if (params.slugLesson === 'introduction') {
          const response = await api.get<IResponseCourse>(
            `/courses/students/${params.slug}`
          );
          const downloads: IlessonDownload[] = [];
          const quiz: IQuestion[] = [];
          const notesData: IAnswers[] = [];
          const data: ILesson = {
            id: response.data.id,
            title: response.data.title,
            description: response.data.description,
            duration: response.data.video
              ? response.data.video.duration
              : '00:00:00',
            watch_full_video: false,
            answer_quiz: false,
            coach_button: false,
            coach_unlock: false,
            allow_about: true,
            allow_next_step: true,
            allow_special_note: false,
            allow_discussion: false,
            allow_downloads: false,
            special_notes_coach: false,
            about: `<h2 className="mt-5">${language.lesson.h2_1}</h2><p className="mt-4">${response.data.description}</p>`,
            next_step: response.data.description,
            special_note: '',
            slug: 'introduction',
            video_url: response.data.video ? response.data.video.video_url : '',
            thumbnail_url: response.data.thumbnail
              ? response.data.thumbnail.thumbnail_url
              : '',
            downloads,
            lessonStudent: [],
            quiz,
            notes: notesData,
          };

          const courseStudentSelected = response.data.courseStudent.find(
            (courseStudent) => courseStudent.student_id === user.id
          );

          if (courseStudentSelected) {
            setCourseStudentId(courseStudentSelected.id);
          }

          const courseStudentCheck = response.data.courseStudent.find(
            (courseStudent) => courseStudent.locked
          );

          if (courseStudentCheck) {
            setCoachLocked(true);
          }

          const introduction: IModule = {
            id: data.id,
            title: 'introduction',
            locked: false,
            slug: 'introduction',
            lessons: [] as any,
            type: 'introduction',
          };

          const modulesData: IModule[] = response.data.modules.map((module) => {
            const lessonsData: ILessonList[] = module.lessons.map(
              (lessonData) => {
                return {
                  id: lessonData.id,
                  title: lessonData.title,
                  duration: lessonData.duration,
                  status: lessonData.lessonStudent[0].status,
                  slug: lessonData.slug,
                  finished: lessonData.lessonStudent[0].completed,
                };
              }
            );

            let exam;
            if (module.exam) {
              const allQuestionsCorrect = !!module.exam.examsStudents.find(
                (examStudent) =>
                  module.exam?.module_id === module.id &&
                  examStudent.note === '10.00'
              );

              exam = {
                id: module.exam.id,
                questions: module.exam.questions,
                answered: !!module.exam.examStudent,
                allQuestionsCorrect,
              };
            }

            return {
              id: module.id,
              title: module.title,
              locked: module.moduleStudent.locked,
              lessons: lessonsData,
              slug: module.slug,
              exam,
            };
          });

          const courseIsConclude = response.data.courseStudent.find(
            (courseStudent) =>
              courseStudent.student_id === user.id && !!courseStudent.end_date
          );

          checkIfReleaseCertificate(response.data.modules, !!courseIsConclude);

          setCourseConclude(!!courseIsConclude);
          setModules([introduction, ...modulesData]);
          setLesson(data);
        } else {
          const response = await api.get<IResponseCourse>(
            `/courses/students/${params.slug}`
          );
          const responseLesson = await api.get(
            `lessons/students/${response.data.id}/${params.slugLesson}`
          );

          const courseStudentCheck = response.data.courseStudent.find(
            (courseStudent) => courseStudent.locked
          );

          if (courseStudentCheck) {
            setCoachLocked(true);
          }

          if (responseLesson.data) {
            // const responseAnnotation = await api.get(
            //   `annotations/students/${responseLesson.data.id}`
            // );

            const data: ILesson = {
              id: responseLesson.data.id,
              title: responseLesson.data.title,
              description: responseLesson.data.description,
              duration: '00:00:00',
              watch_full_video: responseLesson.data.watch_full_video,
              answer_quiz: responseLesson.data.answer_quiz,
              coach_button: responseLesson.data.coach_button,
              coach_unlock: responseLesson.data.coach_unlock,
              allow_about: responseLesson.data.allow_about,
              allow_next_step: responseLesson.data.allow_next_step,
              allow_special_note: responseLesson.data.allow_special_note,
              allow_discussion: responseLesson.data.allow_discussion,
              allow_downloads: responseLesson.data.allow_downloads,
              special_notes_coach: responseLesson.data.special_notes_coach,
              about: responseLesson.data.about,
              next_step: responseLesson.data.next_step,
              special_note: responseLesson.data.special_note,
              slug: responseLesson.data.slug,
              video_url: responseLesson.data.video
                ? responseLesson.data.video.video_url
                : '',
              thumbnail_url: responseLesson.data.thumbnail
                ? responseLesson.data.thumbnail.thumbnail_url
                : '',
              downloads: responseLesson.data.downloads,
              lessonStudent: responseLesson.data.lessonStudent,
              quiz: responseLesson.data.exam
                ? responseLesson.data.exam.questions
                : ([] as any),
              notes: responseLesson.data.notes,
            };

            const courseStudentSelected = response.data.courseStudent.find(
              (courseStudent) => courseStudent.student_id === user.id
            );

            if (courseStudentSelected) {
              setCourseStudentId(courseStudentSelected.id);
            }

            const introduction: IModule = {
              id: data.id,
              title: 'introduction',
              locked: false,
              slug: 'introduction',
              lessons: [] as any,
              type: 'introduction',
            };

            const modulesData: IModule[] = response.data.modules.map(
              (module) => {
                const lessonsData: ILessonList[] = module.lessons.map(
                  (lessonData) => ({
                    id: lessonData.id,
                    title: lessonData.title,
                    duration: lessonData.duration,
                    status: lessonData.lessonStudent[0].status,
                    slug: lessonData.slug,
                    finished: lessonData.lessonStudent[0].completed,
                  })
                );

                let exam;
                if (module.exam) {
                  const allQuestionsCorrect = !!module.exam.examsStudents.find(
                    (examStudent) =>
                      module.exam?.module_id === module.id &&
                      examStudent.note === '10.00'
                  );

                  exam = {
                    id: module.exam.id,
                    questions: module.exam.questions,
                    answered: !!module.exam.examStudent,
                    allQuestionsCorrect,
                  };
                }

                return {
                  id: module.id,
                  title: module.title,
                  locked: module.moduleStudent.locked,
                  lessons: lessonsData,
                  slug: module.slug,
                  exam,
                };
              }
            );

            const courseIsConclude = response.data.courseStudent.find(
              (courseStudent) =>
                courseStudent.student_id === user.id && !!courseStudent.end_date
            );

            checkIfReleaseCertificate(
              response.data.modules,
              !!courseIsConclude
            );

            // setTextareaContent(responseAnnotation.data.content.length);
            // setAnnotation(responseAnnotation.data);
            setCourseConclude(!!courseIsConclude);
            setModules([introduction, ...modulesData]);
            setLesson(data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    checkIfReleaseCertificate,
    language.lesson.h2_1,
    params.slug,
    params.slugLesson,
    user.id,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingMessage(language.message_timeout.msg_1);
    }, 1000);

    setTimeout(() => {
      setLoadingMessage(language.message_timeout.msg_2);
    }, 5000);

    setTimeout(() => {
      setLoadingMessage(language.message_timeout.msg_3);
    }, 10000);
  }, [
    language.message_timeout.msg_1,
    language.message_timeout.msg_2,
    language.message_timeout.msg_3,
  ]);

  useEffect(() => {
    // api
    //   .get<IResponseCourse>(`/courses/students/${params.slug}`)
    //   .then(async (response) => {
    //     const lessons: ILesson[] = [];
    //     response.data.modules.forEach((module) => {
    //       lessons.push(...module.lessons);
    //     });
    //     const videos = lessons.length;
    //     const complete = lessons.filter((lessonCourse) => {
    //       return (
    //         lessonCourse.lessonStudent[0].completed ||
    //         (!lessonCourse.watch_full_video &&
    //           !lessonCourse.coach_unlock &&
    //           !lessonCourse.answer_quiz)
    //       );
    //     }).length;
    //     const percentage = parseFloat(((complete * 100) / videos).toFixed(2));
    //     if (percentage === 100) {
    //       await api.post(`certificates/${params.slug}`);
    //     }
    //   });
  }, [params.slug]);

  const loadDays = useCallback(async (day, month, year, coach_id) => {
    const response = await api.get<IHour[]>(
      `appointments/coaches/${coach_id}/day-availability`,
      {
        params: {
          day,
          month,
          year,
        },
      }
    );
    const options: IOption[] = response.data.map((hourData) => ({
      id: hourData.hour.toString(),
      value: `${hourData.hour.toString().padStart(2, '0')}h00`,
      selected: false,
      disabled: !hourData.available,
    }));

    setDayOptions(options);
  }, []);

  useEffect(() => {
    const day = selectedDate.getDate();
    const month = selectedDate.getMonth() + 1;
    const year = selectedDate.getFullYear();
    const coach_id = '0489abad-70dd-4c1b-9d9a-5ae463d46869';

    loadDays(day, month, year, coach_id);
  }, [loadDays, selectedDate]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);
  const handleShowAi = useCallback(() => setShowAi(true), []);

  // const handleDoneTyping = useCallback(
  //   async (content: string) => {
  //     const formData = {
  //       lesson_id: lesson.id,
  //       content,
  //     };

  //     let response: AxiosResponse;

  //     if (annotation.id) {
  //       response = await api.put(`annotations/${annotation.id}`, formData);
  //     } else {
  //       response = await api.post('annotations', formData);
  //     }

  //     setAnnotation(response.data);
  //   },
  //   [annotation.id, lesson.id]
  // );

  // const handleChange = useCallback(
  //   (e) => {
  //     const content = e.target.value;
  //     setAnnotation((state) => ({
  //       id: state.id,
  //       content,
  //     }));
  //     setTextareaContent(e.target.value.length);
  //     clearTimeout(typingTimer);
  //     typingTimer = setTimeout(handleDoneTyping, 2000, e.target.value);
  //   },
  //   [handleDoneTyping]
  // );

  const handleStartLesson = useCallback(async () => {
    const formData = {
      last_lesson_slug: lesson.slug,
    };

    await api.patch(`/courses-students/${courseStudentId}`, formData);
  }, [courseStudentId, lesson.slug]);

  const handleRunningLesson = useCallback(
    (time) => {
      const h = `0${Math.floor(time / 3600)}`.slice(-2);
      const m = `0${Math.floor((time % 3600) / 60)}`.slice(-2);
      const s = `0${Math.floor((time % 3600) % 60)}`.slice(-2);
      const time_watched = `${h}:${m}:${s}`;
      if (lesson.id) {
        api.patch(`lessons-students/save/${lesson.id}`, {
          time_watched,
        });
      }
    },
    [lesson.id]
  );

  const handleCompleteLesson = useCallback(async () => {
    const time_watched = `00:00:00`;
    if (lesson.id) {
      api.patch(`lessons-students/conclude/${lesson.id}`, {
        time_watched,
        completed: true,
      });

      const newModules = modules.slice();
      let lessonSelected;
      let moduleIndex = -1;
      let lessonIndex = -1;
      newModules.forEach((module, index) => {
        const lessonDataSelected = module.lessons.find(
          (lessonData) => lessonData.id === lesson.id
        );
        const lessonSelectedIndex = module.lessons.findIndex(
          (lessonData) => lessonData.id === lesson.id
        );
        if (lessonDataSelected) {
          lessonIndex = lessonSelectedIndex;
          moduleIndex = index;
          lessonSelected = lessonDataSelected;
        }
      });

      if (lessonSelected) {
        newModules[moduleIndex].lessons[lessonIndex].finished = true;
        setModules(newModules);
        const responseModules = await api.get<IResponseCourse>(
          `/courses/students/${params.slug}`
        );
        if (responseModules.data) {
          checkIfReleaseCertificate(responseModules.data.modules);
        }
      }
    }
  }, [checkIfReleaseCertificate, lesson.id, modules, params.slug]);

  const handleAnsweredQuestions = useCallback(
    async (answeredQuestions: IAnswers[]) => {
      if (
        !answeredQuestions.find(
          (answeredQuestion) => !answeredQuestion.correct_answer
        )
      ) {
        await api.patch(`lessons-students/answered-quiz/${lesson.id}`);

        lesson.lessonStudent[0].answered_quiz = true;

        setLesson(lesson);
      }
    },
    [lesson]
  );

  const handleAnsweredExamModule = useCallback(async () => {
    try {
      const response = await api.get<IResponseCourse>(
        `/courses/students/${params.slug}`
      );
      if (response.data) {
        checkIfReleaseCertificate(response.data.modules);
      }
    } catch (error) {
      console.log(error);
    }
  }, [params.slug, checkIfReleaseCertificate]);

  const handleClickFinished = useCallback(
    async (lesson_id) => {
      try {
        const response = await api.get(
          `lessons-students/conclude/${lesson_id}`
        );
        await api.patch(`lessons-students/conclude/${lesson_id}`, {
          time_watched: response.data.time_watched,
          completed: !response.data.completed,
        });

        const newModules = modules.slice();
        let lessonSelected;
        let moduleIndex = -1;
        let lessonIndex = -1;
        newModules.forEach((module, index) => {
          const lessonDataSelected = module.lessons.find(
            (lessonData) => lessonData.id === lesson_id
          );
          const lessonSelectedIndex = module.lessons.findIndex(
            (lessonData) => lessonData.id === lesson_id
          );
          if (lessonDataSelected) {
            lessonIndex = lessonSelectedIndex;
            moduleIndex = index;
            lessonSelected = lessonDataSelected;
          }
        });
        if (lessonSelected) {
          newModules[moduleIndex].lessons[lessonIndex].finished = !response.data
            .completed;
          setModules(newModules);
          const responseModules = await api.get<IResponseCourse>(
            `/courses/students/${params.slug}`
          );
          if (responseModules.data) {
            checkIfReleaseCertificate(responseModules.data.modules);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [checkIfReleaseCertificate, modules, params.slug]
  );

  const handleDateChange = useCallback(
    async (day: Date, modifiers: DayModifiers) => {
      if (modifiers.available && !modifiers.disabled) {
        setSelectedDate(day);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: IHourDAta) => {
      setLoading(true);
      setLoadingMessage('Scheduling call...');
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          hour: Yup.string().required(language.lesson.time),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { hour } = data;

        const day = selectedDate.getDate();
        const month = selectedDate.getMonth() + 1;
        const year = selectedDate.getFullYear();
        const coach_id = '0489abad-70dd-4c1b-9d9a-5ae463d46869';
        const formData = {
          coach_id,
          date: `${year}-${month
            .toString()
            .padStart(2, '0')}-${day}T${hour.padStart(2, '0')}:00:00`,
        };

        const response = await api.post('appointments', formData);

        if (response.data) {
          Swal.fire(
            language.lesson.success_1,
            language.lesson.success_2,
            'success'
          ).then(() => {
            loadDays(day, month, year, coach_id);
            handleClose();
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.error_component.error_message, 'error');
        }
      } finally {
        setLoading(false);
        setLoadingMessage('');
      }
    },
    [
      handleClose,
      language.error_component.error_message,
      language.lesson.success_1,
      language.lesson.success_2,
      language.lesson.time,
      loadDays,
      selectedDate,
    ]
  );

  const handleChangeMessage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChangeMessage(e.target.value);
    },
    []
  );

  const handleSendMessage = useCallback(() => {
    setMessages((state) => [
      ...state,
      {
        id: state.length + 1,
        message: changeMessage,
        me: true,
      },
    ]);
    setChangeMessage('');
  }, [changeMessage]);

  return (
    <Container className="d-xl-flex h-100">
      {loading ? (
        <Skeleton className="container">
          <div className="row">
            <div className="col-12 pt-3 px-0">
              <Welcome>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <div className="d-flex align-items-center mb-3">
                        <button
                          type="button"
                          className="border-0 bg-transparent"
                        >
                          <div className="skeleton skeleton-arrow arrow-xs" />
                        </button>
                        <h1 className="skeleton ml-3 mb-0 h3">
                          Estrategias de venta
                        </h1>
                      </div>
                      <p className="skeleton">
                        {language.lesson.p_1}
                        <small className="d-block">
                          {language.lesson.small}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </Welcome>
              <LessonData>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="mb-3 skeleton skeleton-player" />

                      <button
                        type="button"
                        className="skeleton w-100 py-2 py-sm-3 px-2 mb-3 gray-button"
                      >
                        {language.lesson.button_4}
                      </button>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="pt-3 pt-lg-0">
                        <h3 className="skeleton h3-md h5 font-weight-600">
                          {language.lesson.h3_1}
                        </h3>
                        <p className="skeleton">
                          En esta lección te mostraré algunas estrategias de
                          ventas que sin duda ayudarán a atraer a clientes y
                          maximizar las ventas de tu tienda en línea.
                        </p>
                        <hr className="my-4" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="p-relative pt-3 pt-lg-0">
                        <h4 className="skeleton font-weight-600">
                          {language.lesson.h3_2}
                        </h4>
                      </div>
                    </div>
                    <div className="col-xl-8">
                      <div className="skeleton w-100 p-5" />
                    </div>
                  </div>
                </div>
              </LessonData>
            </div>
          </div>
        </Skeleton>
      ) : (
        <>
          <div className="w-100">
            {Object.keys(lesson).length > 0 && (
              <LessonBg background="https://loremflickr.com/500/500">
                <div className="container">
                  <div className="row">
                    <div className="col-12 px-0">
                      <Welcome className="welcome">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="">
                                  <h1 className="display-2 font-weight-600 mb-3">
                                    {lesson.title}
                                  </h1>
                                  <div className="pt-3 pt-lg-0">
                                    <p className="h5 font-weight-light mb-5">
                                      <span className="h5 font-weight-regular">
                                        {language.lesson.h3_1}:{' '}
                                      </span>
                                      {lesson.description}
                                    </p>
                                  </div>
                                </div>
                                {coachLocked && (
                                  <Locked className="locked">
                                    <img src={lockedIcon} alt="Locked" />
                                    <span className="d-block lock-message">
                                      O coach bloaqueou o curso, marque um call
                                      com o coach para ele desbloquear
                                    </span>
                                  </Locked>
                                )}
                              </div>
                              <div className="d-flex">
                                <div className="mr-5 pr-4 my-1 my-lg-0 d-flex align-items-center justify-content-lg-start">
                                  <img
                                    src={members}
                                    alt="members"
                                    className="mr-3"
                                  />
                                  <p className="mb-0 h5">10 participants</p>
                                </div>
                                <div className="mr-5 pr-4 my-1 my-lg-0 d-flex align-items-center justify-content-lg-center">
                                  <img
                                    src={video}
                                    alt="video"
                                    className="mr-3"
                                  />
                                  <p className="mb-0 h5">
                                    10 {language.course.p_2}
                                  </p>
                                </div>
                                <div className="my-1 my-lg-0 d-flex align-items-center justify-content-lg-end">
                                  <img
                                    src={hourIcon}
                                    alt="hour"
                                    className="mr-3"
                                  />
                                  <p className="mb-0 h5">
                                    {language.course.p_3} {lesson.duration}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-5">
                                <button
                                  onClick={() => setOpenVideo(true)}
                                  className="btn-watch"
                                  type="button"
                                >
                                  Watch Lesson
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="height px-4">
                                {modules[indexModule].lessons.map((item) => (
                                  <>
                                    {modules[indexModule].slug !==
                                      'introduction' && (
                                      <LinkLesson
                                        src="https://loremflickr.com/500/500"
                                        to={`${process.env.PUBLIC_URL}/courses/${modules[indexModule].slug}/${item.slug}`}
                                        className="w-100 d-block my-3 p-4"
                                      >
                                        <span>{item.title}</span>
                                      </LinkLesson>
                                    )}
                                  </>
                                ))}
                              </div>
                            </div>
                            {console.log(modules)}
                          </div>
                        </div>
                      </Welcome>
                      <LessonData>
                        <span className="d-block lock-message">
                          O coach bloaqueou o curso, marque um call com o coach
                          para ele desbloquear
                        </span>

                        <div className="col-12 mt-5">
                          {modules.map((item, index) => (
                            <>
                              {item.slug === 'introduction' ? (
                                <Link
                                  to={`${process.env.PUBLIC_URL}/courses/${params.slug}/${item.slug}`}
                                  className={`btn-module${
                                    indexModule === index ? '-active' : ''
                                  } mb-0 bg-transparent h5 font-weight-light px-5 py-3 border-top-0 border-left-0 border-right-0`}
                                  onClick={() => setIndexModule(index)}
                                >
                                  INTRODUCTION
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className={`btn-module${
                                    indexModule === index ? '-active' : ''
                                  } mb-0 bg-transparent h5 font-weight-light px-5 py-3 border-top-0 border-left-0 border-right-0`}
                                  onClick={() => setIndexModule(index)}
                                >
                                  {`MODULE ${index}`}
                                </button>
                              )}
                            </>
                          ))}
                          {console.log(modules)}
                          {/* <Lessons className="w-100">
                            <Modules
                              data={modules}
                              slugCourse={params.slug}
                              onAnsweredExam={handleAnsweredExamModule}
                              onClickFinished={handleClickFinished}
                            />
                          </Lessons> */}
                          <hr className="separator" />
                          <Actions onShow={handleShowAi} />
                        </div>
                      </LessonData>
                    </div>
                  </div>
                </div>

                <div className="d-none">
                  {lesson.video_url ? (
                    <Player
                      src={lesson.video_url}
                      thumbnail={lesson.thumbnail_url}
                      onPlay={handleStartLesson}
                      onChangeTime={handleRunningLesson}
                      onComplete={handleCompleteLesson}
                      callChangeTimeBySeconds={5}
                      className="mb-3 player"
                      // disabled={coachLocked}
                    />
                  ) : (
                    <WaveForm
                      thumbnail="https://loremflickr.com/500/500"
                      audio="https://s3.amazonaws.com/wiserr.io/audios/file_example_MP3_1MG.mp3"
                    />
                  )}
                  {(coachLocked || lesson.coach_button) && (
                    <button
                      type="button"
                      onClick={handleShow}
                      className="w-100 py-3 px-2 mb-3 gray-button"
                    >
                      <b>{language.lesson.button_4}</b>
                    </button>
                  )}
                  {lesson.answer_quiz && (
                    <Quizzes
                      dataQuestions={lesson.quiz}
                      onAnsweredQuestions={handleAnsweredQuestions}
                      answered={lesson.lessonStudent[0].answered_quiz}
                    />
                  )}
                </div>
              </LessonBg>
            )}
          </div>
          {openVideo && (
            <>
              <div className="open-video position-fixed" />
              <Player
                className="w-100 w-lg-75 video-lesson"
                src="https://www.w3schools.com/html/mov_bbb.mp4"
                btnClose
                onClose={() => setOpenVideo(false)}
                autoPlay
              />{' '}
            </>
          )}

          <ModalCoach
            size="xl"
            className="modal-quizzes"
            show={show}
            onHide={handleClose}
          >
            <ModalCoach.Header className="border-0 py-5 pr-5" closeButton>
              <ModalCoach.Title className="text-center w-100">
                <img src={calendar} alt="calendar" className="mr-3" />
                {language.lesson.button_6}
              </ModalCoach.Title>
            </ModalCoach.Header>
            <ModalCoach.Body>
              <div className="m-auto w-75">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-center">
                      <img
                        src={profile}
                        alt="profile"
                        className="profilePhoto"
                      />
                      <h3 className="h4 mt-3 mb-0">Monica Ramos</h3>
                      <p>Senior Coach</p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Calendar className="mx-auto">
                        <DayPicker
                          showOutsideDays
                          fromMonth={new Date()}
                          initialMonth={selectedDate}
                          selectedDays={selectedDate}
                          modifiers={{
                            available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                          }}
                          onDayClick={handleDateChange}
                        />
                      </Calendar>
                      <div className="group mx-auto">
                        <div className="my-3">
                          <label className="mb-1">
                            {language.lesson.modal_coach_label}
                          </label>
                          <Select
                            options={dayOptions}
                            name="hour"
                            className="input-calendar"
                          />
                        </div>
                        <button
                          type="submit"
                          className="gray-button mb-4 py-3 w-100"
                        >
                          <span className="d-block py-1 px-2">
                            {language.quiz_component.button_3}
                          </span>
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </ModalCoach.Body>
          </ModalCoach>
          <Modal className="modal-info" show={locked}>
            <Modal.Header className="border-0 pt-5 pb-3 pr-5">
              <Modal.Title className="text-center w-100">
                {language.lesson.modal_title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-5">
              <div className="m-auto w-75">
                <div className="row">
                  <div className="col-12">
                    <p className="mb-0 info p-4">
                      {language.lesson.modal_p_1_1} {user.name}{' '}
                      {language.lesson.modal_p_1_2}{' '}
                      <Link
                        to={`${process.env.PUBLIC_URL}${langSelected}/profile/payment-plans`}
                        onClick={() => setLocked(false)}
                      >
                        {language.lesson.modal_a}
                      </Link>{' '}
                      {language.lesson.modal_p_1_3}
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Alert
            show={showAlert}
            setShow={setShowAlert}
            content={language.quiz_component.alert_message}
            showTime={15000}
            background="#202020"
          />
          <Alert
            show={showCertificateAlert}
            setShow={setShowCertificateAlert}
            content={language.quiz_component.certificate_message}
            showTime={3000}
            color="#29a946"
            background="#202020"
          />
        </>
      )}
      <BgBlurAi active={showAi} onClose={() => setShowAi(false)}>
        <div className="container">
          <div className="row">
            <Form
              onSubmit={() => console.log('submit')}
              className="col-12 mx-auto"
            >
              <div className="message-side mb-4">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={message.me ? 'me' : 'd-flex luna'}
                  >
                    {!message.me && (
                      <span className="mt-3">
                        <img
                          src={lunaAvatar}
                          width={46}
                          height={46}
                          alt="Luna"
                        />
                      </span>
                    )}
                    <p
                      className="p-3"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    />
                  </div>
                ))}
              </div>
              <div className="position-relative">
                <Input
                  type="text"
                  className="text-ai px-5"
                  name="textAi"
                  value={changeMessage}
                  onChange={handleChangeMessage}
                  onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                  placeholder="Ask me anything...."
                />
                <button
                  type="button"
                  disabled={!changeMessage}
                  onClick={handleSendMessage}
                  className="btn-send border-0 rounded-circle"
                >
                  <IoMdArrowUp color="#18191A" size={34} />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </BgBlurAi>
    </Container>
  );
};

export default Resources;
