import styled from 'styled-components';

export const Container = styled.div`
  .titleSocial {
    background: var(--closedGray);
    border-radius: 20px 20px 0px 0px;
  }

  h4 {
    color: var(--blizzard);
    font-weight: 400 !important;
  }

  p {
    color: var(--blizzard) !important;
  }

  .contentSocial {
    background: var(--asphaltGray);
    border-radius: 0 0 20px 20px;
  }
`;
