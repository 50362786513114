import React, { useEffect, useState, useCallback } from 'react';
import { format, parseISO } from 'date-fns';

import { useLanguage } from '~/hooks/Language';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';

import { Container } from './styles';

import filterIcon from '~/assets/icons/filter.svg';
import noRegister from '~/assets/icons/no-register-icon.svg';

interface IOrdersProps {
  active: boolean;
}

interface IOrderResponse {
  id: string;
  amount_paid: number;
  created_at: string;
  course: {
    title: string;
    thumbnail: {
      thumbnail_url: string;
    };
  };
  product: {
    name: string;
    thumbnail: {
      thumbnail_url: string;
    };
  };
  offer: {
    title: string;
  };
}

interface IOrder {
  id: string;
  amount_paid: string;
  title: string;
  thumbnail: string;
  created_at: string;
}

const Purchases: React.FC<IOrdersProps> = ({ active }) => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();
  const [filterDisplaySelected, setFilterDisplaySelected] = useState(
    language.my_purchases.button_1
  );
  const [filterSelected, setFilterSelected] = useState('All');
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    api
      .get<IOrderResponse[]>('orders', {
        params: {
          filter: filterSelected,
        },
      })
      .then((response) => {
        const data: IOrder[] = response.data.map((order) => {
          let title = '';
          let thumbnail = '';
          if (order.course || order.product) {
            title = order.course ? order.course.title : order.product.name;
            thumbnail = order.course
              ? order.course.thumbnail.thumbnail_url
              : order.product.thumbnail.thumbnail_url;
          } else if (order.offer) {
            title = order.offer.title;
          }
          return {
            id: order.id,
            thumbnail,
            title,
            amount_paid: formatPrice(order.amount_paid),
            created_at: format(parseISO(order.created_at), 'yyyy-MM-dd'),
          };
        });
        setOrders(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterSelected]);

  useEffect(() => {
    switch (filterSelected) {
      case 'Courses':
        setFilterDisplaySelected(language.my_purchases.button_2);
        break;
      case 'Franchises':
        setFilterDisplaySelected(language.my_purchases.button_3);
        break;
      default:
        setFilterDisplaySelected(language.my_purchases.button_1);
        break;
    }
  }, [filterSelected, language.my_purchases]);

  const handleSelectFilter = useCallback((filter) => {
    setFilterSelected(filter);
    setMenuOpened(false);
  }, []);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row bg-gray p-2 pb-lg-5">
              <div className="p-relative col-12 my-3 mb-md-n3 mb-lg-0">
                <div className="d-flex justify-content-end p-relative">
                  <button
                    type="button"
                    onClick={() => {
                      menuOpened === true
                        ? setMenuOpened(false)
                        : setMenuOpened(true);
                    }}
                  >
                    {filterDisplaySelected}
                    <img src={filterIcon} alt="filter" className="ml-2" />
                  </button>
                  <div
                    className={`${
                      menuOpened === true ? 'd-block' : 'd-none'
                    } p-absolute filters text-center`}
                  >
                    <button
                      type="button"
                      className="py-2 px-5"
                      onClick={() => handleSelectFilter('All')}
                    >
                      {language.my_purchases.button_1}
                    </button>
                    <hr />
                    <button
                      type="button"
                      className="py-2 px-5"
                      onClick={() => handleSelectFilter('Courses')}
                    >
                      {language.my_purchases.button_2}
                    </button>
                    <hr />
                    <button
                      type="button"
                      className="py-2 px-5"
                      onClick={() => handleSelectFilter('Franchises')}
                    >
                      {language.my_purchases.button_3}
                    </button>
                  </div>
                </div>
              </div>
              {loading && (
                <div className="col-12">
                  <div className="row align-items-start">
                    <div className="col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-5">
                      <div className="d-flex h-100 justify-content-center">
                        <div
                          className={`${
                            loading === true ? 'skeleton' : ''
                          } img-width radius-20`}
                        />
                      </div>
                    </div>
                    <div className="col-7 col-md-3 col-lg-4 col-xl-3 mb-0 mb-md-4 mb-lg-5">
                      <div className="d-flex align-items-center h-100">
                        <div className="mt-lg-2">
                          <h3
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } h6`}
                          >
                            Digital Startup
                          </h3>
                          <br />
                          <p className={loading === true ? 'skeleton' : ''}>
                            by Wiserr{' '}
                          </p>
                          <br />
                          <h3
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } h6 mt-lg-4`}
                          >
                            {language.my_purchases.h3_1}
                          </h3>
                          <br />
                          <p className={loading === true ? 'skeleton' : ''}>
                            2021-07-29
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 col-md-3 col-lg-4 col-xl-3 mb-0 mb-md-4 mb-lg-5">
                      <div className="d-flex h-100">
                        <div className="mt-lg-2">
                          <h3
                            className={`${
                              loading === true ? 'skeleton' : ''
                            } h6`}
                          >
                            {language.my_purchases.h3_2}
                          </h3>
                          <br />
                          <p className={loading === true ? 'skeleton' : ''}>
                            $000.00
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {orders.length > 0 ? (
                <>
                  {orders.map((order) => (
                    <div className="col-12">
                      <div className="row align-items-start">
                        <div className="col-md-4 col-xl-3 mb-4 mb-md-4 mb-lg-5">
                          <div className="d-flex h-100 justify-content-center">
                            <div>
                              <img
                                src={order.thumbnail}
                                alt={order.title}
                                className="img-width radius-20"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-7 col-md-3 col-lg-4 col-xl-3 mb-0 mb-md-4 mb-lg-5">
                          <div className="d-flex align-items-center h-100">
                            <div className="mt-lg-2">
                              <h3 className="h6">{order.title}</h3>
                              <p>by Wiserr </p>
                              <h3 className="h6 pt-lg-4">
                                {language.my_purchases.h3_1}
                              </h3>
                              <p>{order.created_at}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-5 col-md-3 col-lg-4 col-xl-3 mb-0 mb-md-4 mb-lg-5">
                          <div className="d-flex h-100">
                            <div className="mt-lg-2">
                              <h3 className="h6">
                                {language.my_purchases.h3_2}
                              </h3>
                              <p>{order.amount_paid}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className={`${loading === true ? 'd-none' : ''} col-12`}>
                  <div className="row h-40vh align-items-center justify-content-center">
                    <div className="col-md-8 d-md-flex text-center align-items-md-center justify-content-md-center">
                      <img
                        src={noRegister}
                        className="mb-3 mb-md-0"
                        alt="No Register icon"
                      />
                      <p className="h5 mb-0 pl-md-3 text-no-certificates text-center">
                        {language.no_register.p}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Purchases;
