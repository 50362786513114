/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import RemoveCreditCard from '../RemoveCreditCard';
import Input from '../Input';

import { Container, Modal } from './styles';
import AddCreditCard from '../AddCreditCard';

import visa from '~/assets/icons/cc-visa.svg';
import mastercard from '~/assets/icons/cc-mastercard.svg';
import discover from '~/assets/icons/cc-discover.svg';
import amex from '~/assets/icons/cc-amex.svg';
import ccDefault from '~/assets/icons/cc-default.svg';
import cardLogo from '~/assets/logotipos/credit-card-logo.png';
import wallet from '~/assets/icons/wallet.svg';
import closeIcon from '~/assets/icons/close.svg';

interface IBuyCreditCardProps {
  product_id: string;
  onSucceededPaid?(data?: any): void;
}

interface IDataPayment {
  credit_card: string;
}

interface ICreditCardResponse {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  primary_card: boolean;
}

interface ICreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  brandIcon: string;
  primary_card: boolean;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string);

const CreditCardPay: React.FC<IBuyCreditCardProps> = ({
  product_id,
  onSucceededPaid,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const [show, setShow] = useState(false);

  const [showCreditCard, setShowCreditCard] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<ICreditCard[]>([]);
  const [cardSelected, setCardSelected] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorPaymentMethods, setErrorPaymentMethods] = useState('');

  const handleClose = useCallback(() => setShow(false), []);

  const handleCreditCardClose = useCallback(() => setShowCreditCard(false), []);
  const handleCreditCardShow = useCallback(async () => {
    const response = await api.get<ICreditCardResponse[]>(
      'payments/customers/credit-cards'
    );
    if (response.data.length > 0) {
      const data: ICreditCard[] = response.data.map((creditCard) => {
        let brandIcon;
        switch (creditCard.brand) {
          case 'Visa':
            brandIcon = visa;
            break;

          case 'MasterCard':
            brandIcon = mastercard;
            break;

          case 'Discover':
            brandIcon = discover;
            break;

          case 'American Express':
            brandIcon = amex;
            break;

          default:
            brandIcon = ccDefault;
            break;
        }
        if (creditCard.primary_card) {
          setCardSelected(creditCard.id);
        }
        return {
          id: creditCard.id,
          name: creditCard.name,
          number: creditCard.number,
          expirity: creditCard.expirity,
          primary_card: creditCard.primary_card,
          brand: creditCard.brand,
          brandIcon,
        };
      });
      setPaymentMethods(data);
      setShowCreditCard(true);
    } else {
      setShowCreditCard(true);
      setShowAdd(true);
    }
  }, []);

  const handleAddCardClose = useCallback(
    (active) => {
      if (active) {
        setShowAdd(false);
      }
      if (paymentMethods.length <= 0) {
        setShowCreditCard(false);
      }
    },
    [paymentMethods.length]
  );

  const handleClick = useCallback(async (id) => {
    setCardSelected(id);
  }, []);

  const handleCreatedCard = useCallback((card) => {
    let brandIcon;
    switch (card.flag) {
      case 'Visa':
        brandIcon = visa;
        break;

      case 'MasterCard':
        brandIcon = mastercard;
        break;

      case 'Discover':
        brandIcon = discover;
        break;

      case 'American Express':
        brandIcon = amex;
        break;

      default:
        brandIcon = ccDefault;
        break;
    }

    const data = {
      id: card.id,
      name: card.name,
      number: card.number,
      expirity: card.expirity,
      primary_card: card.primary_card,
      brand: card.flag,
      brandIcon,
    };
    setPaymentMethods((state) => [...state, data]);
    setShowAdd(false);
  }, []);

  const handlePay = useCallback(
    async (data: IDataPayment) => {
      setProcessing(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          credit_card: Yup.string().required(language.buy_component.yup),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const payment_method = data.credit_card;
        const stripe = await stripePromise;

        if (stripe) {
          const response = await api.post(`payments/credit-cards`, {
            product_id,
            payment_method,
          });

          if (response.data) {
            const result = await stripe.confirmCardPayment(
              response.data.payment.client_secret
            );

            if (
              result.paymentIntent &&
              result.paymentIntent.status === 'succeeded'
            ) {
              const responseOrder = await api.patch(
                `orders/close/${response.data.order.id}`
              );
              if (onSucceededPaid) {
                onSucceededPaid(responseOrder.data);
              }
            }

            if (result.error) {
              Swal.fire('Opss...', result.error.message, 'error').then(() =>
                setProcessing(false)
              );
            }
          }
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          setErrorPaymentMethods(errors.paymentMethods_id);
          formRef.current?.setErrors(errors);
          setProcessing(false);
        } else {
          Swal.fire(
            'Opss...',
            language.error_component.error_message,
            'error'
          ).then(() => setProcessing(false));
        }
      }
    },
    [
      language.buy_component.yup,
      language.error_component.error_message,
      product_id,
      onSucceededPaid,
    ]
  );

  const handleDeleteCreditCard = useCallback(
    async (card) => {
      const newCreditCardsList = paymentMethods.slice();
      if (card.primary_card && paymentMethods.length > 1) {
        if (card === paymentMethods[0]) {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[1].id}`
          );
          newCreditCardsList[1].primary_card = true;
        } else {
          await api.patch(
            `payments/customers/credit-cards/${newCreditCardsList[0].id}`
          );
          newCreditCardsList[0].primary_card = true;
        }
      }
      const cards = newCreditCardsList.filter(
        (creditCard) => creditCard.id !== card.id
      );

      const primaryCard = newCreditCardsList.find(
        (creditCard) => !!creditCard.primary_card
      );
      if (primaryCard) {
        setCardSelected(primaryCard.id);
      }
      setPaymentMethods(cards);
    },
    [paymentMethods]
  );

  return (
    <Container>
      <button
        type="button"
        className="h4 h-100 w-100 payment-button"
        onClick={handleCreditCardShow}
      >
        <img
          src={cardLogo}
          alt={language.buy_product_button.button_1}
          className="pt-3 pt-xl-1 mt-xl-4 mb-5 mb-xl-4 max-width"
        />
        {language.buy_product_button.button_1}
      </button>

      <Modal
        className="modal-credit-card bg-credit-card px-0 border-credit-card"
        centered
        size="lg"
        show={showCreditCard}
        onHide={handleCreditCardClose}
      >
        <Modal.Header className="border-0 p-3 justify-content-end">
          <button
            type="button"
            className="close-button"
            onClick={handleCreditCardClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          <h3 className="h5 h3-sm text-center payment-color">
            <img src={wallet} alt="wallet" className="mr-4" />
            {language.buy_component.h4_1}
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-10 px-2 px-sm-3">
              <div className="w-100 mt-4 d-flex justify-content-center justify-content-sm-end">
                <AddCreditCard
                  onClose={handleAddCardClose}
                  active={showAdd}
                  onCreatedCard={handleCreatedCard}
                  className="border-0 bg-transparent"
                />
              </div>
              <Form ref={formRef} className="mb-2 mx-auto" onSubmit={handlePay}>
                <div className="pt-2 pb-5">
                  {paymentMethods.map((card, index) => (
                    <label
                      key={card.id}
                      htmlFor={`credit-card-${index + 1}`}
                      onClick={() => handleClick(card.id)}
                      className={`${
                        cardSelected === card.id ? 'gray-button' : ''
                      } bg-payment-cards px-4 px-lg-0 py-3 mt-4 d-block`}
                    >
                      <div className="row">
                        <div className="col-sm-3 text-center d-flex align-items-center justify-content-sm-center">
                          <img
                            src={card.brandIcon}
                            alt={card.brand}
                            className="pl-2 pl-sm-0 mb-3 mb-sm-0"
                          />
                        </div>
                        <div className="col pr-0 px-sm-3 d-flex align-items-center">
                          <p className="mb-0 pl-2 pl-sm-0">
                            {card.brand}:&nbsp;&nbsp;****-{card.number}
                          </p>
                        </div>
                        <div className="col-2 text-center pl-1 pr-4 px-sm-3 d-flex align-items-center justify-content-end justify-content-sm-center">
                          <RemoveCreditCard
                            card={card as any}
                            onRemoveCard={handleDeleteCreditCard}
                          />
                        </div>
                      </div>
                      <Input
                        type="radio"
                        id={`credit-card-${index + 1}`}
                        name="credit_card"
                        value={card.id}
                        className="d-none"
                        checked={cardSelected === card.id}
                      />
                    </label>
                  ))}
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="gray-button px-5 py-3 mt-5 w-md-50 w-100"
                  >
                    <span>
                      {processing ? (
                        <div className="spinner" id="spinner" />
                      ) : (
                        language.buy_component.button_5
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </Container>
  );
};

export default CreditCardPay;
