import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface IPropsBox {
  src: string;
}

interface IBuyButton {
  disabled?: boolean;
  info?: string;
}

export const Container = styled.div``;

export const Box = styled.div<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 250px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    .show-courses {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;

      :hover {
        background-color: var(--deepGreyLighten);
        transition: 0.5s;
      }
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1400px) {
    max-height: 340px;
    height: 18vw;
  }
`;

export const BuyButton = styled(Link)<IBuyButton>`
  background-color: var(--mysteriousGrey);
  color: ${(props) =>
    props.disabled ? 'var(--blizzardOpacity)' : 'var(--blizzard)'} !important;
  text-decoration: none !important;
  border-radius: 20px;
  transition-duration: 0.3s;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  .info-alert {
    display: block;
    background-color: var(--deepGrey);
    position: absolute;
    border-radius: 50px;
    top: -55px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.3s;
    z-index: 1;
    height: 50px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    :after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      transform: rotateZ(45deg);
      background: var(--deepGrey);
      bottom: -3px;
      left: 15%;
      z-index: -1;
    }
  }

  :hover {
    ${(props) =>
      !props.disabled
        ? css`
            background-color: var(--mysteriousGreyDarken);
          `
        : css``}

    .info-alert {
      opacity: 1;
      visibility: visible;
    }
  }

  :active {
    pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  }
`;

export const Skeleton = styled.div`
  .skeleton {
    height: 250px;
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
    @media screen and (min-width: 1400px) {
      max-height: 340px;
      height: 18vw;
    }
  }
`;
