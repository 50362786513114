import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useLanguage } from '~/hooks/Language';
import BuyButton from '~/components/BuyButton';

import { Container, Welcome, Parcel, Modal, Skeleton } from './styles';

import franchise from '~/assets/icons/franchise-icon.svg';
import check from '~/assets/icons/icon-circle-check.svg';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import closeIcon from '~/assets/icons/close.svg';
import download from '~/assets/icons/desk-download.svg';

interface IParams {
  slug: string;
}

interface IProductResponse {
  id: string;
  name: string;
  price: number;
}

interface IProduct {
  id: string;
  name: string;
  price: string;
  info: string;
  installment: string;
}

interface IInstallments {
  price: string;
  discount: string;
}

const Payment: React.FC = () => {
  const params = useParams<IParams>();

  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [show, setShow] = useState(false);
  const { language } = useLanguage();
  const [installments, setInstallments] = useState<IInstallments[]>([]);
  const [installmentsSelected, setInstallmentsSelected] = useState<IProduct>(
    {} as IProduct
  );
  const [active, setActive] = useState('parcel_1');
  const [loading, setLoading] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const handleChange = useCallback((e) => {
    setCheckTerms(e.target.checked);
  }, []);
  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(async () => {
    setShow(true);
  }, []);

  const handleSelect = useCallback(
    (data: IInstallments, parcel: string) => {
      const times =
        parcel.slice(-1) === '1' ? '1 time' : `${parcel.slice(-1)} times`;
      const installmentsSelectedData: IProduct = {
        id: installmentsSelected.id,
        name: installmentsSelected.name,
        price: data.price,
        info: `${installmentsSelected.name} in ${times}`,
        installment: parcel.slice(-1),
      };
      setActive(parcel);
      setInstallmentsSelected(installmentsSelectedData);
    },
    [installmentsSelected.id, installmentsSelected.name]
  );

  const loadProducts = useCallback(() => {
    setLoading(true);
    api
      .get<IProductResponse>(`products/students/${params.slug}`)
      .then(async (response) => {
        const data: IProduct = {
          id: response.data.id,
          name: response.data.name,
          price: formatPrice(response.data.price),
          info: '',
          installment: '1',
        };

        const installmentsData: IInstallments[] = [
          {
            discount: formatPrice(
              Math.ceil(
                parseFloat(response.data.price.toString()) +
                  parseFloat(response.data.price.toString()) * 0.25
              ) - parseFloat(response.data.price.toString())
            ),
            price: formatPrice(
              parseFloat(response.data.price.toString()) +
                parseFloat(response.data.price.toString()) * 0
            ),
          },
          {
            discount: formatPrice(
              Math.ceil(
                parseFloat(response.data.price.toString()) +
                  parseFloat(response.data.price.toString()) * 0.25
              ) -
                Math.ceil(
                  parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.1
                )
            ),
            price: formatPrice(
              parseFloat(
                (
                  (parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.1) /
                  2
                ).toFixed(0)
              )
            ),
          },
          {
            discount: formatPrice(
              Math.ceil(
                parseFloat(response.data.price.toString()) +
                  parseFloat(response.data.price.toString()) * 0.25
              ) -
                Math.ceil(
                  parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.15
                )
            ),
            price: formatPrice(
              parseFloat(
                (
                  (parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.15) /
                  3
                ).toFixed(0)
              )
            ),
          },
          {
            discount: formatPrice(
              Math.ceil(
                parseFloat(response.data.price.toString()) +
                  parseFloat(response.data.price.toString()) * 0.25
              ) -
                Math.ceil(
                  parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.2
                )
            ),
            price: formatPrice(
              parseFloat(
                (
                  (parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.2) /
                  4
                ).toFixed(0)
              )
            ),
          },
          {
            discount: 'No Savings',
            price: formatPrice(
              parseFloat(
                (
                  (parseFloat(response.data.price.toString()) +
                    parseFloat(response.data.price.toString()) * 0.25) /
                  5
                ).toFixed(0)
              )
            ),
          },
        ];

        setInstallments(installmentsData);
        setInstallmentsSelected(data);
      })
      .finally(() => setLoading(false));
  }, [params.slug]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleSucceededPaid = useCallback(() => {
    console.log(installmentsSelected);
  }, [installmentsSelected]);

  return (
    <Container className="py-3 py-lg-0 text-center text-md-left">
      <div className="container">
        <div className="row mx-lg-2">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="mb-3 mt-5 text-center">
                      {language.payment.h1}
                    </h1>
                    <p className="text-center font-weight-400">
                      {language.payment.p_1}
                    </p>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="row">
            <div className="col-xl-6 my-2 mt-lg-4 px-xl-0">
              <div className="row align-items-center text-left p-4 p-sm-owner mx-2 box">
                <div className="d-flex col-12 justify-content-xl-between px-0">
                  <h2 className="h5 h2-sm mb-0">{language.payment.h2_1}</h2>
                  <img
                    src={franchise}
                    alt={language.become_affiliate.img_2}
                    className="ml-4"
                  />
                </div>

                <div className="col-12 px-0">
                  <hr className="rounded-pill w-25" />
                </div>

                <div className="row my-xl-3 pt-xl-3 pb-xl-2">
                  <div className="col-md-6 col-xl-12 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span>40% </span>
                          {language.become_affiliate.p_16}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span>10% </span>
                          {language.become_affiliate.p_18}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span>{language.become_affiliate.span_2} </span>
                          {language.become_affiliate.p_20}{' '}
                          {language.payment.p_2}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-12 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span className="text-uppercase">
                            {language.become_affiliate.h3_2}{' '}
                          </span>
                          {language.become_affiliate.p_22}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span>{language.payment.p_3_1} </span>
                          {language.payment.p_3_2}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-1 px-0 pr-sm-3 mb-1">
                        <img
                          src={check}
                          className="img-icon"
                          alt="Icon check"
                        />
                      </div>
                      <div className="col-11 pl-4 pl-sm-3 my-4">
                        <p className="mb-0 h6 h5-sm">
                          <span>{language.payment.p_4_1} </span>
                          {language.payment.p_4_2}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Parcel className="col-xl-6 d-flex d-xl-block flex-wrap mx-2 mx-xl-0 my-2 mt-lg-4">
              <>
                {loading && (
                  <Skeleton className="col-12 px-0 d-flex flex-wrap">
                    <div className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex">
                      <label className="col-12 px-0" htmlFor="parcel_1">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                        />
                        <div className="form-check-label mb-3 d-flex height align-items-center bg py-4">
                          <div className="col-2 pr-2 d-flex justify-content-center">
                            <div className="check skeleton" />
                          </div>
                          <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                            <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                              <h6 className="skeleton h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                                1 Payment
                              </h6>
                              <div className="">
                                <h6 className="skeleton mb-0 info-save d-inline-block py-2 px-4">
                                  Save $100
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                              <h2 className="skeleton h3 h2-sm mb-0 font-weight-bold">
                                $397
                              </h2>
                              <span className="skeleton ml-2 ml-sm-3">
                                / One-time
                              </span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex">
                      <label className="col-12 px-0" htmlFor="parcel_1">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                        />
                        <div className="form-check-label mb-3 d-flex height align-items-center bg py-4">
                          <div className="col-2 pr-2 d-flex justify-content-center">
                            <div className="check skeleton" />
                          </div>
                          <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                            <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                              <h6 className="skeleton h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                                1 Payment
                              </h6>
                              <div className="">
                                <h6 className="skeleton mb-0 info-save d-inline-block py-2 px-4">
                                  Save $100
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                              <h2 className="skeleton h3 h2-sm mb-0 font-weight-bold">
                                $397
                              </h2>
                              <span className="skeleton ml-2 ml-sm-3">
                                / One-time
                              </span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex">
                      <label className="col-12 px-0" htmlFor="parcel_1">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                        />
                        <div className="form-check-label mb-3 d-flex height align-items-center bg py-4">
                          <div className="col-2 pr-2 d-flex justify-content-center">
                            <div className="check skeleton" />
                          </div>
                          <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                            <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                              <h6 className="skeleton h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                                1 Payment
                              </h6>
                              <div className="">
                                <h6 className="skeleton mb-0 info-save d-inline-block py-2 px-4">
                                  Save $100
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                              <h2 className="skeleton h3 h2-sm mb-0 font-weight-bold">
                                $397
                              </h2>
                              <span className="skeleton ml-2 ml-sm-3">
                                / One-time
                              </span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex">
                      <label className="col-12 px-0" htmlFor="parcel_1">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                        />
                        <div className="form-check-label mb-3 d-flex height align-items-center bg py-4">
                          <div className="col-2 pr-2 d-flex justify-content-center">
                            <div className="check skeleton" />
                          </div>
                          <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                            <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                              <h6 className="skeleton h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                                1 Payment
                              </h6>
                              <div className="">
                                <h6 className="skeleton mb-0 info-save d-inline-block py-2 px-4">
                                  Save $100
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                              <h2 className="skeleton h3 h2-sm mb-0 font-weight-bold">
                                $397
                              </h2>
                              <span className="skeleton ml-2 ml-sm-3">
                                / One-time
                              </span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex">
                      <label className="col-12 px-0" htmlFor="parcel_1">
                        <input
                          className="form-check-input d-none"
                          type="radio"
                        />
                        <div className="form-check-label mb-3 d-flex height align-items-center bg py-4">
                          <div className="col-2 pr-2 d-flex justify-content-center">
                            <div className="check skeleton" />
                          </div>
                          <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                            <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                              <h6 className="skeleton h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                                1 Payment
                              </h6>
                              <div className="">
                                <h6 className="skeleton mb-0 info-save d-inline-block py-2 px-4">
                                  Save $100
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                              <h2 className="skeleton h3 h2-sm mb-0 font-weight-bold">
                                $397
                              </h2>
                              <span className="skeleton ml-2 ml-sm-3">
                                / One-time
                              </span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 col-xl-12 pr-lg-0 pl-lg-3 px-xl-0 text-center">
                      <button type="button" className="skeleton w-100 mb-3">
                        {language.payment.button}
                      </button>
                      <Link to="/" className="skeleton">
                        {language.payment.a}
                      </Link>
                    </div>
                  </Skeleton>
                )}
                {installments.map((installment, index) => (
                  <div
                    key={installment.price}
                    className="col-lg-6 col-xl-12 px-0 pr-lg-3 pr-xl-0 form-check d-flex"
                  >
                    <label
                      className="col-12 px-0"
                      htmlFor={`parcel_${index + 1}`}
                    >
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="parcel"
                        id={`parcel_${index + 1}`}
                        value={`parcel_${index + 1}`}
                        onChange={() =>
                          handleSelect(installment, `parcel_${index + 1}`)
                        }
                        checked={active === `parcel_${index + 1}`}
                      />
                      <div
                        className={`form-check-label mb-3 d-flex align-items-center bg py-4 ${
                          active === `parcel_${index + 1}` ? 'active' : ''
                        }`}
                      >
                        <div className="col-2 pr-2 d-flex justify-content-center">
                          <div
                            className={`check ${
                              active === `parcel_${index + 1}` ? 'bg-check' : ''
                            }`}
                          />
                        </div>
                        <div className="col-10 px-0 d-md-flex d-lg-block d-xxl-flex">
                          <div className="col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0 mb-lg-3 mb-xxl-0 d-450-flex d-md-block d-lg-flex d-xxl-block align-items-center justify-content-between text-left">
                            <h6 className="h5 h4-sm mg-400 mb-0 mb-md-3 mb-lg-0 mb-xxl-3 font-weight-600">
                              {index === 0 ? (
                                <>1 {language.payment.h6_1}</>
                              ) : (
                                <>
                                  {index + 1} {language.payment.h6_3}
                                </>
                              )}
                            </h6>
                            <div className="">
                              <h6 className="mb-0 info-save d-inline-block py-2 px-4">
                                {installments.length === index + 1 ? (
                                  <>{installment.discount}</>
                                ) : (
                                  <>
                                    {language.payment.h6_2}{' '}
                                    {installment.discount.slice(0, -3)}
                                  </>
                                )}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-12 col-xxl-6 d-flex align-items-center">
                            <h2 className="h3 h2-sm mb-0 font-weight-bold">
                              {installment.price.slice(0, -3)}
                            </h2>
                            <span className="pl-2 pl-sm-3">
                              {index === 0 ? (
                                <>/ {language.payment.span_1}</>
                              ) : (
                                <>
                                  / {index + 1} {language.payment.span_2}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}

                <div
                  className={`${
                    loading && 'd-none'
                  } col-lg-6 col-xl-12 pr-lg-0 pl-lg-3 px-xl-0 text-center`}
                >
                  <button
                    type="button"
                    className="w-100 mb-3"
                    onClick={handleShow}
                  >
                    {language.payment.button}
                  </button>
                  <Link to="/">
                    <u>{language.payment.a}</u>
                  </Link>
                </div>
              </>
            </Parcel>
          </div>
        </div>
      </div>

      <Modal className="modal-terms px-0" centered size="xl" show={show}>
        <Modal.Header className="border-0 pb-0 pt-3 px-4">
          <Modal.Title className="text-center w-100" />
          <button type="button" className="close-button" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-2 px-sm-4 px-lg-5 pb-5">
          <div className="col-12 px-2">
            <h2 className="h5 h4-sm payment-color text-center mb-4 mb-sm-5 font-weight-600">
              Wiserr
            </h2>
          </div>
          <div className="row mx-2 justify-content-center mt-lg-5">
            <div className="col-12 card-terms px-4 py-5 p-lg-5">
              <h2 className="h5 h4-sm payment-color font-weight-bold pb-2 mb-2 mb-sm-4">
                {language.payment.modal_h2}
              </h2>
              <div className="overflow-auto height mb-4 pr-2">
                <p>{language.payment.modal_p_1}</p>
                <p>{language.payment.modal_p_2}</p>
                <p>{language.payment.modal_p_3}</p>
              </div>
              <input
                className="mr-3"
                onChange={handleChange}
                type="checkbox"
                name="terms"
                id="terms"
              />
              <label htmlFor="terms" className="">
                {language.payment.modal_label}
              </label>
            </div>
            <div className="col-12 px-0 d-flex flex-wrap align-items-center mt-4 mt-sm-5">
              <div className="col-12 col-sm-4 py-3 mb-4 mb-sm-0 pl-0">
                <Link
                  to="/"
                  className="bg-img p-3"
                  aria-label={language.payment.modal_img}
                >
                  <img src={download} className="" alt="Download" />
                </Link>
              </div>
              <div className="col-6 col-sm-4 pr-2 pr-sm-3 pl-0">
                <button
                  type="button"
                  className="font-weight-bold btn-decline w-100"
                  onClick={handleClose}
                >
                  {language.payment.modal_button_1}
                </button>
              </div>
              <div className="col-6 col-sm-4 pl-2 pl-sm-3 pr-0">
                {Object.keys(installmentsSelected).length > 0 && (
                  <BuyButton
                    product_id={installmentsSelected.id}
                    title={installmentsSelected.name}
                    price={installmentsSelected.price.slice(1).replace(',', '')}
                    className="btn-color"
                    info={language.payment.modal_info}
                    installments={parseInt(
                      installmentsSelected.installment,
                      10
                    )}
                    onSucceededPaid={handleSucceededPaid}
                    disabled={!checkTerms}
                  >
                    {language.payment.modal_button_2}
                  </BuyButton>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Payment;
