import styled, { css } from 'styled-components';

import dashboard from '~/assets/icons/dashboard-icon.svg';
import dashboardActive from '~/assets/icons/dashboard-icon-active.svg';
import courses from '~/assets/icons/courses-icon.svg';
import coursesActive from '~/assets/icons/courses-icon-active.svg';
import profile from '~/assets/icons/profile-icon.svg';
import profileActive from '~/assets/icons/profile-icon-active.svg';
import resources from '~/assets/icons/resources-icon.svg';
import resourcesActive from '~/assets/icons/resources-icon-active.svg';
import becomeAffiliate from '~/assets/icons/become-affiliate-icon.svg';
import becomeAffiliateActive from '~/assets/icons/become-affiliate-icon-active.svg';
import products from '~/assets/icons/product-icon.svg';
import productsActive from '~/assets/icons/product-icon-active.svg';
import affiliates from '~/assets/icons/affiliates-icon.svg';
import affiliatesActive from '~/assets/icons/affiliates-icon-active.svg';
import faq from '~/assets/icons/faq-icon.svg';
import faqActive from '~/assets/icons/faq-icon-active.svg';
import logout from '~/assets/icons/log-out-icon.svg';
import logoutActive from '~/assets/icons/log-out-icon-active.svg';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  position: fixed;
  top: 0;
  background: var(--asphaltGray);
  z-index: 100;

  .btn-idiom {
    img {
      width: 22px;
      height: 22px;
      transition-duration: 0.3s;
      filter: grayscale(1);
      border-radius: 50%;
    }

    :hover img,
    .active-idiom {
      filter: grayscale(0);
    }
  }

  @media screen and (max-width: 991px) {
    height: 60px;
  }

  @media (min-height: 500px) and (max-height: 800px) {
    @media screen and (min-width: 1400px) {
      position: unset;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  .min-height {
    min-height: 50px;
  }
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: var(--blizzard);
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 90%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  .menu-group {
    height: calc(100vh - 100px);
  }

  .menu,
  .logoff {
    a,
    button {
      background: var(--asphaltGray);
      color: var(--blizzard);
      position: relative;

      div {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        transition-duration: 0.3s;
      }

      .dashboard {
        background-image: url(${dashboard});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .courses {
        background-image: url(${courses});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .profile {
        background-image: url(${profile});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .resources {
        background-image: url(${resources});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .products {
        background-image: url(${products});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .become-affiliate {
        background-image: url(${becomeAffiliate});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .affiliates {
        background-image: url(${affiliates});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .faq {
        background-image: url(${faq});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .logout {
        background-image: url(${logout});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      small {
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: -50px;
        background: var(--asphaltGray);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 5px 5px 0;
        width: max-content;
      }
    }
    .become-affiliate-link {
      line-height: 1;
    }

    a:hover,
    button:hover,
    a.active {
      text-decoration: none;

      .dashboard {
        background-image: url(${dashboardActive});
      }

      .courses {
        background-image: url(${coursesActive});
      }

      .profile {
        background-image: url(${profileActive});
      }

      .resources {
        background-image: url(${resourcesActive});
      }

      .affiliates {
        background-image: url(${affiliatesActive});
      }

      .products {
        background-image: url(${productsActive});
      }

      .become-affiliate {
        background-image: url(${becomeAffiliateActive});
      }

      .faq {
        background-image: url(${faqActive});
      }

      .logout {
        background-image: url(${logoutActive});
      }
    }

    .flag-text {
      color: var(--greyMineral) !important;
    }

    .flag-text-light,
    .flag-text:focus,
    .flag-text:hover {
      color: var(--blizzard) !important;
    }

    a:hover,
    button:hover {
      small {
        padding: 0 20px;
        left: 100%;

        @media screen and (min-width: 992px) {
          min-height: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .menu-group {
      width: 100%;
      height: 100vh;
      background: var(--asphaltGray);
      transition-duration: 0.3s;
      top: 60px;
      left: ${(props) => (props.active ? '0px' : '1000px')};
    }

    .overflow {
      overflow: auto;
      ::-webkit-scrollbar {
        background-color: var(--asphaltGray) !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--asphaltGray) !important;
      }
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px 0 0;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          font-size: 24px;
          justify-content: start;
          left: 0 !important;
          padding: 5px 0 !important;
        }
      }
    }
  }
`;
