import React, { useState, useCallback } from 'react';
import { ReactCreditCard } from '@repay/react-credit-card';
import '@repay/react-credit-card/dist/react-credit-card.css';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';

import cvcCard from '~/assets/icons/cvc-card.svg';
import amex from '~/assets/icons/amex-color.svg';
import discover from '~/assets/icons/discover-color.svg';
import mastercard from '~/assets/icons/mastercard-color.svg';
import visa from '~/assets/icons/visa-color.svg';

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
}

interface creditCardProps {
  initialValue?: ICreditCard;
  className?: string;
}

const CreditCard: React.FC<creditCardProps> = ({ initialValue, className }) => {
  const { language } = useLanguage();
  const [values, setValues] = useState({
    name: initialValue ? initialValue.name : '',
    number: initialValue ? initialValue.number : '',
    expiration: initialValue ? initialValue.expiration : '',
    cvc: initialValue ? initialValue.cvc : '',
    flag: initialValue ? initialValue.flag : '',
  });

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const noSpaceValue = value.replace(/ /g, '');
      setValues((v) => ({
        ...v,
        [name]: name === 'number' ? noSpaceValue : value,
      }));
      if (name === 'number') {
        const ReactCreditCardElement = document.getElementsByClassName(
          'ReactCreditCard'
        )[0];
        let flag = ReactCreditCardElement.classList[1].replace(
          'ReactCreditCard--',
          ''
        );

        flag = flag.charAt(0).toUpperCase() + flag.slice(1);
        setValues((v) => ({ ...v, flag: flag === 'Unknown' ? '' : flag }));
      }
    },
    [setValues]
  );

  const [focused, setFocus] = useState<
    'number' | 'cvc' | 'expiration' | 'name' | undefined
  >(undefined);
  const handleFocus = useCallback(
    (event) => {
      setFocus(event.target.name || event.target.placeholder.toLowerCase());
    },
    [setFocus]
  );
  const handleBlur = React.useCallback(() => setFocus(undefined), [setFocus]);

  return (
    <Container className={className}>
      <div className="row align-items-lg-center">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <div className="credit-card-preview w-100 text-center">
            <ReactCreditCard {...values} focused={focused} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="credit-card-data">
            <label htmlFor="name">
              {language.credit_card_component.label_1}
            </label>
            <Input
              name="name"
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.name}
              placeholder={language.credit_card_component.input_2}
              className="mt-2 form-control radius-10"
            />
            <div className="row mt-4">
              <div className="col-12">
                <label htmlFor="credit-card">
                  {language.credit_card_component.label_2}
                </label>
                <div className="p-relative">
                  <InputMask
                    kind="credit-card"
                    name="number"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={values.number}
                    placeholder={language.credit_card_component.input_1}
                    className="mt-2 credit-number bg-transparent"
                  />
                  <div className="p-absolute d-flex flags flags-xs mt-0">
                    <img src={visa} alt="visa" className="px-0 px-sm-1" />
                    <img src={mastercard} alt="mastercard" className="px-1" />
                    <img src={amex} alt="amex" className="pr-1 px-sm-1" />
                    <img
                      src={discover}
                      alt="discover"
                      className="px-0 px-sm-1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 pr-0">
                <InputMask
                  kind="datetime"
                  options={{
                    format: 'MM/YY',
                  }}
                  name="expiration"
                  placeholder="MM / YY"
                  className="form-control credit-date bg-transparent"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values.expiration}
                />
              </div>
              <div className="col-6 pl-0 p-relative">
                <Input
                  name="cvc"
                  onChange={(e) =>
                    setValues({ ...values, cvc: e.target.value })
                  }
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={values.cvc}
                  placeholder={language.credit_card_component.input_4}
                  className="form-control credit-cvc"
                />
                <img src={cvcCard} alt="cvcCard" className="p-absolute flags" />
              </div>
            </div>
            <Input
              name="flag"
              onChange={(e) => setValues({ ...values, flag: e.target.value })}
              value={values.flag}
              placeholder={language.credit_card_component.input_5}
              className="form-control mt-2 radius-10"
            />
            {false && (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="country">
                      {language.credit_card_component.label_3}
                    </label>
                    <Input
                      name="country"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="country"
                      className="mt-2 credit-number bg-transparent"
                    />
                  </div>
                  <div className="col-md-12">
                    <Input
                      name="zipCode"
                      placeholder="Zip Code"
                      className="form-control credit-zip-code bg-transparent"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="bank">
                      {language.credit_card_component.label_4}
                    </label>
                    <Input
                      name="bank"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="Bank"
                      className="mt-2 form-control bg-transparent"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreditCard;
