import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Button } from './styles';

import deleteIcon from '~/assets/icons/delete.svg';

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  primary_card: boolean;
}

interface IRemoveCreditCardProps {
  card: IReponseCreditCard;
  onRemoveCard?(data: IReponseCreditCard): void;
}

const RemoveCreditCard: React.FC<IRemoveCreditCardProps> = ({
  card,
  onRemoveCard,
}) => {
  const { language } = useLanguage();
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleRemove = useCallback(async () => {
    try {
      await api.delete(`payments/customers/credit-cards/${card.id}`);
      Swal.fire('Okay!', 'Successfully deleted.', 'success').then(() => {
        if (onRemoveCard) {
          onRemoveCard(card);
        }
        handleClose();
      });
    } catch (error) {
      Swal.fire('Opss...', language.error_component.error_message, 'error');
    }
  }, [card, handleClose, language.error_component.error_message, onRemoveCard]);

  return (
    <>
      <Button
        type="button"
        className="border-0 bg-transparent"
        onClick={handleShow}
      >
        <img src={deleteIcon} alt="deletar cartão" />
      </Button>

      <Modal
        className="modal-credit-card"
        show={show}
        onHide={handleClose}
        centered
        size="xl"
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="my-5 ml-auto">
            {language.subscriptions_cards.h4_2}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto w-75">
          <p className="h5 my-5 pb-5 px-5 text-center text-gray-stroke">
            {language.subscriptions_cards.p}
          </p>
          <div className="d-flex my-5 pt-5 remove-cards-button">
            <button
              type="button"
              className="w-100 mr-4 py-3"
              onClick={handleClose}
            >
              <span>{language.subscriptions_cards.button_5}</span>
            </button>
            <button
              type="button"
              onClick={handleRemove}
              className="w-100 ml-4 py-3"
            >
              <span>{language.subscriptions_cards.button_6}</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </>
  );
};

export default RemoveCreditCard;
