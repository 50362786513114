import React, { useState, useCallback, useEffect } from 'react';

import { FaSearch } from 'react-icons/fa';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useLanguage } from '~/hooks/Language';

import { Container, Welcome, Options, BookBanner } from './styles';
import Resource from './Resources';
import Downloads from './Downloads';

import resourceDownload from '~/assets/icons/resource-download.svg';

const Resources: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { language, idiom } = useLanguage();
  const [optionSelected, setOptionSelected] = useState(() => {
    const lang = location.pathname.substr(0, 3);
    if (lang !== '/en' && lang !== '/es') {
      return location.pathname.slice(11);
    }
    return location.pathname.slice(14);
  });
  const [langSelected] = useState(() => {
    return idiom === 'en' || idiom === 'es' ? `/${idiom}` : '';
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    switch (optionSelected) {
      case 'links':
        setOptionSelected('links');
        break;
      default:
        setOptionSelected('downloads');
        break;
    }
  }, [language.courses, optionSelected]);

  useEffect(() => {
    let page = '';
    if (langSelected !== '/en' && langSelected !== '/es') {
      page = location.pathname.slice(11);
    } else {
      page = location.pathname.slice(14);
    }

    setOptionSelected(page);
  }, [history, location.pathname, langSelected]);

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <Container className="py-3 py-lg-0">
      <div className="container">
        <div className="row mt-3 mt-xl-0 mt-xxl-5">
          <div className="col-12">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-12 px-md-0 px-xxl-3">
                    <div className="row justify-content-center justify-content-lg-start">
                      <div className="col-lg-7 col-xxl-4 pl-xxl-0">
                        <h1 className="h2-md h4 text-center text-md-left">
                          {language.resources.h1}
                        </h1>
                      </div>
                      <div className="col-lg-5 col-xxl-4 text-center align-items-center d-flex">
                        <div className="d-flex search w-100 align-items-center px-3">
                          <input
                            type="text"
                            id="search"
                            className="w-100"
                            placeholder={language.resources.input}
                            onChange={handleChange}
                          />
                          <FaSearch />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-11 col-md-9 col-lg-12 mx-lg-3 px-3 px-lg-0 py-3 my-3 my-xxl-4">
                    <div className="row justify-content-center text-center">
                      <div className="d-flex align-items-center justify-content-center my-1 my-lg-0 col-12">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}${langSelected}/resources/downloads`}
                          className="bg-btn rounded-pill m-1 my-lg-0 mx-xl-3 px-3 px-xxl-4 py-1 py-xxl-1 font-weight-600 text-center"
                          activeClassName="active"
                        >
                          <span className="d-block px-xl-2 py-xl-1">
                            {language.resources.button_2}
                          </span>
                        </NavLink>
                        <NavLink
                          to={`${process.env.PUBLIC_URL}${langSelected}/resources/links`}
                          className="bg-btn rounded-pill m-1 my-lg-0 mx-xl-3 px-3 px-xxl-4 py-1 py-xxl-1 font-weight-600 text-center"
                          activeClassName="active"
                        >
                          <span className="d-block px-xl-2 py-xl-1">
                            {language.resources.button_1}
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
            <BookBanner className="row">
              <div className="col-12 pb-4">
                <a
                  href="https://cdn.wiserr.io/promotional-stuff/el-atajo-del-milionario.pdf"
                  className="thumb-link d-block p-relative thumb w-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="short-title w-100 p-absolute">
                    <div className="row h-100">
                      <div className="col-sm-6 col-lg-7 col-xl-5">
                        <p className="w-75 h3">
                          eBook - El atajo del milionario
                        </p>
                      </div>
                      <div className="col-12">
                        <div className="row h-100 align-items-end">
                          <div className="col-12">
                            <div className="see-more-link">
                              <a
                                href="https://cdn.wiserr.io/promotional-stuff/el-atajo-del-milionario.pdf"
                                className="btn h6 px-4 py-1 py-sm-2 mt-sm-3 mt-xl-0 mb-0"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {language.resources.button_4}
                                <img
                                  src={resourceDownload}
                                  alt="resourceDownload"
                                  className="ml-2"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </BookBanner>
            <Resource active={optionSelected === 'links'} search={search} />
            <Downloads
              active={optionSelected === 'downloads'}
              search={search}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Resources;
