import { io, Socket } from 'socket.io-client';

let socket: Socket | undefined;

export interface ICheckChat {
  chat_id: string;
  user_id: string;
}

interface ISocketCheckChat {
  onCheckChat: (data: ICheckChat) => Promise<void>;
}

export const initializeSocketCheckChat = (): boolean => {
  socket = io(process.env.REACT_APP_API_URL as string, {
    reconnection: true,
    reconnectionDelay: 500,
  });

  return !!socket;
};

export const initializeSocketCheckChatEvents = ({
  onCheckChat,
}: ISocketCheckChat): void => {
  if (socket) {
    socket.on('connect', () => {
      console.log('socket connected');
    });

    socket.on('user-disconnected', (userData: any) => {
      console.log('user disconnected-- closing peers', userData);
    });

    socket.on('disconnect', () => {
      console.log('socket disconnected --');
    });

    socket.on('error', (err: any) => {
      console.log('socket error --', err);
    });

    socket.on('check-chat', (data: ICheckChat) => {
      onCheckChat(data);
    });
  }
};

export const disconnectSocketCheckChat = (): void => {
  if (socket) {
    socket.disconnect();
    socket = undefined;
  }
};
