import React, { useCallback, useRef } from 'react';
import Pdf from '@mikecousins/react-pdf';

interface Iprops {
  filePdf: string;
  onChangeThumb(thumb: string): void;
}
const PdfCertificate: React.FC<Iprops> = ({ filePdf, onChangeThumb }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handlePageLoadSuccess = useCallback(async () => {
    try {
      const pdfBlob: Blob = await new Promise((resolve: any) => {
        if (canvasRef.current) {
          canvasRef.current.toBlob(resolve);
        } else {
          resolve();
        }
      });

      if (pdfBlob) {
        onChangeThumb(URL.createObjectURL(pdfBlob));
      }
    } catch (error) {
      console.log(error);
    }
  }, [onChangeThumb]);

  return (
    <Pdf
      ref={canvasRef}
      file={filePdf}
      page={1}
      className="d-none"
      onPageRenderSuccess={handlePageLoadSuccess}
    />
  );
};

export default PdfCertificate;
