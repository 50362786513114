/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { IoIosArrowBack } from 'react-icons/io';

import { Link } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';
import { useSetup } from '~/hooks/Setup';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import wiserr from '~/assets/defaults/wiserr-new.png';
import logoMobWhite from '~/assets/logotipos/logo-white-modal.svg';
import api from '~/services/api';

interface SignInFormData {
  email: string;
}

const ForgotPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const {
    setupName,
    logo,
    loginBackground,
    socialMedias,
    socialUserName,
  } = useSetup();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email(language.forgot_password.erro_1)
            .required(language.forgot_password.erro_2),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('students/sessions/forgot-password', {
          email: data.email,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        }
      }
      Swal.fire('', language.forgot_password.success, 'success');
    },
    [
      language.forgot_password.erro_1,
      language.forgot_password.erro_2,
      language.forgot_password.success,
    ]
  );

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start align-items-center h-100">
          <Background
            src={loginBackground}
            className="d-none d-lg-block col-lg-6 text-center"
          >
            <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
              <img src={logo} alt="Logo" />
            </div>
          </Background>

          <div className="p-absolute position d-none d-xl-block">
            <img src={wiserr} alt="Wiserr" />
          </div>

          <div className="bg-right-side col-lg-6 px-0 px-sm-3 my-0">
            <AnimationContainer className="h-100">
              <div className="align-items-center mt-4 mb-xl-3">
                <div className="p-xxl-5 p-lg-4 p-sm-5 p-4 login">
                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="text-black w-sm-25 d-flex align-items-center font-weight-bold"
                  >
                    <IoIosArrowBack size={25} color="#000" />
                    &nbsp;&nbsp;{language.forgot_password.arrow}
                  </Link>
                  <h1 className="roboto text-black h2 h1-md font-weight-bold mt-4 mb-1">
                    {language.forgot_password.h1}
                  </h1>
                  <p className="roboto text-black font-weight-300">
                    {language.forgot_password.h2}
                  </p>

                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <label
                      htmlFor="email"
                      className="sans-pro font-weight-600 h6 text-black d-block mb-3 mt-3"
                    >
                      {language.forgot_password.label_1}
                    </label>
                    <Input id="email" name="email" className="input" />

                    <button
                      type="submit"
                      className="button-login mt-5 mb-0 my-lg-5 w-100"
                    >
                      <span className="font-weight-bold d-block">
                        {language.forgot_password.button}
                      </span>
                    </button>
                  </Form>
                </div>
              </div>
            </AnimationContainer>
            <div className="d-lg-none left-side position-relative mb-3 mb-sm-0">
              <div
                className={` bg-change-plan  flex-column justify-content-center align-items-center`}
              />

              <div className="bg d-flex justify-content-center align-items-center position-relative">
                <img src={logoMobWhite} alt="Wiserr" className="" />
              </div>
              <div className="px-4 px-lg-5 bg-left-bottom">
                <h2 className="text-center">
                  Master Transformative Skills to{' '}
                  <span className="text-white">
                    Build Your Freedom Business
                  </span>
                </h2>
                <h3 className="text-center px-lg-5">
                  At Wiserr, we fuel your entrepreneurial journey with courses
                  designed to{' '}
                  <span className="font-weight-600">
                    go beyond learning and ignite your potential and foster
                    innovation.
                  </span>{' '}
                  Our goal is to help you build a thriving business future.
                </h3>

                <div className="socials text-center mt-5 pt-5 pb-5">
                  <Link to="/" className="mx-1 mx-lg-3">
                    Privacy Policy
                  </Link>{' '}
                  |
                  <Link to="/" className="mx-1 mx-lg-3">
                    Terms of Service
                  </Link>{' '}
                  |
                  <Link to="/" className="mx-1 mx-lg-3">
                    Security
                  </Link>{' '}
                  |
                  <Link to="/" className="mx-1 mx-lg-3">
                    Cookies
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
