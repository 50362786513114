import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import AutoLogin from '~/pages/AutoLogin';
import SignUp from '~/pages/SignUp';
import ForgotPassword from '~/pages/ForgotPassword';
import ChangePassword from '~/pages/ChangePassword';
import CheckLogin from '~/pages/CheckLogin';
import SignOut from '~/pages/SignOut';
import Home from '~/pages/Home';
import Course from '~/pages/Course';
import Profile from '~/pages/Profile';
import Resources from '~/pages/Resources';
import Affiliate from '~/pages/Affiliate';
import Products from '~/pages/Products';
import Product from '~/pages/Product';
import Payment from '~/pages/Payment';
import Faq from '~/pages/Faq';
import Lesson from '~/pages/Lesson';
// import UpdateWarning from '~/pages/UpdateWarning';

const Es: React.FC = () => {
  return (
    <Switch>
      {/* <Route path={`${process.env.PUBLIC_URL}/`} component={UpdateWarning} /> */}
      <Route path={`${process.env.PUBLIC_URL}/es`} exact component={SignIn} />
      {/* <Route
        path={`${process.env.PUBLIC_URL}/es/auto-login/:password/:email`}
        exact
        component={AutoLogin}
      /> */}
      <Route
        path={`${process.env.PUBLIC_URL}/es/sign-up`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/sign-up/i/:referenced`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/forgot-password/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/auto-login/:token`}
        exact
        component={CheckLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/check-login/:token`}
        exact
        component={CheckLogin}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/dashboard`}
        isPrivate
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/courses/preview/:slug`}
        exact
        isPrivate
        component={Course}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/courses/:slug`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/courses/:slug/:slugLesson`}
        exact
        isPrivate
        component={Lesson}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/profile`}
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/resources`}
        isPrivate
        component={Resources}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/become-affiliate`}
        isPrivate
        component={Affiliate}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/products`}
        isPrivate
        component={Products}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/product/:slug`}
        exact
        isPrivate
        component={Product}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/product/:slug/payment`}
        isPrivate
        component={Payment}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/faq`}
        isPrivate
        component={Faq}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/es/sign-out`}
        isPrivate
        component={SignOut}
      />
    </Switch>
  );
};

export default Es;
