import styled from 'styled-components';

export const Container = styled.div`
  .h-40vh {
    height: 40vh;
  }

  .text-no-register {
    color: var(--greyMineral);
    font-weight: 600;
  }

  .bg-gray {
    background: var(--midnight);
    border-radius: 20px;
  }
`;

export const Orders = styled.div`
  .order {
    transition-duration: 0.3s;
    background: var(--asphaltGray);
    color: var(--graniteGray);
    border-radius: 20px;

    svg {
      transition-duration: 0.3s;
      color: var(--graniteGray);
      min-width: 25px;
      min-height: 25px;
    }
  }

  .bg-infos {
    background: var(--coal);
  }

  .infos {
    transition-duration: 0.3s;
    color: var(--technologicalGray);

    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;

    .gray-dark {
      color: var(--greyMineral);
    }
  }

  .order-box.active {
    .order {
      border-radius: 20px 20px 0px 0px;

      svg {
        transform: rotate(180deg);
      }
    }

    .bg-infos {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-radius: 0px 0px 20px 20px;
    }

    .infos {
      height: 250px;

      ::-webkit-scrollbar {
        background-color: var(--coal);
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: var(--asphaltGray);
      }
    }
  }
`;

export const Skeleton = styled.div`
  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;
