import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, BoxLoading } from './styles';
import timer from '~/assets/icons/timer.svg';
import noCourse from '~/assets/icons/no-purchased-course-icon.svg';

interface ICourse {
  id: string;
  title: string;
  description: string;
  release_date: string;
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface IUpcoming {
  id: string;
  title: string;
  description: string;
  release_date: string;
  thumbnail: string;
}

interface IUpcomingProps {
  active: boolean;
}

const Upcoming: React.FC<IUpcomingProps> = ({ active }) => {
  const { language } = useLanguage();
  const [upcoming, setUpcoming] = useState<IUpcoming[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get<ICourse[]>('courses/students/drafts')
      .then((response) => {
        const data = response.data.map((course) => ({
          id: course.id,
          title: course.title,
          description: course.description,
          release_date: format(new Date(course.release_date), 'MMMM / yyyy'),
          thumbnail: course.thumbnail.thumbnail_url,
        }));
        setUpcoming(data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Container className={active ? 'd-block' : 'd-none'}>
        <div className="row pt-3">
          <div className="col-12 mb-4">
            <h3 className="h5">{language.upcoming.h3}</h3>
          </div>
          {loading && (
            <>
              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>

              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <BoxLoading />
              </div>
            </>
          )}
          {upcoming.length > 0 ? (
            <>
              {upcoming.map((course) => (
                <div key={course.id} className="col-sm-6 col-xl-4 mb-5">
                  <Box
                    src={course.thumbnail}
                    className="course-link d-block p-relative thumb w-100"
                  >
                    <div className="short-title w-100 p-2 p-absolute ">
                      <div className="row h-100">
                        <div className="col-12">
                          <img
                            src={timer}
                            alt="stamp"
                            className="float-right mr-3 mt-n2"
                          />
                          <p className="font-weight-600 mb-0 pl-2 pl-xxl-4 ml-xxl-3 w-75 h5 h4-xxl mt-5">
                            {course.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              ))}
            </>
          ) : (
            <div className={`${loading === true ? 'd-none' : ''} col-12`}>
              <div className="row h-40vh align-items-center justify-content-center">
                <div className="col-md-8 d-md-flex text-center justify-content-md-center align-items-center">
                  <img
                    src={noCourse}
                    className="mb-3 mb-md-0"
                    alt="No Course icon "
                  />
                  <p className="h5 mb-0 pl-md-3 text-no-courses text-center">
                    {language.no_register.p}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Upcoming;
