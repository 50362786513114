import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IPropsBox {
  src: string;
}

export const Container = styled.div`
  h3,
  p {
    color: var(--blizzard) !important;
  }

  h3 {
    padding: 0 20px;
  }

  .stamp.visible {
    opacity: 1;
  }

  .stamp.hidden {
    opacity: 0;
  }

  @media screen and (max-width: 1399px) {
    .float-right {
      height: 25px;
    }
  }
`;

export const Box = styled(Link)<IPropsBox>`
  border-radius: 20px;
  text-decoration: none;
  height: 185px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      background-color: var(--deepGrey);
      color: var(--blizzard);
      text-decoration: none;
      border-radius: 20px;
    }
  }

  .short-title {
    bottom: 0;
    background: var(--midnightOpacity);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: var(--ultraWhite);
    }
  }

  @media screen and (min-width: 1800px) {
    height: 300px;

    .align-center-1800 {
      align-items: center;
    }
  }
`;
