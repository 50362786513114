export default async function apiVideo(url: string): Promise<string> {
  return new Promise<string>((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = () => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const readerRes: any = reader;
        const byteCharacters = atob(
          readerRes.result.slice(readerRes.result.indexOf(',') + 1)
        );

        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i += 1) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'video/mp4' });
        const urlVideo = URL.createObjectURL(blob);
        resolve(urlVideo);
        setTimeout(() => {
          URL.revokeObjectURL(urlVideo);
        }, 1000);
      };

      reader.readAsDataURL(xhr.response);
    };

    // xhr.open('GET', `${process.env.REACT_APP_VIDEO_BASEURL}/${url}`);
    xhr.open('GET', `${process.env.REACT_APP_VIDEO_BASEURL}/${url}`);
    xhr.send();
  });
}
