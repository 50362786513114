import React, { useEffect, useState } from 'react';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container, Box, BoxLoading } from './styles';
import noCourse from '~/assets/icons/no-purchased-course-icon.svg';

interface ILessonStudent {
  id: string;
  completed: boolean;
}

interface ILesson {
  id: string;
  slug: string;
  lessonStudent: ILessonStudent[];
}

interface ICourseStudent {
  id: string;
  student_id: string;
  locked_down: boolean;
}

interface ICourse {
  id: string;
  title: string;
  description: string;
  slug: string;
  release_date: string;
  price: string;
  promotion_price: string;
  lessons: ILesson[];
  courseStudent: ICourseStudent[];
  thumbnail: {
    title: string;
    thumbnail_url: string;
  };
}

interface IMyCoursesProps {
  active: boolean;
}

const Discover: React.FC<IMyCoursesProps> = ({ active }) => {
  const { language, idiom } = useLanguage();
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api
      .get('courses/students/discover')
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  console.log(courses.length);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="row pt-3">
        <div className="col-12 mb-4">
          <h3 className="h5">{language.discover.h3}</h3>
        </div>
        {loading && (
          <>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>

            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
            <div className="col-sm-6 col-xl-4 mb-5">
              <BoxLoading />
            </div>
          </>
        )}
        {courses.length > 0 ? (
          <>
            {courses.map((course) => (
              <div className="col-sm-6 col-xl-4 mb-5">
                <Box
                  src={course.thumbnail ? course.thumbnail.thumbnail_url : ''}
                  to={`${process.env.PUBLIC_URL}/${idiom}/courses/preview/${course.slug}`}
                  className="d-block p-relative w-100 course-link"
                >
                  <div className="short-title p-absolute w-100 p-4 p-5-1800">
                    <div className="row h-100">
                      <div className="col-12">
                        <p className="mb-0 py-2 w-100 h5 h4-xxl font-weight-600">
                          {course.thumbnail ? course.thumbnail.title : ''}
                        </p>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </>
        ) : (
          <div className={`${loading === true ? 'd-none' : ''} col-12`}>
            <div className="row h-40vh align-items-center justify-content-center">
              <div className="col-lg-8 text-center text-md-left d-md-flex align-items-center justify-content-center">
                <img
                  src={noCourse}
                  className="mb-3 mb-md-0"
                  alt="No Course icon"
                />
                <p className="h5 mb-0 pl-md-3 text-no-courses text-center">
                  {language.no_register.p}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Discover;
