import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

import check from '~/assets/icons/icon-circle-check.svg';

export const Container = styled.div`
  hr {
    border: solid 3px var(--technologicalGray);
    background-color: var(--technologicalGray);
  }

  .img-icon {
    width: 23px;
  }
  .box {
    background: var(--coal);
    border-radius: 20px;

    h1,
    h2 {
      color: var(--blizzard);
    }

    h2 {
      font-size: 36px;
    }

    p {
      color: var(--deepGrey);
      font-weight: 400;
      font-size: 22px;
    }

    .text-gray {
      color: var(--technologicalGray);
    }

    span {
      color: var(--blizzard);
      font-weight: 700;
    }
  }

  @media screen and (min-width: 575px) {
    .p-sm-owner {
      padding: 3.6rem !important;
    }
  }

  @media screen and (min-width: 1199px) {
    .p-sm-owner {
      padding: 4.5rem 3.6rem !important;
    }
  }

  @media screen and (max-width: 1399px) {
    .box h2 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1200px) {
    .box p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {
    .box h2 {
      font-size: 24px;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: var(--blizzard);
    font-size: 36px;
  }

  p {
    color: var(--technologicalGray);
    font-size: 18px;
  }
  .text-black-gray {
    color: var(--greyMineral);
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 24px;
    }
  }
`;

export const Parcel = styled.div`
  a {
    color: var(--deepGrey);
    font-size: 14px;
  }
  button {
    background-color: var(--deepGrey);
    border: none;
    border-radius: 20px;
    padding: 18.5px 0 !important;
    font-size: 18px !important;
    color: var(--ultraWhite);
    font-weight: bold;
    :hover {
      background-color: var(--deepGreyLighten);
      transition: 0.5s;
    }
  }

  label {
    cursor: pointer;
  }
  label h2,
  h6,
  span {
    color: var(--technologicalGray);
  }

  .form-check-label:hover {
    background-color: var(--midnightLighten);
    h2,
    h6 {
      color: var(--ultraWhite);
    }
  }

  img {
    width: 36px;
  }

  .check {
    width: 36px;
    height: 36px;
    border: solid 1px var(--closedGray);
    border-radius: 25px;
  }

  .bg-check {
    background-image: url(${check});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .bg {
    background-color: var(--midnight);
    border: solid 1px var(--closedGray);
    border-radius: 25px;
  }

  .active {
    background-color: var(--closedGray);
    h2,
    h6 {
      color: var(--ultraWhite);
    }
    :hover {
      background-color: var(--closedGray);
    }
  }

  .info-save {
    background-color: var(--midnight);
    border: solid 1px var(--closedGray);
    border-radius: 40px;
  }

  @media screen and (min-width: 450px) {
    .d-450-flex {
      display: flex;
    }
  }

  @media screen and (max-width: 450px) {
    .mg-400 {
      margin-bottom: 1.25rem !important;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      padding: 9px 0 !important;
      border-radius: 15px !important;
    }
    .check {
      width: 25px;
      height: 25px;
    }

    .info-save {
      font-size: 14px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: var(--asphaltGray) !important;
  }

  label {
    color: var(--grayImmensity);
    font-weight: 300;
    font-size: 14px;
  }

  .btn-decline {
    background-color: var(--asphaltGray);
    color: var(--ultraWhite);
    border: none;
    text-decoration: none;
    border-radius: 15px;
    padding: 16px;
    :hover {
      background-color: var(--asphaltGrayDarken);
      transition: 0.3s;
    }
  }

  .buy-button {
    border-radius: 15px !important;
    font-weight: 700;
    span {
      color: var(--ultraWhite) !important;
      padding: 8px;
    }

    .info-alert {
      font-size: 14px !important;
    }
  }

  .bg-img {
    background-color: var(--closedGray);
    border-radius: 14px;
  }

  [aria-label] {
    position: relative;
  }

  [aria-label]::after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: -10px;
    left: 40px;
    z-index: 5000;
    pointer-events: none;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 12px;
    color: var(--technologicalGray);
    background-color: var(--asphaltGray);
    border-radius: 6px;
    filter: drop-shadow(10px 5px 12px var(--midnightOpacity));
  }

  [aria-label]:hover::after {
    display: block;
  }

  .card-terms {
    background-color: var(--asphaltGray);
    border-radius: 22px;
  }

  .height {
    height: 420px;
    p {
      color: var(--graniteGray) !important;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 1.25rem;
    }

    ::-webkit-scrollbar {
      background-color: var(--asphaltGray);
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: var(--deepGrey) !important;
    }
  }

  @media screen and (max-width: 991px) {
    .buy-button .info-alert {
      font-size: 11px !important;
    }
  }

  @media screen and (max-width: 450px) {
    input {
      width: 100%;
    }
    label {
      text-align: center;
      width: 100%;
    }
    .height {
      height: 300px;
      p {
        font-size: 16px;
        margin-bottom: 1rem;
      }
    }
    .payment-color {
      text-align: center;
    }
  }
`;

export const Skeleton = styled.div`
  pointer-events: none;

  .skeleton {
    background-color: var(--closedGray) !important;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    border: none !important;
    color: transparent !important;
    pointer-events: none;
    display: inline-block;
    box-shadow: none;

    span,
    input {
      color: transparent !important;
    }
    img {
      display: none;
    }

    button {
      display: none;
    }

    :after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        var(--closedGray) 0%,
        var(--skeletonTransparent) 0%,
        var(--skeletonTransparent) 10%,
        var(--skeletonQuarter) 20%,
        var(--skeletonMiddle) 40%,
        var(--skeletonFull) 50%,
        var(--skeletonQuarter) 80%,
        var(--skeletonTransparent) 90%,
        var(--skeletonTransparent) 100%
      ) !important;
      animation: glow 1.3s linear infinite;
    }

    @keyframes glow {
      100% {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .height {
      max-height: 132px;
    }
  }
`;
