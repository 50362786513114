import React, { FC, useEffect } from 'react';
import { useAuth } from '~/hooks/Auth';

const SignOut: FC = () => {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <div />;
};

export default SignOut;
