import styled from 'styled-components';

interface TableContentProps {
  showHeader: boolean;
  rowIsClickable: boolean;
}

export const Search = styled.div`
  padding: 3px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: var(--coal);

  input {
    margin: 4px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: var(--coal);
  }
`;

export const TableContent = styled.div<TableContentProps>`
  border-radius: 10px;
  overflow: hidden;
  background-color: transparent;
  z-index: 0;

  .menu-table {
    z-index: 1000;
    top: 40px;
    left: 15px;
    right: 15px;

    .search {
      padding: 1px;
      border-radius: 100px;

      input {
        margin: 10px 0;
        padding: 0 10px;
        border: none;
        border-radius: 100px;
        background-color: var(--coal);
      }
    }

    .inputDate {
      border: 1px solid #4a4b4b;
      background-color: transparent;
      color: var(--grayImmensity) !important;
      padding: 7px 10px;
      border-radius: 100px;
      text-align: center;
    }
  }

  div.tableData {
    .rdt_TableHeader {
      display: ${(props) => (props.showHeader ? 'block' : 'none')};
    }

    div {
      background-color: transparent;
    }

    .rdt_TableRow {
      padding: 5px 0;
      cursor: ${(props) => (props.rowIsClickable ? 'pointer' : 'default')};
      transition-duration: 0.3s;

      div {
        transition-duration: 0.3s;
      }

      :hover {
        background-color: var(--coalDarken);

        div {
          background-color: var(--coalDarken);
        }
      }
    }
  }

  @media screen and (max-width: 1399px) {
    div.tableData {
      max-width: 1200px;
    }
  }
`;

export const Pagination = styled.div`
  p,
  button {
    color: var(--grayImmensity) !important;
  }

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: bold;

    :first-child,
    :last-child {
      width: auto;
      border-radius: 5px;
    }
  }

  button.active,
  button:hover {
    background: var(--mysteriousGrey) !important;
    color: var(--blizzard) !important;
  }
`;
