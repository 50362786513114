import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  > img {
    opacity: 0;
    width: 90%;
  }

  .ReactCreditCard__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    min-height: 160px;

    .ReactCreditCard__front {
      display: flex;
      flex-direction: column;

      .nfc {
        opacity: 0;
        align-self: flex-end;
        padding: 40px;
        transition: 0.3s;
      }

      .wiserr-gift {
        opacity: 0;
        display: flex;
        align-items: center;
        padding: 25px 20px;
        transition: 0.3s;

        div {
          display: block;
          height: 25px;
          width: 1px;
          background: #fff;
          margin: 0 10px;
        }

        p {
          margin-bottom: 0;
          color: #fff;
          font-size: 22px;
        }
      }
    }

    .ReactCreditCard__name {
      text-transform: capitalize;
      max-height: 50px;
    }

    .ReactCreditCard--giftcard {
      .ReactCreditCard__front {
        background: linear-gradient(180deg, #ec008c 0%, #fc6767 100%);

        .wiserr-gift {
          opacity: 1;
        }

        .nfc {
          opacity: 1;
          padding: 0 40px;
        }

        .spacer {
          height: 80px;
        }

        .ReactCreditCard__logo {
          opacity: 1;
          height: 50px;
          width: 50px;
          top: 10px;
        }

        .ReactCreditCard__display {
          opacity: 1;
          width: 100%;
          font-size: 21px;
          span {
            font-size: 14px;
            color: #fff;
          }
        }

        .ReactCreditCard__number {
          margin-bottom: 51px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .ReactCreditCard__container {
      .wiserr-gift {
        padding-bottom: 0 !important;
      }

      .ReactCreditCard__logo {
        width: 20%;
        margin-top: 5%;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .ReactCreditCard__container {
      left: -50%;
      transform: translateX(50%);

      .ReactCreditCard__logo {
        margin-top: 0;
      }

      .nfc {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 399px) {
    .ReactCreditCard__container {
      .ReactCreditCard__logo {
        margin-top: 10%;
      }

      .ReactCreditCard--giftcard {
        .ReactCreditCard__front {
          .ReactCreditCard__display {
            font-size: 18px;
          }

          .ReactCreditCard__logo {
            height: 30px;
            width: 30px;
          }
        }

        .wiserr-gift p {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 330px) {
    .ReactCreditCard__container {
      left: -51%;
    }
  }

  @media screen and (max-width: 325px) {
    .ReactCreditCard__container {
      left: -53.5%;
    }
  }
`;
